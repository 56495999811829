define("account-talentrh/pods/register/account/plan-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PlanItemComponent = (_dec = Ember.computed('args.plan'), _dec2 = Ember.computed('args.plan.{price,usersLimit}'), (_class = class PlanItemComponent extends _component.default {
    get planColor() {
      const plan = this.args.plan;
      if (plan.usersLimit <= 20) {
        return 'lime';
      } else if (plan.usersLimit <= 50) {
        return 'blue';
      } else {
        return 'purple';
      }
    }
    get pricePerUser() {
      return this.args.plan.price / this.args.plan.usersLimit;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "planColor", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "planColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pricePerUser", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pricePerUser"), _class.prototype)), _class));
  _exports.default = PlanItemComponent;
});