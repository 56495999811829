define("account-talentrh/pods/components/image-cropper/component", ["exports", "cropper-js"], function (_exports, _cropperJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    didInsertElement() {
      this.element.setAttribute('style', 'max-height:500px;');
      let img = this.element.firstElementChild;
      img.setAttribute('style', 'max-width:100%;');
      img.src = window.URL.createObjectURL(this.image);
      img.onload = () => {
        if (img.naturalWidth < 200 || img.naturalHeight < 200) {
          img.src = null;
          return this.swal.warning('As dimensões da imagem devem ser no mínimo 200x200', {
            title: 'A imagem é muito pequena'
          }).then(() => {
            if (this.onFail) {
              this.onFail();
            }
          });
        }
        new _cropperJs.default(img, {
          aspectRatio: 1,
          viewMode: 2,
          minCanvasHeight: 200,
          minContainerWidth: 466,
          minContainerHeight: 466,
          responsive: false,
          cropmove: e => {
            let cropped = e.target.cropper.getData();
            if (cropped.width < 200 || cropped.height < 200) {
              cropped.width = 200;
              cropped.height = 200;
              e.target.cropper.setData(cropped);
            }
          },
          crop: e => {
            e.target.cropper.getCroppedCanvas().toBlob(blob => {
              let blobFile = new File([blob], this.image.name, {
                type: this.image.type
              });
              Ember.set(this, 'image', blobFile);
            });
          }
        });
      };
    }
  });
  _exports.default = _default;
});