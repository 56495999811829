define("account-talentrh/pods/user/changepassword/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return {};
    },
    actions: {
      save(model) {
        let newPassword = model.newPassword;
        let confirmPassword = model.confirmPassword;
        if (newPassword !== confirmPassword) {
          this.swal.warning('As senhas informadas não coincidem');
          Ember.set(model, 'newPassword', null);
          Ember.set(model, 'confirmPassword', null);
          return;
        }
        this.ajax.post('auth/changePassword', {
          data: model
        }).then(() => {
          this.swal.fire({
            title: 'A sua senha foi alterada com sucesso.',
            position: 'middle',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          this.transitionTo('home');
        }).catch(err => {
          this.swal.fire('Não foi possível alterar a senha', err.payload, 'error');
        });
      }
    }
  });
  _exports.default = _default;
});