define("account-talentrh/models/benefit", ["exports", "@ember-data/model", "ember-cp-validations", "account-talentrh/config/environment"], function (_exports, _model, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    }),
    category: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário selecionar uma categoria'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    category: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    logoUrl: (0, _model.attr)('string'),
    onAllBranches: (0, _model.attr)('boolean'),
    onAllCities: (0, _model.attr)('boolean'),
    title: (0, _model.attr)('string'),
    deactivatedAt: (0, _model.attr)('date'),
    activatedAt: (0, _model.attr)('date'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    branches: (0, _model.hasMany)('branch', {
      inverse: 'benefits'
    }),
    cities: (0, _model.hasMany)('city', {
      inverse: 'benefits'
    }),
    benefitImages: (0, _model.hasMany)('benefit-image'),
    benefitVideos: (0, _model.hasMany)('benefit-video'),
    // Computed Properties
    logo: Ember.computed('logoUrl', function () {
      if (!this.logoUrl) {
        return '/images/benefit.png';
      }
      return `${_environment.default.apiBaseUrl}${this.logoUrl}`;
    })
  });
  _exports.default = _default;
});