define("account-talentrh/pods/registry/avd/skill/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistryAvdSkillController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('filterType.value', 'typeOptions'), _dec6 = Ember._action, (_class = class RegistryAvdSkillController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "filters", _descriptor, this);
      _initializerDefineProperty(this, "termFilter", _descriptor2, this);
      _initializerDefineProperty(this, "openFilter", _descriptor3, this);
      _initializerDefineProperty(this, "filterType", _descriptor4, this);
      _defineProperty(this, "typeOptions", Ember.A([{
        label: 'Todos',
        value: ''
      }, {
        label: 'Não informado',
        value: 'uninformed'
      }, {
        label: 'Soft',
        value: 'soft'
      }, {
        label: 'Hard',
        value: 'hard'
      }]));
    }
    get typeSelected() {
      let type;
      if (this.filterType && this.filterType.value) {
        type = this.filterType.value;
      }
      return this.typeOptions.findBy('value', type || '');
    }
    clearFilters() {
      this.filters = {};
      this.termFilter = null;
      this.filterType = {
        label: 'Todos',
        value: ''
      };
    }
    *search() {
      let filters = {};
      yield (0, _emberConcurrency.timeout)(300);
      if (this.termFilter) {
        filters.term = this.termFilter;
      }
      if (this.filterType && this.filterType.value) {
        filters.type = this.filterType.value;
      }
      this.filters = filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "termFilter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "openFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filterType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "typeSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "typeSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
  _exports.default = RegistryAvdSkillController;
});