define("account-talentrh/pods/registry/avd/skill/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryAvdSkillRoute extends Ember.Route {
    deactivate() {
      this.controller.clearFilters();
      this.controller.openFilter = false;
      this.filterType = {
        label: 'Todos',
        value: ''
      };
    }
  }
  _exports.default = RegistryAvdSkillRoute;
});