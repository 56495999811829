define("account-talentrh/pods/registry/general/access-profile/show/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/ZwWGxbX",
    "block": "{\"symbols\":[\"Card\",\"user\",\"Card\",\"Avatar\"],\"statements\":[[8,\"ui-card\",[[24,0,\"border-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"accessProfile\",\"users\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-xl-4 col-md-6 mb-2\"],[12],[2,\"\\n          \"],[8,\"ui-card\",[[24,0,\"shadow-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,3,[\"Body\"]],[[24,0,\"d-flex align-items-center\"],[16,\"title\",[32,2,[\"fullName\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,[32,4,[\"Img\"]],[[24,0,\"avatar-5\"],[16,\"alt\",[32,2,[\"fullName\"]]]],[[],[]],null],[2,\"\\n              \"]],\"parameters\":[4]}]]],[2,\"\\n\\n              \"],[10,\"div\"],[14,0,\"flex-fill text-truncate ml-2\"],[12],[2,\"\\n                \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold mb-0\"],[12],[1,[32,2,[\"fullName\"]]],[13],[2,\"\\n                \"],[10,\"small\"],[12],[1,[30,[36,0],[[32,2,[\"jobRole\",\"title\"]],\"-\"],null]],[13],[2,\"\\n              \"],[13],[2,\"\\n\\n              \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"registry.general.user.show\",[32,2,[\"id\"]]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[3]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/access-profile/show/users/template.hbs"
    }
  });
  _exports.default = _default;
});