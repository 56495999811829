define("account-talentrh/pods/registry/general/branch-group/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralBranchGroupEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = RegistryGeneralBranchGroupEditRoute;
});