define("account-talentrh/pods/registry/general/user/edit/index/professional-interest/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfessionalInterestComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed.alias('args.user'), _dec9 = Ember.computed.alias('args.professionalInterests'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ProfessionalInterestComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "openModal", _descriptor4, this);
      _initializerDefineProperty(this, "modalProfessionalInterest", _descriptor5, this);
      _initializerDefineProperty(this, "modalTitle", _descriptor6, this);
      _initializerDefineProperty(this, "characterCount", _descriptor7, this);
      _initializerDefineProperty(this, "user", _descriptor8, this);
      _initializerDefineProperty(this, "professionalInterests", _descriptor9, this);
    }
    get quantityCharacteres() {
      if (this.modalProfessionalInterest && this.modalProfessionalInterest.description) {
        this.characterCount = this.modalProfessionalInterest.description.length;
        return true;
      }
      ;
    }
    *save() {
      let professionalInterest = this.modalProfessionalInterest;
      let message = professionalInterest.isNew ? 'Interesse profissional cadastrado' : 'Interesse profissional atualizado';
      try {
        if (!professionalInterest.area) {
          return this.swal.warning('Informe a área do interesse profissional');
        }
        if (professionalInterest.isNew) {
          this.professionalInterests.addObject(professionalInterest);
        }
        yield professionalInterest.save();
        this.toast.success(message);
        this.openModal = false;
      } catch (e) {
        if (this.professionalInterests.includes(professionalInterest)) {
          this.professionalInterests.removeObject(professionalInterest);
        }
        this.swal.catch(e);
      }
    }
    countCharacteres() {
      let professionalInterest = this.modalProfessionalInterest;
      this.characterCount = professionalInterest.description.length;
    }
    closeModal() {
      if (!this.modalProfessionalInterest.isNew) {
        this.modalProfessionalInterest.rollbackAttributes();
      }
    }
    openEditModal(professionalInterest) {
      this.modalProfessionalInterest = professionalInterest;
      this.modalTitle = 'Editar interesse profissional';
      this.openModal = true;
    }
    openCreateModal() {
      this.modalProfessionalInterest = this.store.createRecord('user-professional-interest', {
        user: this.user
      });
      this.modalTitle = 'Incluir interesse profissional';
      this.openModal = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalProfessionalInterest", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "characterCount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "professionalInterests", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countCharacteres", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "countCharacteres"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openEditModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateModal"), _class.prototype)), _class));
  _exports.default = ProfessionalInterestComponent;
});