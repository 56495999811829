define("account-talentrh/pods/registry/general/user/edit/index/emergency-contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6SwPxX1K",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2 mb-3\"],[12],[2,\"Contato de Emergência\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Nome\"],[13],[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[32,1,[\"emergencyContactName\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Telefone\"],[13],[2,\"\\n        \"],[8,\"nela-input-mask\",[[24,0,\"form-control\"]],[[\"@unmasked\",\"@pattern\",\"@validateAs\"],[[32,1,[\"emergencyContactPhone\"]],\"(99) 99999-9999\",\"telephone\"]],null],[2,\"\\n        \"],[10,\"div\"],[14,1,\"telephone-is-not-valid\"],[14,0,\"text-danger\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/edit/index/emergency-contact/template.hbs"
    }
  });
  _exports.default = _default;
});