define("account-talentrh/pods/settings/emails/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.store.query('email-configuration', {}).then(data => data.get('firstObject'));
    }
  });
  _exports.default = _default;
});