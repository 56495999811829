define("account-talentrh/pods/configuration/avd/question/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g8gF9Cx3",
    "block": "{\"symbols\":[\"Page\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Perguntas\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Início\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"registry.avd\"]],[[\"default\"],[{\"statements\":[[2,\"Cadastros Gestão de Desempenho\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"configuration.avd.question\"]],[[\"default\"],[{\"statements\":[[2,\"Perguntas\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/avd/question/template.hbs"
    }
  });
  _exports.default = _default;
});