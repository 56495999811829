define("account-talentrh/pods/registry/general/user/show/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      let user = this.modelFor('registry.general.user.show');
      return Ember.RSVP.hash({
        user,
        experiences: this.store.query('user-experience', {
          user: user.id
        }).then(r => r.toArray()),
        formations: this.store.query('user-formation', {
          user: user.id
        }).then(r => r.toArray()),
        professiontalInterests: this.store.query('user-professional-interest', {
          user: user.id
        }).then(r => r.toArray()),
        userBooks: this.store.query('user-book', {
          user: user.id
        }).then(r => r.toArray())
      });
    }
  });
  _exports.default = _default;
});