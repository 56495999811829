define("account-talentrh/pods/super-user/indicators/avds-by-company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vubkNMFi",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm min-h-vh-7\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Total de Avaliações por empresa\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"indicator-block-h-415\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"pb-3 mb-4 text-center border-bottom\"],[12],[2,\"\\n          \"],[10,\"h6\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fas fa-clipboard-list-check\"],[12],[13],[2,\"\\n            Empresa com mais avaliações\\n          \"],[13],[2,\"\\n\\n          \"],[10,\"h5\"],[14,0,\"mb-2 text-primary\"],[12],[2,\"\\n            \"],[1,[35,0,[\"chartData\",\"labels\",\"firstObject\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-lg-6 col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"indicator-pie-chart\"],[12],[2,\"\\n          \"],[10,\"canvas\"],[14,1,\"chart-avds-by-company\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-lg-6 col-md-12\"],[12],[2,\"\\n        \"],[10,\"ul\"],[14,1,\"chart-avds-by-company-legend\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,1,[\"loaded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner position-absolute centralize\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"data\",\"indicator\",\"unless\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/indicators/avds-by-company/template.hbs"
    }
  });
  _exports.default = _default;
});