define("account-talentrh/pods/registry/general/user/show/index/contact/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    userCellphone: Ember.computed('user.cellphone', function () {
      if (!this.user.cellphone) {
        return '';
      }
      return this.user.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }),
    userPhone: Ember.computed('user.phone', function () {
      if (!this.user.phone) {
        return '';
      }
      return this.user.phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    })
  });
  _exports.default = _default;
});