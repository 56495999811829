define("account-talentrh/pods/registry/general/job-role/form/add-function/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5J5QOArF",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"option\",\"@open\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@toggle\",\"@onClose\"],[[32,4],[30,[36,1],[[30,[36,0],[[32,4]],null]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"functionSelected\"]]],null],null],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Adicionar Função\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[8,\"nela-select\",[],[[\"@selected\",\"@modelName\",\"@placeholder\"],[[32,0,[\"functionSelected\"]],\"jobFunction\",\"Selecione uma função\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,3,[\"title\"]]],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,2],[[32,0,[\"addRecord\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/job-role/form/add-function/template.hbs"
    }
  });
  _exports.default = _default;
});