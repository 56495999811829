define("account-talentrh/pods/registry/avd/evaluation/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    filters: Ember.computed('tabFilter', 'reloadFilter', function () {
      let query = {};
      if (this.tabFilter === 'pending') {
        query.concluded = 0;
      } else if (this.tabFilter === 'concluded') {
        query.concluded = 1;
      }
      if (this.userFilter && this.userFilter.get('id')) {
        query.appraisedUser = this.userFilter.get('id');
      }
      if (this.startDateFilter && this.endDateFilter) {
        query.createdAt = {
          '>=': (0, _moment.default)(this.startDateFilter).format('YYYY-MM-DD 00:00:00'),
          '<=': (0, _moment.default)(this.endDateFilter).format('YYYY-MM-DD 23:59:59')
        };
      }
      return query;
    }),
    actions: {
      filter() {
        Ember.set(this, 'reloadFilter', new Date());
      },
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          statusFilter: 'all',
          startDateFilter: null,
          endDateFilter: null
        });
        this.send('filter');
      }
    }
  });
  _exports.default = _default;
});