define("account-talentrh/pods/registry/general/branch/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AEmz4SDZ",
    "block": "{\"symbols\":[\"@branch\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex bd-highlight mb-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mr-auto p-2 bd-highlight\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \\n  \"],[10,\"div\"],[14,0,\"p-2 bd-highlight\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[14,5,\"color: black;\"],[12],[1,[32,1,[\"city\",\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/branch/item/template.hbs"
    }
  });
  _exports.default = _default;
});