define("account-talentrh/pods/forgot-password/send-to/controller", ["exports", "ember-concurrency-decorators", "account-talentrh/config/environment", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ForgotPasswordSendToController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model.infos'), _dec4 = Ember.computed.alias('model.from'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = class ForgotPasswordSendToController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "recoverPasswordInfos", _descriptor3, this);
      _initializerDefineProperty(this, "from", _descriptor4, this);
      _initializerDefineProperty(this, "userId", _descriptor5, this);
      _initializerDefineProperty(this, "optionSelected", _descriptor6, this);
      _initializerDefineProperty(this, "typeSelected", _descriptor7, this);
    }
    routeToGoOnSuccess() {
      let link = null;
      if (this.from === 'social') {
        link = _environment.default.appUrl.social;
      }
      if (this.from === 'agenda') {
        link = _environment.default.appUrl.agenda;
      }
      if (this.from === 'gestao_desempenho') {
        link = _environment.default.appUrl.avd;
      }
      if (this.from === 'conta') {
        link = _environment.default.appUrl.conta;
      }
      if (this.from === 'fornecedor') {
        link = _environment.default.appUrl.fornecedor;
      }
      if (this.from === 'pesquisa') {
        link = _environment.default.appUrl.pesquisa;
      }
      if (this.from === 'landingPage') {
        link = _environment.default.appUrl.landingPage;
      }
      if (this.from === 'projetos') {
        link = _environment.default.appUrl.projetos;
      }
      if (this.from === 'ted') {
        link = _environment.default.appUrl.ted;
      }
      if (this.from === 'treinamento') {
        link = _environment.default.appUrl.treinamento;
      }
      if (link) {
        window.location.href = link;
      } else {
        this.transitionToRoute('login');
      }
    }
    *sendRequest() {
      try {
        if (!this.optionSelected) {
          return this.swal.warning('Informe uma opção para recuperar a senha!');
        }
        const tokenURL = this.recoverPasswordInfos.filter(r => r.tokenURL).map(r => r.tokenURL)[0];
        this.swal.loading();
        let {
          message
        } = yield this.ajax.post('requestPasswordReset', {
          data: {
            userId: this.userId,
            type: this.typeSelected,
            value: this.optionSelected,
            oldTokenURL: tokenURL
          }
        });
        yield this.swal.success(message);
        yield (0, _emberConcurrency.timeout)(200);
        this.routeToGoOnSuccess();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *cancelRecovery() {
      this.transitionToRoute('login');
    }
    onOptionSelected(option, type, userId) {
      this.optionSelected = option;
      this.typeSelected = type;
      this.userId = userId;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recoverPasswordInfos", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "from", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "optionSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "typeSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendRequest", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendRequest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRecovery", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRecovery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOptionSelected", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onOptionSelected"), _class.prototype)), _class));
  _exports.default = ForgotPasswordSendToController;
});