define("account-talentrh/pods/registry/general/sector/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vju44rqM",
    "block": "{\"symbols\":[\"View\",\"Section\",\"Section\",\"@sector\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"Header\"]],[[24,0,\"font-weight-bold\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Setor: \"],[1,[32,4,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"Body\"]],[[24,0,\"pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Nome do setor\"],[13],[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@maxlength\",\"@value\"],[50,[32,4,[\"name\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Footer\"]],[[24,0,\"text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,4,[\"isNew\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"]],[[\"@route\"],[\"registry.general.sector.index\"]],[[\"default\"],[{\"statements\":[[2,\"Cancelar\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"]],[[\"@route\",\"@model\"],[\"registry.general.sector.details\",[32,4,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Cancelar\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"save\"]]],null]],[12],[2,\"Salvar\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/sector/form/template.hbs"
    }
  });
  _exports.default = _default;
});