define("account-talentrh/pods/components/settings/details/password-requirements/component", ["exports", "@glimmer/component", "account-talentrh/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PasswordRequirementsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('permission.companyPassProp'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class PasswordRequirementsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "companyPassProp", _descriptor6, this);
      _defineProperty(this, "settingsManager", [{
        label: 'Quantidade de tentativas máximas de digitações erradas',
        input: 'Número de tentativas',
        utilize: 'passwordAttempt',
        description: 'Número de tentativas de digitação de senha incorreta antes do bloqueio do usuário'
      }, {
        label: 'Quantidade de meses para expiração de senha',
        input: 'Número de meses',
        utilize: 'passwordLoginExpirationMonths',
        description: 'Número de meses para expiração da senha do usuário'
      }, {
        label: 'Quantidade máxima de reuso de senhas',
        input: 'Número de reusos',
        utilize: 'passwordLastUsed',
        description: 'Número de reuso de senhas anteriores'
      }]);
      _defineProperty(this, "subSettings", [{
        label: 'Tamanhos mínimo e máximo da senha',
        range: true,
        min: 'passwordLengthMin',
        max: 'passwordLengthMax',
        description: 'Tamanho mínimo e máximo da senha'
      }, {
        label: 'Quantidade máxima de caracteres repetidos',
        utilize: 'passwordMaxRepeatChar',
        description: 'Quantidade máxima de caracteres repetidos na senha'
      }, {
        label: 'Quantidade máxima de caracteres em sequência',
        utilize: 'passwordMaxSequence',
        description: 'Quantidade máxima de caracteres repetidos em sequência'
      }, {
        label: 'Quantidade de letras maiúsculas',
        utilize: 'passwordUpperQtd',
        description: 'Quantidade de letras maiúsculas na senha'
      }, {
        label: 'Quantidade de números',
        utilize: 'passwordNumbersQtd',
        description: 'Quantidade de números na senha'
      }, {
        label: 'Quantidade de caracteres especiais',
        utilize: 'passwordSymbolQtd',
        description: 'Quantidade de caracteres especiais na senha'
      }, {
        label: 'Caracteres especiais permitidos',
        string: true,
        utilize: 'passwordSymbolAllowed',
        description: 'Caracteres especiais permitidos na senha'
      }]);
    }
    save() {
      let requiredMessage;
      if (!this.companyPassProp.passwordAttempt) {
        requiredMessage = 'Informe a quantidade de tentativas máximas de digitações erradas';
      } else if (!this.companyPassProp.passwordLoginExpirationMonths) {
        requiredMessage = 'Informe a quantidade de meses para expiração de senha';
      }
      if (!this.companyPassProp.passwordLastUsed) {
        requiredMessage = 'Informe o número de reuso de senhas anteriores';
      } else if (!this.companyPassProp.passwordLengthMin || !this.companyPassProp.passwordLengthMax) {
        requiredMessage = 'Informe o tamanho mínimo e máximo da senha';
      }
      if (this.companyPassProp.passwordLengthMin > this.companyPassProp.passwordLengthMax) {
        requiredMessage = 'O tamanho mínimo da senha não pode ser maior que o tamanho máximo';
      }
      if (!this.companyPassProp.passwordUpperQtd) {
        requiredMessage = 'Informe a quantidade de letras maiúsculas na senha';
      } else if (!this.companyPassProp.passwordNumbersQtd) {
        requiredMessage = 'Informe a quantidade de números na senha';
      }
      if (requiredMessage) {
        return this.swal.warning(requiredMessage);
      }
      this.companyPassProp.save().then(() => {
        this.toast.success('Configuração salva com sucesso');
      }).catch(e => {
        this.swal.catch(e);
      });
    }
    closeModal() {
      this.companyPassProp.rollbackAttributes();
    }
    reset() {
      this.swal.confirm('Deseja realmente restaurar as configurações de senha?').then(_ref => {
        let {
          value
        } = _ref;
        if (!value) {
          return;
        }
        this.fetch.post(`company-pass-prop/reset/${this.companyPassProp.id}`, {
          host: _environment.default.apiUrl.conta
        }).then(async () => {
          const updatedCompanyPassProp = await this.store.loadRecord('company-pass-prop', 'companyPassProps/me');
          this.companyPassProp = updatedCompanyPassProp;
          this.toast.success('Configurações restauradas com sucesso');
        }).catch(e => {
          this.swal.catch(e);
        });
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyPassProp", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class));
  _exports.default = PasswordRequirementsComponent;
});