define("account-talentrh/pods/setup-wizard/steps/groups/group/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o4EeYiSv",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"Habilitar a criação de grupos?\"],[13],[2,\"\\n\\n\"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,1,[\"permitGroups\"]],[30,[36,1],[[30,[36,0],[[32,1,[\"permitGroups\"]]],null]],null]]],null],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n  Garanta privacidade para assuntos específicos dentro dos grupos. Eles servem \\n  para estabelecer conexões no chat e no feed do Voors. Além da troca de mensagens, \\n  os grupos poderão ser criados segmentando o público-alvo para compartilhamento \\n  de informações e documentos de interesses em comum.\\n\"],[13],[2,\"\\n\\n\"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-4\"],[14,\"src\",\"https://player.vimeo.com/video/756948307?h=5b4d7e0d95\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  Se necessário, o grupo poderá ter mais de um administrador e é possível \\n  configurar para que somente os administradores enviem mensagens.\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"permitGroups\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social mt-3\"]],[[\"@route\"],[\"setup-wizard.steps.groups.group.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cadastrar grupos\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/groups/group/index/template.hbs"
    }
  });
  _exports.default = _default;
});