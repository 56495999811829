define("account-talentrh/pods/registry/social/company-program/show/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ps9vj7vB",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\",\"attachments\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-md-3 p-0\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card m-2 border uploaded-file uploaded-file__file\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"card-body py-2 text-center\"],[12],[2,\"\\n            \"],[10,\"i\"],[15,0,[31,[\"fa fa-lg fa-2x my-2 \",[30,[36,0],[[32,1,[\"extension\"]]],null]]]],[12],[13],[2,\"\\n            \"],[10,\"h6\"],[14,0,\"text-truncate my-2\"],[12],[2,\"\\n              \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-12 text-center font-italic\"],[12],[2,\"\\n        Nenhum anexo encontrado\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type-icon\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/social/company-program/show/attachments/template.hbs"
    }
  });
  _exports.default = _default;
});