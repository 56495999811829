define("account-talentrh/pods/registry/general/institution/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DOoPyZET",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-university\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Instituição\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Gerenciamento de instituições\"],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary mt-5\"]],[[\"@route\"],[\"registry.general.institution.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n      Nova instituição\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"didImportInstitutionBase\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-primary d-block mx-auto  mt-3\"],[15,\"onclick\",[30,[36,1],[[35,0]],null]],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-upload\"],[12],[13],[2,\"\\n        Importar instituições\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"importInstitutionBase\",\"perform\",\"accountConfig\",\"unless\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/institution/index/template.hbs"
    }
  });
  _exports.default = _default;
});