define("account-talentrh/pods/setup-wizard/steps/company/programs/manage/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsCompanyProgramsManageNewRoute extends Ember.Route {
    model() {
      return this.store.createRecord('company-program');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = SetupWizardStepsCompanyProgramsManageNewRoute;
});