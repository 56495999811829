define("account-talentrh/models/user-meta", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    controllership: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    user: (0, _model.attr)('string')
  });
  _exports.default = _default;
});