define("account-talentrh/pods/super-user/companies/show/habitus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jdK+VhkU",
    "block": "{\"symbols\":[\"assitant\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"insightOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-checkbox\",[[24,0,\"d-block\"]],[[\"@checked\",\"@disabled\"],[[30,[36,0],[[32,0,[\"companyConfig\",\"insightFlags\"]],[32,1,[\"value\"]]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/companies/show/habitus/template.hbs"
    }
  });
  _exports.default = _default;
});