define("account-talentrh/pods/registry/general/branch/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    permission: Ember.inject.service(),
    // Single line computed properties
    branch: Ember.computed.alias('model'),
    accessAVD: Ember.computed.alias('permission.toAccessAVD'),
    //TODO: Transformar em propriedades do model.
    // Multiline computed properties
    address: Ember.computed('branch.{address,complement,number}', function () {
      let address = this.branch.address;
      if (!Ember.isEmpty(this.branch.number)) {
        address = `${address}, ${this.branch.number}`;
      }
      if (!Ember.isEmpty(this.branch.complement)) {
        address = `${address}, ${this.branch.complement}`;
      }
      return address;
    }),
    cepMasked: Ember.computed('branch.zipcode', function () {
      let cep = this.branch.zipcode;
      if (!cep) {
        return cep;
      }
      return `${cep.substring(0, 2)}.${cep.substring(2, 5)}.${cep.substring(5)}`;
    }),
    cnpjMasked: Ember.computed('branch.cnpj', function () {
      let cnpj = this.branch.cnpj;
      if (cnpj.length < 12) {
        return `${cnpj.substring(0, 3)}.${cnpj.substring(3, 6)}.${cnpj.substring(6, 9)}-${cnpj.substring(9, 11)}`;
      } else {
        return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}`;
      }
    }),
    phoneMasked: Ember.computed('branch.phone', function () {
      if (!this.branch.phone) {
        return '';
      }
      return this.branch.phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }),
    // Tasks
    remove: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm(`Confirmar exclusão da filial ${this.branch.companyName}?`, {
          text: 'Todos os registros associados serão removidos'
        });
        if (!value) {
          return;
        }
        yield this.branch.destroyRecord();
        this.transitionToRoute('registry.general.branch');
        this.toast.success('Filial removida');
      } catch (e) {
        if (e.errors[0]) {
          let msg = e.errors[0].detail.replaceAll(',', ', ');
          this.swal.catch(`${msg}.`);
        } else {
          this.swal.catch('Não foi possível excluir a filial.');
        }
      }
    }).drop()
  });
  _exports.default = _default;
});