define("account-talentrh/pods/super-user/wizard-status/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ftMU6QHm",
    "block": "{\"symbols\":[\"@wizardStatus\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-fill\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"status\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,0,[31,[\"badge float-right \",[32,0,[\"statusClass\"]]]]],[12],[1,[32,0,[\"status\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"company\",\"tradingName\"]]],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate\"],[12],[1,[32,1,[\"company\",\"name\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[1,[32,1,[\"company\",\"city\",\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/item/template.hbs"
    }
  });
  _exports.default = _default;
});