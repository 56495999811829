define("account-talentrh/pods/registry/general/branch-group/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v77oG7mu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-building\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Grupos de Filial\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Gerenciamento de grupos de filial\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"registry.general.branch-group.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n        Incluir grupo\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/branch-group/index/template.hbs"
    }
  });
  _exports.default = _default;
});