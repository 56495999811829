define("account-talentrh/pods/registry/general/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DKYG84Ly",
    "block": "{\"symbols\":[\"Page\",\"link\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Cadastros Gerais\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"Breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,4,[\"Link\"]],[],[[\"@link\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Início\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,4,[\"Link\"]],[],[[\"@link\"],[\"registry.general.index\"]],[[\"default\"],[{\"statements\":[[2,\"Cadastros Gerais\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[4]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"links\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"col-xl-3 col-lg-4 mb-4\"],[12],[2,\"\\n            \"],[8,\"card-menu\",[[16,\"title\",[32,2,[\"label\"]]]],[[\"@route\",\"@icon\"],[[32,2,[\"route\"]],\"fa-file-edit\"]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,2,[\"label\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/index/template.hbs"
    }
  });
  _exports.default = _default;
});