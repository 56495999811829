define("account-talentrh/pods/registry/general/access-profile/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y57reG+S",
    "block": "{\"symbols\":[\"@accessProfile\"],\"statements\":[[6,[37,0],[[32,1,[\"defaultProfile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"badge badge-primary float-right\"],[12],[2,\"Perfil padrão\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/access-profile/item/template.hbs"
    }
  });
  _exports.default = _default;
});