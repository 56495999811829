define("account-talentrh/pods/lgpd/delete/user/show/delete-counts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kkbFLwtg",
    "block": "{\"symbols\":[\"deleteCount\",\"Card\",\"@deleteCounts\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-xl-4 col-md-6 mb-2\"],[12],[2,\"\\n       \"],[8,\"ui-card\",[[24,0,\"shadow-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,2,[\"Body\"]],[[24,0,\"d-flex align-items-center\"],[16,\"title\",[31,[[32,1,[\"label\"]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex-fill text-truncate ml-2\"],[12],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold mb-0\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n              \"],[10,\"h6\"],[12],[2,\"Quantidade: \"],[1,[30,[36,0],[[32,1,[\"count\"]],0],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/lgpd/delete/user/show/delete-counts/template.hbs"
    }
  });
  _exports.default = _default;
});