define("account-talentrh/pods/registry/social/mural/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CwCXOk3w",
    "block": "{\"symbols\":[\"Card\",\"@dashboard\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"text-primary\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[14,0,\"m-0 dashboard-title\"],[12],[1,[32,2,[\"title\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,0,\"control-label\"],[12],[2,\"Título\"],[13],[2,\"\\n          \"],[8,\"input\",[[24,\"placeholder\",\"Título\"],[24,0,\"form-control\"]],[[\"@value\",\"@maxlength\"],[[32,2,[\"title\"]],100]],null],[2,\"\\n          \"],[10,\"small\"],[14,0,\"float-right mr-2\"],[12],[1,[30,[36,0],[[32,2,[\"title\",\"length\"]],[32,2,[\"title\",\"length\"]],0],null]],[2,\"/100\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[8,\"ui-checkbox\",[],[[\"@checked\"],[[32,2,[\"active\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Ativo\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"text-right mt-3\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"]],[[\"@route\"],[\"registry.social.mural.index\"]],[[\"default\"],[{\"statements\":[[2,\"Cancelar\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"save\"]]],null]],[12],[2,\"Salvar\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/social/mural/form/template.hbs"
    }
  });
  _exports.default = _default;
});