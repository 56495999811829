define("account-talentrh/pods/registry/avd/evaluation/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    canCancel: false,
    evaluation: Ember.computed.alias('model'),
    showCancel: Ember.computed('canCancel', 'evaluation.{canceled,id}', function () {
      return !this.evaluation.canceled && this.canCancel;
    }),
    showReopen: Ember.computed.alias('evaluation.concluded'),
    findPermission: (0, _emberConcurrency.task)(function* () {
      const {
        user
      } = this.session;
      const {
        accessProfile
      } = yield this.store.findRecord('user', user.id);
      const generalManager = Ember.get(accessProfile, 'generalManager');
      const isAdmin = Ember.get(accessProfile, 'admin');
      Ember.set(this, 'canCancel', generalManager || isAdmin);
    }).on('init'),
    cancelEvaluation: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar cancelamento da avaliação?', {
          inputPlaceholder: 'Motivo do cancelamento',
          input: 'text',
          inputAttributes: {
            maxLength: 500
          },
          text: 'Avaliações canceladas serão mostradas no histórico'
        });
        if (value === undefined) {
          return;
        }
        if (!value.trim().length) {
          return this.swal.warning('É necessário informar o motivo do cancelamento');
        }
        Ember.setProperties(this.evaluation, {
          canceled: true,
          cancellationReason: value
        });
        yield this.evaluation.save();
        this.toast.success('Avaliação cancelada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    enableExpiredEvaluation: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar liberação da avaliação para ser respondida?', {
          text: 'Esta avaliação foi bloqueada automaticamente pelo sistema, isso ocorre quando o prazo máximo para responder a avaliação chega ao fim.',
          confirmButtonText: 'Sim, liberar'
        });
        if (!value) {
          return;
        }
        Ember.set(this.evaluation, 'periodExpired', 2);
        yield this.evaluation.save();
        this.toast.success('Avaliação liberada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    reopenEvaluation: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar reabertura da avaliação?', {
          text: 'Esta avaliação poderá ser respondida novamente por todos os integrantes.',
          confirmButtonText: 'Sim, reabrir'
        });
        if (!value) {
          return;
        }
        let employeeEvaluation = yield this.fetch.put(`employeeEvaluations/${this.evaluation.id}/reopen`, {
          host: 'avd'
        });
        this.store.pushPayload(employeeEvaluation);
        this.toast.success('Avaliação foi reaberta');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});