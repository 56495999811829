define("account-talentrh/models/course", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha o título'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha a descrição'
    })
  });
  var _default = _model.default.extend(Validations, {
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    description: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company')
  });
  _exports.default = _default;
});