define("account-talentrh/pods/registry/general/sector/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ySlveNfZ",
    "block": "{\"symbols\":[\"View\",\"Section\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Header\"]],[[24,0,\"d-flex align-items-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h6\"],[14,0,\"flex-fill font-weight-bold mb-0\"],[12],[1,[32,0,[\"sector\",\"name\"]]],[13],[2,\"\\n\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-link\"],[24,\"title\",\"Editar\"]],[[\"@route\",\"@model\"],[\"registry.general.sector.edit\",[32,0,[\"sector\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-edit\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn-link text-danger\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"remove\"]]],null]],[14,\"title\",\"Remover\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n      \"],[10,\"li\"],[14,0,\"list-group-item py-4 text-truncate\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row d-flex justify-content-between\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-md-4 text-truncate\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"\\n              \"],[10,\"i\"],[14,0,\"fa fa-align-left text-primary mr-2\"],[12],[13],[2,\"\\n              Nome do setor:\\n            \"],[13],[2,\"\\n            \"],[1,[32,0,[\"sector\",\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/sector/details/template.hbs"
    }
  });
  _exports.default = _default;
});