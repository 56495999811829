define("account-talentrh/pods/configuration/nela/benefit/index/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "33wsMex3",
    "block": "{\"symbols\":[\"@benefit\",\"@removeBenefit\"],\"statements\":[[10,\"tr\"],[14,0,\"d-flex w-100\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"col-4\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"col-1 text-center\"],[12],[1,[30,[36,1],[[32,1,[\"active\"]],\"Ativo\",\"Inativo\"],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"col-2 text-center\"],[12],[1,[30,[36,2],[[32,1,[\"activatedAt\"]],\"L\"],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"col-2 text-center\"],[12],[1,[30,[36,1],[[32,1,[\"active\"]],\"Ainda em vigor\",[30,[36,2],[[32,1,[\"deactivatedAt\"]],\"L\"],null]],null]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"iCanManage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[14,0,\"col-3 text-center\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"],[24,\"title\",\"Editar\"]],[[\"@route\",\"@model\"],[\"configuration.nela.benefit.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn-danger\"],[15,\"onclick\",[30,[36,0],[[32,2],[32,1]],null]],[14,\"title\",\"Remover\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/nela/benefit/index/item/template.hbs"
    }
  });
  _exports.default = _default;
});