define("account-talentrh/pods/register/user/controller", ["exports", "account-talentrh/utils/validate-cpf", "account-talentrh/config/environment", "ember-cp-validations"], function (_exports, _validateCpf, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.user.firstName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.lastName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.email': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.cpf': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.password': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.confirmPassword': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });
  var _default = Ember.Controller.extend(Validations, {
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Custom methods
    fieldsInvalid() {
      let cpf = this.model.user.cpf;
      if (!(0, _validateCpf.default)(cpf)) {
        this.swal.fire('CPF Inválido', 'Confira o CPF e tente novamente.', 'error');
        return true;
      }
      return false;
    },
    checkoutValidations() {
      document.querySelectorAll('.form-group .validation').forEach(element => {
        element.textContent = '';
      });
      if (!this.validations.isValid) {
        this.validations.errors.forEach(error => {
          let dataAttr = error.attribute.replace('model.', '').replace('.', '_');
          let validationSpan = document.querySelector(`.form-group[data-validation="${dataAttr}"] .validation`);
          if (!validationSpan) {
            return;
          }
          if (error.type === 'presence') {
            validationSpan.textContent = '* É necessário preencher este campo.';
          }
        });
        this.toast.warning('Para continuar é necessário preencher todos os campos.');
        return;
      } else {
        return true;
      }
    },
    // Actions
    actions: {
      register() {
        let model = this.model;
        let isValid = this.checkoutValidations();
        if (!isValid) {
          return;
        }
        if (this.model.user.password !== this.model.user.confirmPassword) {
          return this.toast.warning('As senhas não coincidem');
        }
        let unmaskedCpf = Ember.get(model, 'user.cpf').replace(/[.-]/g, '');
        Ember.set(model, 'user.cpf', unmaskedCpf);
        this.swal.fire({
          title: "Aguarde",
          text: "Estamos criando a sua conta",
          imageUrl: "/images/bx_loader.gif",
          showCancelButton: false,
          showConfirmButton: false
        });
        this.ajax.post('/register/user', {
          data: model
        }).then(() => {
          this.swal.success('Seu cadastro foi efetuado com sucesso');
          this.transitionToRoute('login');
          window.location = `${_environment.default.appUrl.social}login`;
        }).catch(e => this.swal.catch(e));
      }
    }
  });
  _exports.default = _default;
});