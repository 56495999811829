define("account-talentrh/pods/registry/avd/type-answer/show/answer/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* () {
      if (this.alternative.validations.isInvalid) {
        return this.swal.validation(this.alternative.validations);
      }
      if (!this.alternative.order) {
        return this.swal.warning('É necessário informar uma ordem para a alternativa');
      }
      if (!this.alternative.percentage && !this.alternative.ignoreToCalculate) {
        return this.swal.warning('Informe um percentual');
      }
      if (this.alternative.ignoreToCalculate) {
        Ember.set(this, 'alternative.percentage', null);
      }
      if (this.alternative.percentage) {
        if (Number(this.alternative.percentage) < 0) {
          return this.swal.warning('Informe um percentual maior ou igual a 0');
        }
        Ember.set(this, 'alternative.percentage', this.alternative.percentage.replace(',', '.'));
      }
      let alternativesOrder = Ember.get(this, 'alternative.typeAnswer.answers').mapBy('order');
      alternativesOrder = alternativesOrder.filter(alt => {
        const order = alt ? alt.toString() : alt;
        return order === this.alternative.order;
      });
      if (alternativesOrder.length > 1) {
        return this.swal.warning('Essa ordem já foi cadastrada em outra alternativa');
      }
      let alternativesPercentage = Ember.get(this, 'alternative.typeAnswer.answers').mapBy('percentage');
      alternativesPercentage = alternativesPercentage.filter(alt => {
        return alt === this.alternative.percentage;
      });
      if (alternativesPercentage.length > 1) {
        return this.swal.warning('Existem alternativas com a mesma porcentagem');
      }
      let message = this.alternative.isNew ? 'Alternativa cadastrada' : 'Alternativa atualizada';
      try {
        yield this.alternative.save();
        this.swal.success(message);
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      closeModal() {
        this.router.transitionTo('registry.avd.type-answer.show');
      },
      checkedIgnore() {
        Ember.set(this, 'alternative.percentage', null);
        Ember.set(this, 'alternative.ignoreToCalculate', !this.alternative.ignoreToCalculate);
      },
      validateOrder() {
        const valueOrder = this.alternative.order;
        this.alternative.order = valueOrder.length >= 1 && valueOrder[0] == 0 ? '' : valueOrder;
      }
    }
  });
  _exports.default = _default;
});