define("account-talentrh/pods/registry/general/user/show/index/social-media/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/OXo+DuF",
    "block": "{\"symbols\":[\"socialMedia\",\"Card\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2\"],[12],[2,\"\\n    Redes Sociais\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"socialMedias\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-xl-4 col-md-6 mt-2\"],[12],[2,\"\\n          \"],[8,\"ui-card\",[[16,\"title\",[32,1,[\"label\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,2,[\"Body\"]],[[24,0,\"cursor-default\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,1,[\"icon\"]]],[2,\"\\n              \"],[1,[32,1,[\"url\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[2]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/index/social-media/template.hbs"
    }
  });
  _exports.default = _default;
});