define("account-talentrh/pods/registry/social/vacancy/show/job-roles/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialVacancyShowJobRolesRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.social.vacancy.show');
    }
  }
  _exports.default = RegistrySocialVacancyShowJobRolesRoute;
});