define("account-talentrh/pods/components/settings/details/webhook-token/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TANh2duj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Token de Integração\"],[13],[2,\"\\n  \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\",\"@type\",\"@disabled\"],[[32,0,[\"companyConfig\",\"webHookIntegrationToken\"]],\"text\",true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/details/webhook-token/template.hbs"
    }
  });
  _exports.default = _default;
});