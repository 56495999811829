define("account-talentrh/pods/components/indicator-block/short-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KeAE2b4v",
    "block": "{\"symbols\":[\"Card\",\"&attrs\",\"@color\",\"@total\",\"@icon\",\"@name\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[16,0,[31,[\"py-3 border-\",[32,3]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"text-right mb-0\"],[12],[1,[32,4]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"avatar-7 position-absolute t-4 border border-3 border-white bg-\",[32,3]]]],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"position-absolute text-white font-weight-bold centralize h4\"],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,5]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"mb-0 text-right\"],[12],[1,[32,6]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"display-2\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"position-absolute centralize opacity-1 fa \",[32,5],\" text-\",[32,3]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/indicator-block/short-info/template.hbs"
    }
  });
  _exports.default = _default;
});