define("account-talentrh/pods/subscription/modal-plan/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "THhzFFHG",
    "block": "{\"symbols\":[\"Modal\",\"@show\",\"@onClose\",\"@plan\",\"@userCount\"],\"statements\":[[8,\"ui-modal\",[[24,1,\"modal-plan\"],[24,\"data-backdrop\",\"static\"]],[[\"@show\",\"@onOpen\",\"@onClose\",\"@size\"],[[32,2],[32,0,[\"onOpen\"]],[32,3],\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"px-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-times fa-2x cursor-pointer float-right\"],[14,\"title\",\"Fechar\"],[15,\"onclick\",[32,0,[\"close\"]]],[12],[13],[2,\"\\n    \"],[8,\"subscription/plan/form-plan\",[],[[\"@plan\",\"@updateSlider\",\"@updateSubscription\",\"@userCount\"],[[32,4],[32,0,[\"updateSlider\"]],[30,[36,0],[[32,0,[\"updateSubscription\"]]],null],[32,5]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/subscription/modal-plan/template.hbs"
    }
  });
  _exports.default = _default;
});