define("account-talentrh/pods/components/form-version/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    toast: Ember.inject.service(),
    modules: Ember.String.w('portal-talentrh agenda-talentrh projetos-talentrh contas-talentrh avd-talentrh treinamento-talentrh pesquisa-talentrh'),
    didReceiveAttrs() {
      this._super(...arguments);
      if (Ember.get(this, 'model.isNew')) {
        Ember.set(this, 'model.changeLog', {
          fixes: '',
          improvements: '',
          features: ''
        });
      }
    },
    actions: {
      moduleSelected(module) {
        Ember.set(this, 'model.module', module);
      },
      save() {
        this.model.save().then(() => {
          this.toast.success('Registro salvo');
        });
      }
    }
  });
  _exports.default = _default;
});