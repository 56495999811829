define("account-talentrh/pods/setup-wizard/steps/collaboration/forum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S09HnhYk",
    "block": "{\"symbols\":[\"Card\",\"@model\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n        \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-8 py-4\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"Habilitar página de gestão do conhecimento?\"],[13],[2,\"\\n\\n        \"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,2,[\"permitForum\"]],[30,[36,1],[[30,[36,0],[[32,2,[\"permitForum\"]]],null]],null]]],null],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Permita que os usuários iniciem um debate aberto sobre temas diversos. \\n          A gestão de conhecimento é uma ferramenta que aproxima os usuários de diversos setores \\n          para debater publicamente assuntos relevantes à empresa e ainda criar um ambiente de aprendizagem.\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-3\"],[14,\"src\",\"https://player.vimeo.com/video/756948269?h=af74a61043\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n          Gestão do Conhecimento pode ser utilizado por qualquer usuário para o compartilhamento e discussão de ideias.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"setup-wizard/step-control\",[],[[\"@saveConfigOnChange\"],[true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/collaboration/forum/template.hbs"
    }
  });
  _exports.default = _default;
});