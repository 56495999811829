define("account-talentrh/pods/components/dashboard/video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f+xsF1kd",
    "block": "{\"symbols\":[\"@dashboardSlideItemVideo\",\"@dashboardSlideItem\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"display-grid h-100 ui-block board-block board-block--album board-height-large color-block--\",[32,2,[\"color\"]],\" text-white\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12 ui-block-title text-center font-weight-bold\"],[12],[2,\"\\n    \"],[1,[32,2,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12 text-center\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"video\"],[15,\"src\",[32,1,[\"url\"]]],[14,\"autoplay\",\"\"],[14,\"loop\",\"\"],[14,\"controls\",\"\"],[14,0,\"mx-auto w-100\"],[14,\"alt\",\"Author\"],[15,5,[32,0,[\"maxHeight\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/dashboard/video/template.hbs"
    }
  });
  _exports.default = _default;
});