define("account-talentrh/pods/registry/avd/type-answer/show/answer/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.store.createRecord('answer', {
        typeAnswer: this.modelFor('registry.avd.type-answer.show')
      });
    },
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });
  _exports.default = _default;
});