define("account-talentrh/pods/registry/general/job-function/show/professional-progressions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+KqdhnbU",
    "block": "{\"symbols\":[\"professionalProgression\"],\"statements\":[[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"jobFunction\",\"professionalProgressions\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item py-3\"],[12],[2,\"\\n      \"],[8,\"registry/general/job-function/form/professional-progressions/item\",[],[[\"@professionalProgression\",\"@details\"],[[32,1],true]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item text-center font-italic\"],[12],[2,\"\\n      Nenhuma progressão profissional relacionada\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/job-function/show/professional-progressions/template.hbs"
    }
  });
  _exports.default = _default;
});