define("account-talentrh/pods/super-user/post/show/publish/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('super-user.post.show');
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('companies', model.posts.map(p => p.company));
    },
    deactivate() {
      this.controller.set('sendToAll', false);
    }
  });
  _exports.default = _default;
});