define("account-talentrh/pods/registry/general/user/show/history/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('registry.general.user.show');
    }
  });
  _exports.default = _default;
});