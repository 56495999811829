define("account-talentrh/pods/registry/avd/team/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryAvdTeamShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.avd.team').filters = {};
      this.controllerFor('registry.avd.team').clearFilters();
    }
  }
  _exports.default = RegistryAvdTeamShowRoute;
});