define("account-talentrh/pods/registry/avd/evaluation/index/route", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel() {
      const allowed = this.permission.accessProfile.generalManager || this.permission.admin;
      if (!allowed) {
        this.swal.error('Você não tem autorização para ver esta página. Fale com um administrador para obter informações sobre as suas permissões de acesso.');
        let href = '';
        if (this.permission.companyConfig.hasSocialModule) {
          href = _environment.default.appUrl.social;
        } else if (this.permission.companyConfig.hasAVDModule) {
          href = _environment.default.appUrl.avd;
        } else if (this.permission.companyConfig.hasProjectManagerModule) {
          href = _environment.default.appUrl.projetos;
        } else if (this.permission.companyConfig.hasTEDModule) {
          href = _environment.default.appUrl.ted;
        } else if (this.permission.companyConfig.hasPesquisaModule) {
          href = _environment.default.appUrl.pesquisa;
        } else {
          href = _environment.default.appUrl.agenda;
        }
        document.location.href = href;
      }
    }
  });
  _exports.default = _default;
});