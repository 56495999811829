define("account-talentrh/pods/super-user/wizard-status/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vAZooNb/",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,1,[\"hasSubstep\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/show/index/template.hbs"
    }
  });
  _exports.default = _default;
});