define("account-talentrh/pods/registry/general/course/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TasDFJn1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-graduation-cap\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Cursos\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Gerenciamento de cursos\"],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary mt-5\"]],[[\"@route\"],[\"registry.general.course.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n      Incluir curso\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"accountConfiguration\",\"didImportCourseBase\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-primary d-block mx-auto mt-3\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"importCourseBase\"]]],null]],[14,\"title\",\"Importar cursos\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-upload\"],[12],[13],[2,\"\\n         Importar cursos\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/course/index/template.hbs"
    }
  });
  _exports.default = _default;
});