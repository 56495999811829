define("account-talentrh/pods/setup-wizard/steps/groups/group/manage/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsGroupsGroupManageNewRoute extends Ember.Route {
    model() {
      return this.store.createRecord('user-group');
    }
  }
  _exports.default = SetupWizardStepsGroupsGroupManageNewRoute;
});