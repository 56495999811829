define("account-talentrh/components/nela-select", ["exports", "nela-select/components/nela-select"], function (_exports, _nelaSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaSelect.default;
    }
  });
});