define("account-talentrh/pods/registry/avd/goal/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryAvdGoalEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.avd.goal').filters = {};
      this.controllerFor('registry.avd.goal').clearFilters();
    }
  }
  _exports.default = RegistryAvdGoalEditRoute;
});