define("account-talentrh/pods/subscription/payment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscriptionPaymentRoute extends Ember.Route {
    model() {
      return this.modelFor('subscription').details;
    }
    deactivate() {
      this.controller.data = {};
    }
  }
  _exports.default = SubscriptionPaymentRoute;
});