define("account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QetVuj0c",
    "block": "{\"symbols\":[\"@jobRole\",\"@remove\"],\"statements\":[[6,[37,2],[[32,0,[\"isEdit\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"setup-wizard/steps/registry/job-role/manage/form\",[],[[\"@jobRole\",\"@save\",\"@cancel\"],[[32,1],[30,[36,1],[[32,0,[\"save\"]],[32,1]],null],[30,[36,1],[[32,0,[\"cancel\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[14,0,\"text-right text-nowrap\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,1],[[30,[36,0],[[32,0,[\"isEdit\"]]],null],true],null]],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil-alt\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-danger py-0\"],[15,\"onclick\",[30,[36,1],[[32,2],[32,1]],null]],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/item/template.hbs"
    }
  });
  _exports.default = _default;
});