define("account-talentrh/pods/registry/avd/evaluation/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UOLiw5gc",
    "block": "{\"symbols\":[\"@evaluation\"],\"statements\":[[10,\"div\"],[14,0,\"w-75\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate mb-2\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Avaliação de:\"],[13],[2,\" \"],[1,[32,1,[\"appraisedUser\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate mb-0\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Tipo:\"],[13],[2,\" \"],[1,[32,1,[\"typeDescription\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"badge border border-primary text-primary mt-2\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ml-auto text-right\"],[12],[2,\"\\n  \"],[10,\"small\"],[14,0,\"d-block\"],[14,\"title\",\"Data de Criação\"],[12],[1,[30,[36,1],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n\\n  \"],[10,\"span\"],[15,0,[31,[\"badge \",[34,2,[\"style\"]],\" mt-2\"]]],[12],[1,[35,2,[\"text\"]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"reopened\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"badge badge-info mt-2\"],[14,\"title\",\"Essa avaliação foi reaberta pelo administrador\"],[12],[2,\"Reaberta\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"evaluationRole\",\"moment-format\",\"statusEvaluation\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/evaluation/item/template.hbs"
    }
  });
  _exports.default = _default;
});