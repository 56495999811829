define("account-talentrh/pods/lgpd/delete/company/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LgpdDeleteCompanyCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('lgpd-delete-company-solicitation');
    }
  }
  _exports.default = LgpdDeleteCompanyCreateRoute;
});