define("account-talentrh/models/user-message", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O título deve ser preenchido'
    }),
    startDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A data de início deve ser preenchida'
    }),
    endDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A data de término deve ser preenchida'
    }), (0, _emberCpValidations.validator)('date', {
      message: 'O término deve ser após o início',
      format: 'DD/MM/YYYY HH:mm',
      precision: 'minute',
      after: Ember.computed('model.startDate', function () {
        return (0, _moment.default)(this.model.startDate).format('DD/MM/YYYY HH:mm');
      })
    })]
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    notifyManager: (0, _model.attr)('boolean'),
    active: (0, _model.attr)('boolean'),
    contact: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('date'),
    startDate: (0, _model.attr)('date'),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    reason: (0, _model.belongsTo)('reason'),
    user: (0, _model.belongsTo)('user'),
    userContact: (0, _model.belongsTo)('user'),
    // Computed Properties
    conclusionDate: Ember.computed('endDate', function () {
      let mDate = (0, _moment.default)(this.endDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    conclusionTime: Ember.computed('endDate', function () {
      let mDate = (0, _moment.default)(this.endDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    }),
    initialDate: Ember.computed('startDate', function () {
      let mDate = (0, _moment.default)(this.startDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    initialTime: Ember.computed('startDate', function () {
      let mDate = (0, _moment.default)(this.startDate);
      if (mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    })
  });
  _exports.default = _default;
});