define("account-talentrh/pods/settings/avd/ninebox-settings/quadrant-scale-form/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mB6I7sw2",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"row\"],[4,[38,0],[[32,0,[\"initValues\"]],[32,0,[\"line\"]]],null],[4,[38,1],[[32,0,[\"initValues\"]],[32,0,[\"line\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-5 align-self-center\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[32,0,[\"column\",\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-7\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[2,\"Valor Inicial\"],[13],[2,\"\\n          \"],[8,\"nela-input-percentage\",[[24,0,\"form-control\"]],[[\"@unmasked\",\"@onUnmaskedChange\",\"@placeholder\"],[[32,0,[\"initialValue\"]],[32,0,[\"initialValueChange\"]],\"Valor inicial\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[2,\"Valor final\"],[13],[2,\"\\n          \"],[8,\"nela-input-percentage\",[[24,0,\"form-control\"]],[[\"@unmasked\",\"@onUnmaskedChange\",\"@placeholder\"],[[32,0,[\"finalValue\"]],[32,0,[\"finalValueChange\"]],\"Valor final\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/settings/avd/ninebox-settings/quadrant-scale-form/column/template.hbs"
    }
  });
  _exports.default = _default;
});