define("account-talentrh/pods/registry/general/user/edit/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SDsSMSn5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-card\",[[24,0,\"border-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"registry/general/user/edit/index/general\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/contact\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/emergency-contact\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/interest\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/social-media\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/experience\",[],[[\"@user\",\"@experiences\"],[[34,0,[\"user\"]],[34,0,[\"experiences\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/formation\",[],[[\"@user\",\"@formations\"],[[34,0,[\"user\"]],[34,0,[\"formations\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/professional-interest\",[],[[\"@user\",\"@professionalInterests\"],[[34,0,[\"user\"]],[34,0,[\"professionalInterests\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/edit/index/book\",[],[[\"@user\",\"@books\"],[[34,0,[\"user\"]],[34,0,[\"books\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/edit/index/template.hbs"
    }
  });
  _exports.default = _default;
});