define("account-talentrh/pods/setup-wizard/radio-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tCxRfG0y",
    "block": "{\"symbols\":[\"@selected\",\"@onChange\"],\"statements\":[[11,\"div\"],[24,0,\"mb-4 text-center\"],[4,[38,1],[[30,[36,0],[[32,0,[\"initProperty\"]]],null]],null],[12],[2,\"\\n  \"],[8,\"ui-radio\",[[24,0,\"mx-3\"]],[[\"@option\",\"@selected\",\"@onChange\"],[true,[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    SIM\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"ui-radio\",[[24,0,\"mx-3\"]],[[\"@option\",\"@selected\",\"@onChange\"],[false,[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    NÃO\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/radio-buttons/template.hbs"
    }
  });
  _exports.default = _default;
});