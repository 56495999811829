define("account-talentrh/pods/registry/general/job-role/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.computed.alias('args.jobRole.skills'), _dec6 = Ember.computed.alias('args.jobRole.groupAdmins'), _dec7 = Ember.computed.alias('permission.toAccessAVD'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "skills", _descriptor5, this);
      _initializerDefineProperty(this, "groupAdmins", _descriptor6, this);
      _initializerDefineProperty(this, "accessAVD", _descriptor7, this);
      _initializerDefineProperty(this, "hardSkills", _descriptor8, this);
      _initializerDefineProperty(this, "softSkills", _descriptor9, this);
      _initializerDefineProperty(this, "openAddFunction", _descriptor10, this);
      _initializerDefineProperty(this, "selectedAdmins", _descriptor11, this);
    }
    *removeJobFunction(jobFunction) {
      jobFunction.jobRole = null;
      try {
        yield jobFunction.save();
        this.toast.success('Função removida');
      } catch (err) {
        Ember.debug(err.message);
        this.swal.catch(err);
      }
    }
    clearInputSelect() {
      let {
        jobRole
      } = this.args;
      if (jobRole.goals.length) {
        this.args.jobRole.goals = [];
      }
    }
    *save() {
      const {
        jobRole
      } = this.args;
      jobRole.groupAdmins = this.selectedAdmins;
      if (!jobRole.validations.isValid) {
        return this.swal.validation(jobRole.validations);
      }
      const successMsg = jobRole.isNew ? 'Cargo cadastrado' : 'Cargo atualizado';
      const loadingMsg = jobRole.isNew ? 'Cadastrando cargo' : 'Atualizando cargo';
      try {
        jobRole.skills = [...this.hardSkills, ...this.softSkills];
        this.swal.loading(loadingMsg);
        yield jobRole.save();
        this.toast.success(successMsg);
        this.router.transitionTo('registry.general.job-role.show', jobRole.id);
      } catch (err) {
        Ember.debug(err.message);
        this.swal.catch(err);
      }
    }
    activateJobRole() {
      const {
        jobRole
      } = this.args;
      if (jobRole.active && jobRole.users.length) {
        return this.swal.warning('Não é possível inativar, pois contém colaboradores vinculados');
      }
      jobRole.active = !jobRole.active;
    }
    showJobRole() {
      const {
        jobRole
      } = this.args;
      jobRole.visible = !jobRole.visible;
    }
    toggleIsChatGroup() {
      const {
        jobRole
      } = this.args;
      jobRole.isChatGroup = !jobRole.isChatGroup;
      if (!jobRole.isChatGroup) {
        jobRole.confirmMessageBeforeSend = false;
        jobRole.onlyAdminSendMessages = false;
        this.selectedAdmins = [];
      }
    }
    toggleConfirmMessageBeforeSend() {
      const {
        jobRole
      } = this.args;
      jobRole.confirmMessageBeforeSend = !jobRole.confirmMessageBeforeSend;
    }
    toggleOnlyAdminSendMessages() {
      const {
        jobRole
      } = this.args;
      jobRole.onlyAdminSendMessages = !jobRole.onlyAdminSendMessages;
      if (!jobRole.onlyAdminSendMessages) {
        this.selectedAdmins = [];
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "groupAdmins", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "accessAVD", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hardSkills", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A(this.skills.filter(_ref => {
        let {
          type
        } = _ref;
        return type === 'hard';
      }));
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "softSkills", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A(this.skills.filter(_ref2 => {
        let {
          type
        } = _ref2;
        return type === 'soft';
      }));
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "openAddFunction", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedAdmins", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A(this.groupAdmins);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeJobFunction", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeJobFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInputSelect", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearInputSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activateJobRole", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "activateJobRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showJobRole", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "showJobRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsChatGroup", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsChatGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleConfirmMessageBeforeSend", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleConfirmMessageBeforeSend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleOnlyAdminSendMessages", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOnlyAdminSendMessages"), _class.prototype)), _class));
  _exports.default = FormComponent;
});