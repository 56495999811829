define("account-talentrh/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;
  function add(params /*, hash*/) {
    return params[0] + params[1];
  }
  var _default = Ember.Helper.helper(add);
  _exports.default = _default;
});