define("account-talentrh/pods/setup-wizard/steps/registry/job-role/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p1oNWFG/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n  O cargo é a posição que a pessoa ocupa dentro de uma organização e essa informação \\n  será apresentada para identificação dos colaboradores no Voors \\n  (por exemplo: Presidente, Auxiliar Administrativo e outros).\\n\"],[13],[2,\"\\n\\n\"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-4\"],[14,\"src\",\"https://player.vimeo.com/video/757138101?h=7a9abf418f\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  Além do cadastro manual é possível realizar a importação de dados em massa a partir de um modelo.\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wizard-step-control text-center mt-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social\"]],[[\"@route\"],[\"setup-wizard.steps.registry.job-role.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Cadastrar cargo\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/job-role/index/template.hbs"
    }
  });
  _exports.default = _default;
});