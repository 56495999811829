define("account-talentrh/pods/registry/general/institution/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    accountConfig: Ember.computed.alias('model'),
    importInstitutionBase: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Importar instituições', {
          text: 'Confirmar importação da base de dados?'
        });
        if (!value) {
          return;
        }
        yield this.ajax.request('institutions/importInstitutionBase');
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});