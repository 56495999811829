define("account-talentrh/pods/components/color-palette/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ColorPaletteComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "classNames", ['dropdown', 'float-right']);
      _defineProperty(this, "colorPalette", Ember.A([{
        value: 'blue'
      }, {
        value: 'warning'
      }, {
        value: 'green'
      }, {
        value: 'navy'
      }, {
        value: 'orange'
      }, {
        value: 'olive'
      }, {
        value: 'purple'
      }, {
        value: 'red'
      }, {
        value: 'dark'
      }]));
    }
  }
  _exports.default = ColorPaletteComponent;
});