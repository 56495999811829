define("account-talentrh/pods/registry/social/company-program/form-topic/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormTopicComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 5
  }), _dec14 = Ember._action, (_class = class FormTopicComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "utils", _descriptor6, this);
      _initializerDefineProperty(this, "companyProgramTopicImageUpload", _descriptor7, this);
      _initializerDefineProperty(this, "companyProgramTopicVideoUpload", _descriptor8, this);
      _initializerDefineProperty(this, "topicModal", _descriptor9, this);
      _initializerDefineProperty(this, "losers", _descriptor10, this);
      _initializerDefineProperty(this, "advancedEditorImages", _descriptor11, this);
      _initializerDefineProperty(this, "advancedEditorVideos", _descriptor12, this);
    }
    *uploadFile(file) {
      let {
        topic
      } = this.args;
      let filename = Ember.get(file, 'name');
      let extension = Ember.get(file, 'name').split('.').pop().toLowerCase();
      let companyProgramTopic = yield this.store.findRecord('company-program-topic', topic.id);
      if (Ember.isEmpty(file)) {
        return;
      }
      try {
        let response = yield this.ajax.request(`companyProgramTopicAttachments/${topic.id}/putSignedLink`);
        let attachment = yield this.store.createRecord('company-program-topic-attachment', {
          companyProgramTopic,
          name: filename,
          path: response.key,
          type: Ember.get(file, 'type'),
          extension
        });
        yield file.uploadBinary(response.link, {
          method: 'PUT'
        });
        yield attachment.save();
        this.toast.success('Arquivo enviado!');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *removeFile(attachment) {
      try {
        const {
          value
        } = yield this.swal.confirm('Deseja remover este arquivo?', {
          text: 'Esta ação não pode ser desfeita'
        });
        if (!value) return;
        yield attachment.destroyRecord();
        this.toast.success('Arquivo removido!');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *save() {
      try {
        let {
          topic,
          companyProgram,
          closeFormTopic
        } = this.args;
        let isNew = topic.isNew;
        let message = isNew ? 'Registro salvo!' : 'O tópico foi atualizado!';
        if (!topic.validations.isValid) {
          return this.swal.validation(topic.validations);
        }
        topic.companyProgram = companyProgram;
        let count = 0;
        let body = topic.body;
        const regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
        body = body.replace(regex, () => count++ + 'i');
        topic.body = body;
        topic = yield topic.save();
        if (this.advancedEditorVideos.length) {
          yield this.companyProgramTopicVideoUpload.save.perform(topic, this.advancedEditorVideos);
        }
        if (this.advancedEditorImages.length) {
          yield this.companyProgramTopicImageUpload.save.perform(topic, this.advancedEditorImages);
        }
        yield topic.save();
        this.toast.success(message);
        if (!isNew) {
          closeFormTopic();
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onCancel() {
      const {
        topic,
        closeFormTopic
      } = this.args;
      topic.rollbackAttributes();
      closeFormTopic();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "companyProgramTopicImageUpload", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "companyProgramTopicVideoUpload", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "topicModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "losers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "advancedEditorImages", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "advancedEditorVideos", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFile", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype)), _class));
  _exports.default = FormTopicComponent;
});