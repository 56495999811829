define("account-talentrh/pods/reports/list-lunch/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    fetch: Ember.inject.service(),
    swal: Ember.inject.service(),
    dataReport: null,
    departments: Ember.A(),
    listLunchDetailed: null,
    listLunchSummary: null,
    show: false,
    totalCost: 0,
    totalCostDiscount: 0,
    typeReport: 'detailed',
    users: Ember.A(),
    colSpanDynamic: 0,
    lastAction: false,
    actions: {
      showReport() {
        if (!this.startDate || !this.finalDate) {
          return this.swal.warning('Data inicial e final devem ser informadas');
        }
        let startDate = (0, _moment.default)(this.startDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm');
        let endDate = (0, _moment.default)(this.finalDate, "DD/MM/YYYY").add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm');
        if ((0, _moment.default)(startDate).isAfter(endDate)) {
          return this.swal.warning('A data inicial precisa ser igual ou anterior a data final.');
        }
        Ember.set(this, 'show', true);
        return this.ajax.request('userlunchsolicitations/listLunchReport?', {
          data: {
            endDate,
            startDate,
            typeReport: this.typeReport,
            users: this.users.mapBy('id'),
            departments: this.departments.mapBy('id')
          }
        }).then(record => {
          this.set('listLunchDetailed', record);
          this.set('colSpanDynamic', record.colSpanDynamic);
          let total = record.list.reduce((sum, user) => {
            return sum + user[0].costLunch;
          }, 0);
          Ember.set(this, 'totalCostDiscount', total);
          Ember.set(this, 'totalCost', total / record.infoLunch.discountPercentageLunch * 100);
        });
      },
      async printReport() {
        if (!this.startDate || !this.finalDate) {
          return this.swal.warning('Data inicial e final devem ser informadas');
        }
        let startDate = (0, _moment.default)(this.startDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm');
        let endDate = (0, _moment.default)(this.finalDate, "DD/MM/YYYY").add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm');
        if ((0, _moment.default)(startDate).isAfter(endDate)) {
          return this.swal.warning('A data inicial precisa ser igual ou anterior a data final.');
        }
        let queryObj = {
          endDate,
          startDate,
          typeReport: this.typeReport,
          users: this.users.mapBy('id'),
          departments: this.departments.mapBy('id'),
          print: true
        };
        this.fetch.file('userlunchsolicitations/listLunchReport', {
          data: queryObj
        }).then(pdf => {
          Ember.set(this, 'dataReport', pdf);
          Ember.set(this, 'openModal', true);
        }).catch(err => {
          Ember.set(this, 'loading', false);
          this.swal.fire('Ops!', err.textMessage, "error");
        });
      },
      verifyReport() {
        if (this.show) {
          this.send('showReport');
        }
      }
    }
  });
  _exports.default = _default;
});