define("account-talentrh/pods/super-user/wizard-status/show/company/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SuperUserWizardStatusShowCompanyRoute extends Ember.Route {
    model() {
      return this.modelFor('super-user.wizard-status.show');
    }
  }
  _exports.default = SuperUserWizardStatusShowCompanyRoute;
});