define("account-talentrh/pods/registry/avd/goal/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QiqEwvuf",
    "block": "{\"symbols\":[\"@goal\"],\"statements\":[[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\",\"@tagName\"],[\"registry.avd.goal.show\",[32,1,[\"id\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n  \"],[10,\"small\"],[12],[1,[32,0,[\"typeLabel\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/goal/item/template.hbs"
    }
  });
  _exports.default = _default;
});