define("account-talentrh/pods/registry/social/vacancy/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialVacancyCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('vacancy');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.social.vacancy').clearFilters();
    }
  }
  _exports.default = RegistrySocialVacancyCreateRoute;
});