define("account-talentrh/pods/subscription/plan/plans-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IOZsKAjG",
    "block": "{\"symbols\":[\"@update\"],\"statements\":[[11,\"div\"],[24,0,\"plan-slider w-100\"],[4,[38,1],[[30,[36,0],[[32,0,[\"getPlans\"]]],null]],null],[4,[38,2],[[32,0,[\"onUpdateElement\"]],[32,1]],null],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Usuários: \"],[10,\"b\"],[14,0,\"ml-1\"],[12],[1,[32,0,[\"selectedPlan\",\"usersLimit\"]]],[13],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Plano selecionado: \"],[10,\"b\"],[14,0,\"ml-1\"],[12],[1,[32,0,[\"selectedPlan\",\"name\"]]],[13],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Valor total: R$ \"],[10,\"b\"],[14,0,\"mx-1\"],[12],[1,[30,[36,3],[[32,0,[\"selectedPlan\",\"price\"]]],null]],[13],[2,\" mensal\"],[13],[2,\"\\n  \"],[8,\"ui-slider\",[],[[\"@range\",\"@step\",\"@connect\",\"@onUpdate\",\"@start\"],[[32,0,[\"range\"]],1,\"lower\",[30,[36,4],[[32,0],\"onSliderUpdate\"],null],[32,0,[\"start\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"did-update\",\"format-currency\",\"action\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/subscription/plan/plans-slider/template.hbs"
    }
  });
  _exports.default = _default;
});