define("account-talentrh/pods/registry/general/user/edit/index/interest/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    // Default values
    hasOtherSport: false,
    sports: Ember.A(['Artes-Marciais', 'Atletismo', 'Automobilismo', 'Basquete', 'Beisebol', 'Bilhar', 'Boliche', 'Ciclismo', 'Corrida', 'e-Sports', 'Fisiculturismo', 'Futebol', 'Futsal', 'Ginástica', 'Golfe', 'Handebol', 'Hóquei', 'Luta', 'Motovelocidade', 'Natação', 'Paintball', 'Ping-Pong', 'Salto', 'Skate', 'Surf', 'Tênis', 'Vôlei', 'Outros']),
    // Actions
    actions: {
      sportSelected(sport) {
        if (Ember.isEqual(sport, 'Outros')) {
          Ember.set(this, 'hasOtherSport', true);
        } else {
          Ember.set(this, 'hasOtherSport', false);
          Ember.set(this, 'user.otherSport', '');
        }
        Ember.set(this, 'user.favoriteSport', sport);
      }
    }
  });
  _exports.default = _default;
});