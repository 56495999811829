define("account-talentrh/pods/registry/general/job-role/show/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralJobRoleShowUsersRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.general.job-role.show');
    }
  }
  _exports.default = RegistryGeneralJobRoleShowUsersRoute;
});