define("account-talentrh/pods/registry/general/course/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralCourseCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('course');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.course').filters = {};
      this.controllerFor('registry.general.course').clearFilters();
    }
  }
  _exports.default = RegistryGeneralCourseCreateRoute;
});