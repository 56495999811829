define("account-talentrh/pods/registry/general/user/show/corporate/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    user: Ember.computed.alias('model'),
    professionalPhone: Ember.computed('user.professionalPhone', function () {
      if (!this.user.professionalPhone) {
        return '';
      }
      return this.user.professionalPhone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    }),
    professionalCellphone: Ember.computed('user.professionalCellphone', function () {
      if (!this.user.professionalCellphone) {
        return '';
      }
      return this.user.professionalCellphone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    })
  });
  _exports.default = _default;
});