define("account-talentrh/pods/super-user/post/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    filter: null
  });
  _exports.default = _default;
});