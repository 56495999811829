define("account-talentrh/pods/registry/general/user/index/import/controller", ["exports", "ember-concurrency", "papaparse", "moment"], function (_exports, _emberConcurrency, _papaparse, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    users: Ember.A(),
    disableImport: Ember.computed('users', 'users.length', function () {
      return this.users.length ? false : true;
    }),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        let childs = this.users.map(user => {
          return this.saveRecord.perform(user);
        });
        yield (0, _emberConcurrency.all)(childs);
        if (this.users.length) {
          this.toast.warning('Alguns registros não puderam ser importados');
        } else {
          this.toast.success('Importação de usuários concluída');
          this.send('closeModal');
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    saveRecord: (0, _emberConcurrency.task)(function* (user) {
      // Ignora a validação de perfil de acesso, cargo e departamento na importação de usuários.
      // To-do: Cadastrar uma tarefa para melhoria para possibilitar editar essas informações no grid
      const ignoreMessages = ['É necessário informar um perfil de acesso', 'É necessário informar um departamento', 'É necessário informar um cargo'];
      try {
        if (!user) {
          return;
        }
        if (user.validations.isInvalid) {
          let errorMessages = user.validations.messages.map(message => {
            if (!ignoreMessages.includes(message)) {
              return message;
            }
          });
          let error = errorMessages.firstObject;
          user.set('error', error);
        }
        if (!user.error) {
          yield yield user.save();
          this.users.removeObject(user);
        }
      } catch (e) {
        const errorMessage = e.errors && e.errors[0]?.detail ? e.errors[0].detail : e;
        user.set('error', errorMessage);
      }
    }).maxConcurrency(3).enqueue(),
    processCSV(results) {
      let users = results.data.map((record, index) => {
        if (!index) {
          return;
        }
        if (record.length < 2) {
          return;
        }
        const cpf = ('00000000000' + record[1]).slice(-11);
        return this.store.createRecord('user', {
          admissionDate: (0, _moment.default)(record[0], 'DD/MM/YYYY').toDate(),
          cpf,
          firstName: String(record[2]).split(' ')[0],
          lastName: String(record[2]).substring(String(record[2]).indexOf(' ') + 1, String(record[2]).length),
          birthDate: (0, _moment.default)(record[3], 'DD/MM/YYYY').toDate(),
          email: record[4],
          zipcode: record[5],
          address: record[6],
          number: record[7] || 0,
          neighborhood: record[8],
          confirmPassword: cpf,
          active: true,
          status: 'active'
        });
      });
      users = users.compact();
      Ember.set(this, 'users', users);
    },
    actions: {
      closeModal() {
        this.transitionToRoute('registry.general.user.index');
      },
      loadCSV(event) {
        let file = event.target.files[0];
        _papaparse.default.parse(file, {
          config: {
            skipEmptyLines: true
          },
          error: () => this.swal.error('Não foi possível carregar o arquivo'),
          complete: results => {
            this.processCSV(results);
          }
        });
      },
      removeUser(user) {
        this.users.removeObject(user);
      }
    }
  });
  _exports.default = _default;
});