define("account-talentrh/pods/setup-wizard/steps/registry/user/manage/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsRegistryUserManageController extends Ember.Controller {
    hideEffect(e) {
      Ember.run.later(() => e.remove(), 2000);
    }
  }
  _exports.default = SetupWizardStepsRegistryUserManageController;
});