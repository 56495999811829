define("account-talentrh/pods/configuration/nela/benefit/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    benefitImageUpload: Ember.inject.service(),
    benefitVideoUpload: Ember.inject.service(),
    selectedCategory: null,
    selectedBranches: Ember.A(),
    selectedCities: Ember.A(),
    advancedEditorImages: Ember.A(),
    advancedEditorVideos: Ember.A(),
    categories: Ember.A([{
      label: 'Academia',
      value: 'gym'
    }, {
      label: 'Saúde',
      value: 'health'
    }, {
      label: 'Educação',
      value: 'education'
    }, {
      label: 'Alimentação',
      value: 'food'
    }, {
      label: 'Transporte',
      value: 'transport'
    }, {
      label: 'Convênio',
      value: 'agreement'
    }, {
      label: 'Auxílio',
      value: 'support'
    }, {
      label: 'Lazer',
      value: 'recreation'
    }, {
      label: 'Vestuário',
      value: 'clothing'
    }, {
      label: 'Beleza',
      value: 'beauty'
    }, {
      label: 'Outros',
      value: 'others'
    }]),
    didReceiveAttrs() {
      this._super(...arguments);
      let selected = this.categories.find(cat => {
        return this.benefit.category === cat.value;
      });
      Ember.set(this, 'selectedCategory', selected);
      Ember.set(this, 'selectedCities', this.benefit.cities);
      Ember.set(this, 'selectedBranches', this.benefit.branches);
    },
    save: (0, _emberConcurrency.task)(function* () {
      if (this.benefit.validations.isInvalid) {
        return this.swal.validation(this.benefit.validations);
      }
      let message = this.benefit.isNew ? 'Benefício cadastrado' : 'Benefício atualizado';
      let {
        description
      } = this.benefit;
      Ember.set(this, 'benefit.description', '');
      try {
        this.benefit.set('branches', this.selectedBranches);
        yield this.benefit.save();
        if (description) {
          this.benefit.description = description;
          yield this.benefitVideoUpload.save.perform(this.benefit, this.advancedEditorVideos);
          yield this.benefitImageUpload.save.perform(this.benefit, this.advancedEditorImages);
        }
        yield this.benefit.save();
        this.toast.success(message);
        if (this.useInWizard) {
          this.router.transitionTo('setup-wizard.steps.company.benefits.manage.index');
        } else {
          this.router.transitionTo('configuration.nela.benefit');
        }
        this.advancedEditorImages.clear();
        this.advancedEditorVideos.clear();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    afterCitySelected: (0, _emberConcurrency.task)(function* (selectedCities) {
      const selectedCitiesIds = selectedCities.map(city => city.id);
      const branchs = yield this.store.findAll('branch');
      const branchsInSelectedCities = branchs.filter(branch => {
        return selectedCitiesIds.includes(branch.get('city.id'));
      });
      Ember.set(this, 'selectedBranches', branchsInSelectedCities);
      Ember.set(this, 'benefit.cities', selectedCities);
    }).drop(),
    actions: {
      afterBranchSelected(branchesSelected) {
        const branchesSelectedCitiesId = branchesSelected.map(branch => branch.get('city.id'));
        const selectedCities = this.selectedCities.filter(city => branchesSelectedCitiesId.includes(city.get('id')));
        Ember.set(this, 'selectedCities', selectedCities);
        Ember.set(this, 'benefit.cities', selectedCities);
        Ember.set(this, 'benefit.branches', branchesSelected);
      },
      clearInputSelect() {
        if (this.benefit.cities) {
          Ember.set(this, 'benefit.cities', []);
        }
        if (this.benefit.branches) {
          Ember.set(this, 'benefit.branches', []);
        }
      },
      checkAllCities() {
        const value = Boolean(this.benefit.onAllCities);
        Ember.set(this, 'benefit.onAllCities', !value);
        if (!value) {
          Ember.set(this, 'benefit.onAllBranches', !value);
        }
        Ember.set(this, 'selectedCities', []);
        Ember.set(this, 'selectedBranches', []);
      },
      selectCategory(selected) {
        Ember.set(this, 'selectedCategory', selected);
        Ember.set(this, 'benefit.category', selected.value);
      }
    }
  });
  _exports.default = _default;
});