define("account-talentrh/pods/lgpd/delete/company/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yvZ+CBUD",
    "block": "{\"symbols\":[\"View\",\"Section\",\"option\",\"@model\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Header\"]],[[24,0,\"font-weight-bold\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Nova solicitação\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"Empresa a ser excluída:\"],[13],[2,\"\\n            \"],[8,\"nela-select\",[],[[\"@selected\",\"@modelName\",\"@allowClear\",\"@placeholder\"],[[32,4,[\"company\"]],\"company\",true,\"Selecionar empresa\"]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,3,[\"name\"]]],[2,\"\\n            \"]],\"parameters\":[3]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Footer\"]],[[24,0,\"text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"]],[[\"@route\"],[\"lgpd.delete.company\"]],[[\"default\"],[{\"statements\":[[2,\"Cancelar\"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"save\"]]],null]],[12],[2,\"Solicitar exclusão\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/lgpd/delete/company/create/template.hbs"
    }
  });
  _exports.default = _default;
});