define("account-talentrh/pods/registry/general/user/show/avd/goals/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EMpatCnS",
    "block": "{\"symbols\":[\"@goal\"],\"statements\":[[2,\" \"],[10,\"div\"],[14,0,\"row border-bottom mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3 mb-3\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"startAt\"]],\"L\"],null]],[2,\" - \"],[1,[30,[36,0],[[32,1,[\"endAt\"]],\"L\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-5 mb-3\"],[15,\"title\",[30,[36,2],[[30,[36,1],[[32,1,[\"title\",\"length\"]],40],null],[32,1,[\"title\"]],\"\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[32,1,[\"title\"]]],[[\"limit\"],[40]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-4 progress p-0\"],[14,5,\"height: 24px\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"progress-bar\"],[24,\"role\",\"progressbar\"],[24,\"aria-valuemin\",\"0\"],[16,\"aria-valuenow\",[32,0,[\"goalSerialized\",\"percent\"]]],[24,\"aria-valuemax\",\"100\"],[4,[38,5],[[30,[36,4],[[32,0],\"setProgressBarWidth\"],null]],null],[12],[2,\"\\n      \"],[10,\"small\"],[14,0,\"justify-content-center d-flex position-absolute w-100 text-dark h6 pt-2\"],[12],[1,[32,0,[\"goalSerialized\",\"percentLabel\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"gt\",\"if\",\"truncate-text\",\"action\",\"did-insert\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/avd/goals/item/template.hbs"
    }
  });
  _exports.default = _default;
});