define("account-talentrh/pods/registry/general/user/show/avd/skills/skill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bxojEJ/H",
    "block": "{\"symbols\":[\"Card\",\"@skill\"],\"statements\":[[8,\"ui-card\",[[24,0,\"my-2\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-2 px-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[15,\"title\",[31,[[30,[36,1],[[32,2,[\"show\"]],\"Ocultar\",\"Exibir\"],null],\" descrição\"]]],[15,0,[31,[[30,[36,1],[[32,2,[\"show\"]],\"border-bottom\"],null],\" mb-0\"]]],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-link btn-block text-body text-left font-weight-bold d-flex justify-content-between align-items-center p-0\"],[15,\"onclick\",[30,[36,4],[[30,[36,3],[[32,2,[\"show\"]]],null],[30,[36,2],[[32,2,[\"show\"]]],null]],null]],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"mr-2 pr-1\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fas fa-chevron-\",[30,[36,1],[[32,2,[\"show\"]],\"up\",\"down\"],null]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,2,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[32,2,[\"description\"]],[32,2,[\"description\"]],[30,[36,0],[\"<i>Sem descrição</i>\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html\",\"if\",\"not\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/avd/skills/skill/template.hbs"
    }
  });
  _exports.default = _default;
});