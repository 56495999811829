define("account-talentrh/pods/registry/avd/type-answer/show/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'py-4'],
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    percentage: Ember.computed('alternative.percentage', function () {
      return this.alternative.percentage ? `${this.alternative.percentage}%` : '';
    }),
    color: Ember.computed('alternative.color', function () {
      let color = this.alternative.color || '#FFF';
      return Ember.String.htmlSafe(`<span class="d-inline-block border border-dark rounded mr-2" style="height:20px;width:20px;background:${color}"/>`);
    }),
    removeAlternative: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da alternativa?', {
          text: this.alternative.description
        });
        if (!value) {
          return;
        }
        yield this.alternative.destroyRecord();
        this.toast.success('Alternativa removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});