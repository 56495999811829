define("account-talentrh/pods/lgpd/delete/user/show/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LgpdDeleteUserShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('model'), _dec7 = Ember.computed.alias('permission.superUser'), _dec8 = Ember.computed('solicitation.isPending', 'permission.superUser'), _dec9 = Ember.computed('model.userToDelete'), (_class = class LgpdDeleteUserShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "ajax", _descriptor5, this);
      _initializerDefineProperty(this, "solicitation", _descriptor6, this);
      _initializerDefineProperty(this, "showCompany", _descriptor7, this);
    }
    // Multi line computed
    get showCancelButton() {
      return this.solicitation.isPending && !this.permission.superUser;
    }
    get userFullName() {
      return `${this.model.userToDelete.get('firstName')} ${this.model.userToDelete.get('lastName')}`;
    }
    *cancelSolicitation() {
      try {
        let value = '';
        let dismiss = false;
        if (this.solicitation.status !== 'pending') {
          return this.swal.warning('Só é permitido o cancelamento de solicitações pendentes!');
        }
        while (value != 'CONFIRMAR') {
          ({
            dismiss,
            value
          } = yield this.swal.fire({
            title: 'Atenção',
            input: 'text',
            text: `Deseja realmente cancelar a exclusão do usuário ${this.userFullName}? 
          É necessário digitar “CONFIRMAR” no campo para efetuar a operação.`,
            showCancelButton: true,
            confirmButtonText: 'Ok'
          }));
          if (dismiss) {
            return;
          }
          if (value != 'CONFIRMAR') {
            yield this.swal.warning('É necessário digitar "CONFIRMAR" no campo para efetuar a operação.');
          }
        }
        yield this.ajax.put(`lgpdDeleteUserSolicitations/${this.solicitation.id}/cancel`);
        yield this.solicitation.reload();
        this.router.transitionTo('lgpd.delete.user');
        this.toast.success('Solicitação removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "solicitation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showCompany", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showCancelButton", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showCancelButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userFullName", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "userFullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSolicitation", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSolicitation"), _class.prototype)), _class));
  _exports.default = LgpdDeleteUserShowController;
});