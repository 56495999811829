define("account-talentrh/pods/registry/general/access-profile/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    baseRoute: Ember.computed('useInWizard', function () {
      return this.useInWizard ? 'setup-wizard.steps.access-profile.manage' : 'registry.general.access-profile';
    }),
    permissionsRoute: Ember.computed('accessProfile.isNew', 'baseRoute', function () {
      if (this.accessProfile.isNew) {
        return `${this.baseRoute}.create.index`;
      }
      return `${this.baseRoute}.edit.index`;
    }),
    schedulesRoute: Ember.computed('accessProfile.isNew', function () {
      if (this.accessProfile.isNew) {
        return `${this.baseRoute}.create.access-schedule`;
      }
      return `${this.baseRoute}.edit.access-schedule`;
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let message = this.accessProfile.isNew ? 'Perfil de acesso cadastrado' : 'Perfil de acesso atualizado';
      try {
        if (this.accessProfile.validations.isInvalid) {
          return this.swal.validation(this.accessProfile.validations);
        }
        let {
          id
        } = yield this.accessProfile.save();
        this.toast.success(message);
        if (this.useInWizard) {
          this.router.transitionTo('setup-wizard.steps.access-profile.manage');
        } else {
          this.router.transitionTo('registry.general.access-profile.show', id);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});