define("account-talentrh/pods/registry/general/user/edit/index/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MKQO9saX",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2 mb-3\"],[12],[2,\"\\n    Contatos\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Email pessoal\"],[13],[2,\"\\n        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[32,1,[\"personalEmail\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"permission\",\"companyConfig\",\"featureFlags\"]],\"changingUsersPassword\"],null]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Celular\"],[13],[2,\"\\n        \"],[8,\"nela-input-mask\",[[24,0,\"form-control\"]],[[\"@unmasked\",\"@pattern\",\"@disabled\",\"@validateAs\"],[[32,1,[\"cellphone\"]],\"(99) 99999-9999\",[30,[36,1],[[30,[36,0],[[32,0,[\"permission\",\"companyConfig\",\"featureFlags\"]],\"changingUsersPassword\"],null]],null],\"cellphone\"]],null],[2,\"\\n        \"],[10,\"div\"],[14,1,\"cellphone-is-not-valid\"],[14,0,\"text-danger\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"not\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/edit/index/contact/template.hbs"
    }
  });
  _exports.default = _default;
});