define("account-talentrh/pods/registry/general/user/index/import/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    rollbackUsers: (0, _emberConcurrency.task)(function* () {
      if (this.controller.users) {
        yield Ember.RSVP.map(this.controller.users, user => {
          return user.rollbackAttributes();
        });
      }
      this.controller.set('users', null);
      this.controllerFor('registry.general.user').set('filters', {});
    }).on('deactivate')
  });
  _exports.default = _default;
});