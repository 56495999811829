define("account-talentrh/pods/register/user/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel() {
      if (this.session.isAuthenticated) {
        this.swal.warning('Você não pode acessar este cadastro enquanto estiver logado no sistema');
      }
      this.session.prohibitAuthentication('home');
    },
    model(params) {
      return Ember.RSVP.hash({
        user: {},
        token: params.token,
        company: this.ajax.request('companies/tokeninfo/' + params.token)
      });
    },
    activate: function () {
      (0, _jquery.default)('body').addClass('body-background');
    },
    deactivate: function () {
      (0, _jquery.default)('body').removeClass('body-background');
    }
  });
  _exports.default = _default;
});