define("account-talentrh/pods/registry/social/import/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialImportsEditRoute extends Ember.Route {
    model(_ref, transaction) {
      let {
        id
      } = _ref;
      const {
        type
      } = transaction.to.queryParams;
      return this.store.findRecord(type, id);
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = RegistrySocialImportsEditRoute;
});