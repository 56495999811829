define("account-talentrh/pods/setup-wizard/steps/groups/group/manage/form/user-manager/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('args.group.users.@each'), _dec7 = Ember.computed('args.group.admins.@each'), _dec8 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "chatService", _descriptor4, this);
      _initializerDefineProperty(this, "session", _descriptor5, this);
    }
    // Computed
    get isMember() {
      let {
        user,
        group
      } = this.args;
      return group.users.findBy('id', user.id);
    }
    get isAdmin() {
      let {
        user,
        group
      } = this.args;
      return group.admins.findBy('id', user.id);
    }
    get canManage() {
      let {
        canManage,
        group,
        user
      } = this.args;
      return canManage && (this.session.user.id !== Number(user.id) || !group.isNew);
    }

    // Task
    *addUser() {
      try {
        let {
          user,
          group
        } = this.args;
        if (group.isNew) {
          group.users.addObject(user);
        } else {
          yield this.ajax.put(`/userGroups/${group.id}/addUsers`, {
            host: _environment.default.apiUrl.social,
            data: {
              users: [user.id]
            }
          });
          group.reload();
          this.toast.success(`${user.fullName} foi adicionado como membro do grupo.`);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *removeUser() {
      try {
        let {
          user,
          group
        } = this.args;
        if (group.isNew) {
          group.users.removeObject(user);
          group.admins.removeObject(user);
        } else {
          let {
            value
          } = yield this.swal.confirm(`Deseja remover ${user.fullName} como membro do grupo?`);
          if (!value) {
            return;
          }
          yield this.ajax.put(`/userGroups/${group.id}/removeUsers`, {
            host: _environment.default.apiUrl.social,
            data: {
              users: [user.id]
            }
          });
          group.reload();
          group.users.removeObject(user);
          this.toast.success(`${user.fullName} foi removido do grupo.`);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *addAdmin() {
      try {
        let {
          user,
          group
        } = this.args;
        if (group.isNew) {
          group.admins.addObject(user);
        } else {
          let {
            value
          } = yield this.swal.confirm(`Deseja tornar ${user.fullName} um administrador do grupo?`);
          if (!value) {
            return;
          }
          yield this.ajax.post(`/userGroups/${group.id}/addUserAdmin/${user.id}`, {
            host: _environment.default.apiUrl.social
          });
          group.reload();
          this.toast.success(`${user.fullName} foi adicionado como administrador do grupo.`);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *removeAdmin() {
      try {
        let {
          user,
          group
        } = this.args;
        if (group.isNew) {
          group.admins.removeObject(user);
        } else {
          let {
            value
          } = yield this.swal.confirm(`Deseja remover as permissões de administrador para ${user.fullName}?`);
          if (!value) {
            return;
          }
          yield this.ajax.delete(`/userGroups/${group.id}/removeUserAdmin/${user.id}`, {
            host: _environment.default.apiUrl.social
          });
          group.reload();
          this.toast.success(`${user.fullName} foi removido como administrador do grupo.`);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    openChat() {
      let {
        user
      } = this.args;
      this.chatService.loadMessages({
        name: user.fullName,
        type: 'direct',
        with: user.id
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isMember", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isMember"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "addUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAdmin", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "addAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAdmin", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openChat", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "openChat"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});