define("account-talentrh/pods/components/settings/details/local-payroll/component", ["exports", "ember-concurrency", "account-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    fetch: Ember.inject.service(),
    swal: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.payroll = {
        folhaLocalURL: '',
        folhaLocalPassword: ''
      };
    },
    loadPayroll: (0, _emberConcurrency.task)(function* () {
      let {
        companyConfiguration
      } = yield this.fetch.request('companyConfigurations/payrollConfig', {
        host: _environment.default.apiUrl.social
      }) || {};
      Ember.set(this, 'payroll.folhaLocalURL', companyConfiguration.folhaLocalURL);
      Ember.set(this, 'payroll.folhaLocalPassword', companyConfiguration.folhaLocalPassword);
      Ember.set(this, 'payroll.id', companyConfiguration.id);
    }).on('didInsertElement'),
    type: Ember.computed('showPassword', function () {
      return this.showPassword ? 'text' : 'password';
    }),
    save: (0, _emberConcurrency.task)(function* () {
      yield this.fetch.put(`companyConfigurations/${this.payroll.id}`, {
        host: _environment.default.apiUrl.social,
        data: {
          companyConfiguration: {
            folhaLocalURL: this.payroll.folhaLocalURL,
            folhaLocalPassword: this.payroll.folhaLocalPassword
          }
        }
      });
      this.toast.success('As configurações foram salvas');
    }),
    actions: {
      testLink() {
        this.swal.loading('Testando conexão com o Folha');
        this.fetch.post('receipts/test', {
          host: _environment.default.apiUrl.social,
          data: {
            link: this.payroll.folhaLocalURL,
            password: this.payroll.folhaLocalPassword
          }
        }).then(response => {
          if (response.result) {
            this.swal.success('Conexão com o Folha estabelecida');
          } else {
            let message = response.message || 'Não foi possível estabelecer uma conexão com o Folha para este endereço.';
            this.swal.error(message);
          }
        }).catch(err => {
          this.swal.catch(err);
        });
      },
      togglePassword() {
        this.toggleProperty('showPassword');
      }
    }
  });
  _exports.default = _default;
});