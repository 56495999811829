define("account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/import/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency", "papaparse"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SetupWizardStepsRegistryJobRoleManageImportController = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.task)({
    maxConcurrency: 3,
    enqueue: true
  }), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SetupWizardStepsRegistryJobRoleManageImportController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "records", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
    }
    // Tasks
    *saveRecord(jobRole) {
      try {
        if (!jobRole.validations.isValid) {
          jobRole.set('error', jobRole.validations.messages.firstObject);
          return;
        }
        yield jobRole.save();
        this.records.removeObject(jobRole);
      } catch (e) {
        jobRole.set('error', e);
      }
    }
    *save() {
      try {
        let childs = this.records.map(jobRole => {
          return this.saveRecord.perform(jobRole);
        });
        yield (0, _emberConcurrency.all)(childs);
        if (this.records.length) {
          this.toast.warning('Alguns registros não puderam ser importados');
        } else {
          this.toast.success('Todos os registros foram importados com sucesso');
          this.transitionToRoute('setup-wizard.steps.registry.job-role.manage.index');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }

    // Actions
    loadCSV(event) {
      let file = event.target.files[0];
      _papaparse.default.parse(file, {
        config: {
          skipEmptyLines: true
        },
        error: () => this.swal.error('Não foi possível carregar o arquivo'),
        complete: _ref => {
          let {
            data
          } = _ref;
          if (!data) {
            return;
          }
          let records = [];
          data.forEach((record, index) => {
            if (!index) {
              return;
            }
            if (record < 1) {
              return;
            }
            let jobRole = this.store.createRecord('job-role');
            jobRole.title = record[0];
            records.push(jobRole);
          });
          this.records = records;
        }
      });
    }
    removeRecord(record) {
      this.records.removeObject(record);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "records", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveRecord", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCSV", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadCSV"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRecord", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeRecord"), _class.prototype)), _class));
  _exports.default = SetupWizardStepsRegistryJobRoleManageImportController;
});