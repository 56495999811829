define("account-talentrh/models/open-ai-repository", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: false,
      message: 'O repositório deve ter um título'
    }),
    version: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: false,
      message: 'A versão é obrigatória'
    }),
    temperature: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: false,
      message: 'A temperatura é obrigatória'
    }),
    prompt: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: false,
      message: 'O prompt é obrigatório'
    }),
    branches: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['branches', 'departments', 'jobRoles', 'jobFunctions', 'employees'],
      validate(value, options, model) {
        return getFilterValidations(model);
      }
    }),
    departments: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['branches', 'departments', 'jobRoles', 'jobFunctions', 'employees'],
      validate(value, options, model) {
        return getFilterValidations(model);
      }
    }),
    jobRoles: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['branches', 'departments', 'jobRoles', 'jobFunctions', 'employees'],
      validate(value, options, model) {
        return getFilterValidations(model);
      }
    }),
    jobFunctions: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['branches', 'departments', 'jobRoles', 'jobFunctions', 'employees'],
      validate(value, options, model) {
        return getFilterValidations(model);
      }
    }),
    employees: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['branches', 'departments', 'jobRoles', 'jobFunctions', 'employees'],
      validate(value, options, model) {
        return getFilterValidations(model);
      }
    })
  });
  function getFilterValidations(model) {
    const {
      branches,
      departments,
      jobRoles,
      jobFunctions,
      employees
    } = model;
    if (branches.length > 0 || departments.length > 0 || jobRoles.length > 0 || jobFunctions.length > 0 || employees.length > 0) {
      return true;
    }
    return 'Informe pelo menos um dos campos:' + ' Filial, Departamento, Cargo' + ' Função ou Colaborador';
  }
  let OpenAiRepositoryModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec11 = (0, _model.belongsTo)('company'), _dec12 = (0, _model.belongsTo)('open-ai-version'), _dec13 = (0, _model.belongsTo)('open-ai-temperature'), _dec14 = (0, _model.hasMany)('branch', {
    inverse: 'openAiRepositories'
  }), _dec15 = (0, _model.hasMany)('department', {
    inverse: 'openAiRepositories'
  }), _dec16 = (0, _model.hasMany)('job-role', {
    inverse: 'openAiRepositories'
  }), _dec17 = (0, _model.hasMany)('job-function', {
    inverse: 'openAiRepositories'
  }), _dec18 = (0, _model.hasMany)('user', {
    inverse: 'openAiRepositories'
  }), _dec19 = (0, _model.hasMany)('open-ai-repository-attachments', {
    inverse: 'openAiRepository'
  }), (_class = class OpenAiRepositoryModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "prompt", _descriptor2, this);
      _initializerDefineProperty(this, "branch", _descriptor3, this);
      _initializerDefineProperty(this, "imageUrl", _descriptor4, this);
      _initializerDefineProperty(this, "useAggregatedRelations", _descriptor5, this);
      _initializerDefineProperty(this, "updatedAt", _descriptor6, this);
      _initializerDefineProperty(this, "createdAt", _descriptor7, this);
      _initializerDefineProperty(this, "webHookMessageId", _descriptor8, this);
      _initializerDefineProperty(this, "indexName", _descriptor9, this);
      _initializerDefineProperty(this, "deleted", _descriptor10, this);
      _initializerDefineProperty(this, "company", _descriptor11, this);
      _initializerDefineProperty(this, "version", _descriptor12, this);
      _initializerDefineProperty(this, "temperature", _descriptor13, this);
      _initializerDefineProperty(this, "branches", _descriptor14, this);
      _initializerDefineProperty(this, "departments", _descriptor15, this);
      _initializerDefineProperty(this, "jobRoles", _descriptor16, this);
      _initializerDefineProperty(this, "jobFunctions", _descriptor17, this);
      _initializerDefineProperty(this, "employees", _descriptor18, this);
      _initializerDefineProperty(this, "attachments", _descriptor19, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "prompt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "branch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "imageUrl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "useAggregatedRelations", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "webHookMessageId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "indexName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "deleted", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "temperature", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "branches", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "departments", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "jobRoles", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "jobFunctions", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "employees", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OpenAiRepositoryModel;
});