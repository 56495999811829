define("account-talentrh/pods/registry/general/institution/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    institution: Ember.computed.alias('model'),
    institutionCNPJ: Ember.computed('institution.{cnpj,physicalPerson}', function () {
      if (this.institution.physicalPerson || !this.institution.cnpj) {
        return '';
      }
      return this.institution.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }),
    institutionCPF: Ember.computed('institution.{cpf,physicalPerson}', function () {
      if (!this.institution.physicalPerson || !this.institution.cpf) {
        return '';
      }
      return this.institution.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }),
    institutionPhone: Ember.computed('institution.phone', function () {
      if (!this.institution.phone) {
        return '';
      }
      return this.institution.phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }),
    remove: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da instituição?', {
          text: this.institution.name
        });
        if (!value) {
          return;
        }
        yield this.institution.destroyRecord();
        this.toast.success('Instituição removida');
        this.router.transitionTo('registry.general.institution.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});