define("account-talentrh/pods/configuration/nela/policy/form/component", ["exports", "ember-concurrency", "account-talentrh/config/environment", "account-talentrh/models/policy"], function (_exports, _emberConcurrency, _environment, _policy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    policyImageUpload: Ember.inject.service(),
    policyVideoUpload: Ember.inject.service(),
    pdf: null,
    permission: Ember.inject.service(),
    advancedEditorImages: Ember.A(),
    advancedEditorVideos: Ember.A(),
    manageOnlyBranch: Ember.computed.alias('permission.accessProfile.manageOnlyBranch'),
    summernoteDescription: Ember.computed('policy.description', function () {
      return Ember.String.htmlSafe(this.policy.description);
    }),
    fileName: Ember.computed('pdf.name', 'policy.pdfUrl', function () {
      if (this.policy && this.policy.pdfUrl) {
        const splitUrl = this.policy.pdfUrl.split('/');
        return splitUrl.length > 1 ? splitUrl[1] : '';
      } else if (this.pdf) {
        return this.pdf.name;
      }
      return '';
    }),
    sendFile: (0, _emberConcurrency.task)(function* (file, policyId) {
      const url = `${_environment.default.apiUrl.conta}/policies/${policyId}/pdf`;
      try {
        this.swal.loading('Enviando arquivo');
        let response = yield file.upload(url, {
          fileKey: 'avatar',
          headers: {
            'Authorization': `Bearer ${this.session.token}`
          }
        });
        this.store.pushPayload(response.body);
        this.toast.success('PDF enviado');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    removePDF: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover PDF?');
        if (!value) {
          return;
        }
        yield this.fetch.post(`policies/${this.policy.id}/removepdf`);
        this.toast.success('PDF removido');
        Ember.set(this, 'policy.pdfUrl', null);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      let message = this.policy.isNew ? 'Política cadastrada' : 'Política atualizada';
      try {
        if (!this.policy.validations.isValid) {
          return this.swal.validation(this.policy.validations);
        }

        // remove emojis do titulo
        this.policy.title = this.policy.title.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
        let count = 0;
        let description = this.policy.description;
        const regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
        if (description) {
          description = description.replace(regex, () => count++ + 'i');
        }
        if (!this.policy.description && !this.pdf) {
          return this.swal.warning('É necessário descrever a política da empresa ou importar documento PDF');
        }
        yield this.policy.save();
        if (this.advancedEditorVideos.length) {
          yield this.policyVideoUpload.save.perform(this.policy, this.advancedEditorVideos);
        }
        if (this.advancedEditorImages.length) {
          yield this.policyImageUpload.save.perform(this.policy, this.advancedEditorImages);
        }
        yield this.policy.save();
        if (this.pdf) {
          yield this.sendFile.perform(this.pdf, this.policy.id);
        }
        this.toast.success(message);
        if (this.useInWizard) {
          this.router.transitionTo('setup-wizard.steps.company.policies.manage.index');
        } else {
          this.router.transitionTo('configuration.nela.policy');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      onAddFile(file) {
        Ember.set(this, 'pdf', file);
      }
    }
  });
  _exports.default = _default;
});