define("account-talentrh/models/benefit-video", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    path: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    benefit: (0, _model.belongsTo)('benefit')
  });
  _exports.default = _default;
});