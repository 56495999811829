define("account-talentrh/pods/configuration/avd/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    userConfigs: Ember.computed.alias('model'),
    convert: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar a conversão dos questionários?');
        if (!value) {
          return;
        }
        let data = yield this.ajax.request('questionnaires/convert');
        if (data && data.length) {
          this.toast.success('Questionários convertidos');
        } else {
          this.toast.info('Nenhum questionário convertido');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});