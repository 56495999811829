define("account-talentrh/services/file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    downloadFile(file, name) {
      let link = document.createElement("a");
      try {
        link.setAttribute("href", URL.createObjectURL(file));
      } catch (e) {
        link.setAttribute("href", file.url);
      }
      if (name) {
        link.setAttribute("download", name);
      } else {
        link.setAttribute("download", file.name);
      }
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
  _exports.default = _default;
});