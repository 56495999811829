define("account-talentrh/pods/settings/avd/ninebox-settings/quadrant-scale-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VjlqnOZc",
    "block": "{\"symbols\":[\"column\",\"index\",\"line\",\"index\"],\"statements\":[[10,\"p\"],[14,0,\"text-primary mb-0\"],[12],[2,\"Informe as faixas de valores de cada linha e coluna da nine box.\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"text-primary\"],[12],[2,\"Escala: 0 - 1000%\"],[13],[2,\"\\n\"],[10,\"label\"],[14,0,\"text-primary font-weight-bold d-block\"],[12],[2,\"Linhas\"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"lines\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"settings/avd/ninebox-settings/quadrant-scale-form/line\",[],[[\"@line\",\"@lineIndex\",\"@lines\"],[[32,3],[32,4],[32,0,[\"lines\"]]]],null],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[10,\"label\"],[14,0,\"text-primary font-weight-bold d-block\"],[12],[2,\"Colunas\"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"settings/avd/ninebox-settings/quadrant-scale-form/column\",[],[[\"@column\",\"@columnIndex\",\"@columns\"],[[32,1],[32,2],[32,0,[\"columns\"]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/settings/avd/ninebox-settings/quadrant-scale-form/template.hbs"
    }
  });
  _exports.default = _default;
});