define("account-talentrh/pods/register/account/index/controller", ["exports", "ember-concurrency-decorators", "account-talentrh/config/environment", "ember-cp-validations"], function (_exports, _emberConcurrencyDecorators, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.user.firstName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.lastName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.email': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.cpf': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.password': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.user.confirmPassword': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.name': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.cnpj': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.phone': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.address': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.number': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.neighborhood': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'model.company.zipcode': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });
  let RegisterAccountIndexController = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class RegisterAccountIndexController extends Ember.Controller.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
    }
    *register() {
      Ember.debug('Will perform register task!');
      this.swal.loading('Estamos cadastrando a sua empresa');
      try {
        // remove o objecto do plano que se deixar da problema de loop infinito na hora de viarar json
        delete this.model.plan;
        const response = yield this.ajax.post('register/account', {
          data: this.model
        });
        Ember.debug('Register Account: ', response);
        this.swal.close();
        // this.transitionToRoute('login');
        this.transitionToRoute('register.account.success');
      } catch (err) {
        Ember.debug('dropTask *register() Error', err);
        this.swal.error('Não foi possível prosseguir');
      }
    }
    checkoutValidations() {
      document.querySelectorAll('.form-group .validation').forEach(element => {
        element.textContent = '';
      });
      if (!this.validations.isValid) {
        this.validations.errors.forEach(error => {
          let dataAttr = error.attribute.replace('model.', '').replace('.', '_');
          let validationSpan = document.querySelector(`.form-group[data-validation="${dataAttr}"] .validation`);
          if (!validationSpan) {
            return;
          }
          if (error.type === 'presence') {
            validationSpan.textContent = '* É necessário preencher este campo.';
          }
        });
        this.toast.warning('Para continuar é necessário preencher todos os campos.');
        return;
      } else {
        return true;
      }
    }
    getCreditCardHash() {
      let isValid = this.checkoutValidations();
      if (!isValid) {
        return;
      }
      if (this.model.user.password !== this.model.user.confirmPassword) {
        return this.toast.warning('As senhas não coincidem');
      }

      // INICIAR A INSTÂNCIA DO CHECKOUT declarando um callback de sucesso
      let checkout = new PagarMeCheckout.Checkout({
        "encryption_key": _environment.default.pagarmeEncriptionKey,
        success: data => {
          //Tratar aqui as ações de callback do checkout, como exibição de mensagem ou envio de token para captura da transação
          this.model.card_hash = data.card_hash;
          this.register.perform();
        },
        error: function (err) {
          console.log(err);
        },
        fail: function () {
          this.swal('Falha ao registrar este cartão');
        }
      });
      checkout.open({
        // "amount": this.get('selectedPlan.price'),
        "paymentButtonText": "Criar Assinatura",
        "customerData": "false",
        "paymentMethods": "credit_card",
        "uiColor": "#398ded",
        "createToken": "false"
        // "headerText": this.get('selectedPlan.description')
      });
    }

    populateAddress(values) {
      const {
        logradouro,
        bairro,
        erro
      } = values;
      if (erro) {
        Ember.set(this, 'model.company.address', null);
        Ember.set(this, 'model.company.neighborhood', null);
      } else {
        Ember.set(this, 'model.company.address', logradouro);
        Ember.set(this, 'model.company.neighborhood', bairro);
      }
    }
    useTermsValidation(values) {
      this.swal.warning('Para prosseguir é necessário aceitar os termos de uso');
    }
    autoComplete() {
      let {
        user
      } = this.model;
      Ember.set(this, 'model.company.name', `${user.firstName} ${user.lastName}`);
      Ember.set(this, 'model.company.cnpj', user.cpf);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "register", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCreditCardHash", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "getCreditCardHash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "populateAddress", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "populateAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "useTermsValidation", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "useTermsValidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoComplete", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "autoComplete"), _class.prototype)), _class));
  _exports.default = RegisterAccountIndexController;
});