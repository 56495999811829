define("account-talentrh/pods/registry/general/user/show/avd/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    user: Ember.computed.alias('model'),
    evaluationType: Ember.computed('user.typeEvaluation', function () {
      if (this.user.typeEvaluation === 1) {
        return '90';
      }
      if (this.user.typeEvaluation === 2) {
        return '180';
      }
      if (this.user.typeEvaluation === 3) {
        return '360';
      }
      return '';
    })
  });
  _exports.default = _default;
});