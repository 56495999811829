define("account-talentrh/pods/setup-wizard/steps/company/programs/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EOnLUMLz",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"\\n  Habilitar a exibição de programas da empresa?\\n\"],[13],[2,\"\\n\\n\"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,1,[\"permitCompanyPrograms\"]],[30,[36,1],[[30,[36,0],[[32,1,[\"permitCompanyPrograms\"]]],null]],null]]],null],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n  Exiba em um espaço exclusivo os programas que a empresa disponibiliza e as \\n  regras para uso: na página de Programas da Empresa é possível compartilhar o \\n  programa de integração, de participação de resultados ou até mesmo os programas \\n  sociais dos quais a empresa faz parte.\\n\"],[13],[2,\"\\n\\n\"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-4\"],[14,\"src\",\"https://player.vimeo.com/video/756948108?h=7bf716d914\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  Os Programas da Empresa ficarão disponíveis no Menu Empresa na tela principal do Voors.\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"permitBenefit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social mt-3\"]],[[\"@route\"],[\"setup-wizard.steps.company.programs.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cadastrar programas\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/company/programs/index/template.hbs"
    }
  });
  _exports.default = _default;
});