define("account-talentrh/pods/registry/avd/evaluation/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KBiqO0R6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-file-signature\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Avaliações\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Gerenciamento de Avaliações.\"],[13],[2,\"\\n\\n    \"],[10,\"a\"],[14,0,\"btn btn-primary mt-5\"],[14,\"target\",\"_blank\"],[15,6,[30,[36,0],[\"avd\",\"evaluations/new\"],null]],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n      Criar uma Avaliação\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"app-url\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/evaluation/index/template.hbs"
    }
  });
  _exports.default = _default;
});