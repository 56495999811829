define("account-talentrh/models/import-time-card", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'É necessário informar um título'
    }), (0, _emberCpValidations.validator)('length', {
      max: 100,
      message: 'O campo título deve conter no máximo 100 caracteres'
    })]
  });
  var _default = _model.default.extend(Validations, {
    competence: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company'),
    branch: (0, _model.belongsTo)('branch'),
    files: (0, _model.hasMany)('import-time-card-file')
  });
  _exports.default = _default;
});