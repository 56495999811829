define("account-talentrh/pods/reports/external-line/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    users: Ember.A(),
    jobFunctions: Ember.A(),
    departments: Ember.A(),
    generateReport: (0, _emberConcurrency.task)(function* () {
      let users = this.users.mapBy('id');
      let departments = this.departments.mapBy('id');
      let jobFunctions = this.jobFunctions.mapBy('id');
      this.swal.fire({
        title: 'Aguarde',
        text: 'Processando...',
        imageUrl: '/images/bx_loader.gif',
        showCancelButton: false,
        showConfirmButton: false
      });
      try {
        let reportData = yield this.ajax.request('/reports/userExternalLine', {
          data: {
            departments,
            jobFunctions,
            users
          }
        });
        if (!reportData.length) {
          return this.swal.warning('Nenhum registro foi encontrado!');
        }
        Ember.set(this, 'reportData', reportData);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    actions: {
      /* Gera um CSV do relatório gerado */
      csvExport() {
        let reportData = this.reportData;
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += ['Nome', 'Sobrenome', 'Telefone da Empresa', 'Ramal', 'Departamento', 'Função'];
        reportData.forEach(item => {
          let dataArray = new Array();
          for (let i in item) {
            let data = item[i] ? item[i] : '0';
            if (i !== "userId" && i !== "branches") {
              dataArray.push(data += ';');
            }
          }
          csvContent += '\r\n' + dataArray.join(' ');
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `ramais-atualizados.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
        document.body.removeChild(link);
      }
    }
  });
  _exports.default = _default;
});