define("account-talentrh/pods/components/payment-card/component", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    actions: {
      update() {
        // INICIAR A INSTÂNCIA DO CHECKOUT declarando um callback de sucesso
        const checkout = new PagarMeCheckout.Checkout({
          "encryption_key": _environment.default.pagarmeEncriptionKey,
          success: data => {
            //Tratar aqui as ações de callback do checkout, como exibição de mensagem ou envio de token para captura da transação
            this.ajax.post('/subscriptions/updateCreditCard', {
              data
            }).then(() => {
              this.swal.success('Dados atualizados com sucesso');
            }).catch(() => {
              this.swal.error('Não foi possível atualizar os dados. Tente novamente');
            });
          },
          fail: () => {
            this.swal.error('Falha ao registrar este cartão');
          }
        });
        checkout.open({
          "paymentButtonText": "Atualizar dados do seu cartão",
          "customerData": true,
          "paymentMethods": "credit_card",
          "uiColor": "#398ded",
          "createToken": "false"
        });
      }
    }
  });
  _exports.default = _default;
});