define("account-talentrh/pods/register/account/success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v8g3Y2rk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center py-5 my-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-check-circle text-success display-1\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"text-social mt-5\"],[12],[2,\"Seu cadastro foi concluído\"],[13],[2,\"\\n  \"],[10,\"a\"],[15,6,[30,[36,0],[\"social\"],null]],[14,0,\"btn btn-social mt-5\"],[12],[2,\"Comece por aqui\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-5\"],[12],[2,\"\\n    * As credenciais de acesso foram enviadas para o e-mail informado\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"app-url\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/register/account/success/template.hbs"
    }
  });
  _exports.default = _default;
});