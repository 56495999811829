define("account-talentrh/router", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('errors', function () {
      this.route('forbidden');
      this.route('inactive-account');
      this.route('not-found');
      this.route('not-access');
    });
    this.route('login');
    this.route('bad-url');
    this.route('configuration', function () {
      this.route('avd', function () {
        this.route('question', function () {
          this.route('edit', {
            path: ':question_id/edit'
          });
          this.route('new');
        });
      });
      this.route('nela', {
        path: 'social'
      }, function () {
        this.route('benefit', function () {
          this.route('edit', {
            path: ':benefit_id/edit'
          });
          this.route('new');
        });
        this.route('book', function () {
          this.route('edit', {
            path: ':book_id/edit'
          });
          this.route('new');
        });
        this.route('register', function () {
          this.route('company');
        });
        this.route('policy', function () {
          this.route('edit', {
            path: ':policy_id/edit'
          });
          this.route('new');
        });
        this.route('reason', function () {
          this.route('edit', {
            path: ':reason_id/edit'
          });
          this.route('new');
        });
        this.route('company-program');
        this.route('post-tag', function () {
          this.route('edit', {
            path: ':post_tag_id/edit'
          });
          this.route('create');
        });
        this.route('birthday-message');
      });
    });
    this.route('user', function () {
      this.route('changepassword');
    });
    this.route('super-user', function () {
      this.route('companies', function () {
        this.route('create');
        this.route('edit', {
          path: ':company_id/edit'
        }, function () {
          this.route('plugins');
          this.route('admins');
          this.route('habitus');
        });
        this.route('show', {
          path: ':company_id'
        }, function () {
          this.route('plugins');
          this.route('admins');
          this.route('habitus');
        });
      });
      this.route('indicators');
      this.route('post', function () {
        this.route('create');
        this.route('edit', {
          path: ':post_template_id/edit'
        });
        this.route('show', {
          path: ':post_template_id'
        }, function () {
          this.route('publish');
        });
      });
      this.route('wizard-status', function () {
        this.route('show', {
          path: ':company_id/show'
        }, function () {
          this.route('access-profile');
          this.route('collaboration');
          this.route('chat');
          this.route('company');
          this.route('groups');
          this.route('registrations');
          this.route('requests');
        });
      });
      this.route('control-version', function () {
        this.route('create');
        this.route('edit', {
          path: ':version_id/edit'
        });
        this.route('show', {
          path: ':version_id'
        });
      });
      this.route('lgpd', function () {
        this.route('delete', function () {
          this.route('user', function () {
            this.route('show', {
              path: ':lgpd_delete_user_solicitation_id'
            }, function () {
              this.route('agenda');
              this.route('educacao-corporativa');
              this.route('gestao-desempenho');
              this.route('pesquisa');
              this.route('projetos');
            });
          });
        });
        this.route('backup', function () {
          this.route('company', function () {
            this.route('show', {
              path: ':lgpd_backup_company_solicitation_id'
            });
          });
        });
      });
      this.route('article', function () {
        this.route('create');
        this.route('edit', {
          path: ':article_id/edit'
        });
        this.route('show', {
          path: ':article_id'
        });
      });
    });
    this.route('nela', function () {});
    this.route('reports', function () {
      this.route('acceptance-users');
      this.route('access-log');
      this.route('external-line');
      this.route('list-lunch');
      this.route('unlogged-users');
      this.route('team-management', function () {
        this.route('edit', {
          path: ':user_id/edit'
        });
      });
    });
    this.route('manage-lunch-list');
    this.route('settings', function () {
      this.route('avd', function () {
        this.route('ninebox-settings');
        this.route('evaluation-skills-questions');
      });
      this.route('emails');
      this.route('corporative-education');
      this.route('nela', {
        path: 'social'
      });
    });
    this.route('indicators', function () {});
    this.route('home', {
      path: '/'
    });
    this.route('manage-complained-post', function () {
      this.route('show', {
        path: ':post_complaining_id'
      });
    });
    this.route('version-history', function () {
      this.route('show', {
        path: ':version_id'
      });
    });
    this.route('registry', function () {
      this.route('avd', function () {
        this.route('evaluation', function () {
          this.route('show', {
            path: ':employee_evaluation_id'
          });
        });
        this.route('skill', function () {
          this.route('create');
          this.route('edit', {
            path: ':skill_id/edit'
          });
          this.route('show', {
            path: ':skill_id'
          }, function () {
            this.route('departments');
            this.route('job-functions');
            this.route('job-roles');
          });
          this.route('index', {
            path: '/'
          });
        });
        this.route('team', function () {
          this.route('create');
          this.route('edit', {
            path: ':team_id/edit'
          });
          this.route('show', {
            path: ':team_id'
          });
        });
        this.route('type-answer', function () {
          this.route('create');
          this.route('edit', {
            path: ':type_answer_id/edit'
          });
          this.route('show', {
            path: ':type_answer_id'
          }, function () {
            this.route('answer', function () {
              this.route('create');
              this.route('edit', {
                path: ':answer_id/edit'
              });
            });
          });
        });
        this.route('rating-notes');
        this.route('goal', function () {
          this.route('create');
          this.route('edit', {
            path: ':goal_id/edit'
          });
          this.route('show', {
            path: ':goal_id'
          }, function () {
            this.route('departments');
            this.route('job-functions');
            this.route('job-roles');
            this.route('exclusive-users');
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('import');
          });
        });
      });
      this.route('general', function () {
        this.route('access-profile', function () {
          this.route('create', function () {
            this.route('access-schedule');
          });
          this.route('edit', {
            path: ':access_profile_id/edit'
          }, function () {
            this.route('access-schedule');
          });
          this.route('show', {
            path: ':access_profile_id'
          }, function () {
            this.route('access-schedule');
            this.route('users');
          });
        });
        this.route('branch', function () {
          this.route('create');
          this.route('edit', {
            path: ':branch_id/edit'
          });
          this.route('show', {
            path: ':branch_id'
          }, function () {
            this.route('skills');
            this.route('goals');
            this.route('users');
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('print');
          });
        });
        this.route('car', function () {
          this.route('create');
          this.route('edit', {
            path: ':car_id/edit'
          });
          this.route('show', {
            path: ':car_id'
          });
          this.route('index', {
            path: '/'
          });
        });
        this.route('course', function () {
          this.route('create');
          this.route('edit', {
            path: ':course_id/edit'
          });
          this.route('show', {
            path: ':course_id'
          }, function () {});
          this.route('index', {
            path: '/'
          });
        });
        this.route('department', function () {
          this.route('create');
          this.route('edit', {
            path: ':department_id/edit'
          });
          this.route('show', {
            path: ':department_id'
          }, function () {
            this.route('trainings');
            this.route('responsible-for-solicitation');
            this.route('skills');
            this.route('goals');
          });
        });
        this.route('institution', function () {
          this.route('create');
          this.route('edit', {
            path: ':institution_id/edit'
          });
          this.route('show', {
            path: ':institution_id'
          });
        });
        this.route('job-function', function () {
          this.route('create');
          this.route('edit', {
            path: ':job_function_id/edit'
          });
          this.route('show', {
            path: ':job_function_id'
          }, function () {
            this.route('skills');
            this.route('trainings');
            this.route('professional-progressions');
            this.route('goals');
          });
        });
        this.route('job-role', function () {
          this.route('create');
          this.route('edit', {
            path: ':job_role_id/edit'
          });
          this.route('show', {
            path: ':job_role_id'
          }, function () {
            this.route('users');
            this.route('skills');
            this.route('goals');
          });
        });
        this.route('professional-progression-board', function () {
          this.route('create');
          this.route('edit', {
            path: ':professional_progression_board_id/edit'
          });
          this.route('details', {
            path: ':professional_progression_board_id'
          }, function () {});
          this.route('index', {
            path: '/'
          });
        });
        this.route('sector', function () {
          this.route('create');
          this.route('edit', {
            path: ':sector_id/edit'
          });
          this.route('details', {
            path: ':sector_id'
          }, function () {});
          this.route('index', {
            path: '/'
          });
        });
        this.route('room', function () {
          this.route('create');
          this.route('edit', {
            path: ':room_id/edit'
          });
          this.route('show', {
            path: ':room_id'
          }, function () {});
          this.route('index', {
            path: '/'
          });
        });
        this.route('user', function () {
          this.route('create');
          this.route('edit', {
            path: ':user_id/edit'
          }, function () {
            this.route('corporate');
            this.route('avd', function () {});
            this.route('trainings');
            this.route('change-password');
            this.route('experience');
            this.route('formation');
            this.route('book');
            this.route('change-recover-password-info');
          });
          this.route('show', {
            path: ':user_id'
          }, function () {
            this.route('corporate');
            this.route('avd');
            this.route('history');
            this.route('trainings');
            this.route('permissions');
          });
          this.route('index', {
            path: ''
          }, function () {
            this.route('import');
          });
        });
        this.route('company');
        this.route('branch-group', function () {
          this.route('create');
          this.route('edit', {
            path: ':branch_group_id/edit'
          });
          this.route('show', {
            path: ':branch_group_id'
          });
        });
        this.route('ia-repositories', function () {
          this.route('create');
          this.route('edit', {
            path: ':open_ai_repository_id/edit'
          });
          this.route('show', {
            path: ':open_ai_repository_id'
          }, function () {
            this.route('branches');
            this.route('departments');
            this.route('job-roles');
            this.route('job-functions');
            this.route('users');
            this.route('index', {
              path: '/'
            });
          });
        });
      });
      this.route('social', function () {
        this.route('company-program', function () {
          this.route('edit', {
            path: ':company_program_id/edit'
          });
          this.route('show', {
            path: ':company_program_id'
          }, function () {
            this.route('attachments');
            this.route('branches');
          });
          this.route('create');
        });
        this.route('vacancy', function () {
          this.route('edit', {
            path: ':vacancy_id/edit'
          });
          this.route('show', {
            path: ':vacancy_id'
          }, function () {
            this.route('publish');
            this.route('job-roles');
            this.route('functions');
          });
          this.route('create');
        });
        this.route('mural', function () {
          this.route('create');
          this.route('edit', {
            path: ':dashboard_id/edit'
          });
          this.route('gridstack', {
            path: 'gridstack/:dashboard_id'
          }, function () {
            this.route('edit');
          });
          this.route('preview', {
            path: 'preview/:dashboard_id'
          });
        });
        this.route('import', function () {
          this.route('create');
          this.route('edit', {
            path: ':id/edit'
          });
          this.route('show', {
            path: ':id/show'
          });
        });
      });
    });
    this.route('privacy-policy');
    this.route('register', function () {
      this.route('account', function () {
        this.route('index', {
          path: '/:plan'
        });
        this.route('plan');
        this.route('success');
      });
      this.route('user', {
        path: '/:token'
      });
    });
    this.route('setup-wizard', function () {
      this.route('welcome');
      this.route('steps', function () {
        this.route('feed', function () {
          this.route('post');
          this.route('communicated');
          this.route('events');
          this.route('marketplace');
          this.route('survey');
          this.route('report-post');
          this.route('news');
        });
        this.route('collaboration', function () {
          this.route('forum');
          this.route('absence');
          this.route('birthday-page');
          this.route('show-birthday');
          this.route('dashboard');
        });
        this.route('chat');
        this.route('access-profile', function () {
          this.route('info');
          this.route('manage', function () {
            this.route('create', function () {
              this.route('access-schedule');
            });
            this.route('edit', {
              path: ':access_profile_id/edit'
            }, function () {
              this.route('access-schedule');
            });
          });
        });
        this.route('registry', function () {
          this.route('branch', function () {
            this.route('manage', function () {
              this.route('import');
            });
          });
          this.route('department', function () {
            this.route('manage', function () {
              this.route('import');
            });
          });
          this.route('job-role', function () {
            this.route('manage', function () {
              this.route('import');
            });
          });
          this.route('user', function () {
            this.route('manage', function () {
              this.route('import');
            });
          });
        });
        this.route('solicitation', function () {
          this.route('managerFlow');
          this.route('rhFlow');
          this.route('manager-flow');
          this.route('rh-flow');
        });
        this.route('company', function () {
          this.route('album');
          this.route('meet');
          this.route('decision');
          this.route('benefits', function () {
            this.route('manage', function () {
              this.route('new');
              this.route('edit', {
                path: ':benefit_id/edit'
              });
            });
          });
          this.route('policies', function () {
            this.route('manage', function () {
              this.route('edit', {
                path: ':policy_id/edit'
              });
              this.route('new');
            });
          });
          this.route('programs', function () {
            this.route('manage', function () {
              this.route('edit', {
                path: ':company_program_id/edit'
              });
              this.route('new');
            });
          });
          this.route('organogram');
        });
        this.route('groups', function () {
          this.route('group', function () {
            this.route('manage', function () {
              this.route('edit', {
                path: ':user_group_id/edit'
              });
              this.route('new');
            });
          });
          this.route('link', function () {
            this.route('manage', function () {
              this.route('new');
              this.route('edit', {
                path: ':user_link_id/edit'
              });
            });
          });
        });
      });
      this.route('done');
      this.route('registry', function () {
        this.route('led', function () {});
      });
    });
    this.route('subscription', function () {
      this.route('payment');
      this.route('plan');
    });
    this.route('payment-validation', function () {
      this.route('create');
    });
    this.route('forgot-password', function () {
      this.route('send-to', {
        path: 'send-to/:tokenURL'
      });
      this.route('not-found');
    });
    this.route('reset-password', {
      path: 'reset-password/:token'
    });
    this.route('general-data');
    this.route('lgpd', function () {
      this.route('backup', function () {
        this.route('company', function () {
          this.route('create');
          this.route('show', {
            path: ':lgpd_backup_company_solicitation_id'
          });
        });
      });
      this.route('delete', function () {
        this.route('user', function () {
          this.route('create');
          this.route('show', {
            path: ':lgpd_delete_user_solicitation_id'
          }, function () {
            this.route('agenda');
            this.route('educacao-corporativa');
            this.route('gestao-desempenho');
            this.route('pesquisa');
            this.route('projetos');
          });
        });
        this.route('company', function () {
          this.route('create');
          this.route('item');
          this.route('show', {
            path: ':lgpd_delete_company_solicitation_id'
          }, function () {
            this.route('agenda');
            this.route('educacao-corporativa');
            this.route('gestao-desempenho');
            this.route('pesquisa');
            this.route('projetos');
          });
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});