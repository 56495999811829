define("account-talentrh/pods/super-user/wizard-status/show/collaboration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vmAMFxUP",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"settings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,1,[\"hasCheck\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/show/collaboration/template.hbs"
    }
  });
  _exports.default = _default;
});