define("account-talentrh/pods/components/indicator-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['indicator-loader', 'application-indicators'],
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    indicatorsToLoad: Ember.A(),
    didInsertElement() {
      this.loadFilterDate();
    },
    isAdmin: Ember.computed.alias('session.user.admin'),
    // Carrega filtros do localStorage e iniciar o carregamento dos indicadores
    loadFilterDate() {
      let storageFilter = this.getStorageFilters() || {};
      let generalFilters = this.generalFilters;
      if ((storageFilter.startDate || !generalFilters.filters.startDate) && (storageFilter.endDate || !generalFilters.filters.endDate)) {
        let departments = storageFilter.department || [];
        let jobFunction = storageFilter.jobFunction || [];
        let jobRole = storageFilter.jobRole || [];
        let branch = storageFilter.branch || [];
        Ember.set(this, 'generalFilters.filterData', {
          startDate: storageFilter.startDate,
          endDate: storageFilter.endDate,
          department: departments,
          jobFunction: jobFunction,
          jobRole: jobRole,
          branch: branch
        });
        this.indicatorsToLoad.clear();
        this.loadIndicators();
      } else {
        this.send('openModalFilter', this.generalFilters);
      }
    },
    // Retorna os filtros do localStorage ou FALSE caso ainda não exista
    getStorageFilters() {
      let storageData = localStorage.getItem('indicatorFilters');
      try {
        return JSON.parse(storageData);
      } catch (e) {
        return false;
      }
    },
    // Método responsável por carregar todos os indicadores ou algum específico caso seja passado por parâmetro.
    loadIndicators(specificIndicator) {
      let indicatorsToLoad = this.indicatorsToLoad;
      let indicatorDefinitions = this.indicatorDefinitions;
      indicatorDefinitions.forEach(indicatorDefinition => {
        if (specificIndicator && specificIndicator.name !== indicatorDefinition.name) {
          return;
        }
        if (indicatorDefinition.allowedForAdmin && !this.isAdmin) {
          return;
        }
        let alreadyAdded = indicatorsToLoad.findBy('name', indicatorDefinition.name);
        if (!alreadyAdded) {
          indicatorsToLoad.pushObject({
            loaded: false,
            data: null,
            name: indicatorDefinition.name,
            template: indicatorDefinition.template,
            classNames: indicatorDefinition.classNames,
            filters: indicatorDefinition.filters,
            componentName: indicatorDefinition.componentName,
            filterData: indicatorDefinition.filterData,
            hideBtnFilter: indicatorDefinition.hideBtnFilter
          });
        } else {
          Ember.set(alreadyAdded, 'loaded', false);
          Ember.set(alreadyAdded, 'data', null);
        }
      });
      indicatorsToLoad.forEach(indicator => {
        if (specificIndicator && specificIndicator.name !== indicator.name) {
          return;
        }
        this.indicatorsLoader.perform(indicator, this.buildIndicatorFilter(indicator));
      });
    },
    buildIndicatorFilter(indicator) {
      let filtersName = ['startDate', 'endDate', 'branch', 'department', 'jobRole', 'jobFunction'];
      let generalFilters = this.generalFilters;
      if (!indicator.filters) return {};
      let filter = {};
      filtersName.forEach(f => {
        if (!indicator.filters[f]) {
          return;
        }
        if (f === 'branch' || f === 'department' || f === 'jobRole' || f === 'jobFunction') {
          let filterArray = indicator.filterData[f] || generalFilters.filterData[f] || [];
          filter[f] = filterArray.mapBy('id');
        } else {
          let date = indicator.filterData[f] || generalFilters.filterData[f];
          if (date) {
            filter[f] = `${moment(date).format("YYYY-MM-DD")} ${f === 'startDate' ? '00:00:00' : '23:59:59'}`;
          }
        }
      });
      return filter;
    },
    indicatorsLoader: (0, _emberConcurrency.task)(function* (indicator, query) {
      try {
        let host = this.host || 'conta';
        Ember.set(indicator, 'loaded', false);
        let indicatorData = yield this.fetch.request(`indicators/${indicator.name}`, {
          host,
          data: query
        });
        Ember.set(indicator, 'loaded', true);
        Ember.set(indicator, 'data', indicatorData);
      } catch (e) {
        Ember.debug(`Falha ao carrega o indicador ${indicator.name}`);
      }
    }),
    actions: {
      load(specificIndicator) {
        if (specificIndicator.isGeneralFilters) {
          this.loadIndicators();
        } else {
          this.loadIndicators(specificIndicator);
        }
      },
      openModalFilter(indicator) {
        Ember.set(this, 'indicatorToSetFilter', indicator);
        Ember.set(this, 'openIndicatorFilters', true);
      }
    }
  });
  _exports.default = _default;
});