define("account-talentrh/pods/registry/avd/rating-notes/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistryAvdRatingNotesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('model'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('ratings.[],.@each.active,value,title'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class RegistryAvdRatingNotesController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _defineProperty(this, "typeOptions", Ember.A([{
        label: 'Pontos',
        value: 'points'
      }, {
        label: 'Porcentagem',
        value: 'percent'
      }]));
      _initializerDefineProperty(this, "ratingNotesConfig", _descriptor5, this);
      _initializerDefineProperty(this, "selectedType", _descriptor6, this);
      _initializerDefineProperty(this, "editing", _descriptor7, this);
      _initializerDefineProperty(this, "editingRatings", _descriptor8, this);
      _initializerDefineProperty(this, "totalNumberOfOrdersSelected", _descriptor9, this);
      _initializerDefineProperty(this, "ratings", _descriptor10, this);
    }
    get ratingsActives() {
      return this.ratings.filterBy('active');
    }
    get isRatingPoints() {
      return this.selectedType.value === 'points';
    }
    *save() {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar alteração da classificação?', {
          text: `As avaliações de ações de PDI's em aberto que foram realizadas com a configuração atual serão excluídas,
        sendo necessário realizar novamente a avaliação com a classificação atualizada.`,
          confirmButtonText: 'Sim'
        });
        if (!value) {
          return;
        }
        if (this.totalNumberOfOrdersSelected) {
          this.ratingNotesConfig.totalNumberOfOrders = this.totalNumberOfOrdersSelected;
        }
        if (this.selectedType) {
          this.ratingNotesConfig.type = this.selectedType.value;
        }
        if (!this.ratingsActives.length) {
          return this.swal.warning('É necessário informar o número de classicações');
        }
        const ratingNoteIdOld = this.ratingNotesConfig.id;
        const ratingNoteConfig = yield this.ratingNotesConfig.save();
        ratingNoteConfig.reload();
        if (Number(ratingNoteIdOld) !== Number(ratingNoteConfig.id)) {
          const newConfig = yield this.store.peekRecord('rating-note', ratingNoteConfig.id);
          yield Ember.RSVP.map(this.ratings, rating => {
            let newRatingItem = this.store.createRecord('rating-note-item', {
              order: rating.order,
              title: rating.title,
              icon: rating.icon,
              value: rating.value,
              active: rating.active,
              company: rating.company,
              ratingNote: newConfig
            });
            return newRatingItem.save();
          });
        } else {
          yield Ember.RSVP.map(this.ratings, rating => {
            return rating.save();
          });
        }
        this.editing = false;
        this.editingRatings = false;
        this.toast.success('Nota de avaliação atualizada');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onChangeType(value) {
      this.selectedType = value;
      this.updateRatings();
    }
    updateRatings() {
      try {
        const totalRatings = this.ratings.filterBy('active').length;
        const gradualValue = 100 / totalRatings;
        this.ratings = this.ratings.sortBy('order').map(rating => {
          if (this.selectedType.value === 'percent') {
            if (rating.active) {
              if (!this.ratingNotesConfig.setPercentManual) {
                Ember.set(rating, 'value', Math.round(rating.order * gradualValue));
              }
              if (!this.ratingNotesConfig.setTitleManual || !this.ratingNotesConfig.setPercentManual) {
                Ember.set(rating, 'title', `${rating.value} %`);
              }
            } else {
              Ember.set(rating, 'value', 0);
              Ember.set(rating, 'title', `${rating.value}`);
            }
          } else {
            if (rating.active) {
              Ember.set(rating, 'value', Math.round(rating.order * gradualValue));
              Ember.set(rating, 'title', `${rating.order}`);
            } else {
              Ember.set(rating, 'value', 0);
              Ember.set(rating, 'title', `${rating.value}`);
            }
          }
          return rating;
        });
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onCancelEdit() {
      this.editing = false;
      this.editingRatings = false;
      this.ratingNotesConfig.rollbackAttributes();
      this.ratingNotesConfig.ratings.toArray().forEach(rating => {
        rating.rollbackAttributes();
      });
      this.onRatingSelect();
    }
    onRatingSelect(rating, indexSelected) {
      try {
        let totalNumberOfOrders = indexSelected;
        if (Ember.isArray(indexSelected) && !indexSelected.length) {
          totalNumberOfOrders = this.ratingNotesConfig.totalNumberOfOrders - 1;
        }
        if (!Ember.isArray(indexSelected)) {
          this.totalNumberOfOrdersSelected = rating ? rating.order : this.ratingNotesConfig.totalNumberOfOrders - 1;
          totalNumberOfOrders = rating ? rating.order - 1 : this.ratingNotesConfig.totalNumberOfOrders - 1;
        }
        this.ratings = this.ratings.map(rt => {
          Ember.set(rt, 'active', false);
          return rt;
        });
        this.ratings = this.ratings.sortBy('order').map((rt, index) => {
          if (index <= totalNumberOfOrders) {
            Ember.set(rt, 'active', !rt.active);
          }
          return rt;
        });
        this.updateRatings();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ratingNotesConfig", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.typeOptions.findBy('value', this.ratingNotesConfig.type);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "editing", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "editingRatings", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "totalNumberOfOrdersSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.ratingNotesConfig.totalNumberOfOrders;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "ratings", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.ratingNotesConfig.ratings;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "ratingsActives", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "ratingsActives"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeType", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRatings", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateRatings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelEdit", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRatingSelect", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onRatingSelect"), _class.prototype)), _class));
  _exports.default = RegistryAvdRatingNotesController;
});