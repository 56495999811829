define("account-talentrh/pods/configuration/avd/question/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    descriptionMessageIsNull: Ember.computed.equal('question.description', undefined),
    disableSkill: Ember.computed('question.{description.length}', function () {
      if (this.question.description && this.question.description.length && this.question.description !== Ember.get(this, 'question.skill.description')) {
        Ember.set(this, 'question.skill', null);
        return true;
      } else {
        return false;
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.question.title) {
        return this.swal.warning('É necessário informar uma pergunta');
      }
      let message = this.question.isNew ? 'Pergunta cadastrada' : 'Pergunta atualizada';
      if (!this.question.description && this.question.skill) {
        Ember.set(this, 'question.description', this.question.skill.get('description'));
      }
      try {
        yield this.question.save();
        this.toast.success(message);
        this.router.transitionTo('configuration.avd.question');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      skillChange(selected) {
        Ember.set(this, 'question.skill', selected);
        Ember.set(this, 'question.description', this.question.skill.get('description'));
      }
    }
  });
  _exports.default = _default;
});