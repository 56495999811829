define("account-talentrh/pods/registry/general/branch/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SuEA//NE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"registry/general/branch/form\",[],[[\"@branch\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/branch/create/template.hbs"
    }
  });
  _exports.default = _default;
});