define("account-talentrh/pods/components/settings/setting-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/7NIpJOf",
    "block": "{\"symbols\":[\"Card\",\"@setting\",\"@value\",\"@onChange\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-card\",[[24,0,\"bg-light mt-2\"],[16,\"title\",[32,2,[\"description\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Body\"]],[[24,0,\"d-flex text-truncate\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"i\"],[16,0,[31,[\"fa fa-cog fa-2x pr-2 \",[32,0,[\"classIcon\"]]]]],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"manage\"],null],[12],[13],[2,\"\\n\\n      \"],[8,\"ui-checkbox\",[[24,0,\"pl-2 pt-1 mb-0 border-left text-truncate\"]],[[\"@checked\",\"@onChange\"],[[32,3],[30,[36,1],[[32,4],[32,2,[\"value\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2,[\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"fn\",\"showIf\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/setting-item/template.hbs"
    }
  });
  _exports.default = _default;
});