define("account-talentrh/pods/super-user/post/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    isMessage: Ember.computed.equal('postTemplate.type', 'message'),
    isAnnouncement: Ember.computed.equal('postTemplate.type', 'announcement'),
    isNews: Ember.computed.equal('postTemplate.type', 'news'),
    save: (0, _emberConcurrency.task)(function* () {
      let validations = this.postTemplate.validations.attrs;
      if (!this.isMessage && validations.title.isInvalid) {
        return this.swal.validation(validations.title);
      }
      if (validations.body.isInvalid) {
        return this.swal.validation(validations.body);
      }
      try {
        let message = this.postTemplate.isNew ? 'Publicação criada' : 'Publicação atualizada';
        yield this.postTemplate.save();
        this.toast.success(message);
        this.router.transitionTo('super-user.post.show', this.postTemplate.id);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});