define("account-talentrh/pods/registry/general/job-function/show/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralJobFunctionShowIndexRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.general.job-function.show');
    }
  }
  _exports.default = RegistryGeneralJobFunctionShowIndexRoute;
});