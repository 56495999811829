define("account-talentrh/pods/registry/general/institution/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'registry.general.institution.show',
    model: Ember.computed.alias('institution.id'),
    identificationNumber: Ember.computed('institution.{cnpj,cpf,physicalPerson}', function () {
      if (this.institution.physicalPerson && this.institution.cpf) {
        return this.institution.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }
      if (!this.institution.physicalPerson && this.institution.cnpj) {
        return this.institution.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
      return '';
    })
  });
  _exports.default = _default;
});