define("account-talentrh/models/group-question-detail", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    questionsGroup: (0, _model.belongsTo)('questionsGroup'),
    question: (0, _model.belongsTo)('question'),
    percentage: (0, _model.attr)('number'),
    order: (0, _model.attr)('number')
  });
  _exports.default = _default;
});