define("account-talentrh/pods/components/settings/details/communication-api/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VYGbdQCa",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"form-group\"],[4,[38,1],[[30,[36,0],[[32,0,[\"loadIntegrationToken\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Token de Integração\"],[13],[2,\"\\n  \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\",\"@type\",\"@disabled\"],[[32,0,[\"integrationToken\"]],\"text\",true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/details/communication-api/template.hbs"
    }
  });
  _exports.default = _default;
});