define("account-talentrh/pods/registry/social/import/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistrySocialImportsFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 5
  }), _dec15 = Ember.computed('args.importFile._internalModel.modelName', 'identifierOptions', 'payrollLoanUser', 'residenceTypeOptions', {
    get() {
      const modelName = this.args.importFile?._internalModel?.modelName ?? null;
      if (!modelName) {
        return '';
      }
      return this.identifierOptions.find(option => {
        return option.model === modelName;
      });
    },
    set(key, selected) {
      if (selected && selected.value) {
        this.disableIdentifierSelection = true;
      }
      return selected;
    }
  }), _dec16 = Ember._action, (_class = class RegistrySocialImportsFormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "utils", _descriptor6, this);
      _initializerDefineProperty(this, "importFileImageUpload", _descriptor7, this);
      _initializerDefineProperty(this, "importFileVideoUpload", _descriptor8, this);
      _initializerDefineProperty(this, "users", _descriptor9, this);
      _initializerDefineProperty(this, "identifierOptions", _descriptor10, this);
      _initializerDefineProperty(this, "disableIdentifierSelection", _descriptor11, this);
      _initializerDefineProperty(this, "errorFiles", _descriptor12, this);
      _initializerDefineProperty(this, "branch", _descriptor13, this);
      _initializerDefineProperty(this, "identifierOptionSelected", _descriptor14, this);
    }
    *uploadFile(file) {
      let filename = Ember.get(file, 'name');
      let cpfFile = '';
      if (Ember.get(file, 'name').includes('.PDF')) {
        cpfFile = Ember.get(file, 'name').replace('.PDF', '');
      } else {
        cpfFile = Ember.get(file, 'name').replace('.pdf', '');
      }
      if (Ember.isEmpty(file)) {
        return;
      }
      if (cpfFile.length != 11 || isNaN(cpfFile)) {
        file.queue.remove(file);
        this.errorFiles.pushObject({
          cpf: cpfFile,
          message: 'O nome do arquivo deve representar o cpf do usuário e o formato aceito é .pdf, exemplo: 01234567890.pdf.'
        });
        return;
      }
      let userObj = yield this.ajax.request(`${this.identifierOptionSelected.route}/getUserId/${cpfFile}`);
      if (!userObj.userId) {
        file.queue.remove(file);
        this.errorFiles.pushObject({
          cpf: cpfFile,
          message: 'CPF não encontrado na base de dados'
        });
        return;
      }
      try {
        let response = yield this.ajax.request(`${this.identifierOptionSelected.route}/putSignedLink`);
        let attachment = yield this.store.createRecord(`${this.identifierOptionSelected.modelFile}`, {
          path: response.key,
          filename: filename
        });
        yield file.uploadBinary(response.link, {
          method: 'PUT'
        });
        let user = yield this.store.findRecord('user', userObj.userId);
        yield user.branches;
        this.users.pushObject(user);
        attachment.set('user', user);
        yield attachment.save().then(created => {
          this.args.importFile.files.pushObject(created);
        });
        this.disableIdentifierSelection = true;
        this.toast.success('Arquivo enviado!');
      } catch (e) {
        file.queue.remove(file);
        this.errorFiles.pushObject({
          cpf: cpfFile,
          message: e
        });
      }
      return;
    }
    *removeFile(attachment) {
      try {
        const {
          value
        } = yield this.swal.confirm('Deseja remover este arquivo?', {
          text: 'Esta ação não pode ser desfeita'
        });
        if (!value) return;
        this.args.importFile.files.removeObject(attachment);
        const index = this.users.findIndex(user => user.id === attachment.user.get('id'));
        if (index >= 0) {
          this.users.removeAt(index);
        }
        yield attachment.destroyRecord();
        this.toast.success('Arquivo removido!');
      } catch (e) {
        console.log(e);
        return this.swal.catch(e);
      }
    }
    checkIfUsersIsOnBranch(users, branch) {
      if (!branch) {
        return null;
      }
      const userIsNotInBranch = [];
      for (const user of users) {
        const isOnBranch = user.branches.find(userBranch => branch.id === userBranch.id);
        if (!isOnBranch) {
          userIsNotInBranch.push(user);
        }
      }
      if (!userIsNotInBranch.length) {
        return null;
      }
      let errorMessage = 'Os usuários abaixo não fazem parte da filial informada. Remova-os da lista para concluir a importação.<br /> <br />';
      userIsNotInBranch.forEach(user => {
        errorMessage += `${user.fullName}<br />`;
      });
      return errorMessage;
    }
    *save() {
      const errorMessage = this.checkIfUsersIsOnBranch(this.users, this.branch);
      if (errorMessage) {
        yield this.swal.fire({
          icon: 'error',
          title: 'Ops',
          html: errorMessage,
          showCancelButton: false
        });
        return;
      }
      let {
        importFile
      } = this.args;
      importFile.branch = this.branch;
      console.log(this.importFile);
      if (!this.identifierOptionSelected) {
        return this.swal.warning('É necessário informar um identificador');
      }
      if (!importFile.competence) {
        return this.swal.warning('É necessário informar uma competência');
      } else {
        if (this.identifierOptionSelected.id === 'CR') {
          const isValidFormat = (0, _moment.default)(importFile.competence, 'YYYY', true).isValid();
          if (!isValidFormat) {
            return this.swal.warning('Competência inválida, use o formato de AAAA (ano).');
          }
        } else {
          const isValidFormat = (0, _moment.default)(importFile.competence, 'MM/YYYY', true).isValid();
          if (!isValidFormat) {
            return this.swal.warning('Competência inválida, use o formato de MM/AAAA (mês /ano).');
          }
        }
      }
      if (!importFile.id) {
        importFile = this.store.createRecord(this.identifierOptionSelected.model, {
          title: importFile.title,
          competence: importFile.competence,
          branch: this.branch
        });
      }
      if (!importFile.validations.isValid) {
        return this.swal.validation(importFile.validations);
      }
      if (!this.args.importFile.files.length) {
        return this.swal.warning('É necessário adicionar ao menos 1 arquivo.');
      }
      try {
        if (!importFile.id) {
          yield importFile.save().then(create => {
            this.args.importFile.files.map(file => {
              file.import = create;
              file.save();
            });
          });
          this.toast.success('Importação salva com sucesso!');
          this.router.transitionTo('registry.social.import');
        } else {
          importFile.files.map(file => {
            file.import = importFile;
            file.save();
          });
          yield importFile.save();
          this.toast.success('Importação salva com sucesso!');
          this.router.transitionTo('registry.social.import.show', importFile.id);
        }
        return;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    showErrorFiles() {
      let htmlMessages = '';
      this.errorFiles.map(error => {
        htmlMessages += '<li>' + error.cpf + ': ' + error.message + '</li>';
      });
      this.swal.warning('', {
        title: 'Arquivos não processados',
        html: `<ul class='list-group'>${htmlMessages}</ul>`
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "importFileImageUpload", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "importFileVideoUpload", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "identifierOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        id: 'RP',
        title: 'Recibo de Pagamento',
        route: 'receiptFile',
        model: 'import-receipt',
        modelFile: 'import-receipt-file'
      }, {
        id: 'CP',
        title: 'Cartão Ponto',
        route: 'timeCard',
        model: 'import-time-card',
        modelFile: 'import-time-card-file'
      }, {
        id: 'CR',
        title: 'Comprovante de Rendimentos',
        route: 'annualIncoming',
        model: 'import-annual-incoming',
        modelFile: 'import-annual-incoming-file'
      }];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "disableIdentifierSelection", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "errorFiles", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "branch", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFile", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "identifierOptionSelected", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showErrorFiles", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "showErrorFiles"), _class.prototype)), _class));
  _exports.default = RegistrySocialImportsFormComponent;
});