define("account-talentrh/pods/setup-wizard/steps/access-profile/manage/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G6wLBX/o",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"wizard-crud\"],[12],[2,\"\\n  \"],[8,\"registry/general/access-profile/form\",[],[[\"@accessProfile\",\"@useInWizard\"],[[32,1],true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/access-profile/manage/edit/template.hbs"
    }
  });
  _exports.default = _default;
});