define("account-talentrh/pods/registry/general/user/show/history/horary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    showContent: false,
    actions: {
      alterShowContent() {
        this.set('showContent', !this.showContent);
      }
    }
  });
  _exports.default = _default;
});