define("account-talentrh/pods/registry/avd/goal/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "COV0TaNZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-chart-line\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Metas\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Gerenciamento de metas\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mt-4\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"registry.avd.goal.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n        Incluir Meta\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"isAdmin\"]],[32,0,[\"permission\",\"accessProfile\",\"generalManager\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"mt-3\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"registry.avd.goal.index.import\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-upload\"],[12],[13],[2,\"\\n            Importar Metas\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/goal/index/template.hbs"
    }
  });
  _exports.default = _default;
});