define("account-talentrh/pods/components/settings/setting-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['disabled:d-none'],
    classIcon: Ember.computed('setting.componentDetail', 'value', function () {
      if (this.setting.componentDetail && this.value) {
        return 'text-primary cursor-pointer';
      } else {
        return 'text-muted';
      }
    }),
    actions: {
      manage() {
        if (this.setting.componentDetail && this.value) {
          this.manageSetting(this.setting);
        }
      }
    }
  });
  _exports.default = _default;
});