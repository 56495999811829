define("account-talentrh/pods/registry/general/user/create/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    branches: null,
    // Single line computed properties
    user: Ember.computed.alias('model'),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.user.validations.isValid) {
        return this.swal.validation(this.user.validations);
      }
      try {
        let user = yield this.user.save();
        this.toast.success('Usuário cadastrado');
        this.transitionToRoute('registry.general.user.show', user.id);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      changeBranch() {
        Ember.set(this, 'branches', this.user.branches.mapBy('id'));
      }
    }
  });
  _exports.default = _default;
});