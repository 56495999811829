define("account-talentrh/pods/registry/social/mural/gridstack/edit/controller", ["exports", "ember-concurrency-decorators", "account-talentrh/utils/dashboard-slide-item-utils", "account-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _dashboardSlideItemUtils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistrySocialMuralGridstackEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('model'), (_class = class RegistrySocialMuralGridstackEditController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['type', 'slideItemId']);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "dashboardSlideItemVideoUpload", _descriptor5, this);
      _initializerDefineProperty(this, "dashboardSlideItemImageUpload", _descriptor6, this);
      _defineProperty(this, "slideItemContent", void 0);
      _defineProperty(this, "types", _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES);
      _defineProperty(this, "properties", {
        birthday: 'dashboardSlideItemBirthday',
        album: 'dashboardSlideItemAlbum',
        admission: 'dashboardSlideItemAdmission',
        absence: 'dashboardSlideItemAbsence',
        ad: 'dashboardSlideItemAds',
        announcement: 'dashboardSlideItemAnnouncement',
        event: 'dashboardSlideItemEvent',
        vacancy: 'dashboardSlideItemVacancy',
        recent_users: 'dashboardSlideItemRecentUser',
        image: 'dashboardSlideItemImage',
        video: 'dashboardSlideItemVideo',
        link: 'dashboardSlideItemLink'
      });
      _initializerDefineProperty(this, "slideItem", _descriptor7, this);
    }
    uploadFile(_ref) {
      var _this = this;
      let {
        file,
        endPoint,
        paramName,
        isImage = true,
        existingFile
      } = _ref;
      return function* () {
        try {
          const url = `${_environment.default.apiUrl.conta}${endPoint}`;
          const fileBlob = yield _this.dataURLtoFile({
            url: file,
            isImage
          });
          const data = new FormData();
          data.append('existingFile', existingFile || null);
          data.append(paramName, fileBlob);
          const response = yield fetch(url, {
            method: 'POST',
            body: data,
            headers: {
              Authorization: `Bearer ${_this.session.token}`
            }
          }).then(res => res.json());
          return response;
        } catch (e) {
          _this.swal.catch(e);
        }
      }();
    }
    dataURLtoFile(_ref2) {
      let {
        url,
        isImage
      } = _ref2;
      return new Promise((resolve, reject) => {
        fetch(url).then(res => res.blob()).then(blob => {
          let file;
          if (isImage) {
            file = new File([blob], 'file-name.png', {
              type: 'image/png'
            });
          } else {
            file = new File([blob], 'file-name.mp4', {
              type: 'video/mp4'
            });
          }
          resolve(file);
        }).catch(reject);
      });
    }

    /**
    * camelCaseToDash('accessFrequency') => "access-frequency"
    * camelCaseToDash('waitAMoment') => "wait-a-moment"
    */
    _camelCaseToDash(str) {
      return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }
    *save(data) {
      const relationshipBranches = [this.types.BIRTHDAY, this.types.ADMISSION, this.types.ABSENCE, this.types.AD, this.types.ANNOUNCEMENT, this.types.RECENT_USERS, this.types.EVENT, this.types.VACANCY];
      try {
        let slideItemContent = yield this.slideItem.get(this.properties[this.type]);
        if (!slideItemContent) {
          let dashboardSlideItemRelStr = this._camelCaseToDash(this.properties[this.type]);
          dashboardSlideItemRelStr = dashboardSlideItemRelStr.replace('ads', 'ad');
          slideItemContent = yield this.store.createRecord(dashboardSlideItemRelStr);
        }
        if (this.type === this.types.VIDEO && data.path && data.path.type) {
          if (data.path.type !== 'video/mp4') {
            return this.swal.warning('Formato não permitido, insira um vídeo no formato .mp4');
          }
        }
        this.slideItem.title = data.title;
        this.slideItem.color = data.color;
        if (relationshipBranches.includes(this.type) && data.branches) {
          slideItemContent.branches = data.branches.length ? data.branches : [];
        }
        if (this.type === this.types.ALBUM) {
          slideItemContent.companyAlbum = data.companyAlbum ? data.companyAlbum : null;
        }
        if (this.type === this.types.LINK) {
          slideItemContent.url = data.url ? data.url : null;
        }
        if (this.type === this.types.IMAGE) {
          if (data.signedUrl && data.signedUrl.includes('base64')) {
            this.swal.loading('Carregando arquivo');
            yield this.dashboardSlideItemImageUpload.save.perform(slideItemContent, data.path);
            this.swal.close();
          } else {
            slideItemContent.url = data.url ? data.url : '';
            slideItemContent.signedUrl = data.signedUrl ? data.signedUrl : '';
            slideItemContent.path = data.path ? data.path : '';
          }
        }
        if (this.type === this.types.VIDEO) {
          if (data.signedUrl && data.signedUrl.includes('base64')) {
            this.swal.loading('Carregando arquivo');
            yield this.dashboardSlideItemVideoUpload.save.perform(slideItemContent, data.path);
            this.swal.close();
          } else {
            slideItemContent.url = data.url ? data.url : '';
            slideItemContent.signedUrl = data.signedUrl ? data.signedUrl : '';
            slideItemContent.path = data.path ? data.path : '';
          }
        }
        yield this.slideItem.save();
        slideItemContent.dashboardSlideItem = this.slideItem;
        if (this.type !== 'ad') {
          yield slideItemContent.save();
        }
        this.toast.success('Programação atualizada.');
        this.router.transitionTo('registry.social.mural.gridstack', this.slideItem.dashboardSlide.get('dashboard.id'));
      } catch (e) {
        console.log(e);
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemVideoUpload", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemImageUpload", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "slideItem", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = RegistrySocialMuralGridstackEditController;
});