define("account-talentrh/pods/settings/avd/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SettingsAvdRoute = (_dec = Ember.inject.service, (_class = class SettingsAvdRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
    }
    model() {
      return this.permission.companyConfig;
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (model.evaluationJobFunctionSkill) {
        const jobFunctionsHardSkill = [];
        const jobFunctionsSoftSkill = [];
        model.evaluationJobFunctionSkill.forEach(jobFunctionSkill => {
          if (jobFunctionSkill.hardSkill) {
            jobFunctionsHardSkill.push(jobFunctionSkill.jobFunction);
          } else {
            jobFunctionsSoftSkill.push(jobFunctionSkill.jobFunction);
          }
        });
        controller.set('jobFunctionsHardSkill', jobFunctionsHardSkill);
        controller.set('jobFunctionsSoftSkill', jobFunctionsSoftSkill);
      }
      if (model.evaluationJobRoleSkill) {
        const jobRolesHardSkill = [];
        const jobRolesSoftSkill = [];
        model.evaluationJobRoleSkill.forEach(jobRoleSkill => {
          if (jobRoleSkill.hardSkill) {
            jobRolesHardSkill.push(jobRoleSkill.jobRole);
          } else {
            jobRolesSoftSkill.push(jobRoleSkill.jobRole);
          }
        });
        controller.set('jobRolesHardSkill', jobRolesHardSkill);
        controller.set('jobRolesSoftSkill', jobRolesSoftSkill);
      }
      if (model.functionEvaluationJobFunctions) {
        controller.set('functionEvaluationJobFunctions', model.functionEvaluationJobFunctions);
      }
      if (model.functionEvaluationJobRoles) {
        controller.set('functionEvaluationJobRoles', model.functionEvaluationJobRoles);
      }
      const functionEvaluationPeriods = controller.get('functionEvaluationPeriods');
      const functionEvaluationTypes = controller.get('functionEvaluationTypes');
      const selectedFunctionEvaluationPeriod = functionEvaluationPeriods.findBy('value', model.functionEvaluationPeriod);
      const selectedFunctionEvaluationType = functionEvaluationTypes.findBy('value', model.functionEvaluationType);
      controller.set('selectedFunctionEvaluationPeriod', selectedFunctionEvaluationPeriod);
      controller.set('selectedFunctionEvaluationType', selectedFunctionEvaluationType);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SettingsAvdRoute;
});