define("account-talentrh/models/access-rule", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    resource: (0, _model.attr)('string'),
    action: (0, _model.attr)('string'),
    accessProfile: (0, _model.belongsTo)('access-profile')
  });
  _exports.default = _default;
});