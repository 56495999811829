define("account-talentrh/pods/registry/general/ia-repositories/show/upload-select-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YFjXYbxc",
    "block": "{\"symbols\":[\"Modal\",\"@modalShow\",\"@closeModal\",\"@onSelectUploadType\"],\"statements\":[[8,\"ui-modal\",[[24,0,\"ui-modal\"]],[[\"@show\",\"@onClose\",\"@size\",\"@position\"],[[32,2],[32,3],\"xl\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"knowledge-insertion \"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12 centralize-x p-10\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12 col-sm-12 col-md-12 text-center\"],[12],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"flex-fill font-weight-bold mb-0 modal-span\"],[12],[2,\"Escolha a maneira que o conhecimento será inserido\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"options\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn option-button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,4],false],null]],null],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/icons/describe.svg\"],[14,\"alt\",\"Descrever conteúdo\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Descrever conteúdo\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[11,\"button\"],[24,0,\"btn option-button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,4],true],null]],null],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/icons/import-pdf.svg\"],[14,\"alt\",\"Importar PDF\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Importar PDF\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/ia-repositories/show/upload-select-modal/template.hbs"
    }
  });
  _exports.default = _default;
});