define("account-talentrh/pods/registry/general/job-role/form/add-function/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    functionSelected: null,
    open: false,
    addRecord: (0, _emberConcurrency.task)(function* () {
      if (!this.functionSelected) {
        return this.toast.warning('Selecione um item');
      }
      let functionInUse = Ember.get(this, 'functionSelected.jobRole.id');
      if (functionInUse) {
        return this.toast.info('Função já atribuida a um cargo');
      }
      try {
        Ember.set(this.functionSelected, 'jobRole', this.job);
        yield this.functionSelected.save();
        this.toast.success('Função adicionada ao cargo');
        Ember.set(this, 'open', false);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});