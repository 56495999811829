define("account-talentrh/pods/configuration/nela/birthday-message/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ConfigurationNelaBirthdayMessageController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed.alias('model'), _dec9 = Ember.computed.alias('permission.companyConfig'), (_class = class ConfigurationNelaBirthdayMessageController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "advancedEditorImages", _descriptor6, this);
      _initializerDefineProperty(this, "advancedEditorVideos", _descriptor7, this);
      _initializerDefineProperty(this, "birthdayMessage", _descriptor8, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor9, this);
    }
    *save() {
      try {
        yield this.companyConfig.save();
        yield this.birthdayMessage.save();
        if (this.advancedEditorVideos.length) {
          yield this.uploadVideos.perform();
        }
        if (this.advancedEditorImages.length) {
          yield this.uploadImages.perform();
        }
        this.advancedEditorImages = [];
        this.advancedEditorVideos = [];
        this.toast.success('Mensagem de aniversário salva!');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *uploadVideos() {
      let count = 0;
      let message = this.birthdayMessage.message;
      const regex = /src=("blob:[^<>"]*"|'[^<>']*'|\w+)/gm;
      message = message.replace(regex, () => `src="${count++}"`);
      for (const [index, video] of this.advancedEditorVideos.entries()) {
        const query = {
          filename: video.name,
          type: video.type
        };
        let birthdayMessageVideo;
        try {
          this.swal.loading('Realizando o upload do vídeo ' + video.name);
          const {
            key,
            url,
            urlUpload
          } = yield this.fetch.request('birthdayMessageVideo/signedUploadUrl', {
            data: query
          });
          birthdayMessageVideo = this.store.createRecord('birthday-message-video', {
            path: key,
            url: url,
            birthdayMessage: this.birthdayMessage
          });
          yield birthdayMessageVideo.save();
          yield fetch(urlUpload, {
            method: 'PUT',
            body: video
          });
          message = message.replace(`src="${index}"`, `src="${url}"`);
        } catch (e) {
          birthdayMessageVideo.destroyRecord();
          Ember.debug('Erro no upload: ', e);
        } finally {
          this.swal.close();
        }
      }
      this.birthdayMessage.message = message;
      yield this.birthdayMessage.save();
    }
    *uploadImages() {
      let count = 0;
      let message = this.birthdayMessage.message;
      const regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
      message = message.replace(regex, () => count++);
      for (const [index, image] of this.advancedEditorImages.entries()) {
        const query = {
          filename: image.name,
          type: image.type
        };
        let birthdayMessageImage;
        try {
          this.swal.loading('Realizando o upload da imagem ' + image.name);
          const {
            key,
            url,
            urlUpload
          } = yield this.fetch.request('birthdayMessageImage/signedUploadUrl', {
            data: query
          });
          birthdayMessageImage = this.store.createRecord('birthday-message-image', {
            path: key,
            url: url,
            birthdayMessage: this.birthdayMessage
          });
          yield birthdayMessageImage.save();
          yield fetch(urlUpload, {
            method: 'PUT',
            body: image
          });
          message = message.replace(`src="${index}"`, `src="${url}"`);
        } catch (e) {
          birthdayMessageImage.destroyRecord();
          Ember.debug('Erro no upload: ', e);
        } finally {
          this.swal.close();
        }
      }
      this.birthdayMessage.message = message;
      yield this.birthdayMessage.save();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "advancedEditorImages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "advancedEditorVideos", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "birthdayMessage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadVideos", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "uploadVideos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadImages", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "uploadImages"), _class.prototype)), _class));
  _exports.default = ConfigurationNelaBirthdayMessageController;
});