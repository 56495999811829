define("account-talentrh/pods/settings/avd/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // Attributes
    selectedFunctionEvaluationType: null,
    selectedFunctionEvaluationPeriod: null,
    jobFunctionsHardSkill: Ember.A(),
    jobFunctionsSoftSkill: Ember.A(),
    jobRolesHardSkill: Ember.A(),
    jobRolesSoftSkill: Ember.A(),
    functionEvaluationJobFunctions: Ember.A(),
    functionEvaluationJobRoles: Ember.A(),
    companyConfig: Ember.computed.alias('model'),
    evaluationTypeOptions: Ember.A([{
      type: 1,
      label: '90'
    }, {
      type: 2,
      label: '180'
    }, {
      type: 3,
      label: '360'
    }]),
    functionEvaluationTypes: Ember.A([{
      value: 'admissionAnniversary',
      label: 'Gerar avaliação por função conforme data aniversario de Admissão'
    }, {
      value: 'custom',
      label: 'Gerar avaliação conforme periodicidade'
    }
    // { value: 'functionChangeAnniversary', label: 'Gerar avaliação por função conforme data aniversario de troca de função' },
    ]),

    functionEvaluationPeriods: Ember.A([{
      value: 'month',
      label: 'Mensalmente'
    }, {
      value: 'year',
      label: 'Anualmente'
    }]),
    // Computed
    disableQuestionary45Days: Ember.computed.or('companyConfig.questionary45DaysFunction', 'companyConfig.singleExperienceEvaluation'),
    disableQuestionary90Days: Ember.computed.or('companyConfig.questionary90DaysFunction', 'companyConfig.singleExperienceEvaluation'),
    typeFirstEvaluation: Ember.computed('companyConfig.typeFirstEvaluation', 'evaluationTypeOptions', {
      get() {
        return this.evaluationTypeOptions.findBy('type', this.companyConfig.typeFirstEvaluation);
      },
      set(key, value) {
        Ember.set(this, 'companyConfig.typeFirstEvaluation', value ? value.type : null);
        return value;
      }
    }),
    typeLastEvaluation: Ember.computed('companyConfig.typeLastEvaluation', 'evaluationTypeOptions', {
      get() {
        return this.evaluationTypeOptions.findBy('type', this.companyConfig.typeLastEvaluation);
      },
      set(key, value) {
        Ember.set(this, 'companyConfig.typeLastEvaluation', value ? value.type : null);
        return value;
      }
    }),
    typeFunctionEvaluation: Ember.computed('companyConfig.typeFunctionEvaluation', 'evaluationTypeOptions', {
      get() {
        return this.evaluationTypeOptions.findBy('type', this.companyConfig.typeFunctionEvaluation);
      },
      set(key, value) {
        Ember.set(this, 'companyConfig.typeFunctionEvaluation', value ? value.type : null);
        return value;
      }
    }),
    // Methods

    setJobFunctionOrJobRoleSkills: (0, _emberConcurrency.task)(function* () {
      if (this.companyConfig.showJobFunction) {
        yield this.setJobFunctionSkills.perform();
      } else {
        yield this.setJobRoleSkills.perform();
      }
    }),
    setJobFunctionSkills: (0, _emberConcurrency.task)(function* () {
      let jobFunctionsHardSkill = [];
      let jobFunctionsSoftSkill = [];
      const mapJobFunctionToJobFunctionSkill = (jobFunction, hardSkill) => {
        return this.store.createRecord('evaluation-job-function-skill', {
          hardSkill,
          companyConfiguration: this.companyConfig,
          jobFunction: jobFunction
        });
      };
      if (!this.companyConfig.hardSkillEvaluationToAll) {
        jobFunctionsHardSkill = this.jobFunctionsHardSkill.map(jobFunction => mapJobFunctionToJobFunctionSkill(jobFunction, true));
      }
      if (!this.companyConfig.softSkillEvaluationToAll) {
        jobFunctionsSoftSkill = this.jobFunctionsSoftSkill.map(jobFunction => mapJobFunctionToJobFunctionSkill(jobFunction, false));
      }
      const jobFunctionsSkills = Ember.A([...jobFunctionsHardSkill, ...jobFunctionsSoftSkill]);
      yield Ember.RSVP.all([this.saveJobFunctionSkills.perform(jobFunctionsSkills), this.deleteJobFunctionSkills.perform(jobFunctionsSkills)]);
    }),
    saveJobFunctionSkills: (0, _emberConcurrency.task)(function* (jobFunctionsSkills) {
      for (const jobFunctionSkill of jobFunctionsSkills) {
        const exists = this.companyConfig.evaluationJobFunctionSkill.find(evaluationJobFunctionSkill => evaluationJobFunctionSkill.get('jobFunction').get('id') === jobFunctionSkill.get('jobFunction').get('id'));
        if (!exists.id) yield jobFunctionSkill.save();
      }
    }),
    deleteJobFunctionSkills: (0, _emberConcurrency.task)(function* (jobFunctionsSkills) {
      for (const evaluationJobFunctionSkill of this.companyConfig.evaluationJobFunctionSkill.toArray()) {
        const exists = jobFunctionsSkills.find(jobFunctionsSkill => {
          return jobFunctionsSkill.get('jobFunction').get('id') === evaluationJobFunctionSkill.get('jobFunction').get('id');
        });
        if (!exists) yield evaluationJobFunctionSkill.destroyRecord();
      }
    }),
    setJobRoleSkills: (0, _emberConcurrency.task)(function* () {
      let jobRolesHardSkill = [];
      let jobRolesSoftSkill = [];
      const mapJobRoleToJobRoleSkill = (jobRole, hardSkill) => {
        return this.store.createRecord('evaluation-job-role-skill', {
          hardSkill,
          companyConfiguration: this.companyConfig,
          jobRole: jobRole
        });
      };
      if (!this.companyConfig.hardSkillEvaluationToAll) {
        jobRolesHardSkill = this.jobRolesHardSkill.map(jobRole => mapJobRoleToJobRoleSkill(jobRole, true));
      }
      if (!this.companyConfig.softSkillEvaluationToAll) {
        jobRolesSoftSkill = this.jobRolesSoftSkill.map(jobRole => mapJobRoleToJobRoleSkill(jobRole, false));
      }
      const jobRolesSkills = Ember.A([...jobRolesHardSkill, ...jobRolesSoftSkill]);
      yield Ember.RSVP.all([this.saveRoleSkills.perform(jobRolesSkills), this.deleteRoleSkills.perform(jobRolesSkills)]);
    }),
    saveRoleSkills: (0, _emberConcurrency.task)(function* (jobRolesSkills) {
      for (const jobRoleSkill of jobRolesSkills) {
        const exists = this.companyConfig.evaluationJobRoleSkill.find(evaluationJobRoleSkill => evaluationJobRoleSkill.get('jobRole').get('id') === jobRoleSkill.get('jobRole').get('id'));
        if (!exists.id) yield jobRoleSkill.save();
      }
    }),
    deleteRoleSkills: (0, _emberConcurrency.task)(function* (jobRolesSkills) {
      for (const evaluationJobRoleSkill of this.companyConfig.evaluationJobRoleSkill.toArray()) {
        const exists = jobRolesSkills.find(jobFunctionsSkill => {
          return jobFunctionsSkill.get('jobRole').get('id') === evaluationJobRoleSkill.get('jobRole').get('id');
        });
        if (!exists) yield evaluationJobRoleSkill.destroyRecord();
      }
    }),
    setFunctionEvaluationFields() {
      if (this.companyConfig.hardSkillEvaluationToAll && this.companyConfig.softSkillEvaluationToAll) return;
      if (this.companyConfig.functionEvaluationType === 'custom') {
        if (this.companyConfig.showJobFunction) {
          this.companyConfig.functionEvaluationJobFunctions = this.functionEvaluationJobFunctions;
          this.companyConfig.functionEvaluationJobRoles = [];
        } else {
          this.companyConfig.functionEvaluationJobRoles = this.functionEvaluationJobRoles;
          this.companyConfig.functionEvaluationJobFunctions = [];
        }
      }
    },
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        let evaluationTypeRequiredMessage;
        if (!this.typeLastEvaluation && !this.typeFirstEvaluation) {
          evaluationTypeRequiredMessage = "Informe os tipos de avaliação para a primeira avaliação e avaliação de efetivação";
        } else if (!this.typeLastEvaluation) {
          evaluationTypeRequiredMessage = "Informe o tipo da avaliação de efetivação";
        } else if (!this.typeFirstEvaluation) {
          evaluationTypeRequiredMessage = "Informe o tipo da primeira avaliação";
        }
        if (evaluationTypeRequiredMessage) {
          return this.swal.warning(evaluationTypeRequiredMessage);
        }

        //soma mais 1, para que o cálculo nas avaliações fique correto
        if (this.companyConfig.daysToExpirationPerformanceEvaluation) {
          let intDaysToExpirationPerformanceEvaluation = parseInt(this.companyConfig.daysToExpirationPerformanceEvaluation);
          this.companyConfig.daysToExpirationPerformanceEvaluation = intDaysToExpirationPerformanceEvaluation + 1;
        }
        if (this.companyConfig.daysToGeneratePerformanceEvaluation) {
          let intDaysToGeneratePerformanceEvaluation = parseInt(this.companyConfig.daysToGeneratePerformanceEvaluation);
          this.companyConfig.daysToGeneratePerformanceEvaluation = intDaysToGeneratePerformanceEvaluation + 1;
        }
        if (this.selectedFunctionEvaluationType && this.selectedFunctionEvaluationType.value === 'custom') {
          this.companyConfig.daysToExpirationPerformanceEvaluation = 0;
        }
        this.setFunctionEvaluationFields();
        yield this.setJobFunctionOrJobRoleSkills.perform();
        let singleExperienceEvaluation = this.companyConfig.singleExperienceEvaluation?.id;
        let questionarySingleEvaluationId = this.companyConfig.questionarySingleEvaluation?.get('id');
        if (singleExperienceEvaluation && !questionarySingleEvaluationId) {
          return this.swal.warning('É necessário informar um questionário para avaliação de experiência!');
        }
        if (this.companyConfig.useNineBox) {
          yield this.store.queryRecord('nine-box', {});
        }
        yield this.companyConfig.save();
        return this.swal.success('As configurações foram salvas com sucesso!');
      } catch (e) {
        console.log(e);
        return this.swal.catch(e);
      }
    }).drop(),
    actions: {
      click(item) {
        document.getElementById(item).click();
      },
      afterUploading(response) {
        this.store.pushPayload(response);
      },
      onSelectFunctionEvaluationType(type) {
        Ember.set(this, 'selectedFunctionEvaluationType', type);
        Ember.set(this, 'companyConfig.functionEvaluationType', type.value);
      },
      onSelectFunctionEvaluationPeriod(type) {
        Ember.set(this, 'selectedFunctionEvaluationPeriod', type);
        Ember.set(this, 'companyConfig.functionEvaluationPeriod', type.value);
      },
      onQuestionary45DaysSelected(question) {
        Ember.set(this, 'questionary45DaysSelected', question);
        Ember.set(this, 'companyConfig.questionary45Days', question.get('id'));
      },
      onQuestionary90DaysSelected(question) {
        Ember.set(this, 'questionary90DaysSelected', question);
        Ember.set(this, 'companyConfig.questionary90Days', question.get('id'));
      },
      onQuestionarySingleEvaluationSelected(question) {
        Ember.set(this, 'questionarySingleEvaluationSelected', question);
        Ember.set(this, 'companyConfig.questionarySingleEvaluation', question.get('id'));
      },
      startDateMonthlyFunctionEvaluationChange(_ref) {
        let {
          date
        } = _ref;
        Ember.set(this, 'companyConfig.startDateMonthlyFunctionEvaluation', date);
        Ember.set(this, 'companyConfig.lastFunctionEvaluation', date);
      },
      onSingleExperienceEvaluationChange() {
        const value = !this.companyConfig.singleExperienceEvaluation;
        Ember.set(this, 'companyConfig.singleExperienceEvaluation', value);
        if (value) {
          Ember.set(this, 'companyConfig.notUseQuestionnaire', false);
        }
      }
    }
  });
  _exports.default = _default;
});