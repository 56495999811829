define("account-talentrh/services/wizard", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let WizardService = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('permission.admin'), (_class = class WizardService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "status", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "session", _descriptor5, this);
      _initializerDefineProperty(this, "admin", _descriptor6, this);
    }
    get steps() {
      return [{
        title: 'Feed',
        substeps: [{
          title: 'Posts',
          route: 'feed.post',
          icon: 'fa-tasks'
        }, {
          title: 'Comunicados',
          route: 'feed.communicated',
          icon: 'fa-bullhorn'
        }, {
          title: 'Eventos',
          route: 'feed.events',
          icon: 'fa-calendar-alt'
        }, {
          title: 'Notícias',
          route: 'feed.news',
          icon: 'fa-newspaper'
        }, {
          title: 'Classificados',
          route: 'feed.marketplace',
          icon: 'fa-shopping-cart'
        }, {
          title: 'Enquetes',
          route: 'feed.survey',
          icon: 'fa-clipboard-list'
        }, {
          title: 'Reportar Posts',
          route: 'feed.report-post',
          icon: 'fa-comment-alt-exclamation'
        }]
      }, {
        title: 'Colaboração',
        substeps: [{
          title: 'Gestão do Conhecimento',
          route: 'collaboration.forum',
          icon: 'fa-comment-alt'
        }, {
          title: 'Ausências',
          route: 'collaboration.absence',
          icon: 'fa-clock'
        }, {
          title: 'Página de aniversários',
          route: 'collaboration.birthday-page',
          icon: 'fa-birthday-cake'
        }, {
          title: 'Exibição de aniversariantes',
          route: 'collaboration.show-birthday',
          icon: 'fa-calendar-alt'
        }]
      }, {
        title: 'Chat',
        substeps: [{
          title: 'Chat',
          route: 'chat',
          icon: 'fa-comment'
        }]
      }, {
        title: 'Perfil de Acesso',
        substeps: [{
          title: 'Perfil de Acesso',
          route: 'access-profile.info',
          icon: 'fa-user-lock'
        }, {
          title: 'Cadastro de perfil',
          route: 'access-profile.manage',
          icon: 'fa-user-cog'
        }]
      }, {
        title: 'Cadastros',
        substeps: [{
          title: 'Unidades de Negócio/Filiais',
          route: 'registry.branch',
          icon: 'fa-building'
        }, {
          title: 'Departamentos',
          route: 'registry.department',
          icon: 'fa-users-class'
        }, {
          title: 'Cargos',
          route: 'registry.job-role',
          icon: 'fa-chair-office'
        }, {
          title: 'Usuários',
          route: 'registry.user',
          icon: 'fa-users'
        }]
      }, {
        title: 'Solicitações',
        substeps: [{
          title: 'Solicitações',
          route: 'solicitation.index',
          icon: 'fa-tasks'
        }, {
          title: 'Solicitações de Gestores',
          route: 'solicitation.manager-flow',
          icon: 'fa-tasks'
        }, {
          title: 'Solicitações de Colaboradores',
          route: 'solicitation.rh-flow',
          icon: 'fa-tasks'
        }]
      }, {
        title: 'Empresa',
        substeps: [{
          title: 'Álbuns',
          route: 'company.album',
          icon: 'fa-images'
        }, {
          title: 'Atas de Reunião',
          route: 'company.meet',
          icon: 'fa-file-alt'
        }, {
          title: 'Tomada de Decisão',
          route: 'company.decision',
          icon: 'fa-map-signs'
        }, {
          title: 'Benefícios',
          route: 'company.benefits',
          icon: 'fa-exclamation-circle'
        }, {
          title: 'Políticas da Empresa',
          route: 'company.policies',
          icon: 'fa-list-alt'
        }, {
          title: 'Programas da Empresa',
          route: 'company.programs',
          icon: 'fa-tasks'
        }, {
          title: 'Organograma',
          route: 'company.organogram',
          icon: 'fa-sitemap'
        }]
      }, {
        title: 'Grupos',
        substeps: [{
          title: 'Grupos',
          route: 'groups.group',
          icon: 'fa-images'
        }, {
          title: 'Links Externos',
          route: 'groups.link',
          icon: 'fa-images'
        }]
      }];
    }
    *start(reopen) {
      try {
        if (reopen) {
          this.router.transitionTo(`setup-wizard.welcome`);
          return;
        }
        let status = this.status;
        if (!this.session.isAuthenticated) {
          return;
        }
        if (!status) {
          let result = yield this.fetch.request('wizardStatuses/me');
          status = result.wizardStatus;
          status.step = status.step || 0;
          status.step = status.step || 0;
          this.status = status;
        }
        if (status.done || !this.admin) {
          Ember.debug(`WizardService :: start :: Wizard já completado ou usuário não é administrador`);
        } else if (status.step || status.substep) {
          let currentStep = this.steps[status.step].substeps[status.substep];

          // Caso uma etapa seja removida do wizard e não seja encontrada, o sistema vai tentar encontrar uma próxima.
          if (currentStep) {
            this.router.transitionTo(`setup-wizard.steps.${currentStep.route}`);
          } else {
            this.nextStep();
          }
        } else {
          this.router.transitionTo(`setup-wizard.welcome`);
        }
      } catch (e) {
        Ember.debug(`WizardService :: start :: ${e}`);
      }
    }
    *updateStatus() {
      try {
        let wizardStatus = this.status;
        yield this.fetch.put(`wizardStatuses/${wizardStatus.id}`, {
          data: {
            wizardStatus
          }
        });
      } catch (e) {
        Ember.debug(`WizardService :: updateStatus :: ${e}`);
      }
    }
    startSteps() {
      let firstStep = this.steps[0].substeps[0];
      this.router.transitionTo(`setup-wizard.steps.${firstStep.route}`);
    }
    nextStep() {
      try {
        let steps = this.steps;
        let {
          step,
          substep,
          done
        } = this.status;
        let nextStep = step + 1;
        let nextSubstep = substep + 1;
        if (steps[step].substeps[nextSubstep]) {
          // Verifica se tem mais sub etapas
          substep = nextSubstep;
          let stepItem = steps[step].substeps[substep];
          this.router.transitionTo(`setup-wizard.steps.${stepItem.route}`);
        } else if (steps[nextStep]) {
          // Caso contrário verifica se tem uma nova etapa
          step = nextStep;
          substep = 0;
          let stepItem = steps[step].substeps[substep];
          this.router.transitionTo(`setup-wizard.steps.${stepItem.route}`);
        } else {
          // Se não há novas etapas, finaliza o wizard;
          done = true;
          this.router.transitionTo(`setup-wizard.done`);
        }
        this.status = {
          ...this.status,
          step,
          substep,
          done
        };
        this.updateStatus.perform();
      } catch (e) {
        Ember.debug(`WizardService :: nextStep :: ${e}`);
      }
    }
    prevStep() {
      try {
        let steps = this.steps;
        let {
          step,
          substep
        } = this.status;
        let prevStep = step - 1;
        let prevSubstep = substep - 1;

        // Verifica se tem mais sub etapas
        if (steps[step].substeps[prevSubstep]) {
          substep = prevSubstep;
        }
        // Caso contrário verifica se tem uma nova etapa
        else if (steps[prevStep]) {
          step = prevStep;
          substep = steps[prevStep].substeps.length - 1;
        }

        // Após encontrar etapa anterior, faz a transição para a sua rota
        let stepItem = steps[step].substeps[substep];
        this.router.transitionTo(`setup-wizard.steps.${stepItem.route}`);
        this.status = {
          ...this.status,
          step,
          substep
        };
      } catch (e) {
        Ember.debug(`WizardService :: prevStep :: ${e}`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStatus", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateStatus"), _class.prototype)), _class));
  _exports.default = WizardService;
});