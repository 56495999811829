define("account-talentrh/pods/super-user/companies/edit/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SuperUserCompaniesEditIndexRoute extends Ember.Route {
    model() {
      return this.modelFor('super-user.companies.edit');
    }
    deactivate() {
      this.controllerFor('super-user.companies').set('filters', {});
    }
  }
  _exports.default = SuperUserCompaniesEditIndexRoute;
});