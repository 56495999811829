define("account-talentrh/pods/register/account/plan/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g43u/0qx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"py-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"plans d-flex flex-wrap flex-lg-nowrap mb-5\"],[12],[2,\"\\n   \"],[8,\"subscription/plan/form-plan\",[],[[\"@plan\",\"@updateSubscription\"],[[32,0,[\"selectedPlan\"]],[32,0,[\"updateSubscription\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/register/account/plan/template.hbs"
    }
  });
  _exports.default = _default;
});