define("account-talentrh/pods/super-user/companies/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    // HTML Attributes
    tagName: 'li',
    classNames: ['list-group-item'],
    // Default values
    route: 'super-user.companies.show',
    // Single line computed properties
    model: Ember.computed.alias('company.id'),
    // Multiline computed properties

    sellType: Ember.computed('company.sellType', function () {
      switch (this.company.sellType) {
        case "convencional":
          return "Convencional";
        case "saas":
          return "Saas";
        case "embarcada":
          return "Embarcada";
        default:
          return "Convencional";
      }
    }),
    status: Ember.computed('company.active', function () {
      if (!this.company.active) {
        return 'Inativa';
      }
      return '';
    }),
    statusClass: Ember.computed('company.active', function () {
      if (!this.company.active) {
        return 'badge-danger';
      }
      return '';
    })
  });
  _exports.default = _default;
});