define("account-talentrh/pods/registry/social/company-program/show/attachments/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialCompanyProgramShowAttachmentsRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.social.company-program.show');
    }
  }
  _exports.default = RegistrySocialCompanyProgramShowAttachmentsRoute;
});