define("account-talentrh/pods/setup-wizard/substep-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "F+2VTW8T",
    "block": "{\"symbols\":[\"substep\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"substeps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"list-group-item rounded mb-2 border align-items-center d-flex bg-light \",[30,[36,0],[[32,1,[\"active\"]],\"text-social border-social\"],null]]]],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,1,[\"icon\"]]]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"mb-0 ml-3\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"fa-check-circle ml-auto \",[30,[36,0],[[32,1,[\"done\"]],\"fas text-social\",\"fa\"],null]]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/substep-list/template.hbs"
    }
  });
  _exports.default = _default;
});