define("account-talentrh/pods/registry/general/user/show/avd/goals/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const to = {
    notRated(unitType) {
      return unitType === 'lessThanOrEqual' || unitType === 'lessThan' ? Infinity : '';
    },
    calcPercent(value, valueAchieved, unitType, valueMin, valueMax) {
      if (unitType === 'lessThanOrEqual') {
        const newValueAchieved = valueAchieved < value ? value : valueAchieved;
        return `${Math.round(100 * value / newValueAchieved)}%`;
      } else if (unitType === 'greaterThanOrEqual') {
        const newValueAchieved = valueAchieved > value ? value : valueAchieved;
        return `${Math.round(newValueAchieved / value * 100)}%`;
      } else if (unitType === 'greaterThan') {
        if (valueAchieved > value) {
          return '100%';
        } else if (valueAchieved === value) {
          return '99%';
        } else {
          const newValueAchieved = valueAchieved > value ? value : valueAchieved;
          let percentage = Math.round(newValueAchieved / value * 100);
          return `${percentage < 99 ? percentage : 99}%`;
        }
      } else if (unitType === 'lessThan') {
        if (valueAchieved < value) {
          return '100%';
        } else if (valueAchieved === value) {
          return '99%';
        } else {
          const newValueAchieved = valueAchieved < value ? value : valueAchieved;
          let percentage = Math.round(100 * value / newValueAchieved);
          return `${percentage < 99 ? percentage : 99}%`;
        }
      } else if (unitType === 'between') {
        return valueAchieved >= valueMin && valueAchieved <= valueMax ? '100%' : '0%';
      } else {
        return '100%';
      }
    },
    toNumber(value, unitType) {
      return value === null ? this.notRated(unitType) : Number(value);
    },
    getValues(goal) {
      const valueNumeric = this.toNumber(goal.value, goal.unitType);
      const valueAchievedNumeric = this.toNumber(goal.valueAchieved, goal.unitType);
      let valueMinNumeric;
      let valueMaxNumeric;
      if (goal.unitType === 'between') {
        valueMinNumeric = this.toNumber(goal.minValue, goal.unitType);
        valueMaxNumeric = this.toNumber(goal.maxValue, goal.unitType);
      }
      let percent = this.calcPercent(valueNumeric, valueAchievedNumeric, goal.unitType, valueMinNumeric, valueMaxNumeric);
      let percentLabel = percent;
      let centerLabel = false;
      let processBarColor = 'bg-info';
      if (goal.unitType === 'between') {
        percentLabel = percent === '100%' ? 'Meta atingida' : 'Meta não atingida';
        processBarColor = percent === '100%' ? 'bg-info' : 'bg-not-achieved';
        centerLabel = true;
      }
      return {
        ...goal,
        percent,
        percentLabel,
        processBarColor,
        centerLabel
      };
    },
    getQualitativeValues(goal) {
      const percentLabel = goal.valueAchieved === 'S' ? 'Meta atingida' : 'Meta não atingida';
      const processBarColor = goal.valueAchieved === 'S' ? 'bg-info' : 'bg-not-achieved';
      const percent = goal.valueAchieved === 'S' ? '100%' : '0%';
      return {
        ...goal,
        percent,
        processBarColor,
        percentLabel,
        centerLabel: true
      };
    }
  };
  let ItemComponent = (_dec = Ember.computed('args.goal'), _dec2 = Ember._action, (_class = class ItemComponent extends _component.default {
    get goalSerialized() {
      let {
        goal
      } = this.args;
      const {
        percent,
        percentLabel
      } = goal.unit === 'qualitative' ? to.getQualitativeValues(goal) : to.getValues(goal);
      return {
        ...goal,
        percent,
        percentLabel
      };
    }
    setProgressBarWidth(el) {
      el?.setAttribute('style', 'width:0');
      setTimeout(() => {
        el?.setAttribute('style', `width:${el.getAttribute('aria-valuenow')}`);
      }, 10);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "goalSerialized", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "goalSerialized"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProgressBarWidth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setProgressBarWidth"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});