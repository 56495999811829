define("account-talentrh/pods/setup-wizard/steps/registry/user/manage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tS7iYOCH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"col-md-4 py-4 border-right \",[30,[36,0],[[32,0,[\"expandForm\"]],\"d-none\"],null]]]],[12],[2,\"\\n  \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"expandForm\"]],\"col-12\",\"col-md-8\"],null],\" py-4 position-relative\"]]],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-link position-absolute l-0 centralize-y p-0\"],[14,\"title\",\"Expandir formulário\"],[15,\"onclick\",[30,[36,3],[[30,[36,2],[[32,0,[\"expandForm\"]]],null],[30,[36,1],[[32,0,[\"expandForm\"]]],null]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"fa fa-angle-double-\",[30,[36,0],[[32,0,[\"expandForm\"]],\"right\",\"left\"],null],\" h3 text-social ml-n2\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[11,\"div\"],[24,0,\"spinner-grow text-social position-absolute t-0 ml-n3\"],[24,\"role\",\"status\"],[4,[38,4],[[32,0,[\"hideEffect\"]]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"not\",\"mut\",\"fn\",\"did-insert\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/user/manage/template.hbs"
    }
  });
  _exports.default = _default;
});