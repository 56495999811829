define("account-talentrh/pods/super-user/post/show/publish/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    fetch: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    postTemplate: Ember.computed.alias('model'),
    isMessage: Ember.computed.equal('postTemplate.type', 'message'),
    isAnnouncement: Ember.computed.equal('postTemplate.type', 'announcement'),
    isNews: Ember.computed.equal('postTemplate.type', 'news'),
    successMessage: Ember.computed('isNews', 'isAnnouncement', 'isMessage', function () {
      if (this.isAnnouncement) {
        return 'Comunicado publicado';
      }
      if (this.isNews) {
        return 'Notícia publicada';
      }
      return 'Mensagem publicada';
    }),
    init() {
      this._super(...arguments);
      this.companies = Ember.A();
      this.segments = Ember.A();
      this.sendToAll = false;
    },
    publish: (0, _emberConcurrency.task)(function* () {
      try {
        let confirmMessage = this.sendToAll ? 'Confirmar publicação à todas as empresas?' : 'Confirmar publicação para a(s) empresa(s) selecionada(s)?';
        if (!this.sendToAll && !this.companies.length && this.segments.length) {
          confirmMessage = 'Confirmar publicação para o(s) segmento(s) selecionado(s)?';
        }
        let {
          value
        } = yield this.swal.confirm(confirmMessage);
        if (!value) {
          return;
        }
        let {
          posts
        } = yield this.fetch.post(`postTemplates/${this.postTemplate.id}/publish`, {
          data: {
            sendToAll: this.sendToAll,
            companies: this.companies.mapBy('id'),
            segments: this.segments.mapBy('id')
          }
        });
        this.store.pushPayload({
          posts
        });
        this.companies.clear();
        this.segments.clear();
        this.toast.success(this.successMessage);
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      closeModal() {
        this.transitionToRoute('super-user.post.show');
      },
      segmentSelected(segments, event) {
        const selected = event.highlighted;
        if (selected && !selected.companies.length) {
          this.swal.warning('Segmento selecionado não possui nenhuma empresa!');
        }
      }
    }
  });
  _exports.default = _default;
});