define("account-talentrh/pods/setup-wizard/steps/registry/branch/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SKchlbOO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h5\"],[14,0,\"mb-3\"],[12],[2,\"\\n  O cadastro de Unidades de Negócio/Filiais possibilitam uma melhor identificação \\n  e alocação dos colaboradores no Voors. Caso sua empresa não possua uma filial ou \\n  esteja dividida em unidades de negócio, basta ir ao próximo passo, os usuários do \\n  Voors serão vinculados automaticamente a empresa já cadastrada.\\n\"],[13],[2,\"\\n\\n\"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-3\"],[14,\"src\",\"https://player.vimeo.com/video/757137973?h=80dd664484\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  Além do cadastro manual é possível realizar a importação de dados em massa a partir de um modelo.\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wizard-step-control text-center mt-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social\"]],[[\"@route\"],[\"setup-wizard.steps.registry.branch.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Cadastrar filial\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/branch/index/template.hbs"
    }
  });
  _exports.default = _default;
});