define("account-talentrh/pods/manage-lunch-list/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      const current = new Date();
      let filter = {
        day: current.getDate(),
        month: current.getMonth() + 1,
        year: current.getFullYear()
      };
      let filterWithSort = {
        sort: 'name ASC'
      };
      Ember.assign(filterWithSort, filter);
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', this.session.user.company),
        employees: this.store.query('user-lunch-solicitation', filter),
        guest: this.store.query('guest-lunch-solicitation', filterWithSort).then(o => o.toArray())
      });
    }
  });
  _exports.default = _default;
});