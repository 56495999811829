define("account-talentrh/models/version", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    number: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar o número da versão'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar a implementação/melhoria'
    })
  });
  var _default = _model.default.extend(Validations, {
    module: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    notificationSent: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    changeLog: (0, _model.attr)('raw'),
    companiesRead: (0, _model.attr)('raw'),
    createdAt: (0, _model.attr)('date'),
    publishedAt: (0, _model.attr)('date'),
    viewedAt: (0, _model.attr)('date')
  });
  _exports.default = _default;
});