define("account-talentrh/pods/errors/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rZzB4iPH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"section-body contain-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-meh-o text-blue\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"Ops! Está página não existe.\"],[13],[2,\"\\n\\n        \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n        \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary btn-md\",\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Voltar para Home \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[3,\"end .col \"],[2,\"\\n    \"],[13],[2,\"\\n    \"],[3,\"end .row \"],[2,\"\\n  \"],[13],[2,\"\\n  \"],[3,\"end .section-body \"],[2,\"\\n\"],[13],[2,\"\\n\"],[3,\" END 404 MESSAGE \"],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/errors/not-found/template.hbs"
    }
  });
  _exports.default = _default;
});