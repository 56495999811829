define("account-talentrh/pods/registry/avd/type-answer/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    altSorting: Ember.A(['order']),
    alternatives: Ember.computed.sort('typeAnswer.answers', 'altSorting'),
    typeAnswer: Ember.computed.alias('model'),
    generatePercentages: (0, _emberConcurrency.task)(function* () {
      let interval = 100;
      if (!this.typeAnswer.answers.length) {
        return this.swal.warning('Adicione pelo menos uma resposta antes de gerar os percentuais');
      }
      let typeAnswer = this.typeAnswer.answers.filter(value => {
        return !value.ignoreToCalculate;
      });
      if (typeAnswer.length > 1) {
        interval = 100 / typeAnswer.length;
      }
      try {
        let {
          value
        } = yield this.swal.confirm('Gerar percentuais automaticamente?', {
          text: 'Todos os percentuais já informados serão editados',
          input: "text",
          inputPlaceholder: interval,
          inputValue: interval
        });
        if (value === undefined) {
          return;
        }
        let currentPercent = 100;
        let decrementValue = currentPercent / typeAnswer.length;
        decrementValue = value ? parseFloat(value) : decrementValue;
        let contentArray = this.typeAnswer.answers.toArray();
        contentArray = contentArray.sortBy('order').reverse();
        contentArray.forEach(obj => {
          if (obj.ignoreToCalculate) {
            Ember.set(obj, 'percentage', 0);
            obj.save();
            return;
          }
          currentPercent = currentPercent < 0 ? 0 : currentPercent.toFixed(2);
          Ember.set(obj, 'percentage', currentPercent);
          obj.save();
          currentPercent -= decrementValue;
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    remove: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do tipo de resposta?', {
          text: this.typeAnswer.name
        });
        if (!value) {
          return;
        }
        yield this.typeAnswer.destroyRecord();
        this.toast.success('Tipo de resposta removido');
        this.transitionToRoute('registry.avd.type-answer.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});