define("account-talentrh/pods/registry/social/import/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9C6GlXLX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fal fa-file-upload\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Importações\"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Utilize essa rotina para importar os recibos de pagamento, cartões ponto e comprovantes de rendimentos para exibição a seus colaboradores.\"],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary mt-5\"]],[[\"@route\"],[\"registry.social.import.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-upload\"],[12],[13],[2,\"\\n      Nova importação\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/social/import/index/template.hbs"
    }
  });
  _exports.default = _default;
});