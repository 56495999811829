define("account-talentrh/pods/registry/general/access-profile/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralAccessProfileEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.access-profile').clearFilters();
    }
  }
  _exports.default = RegistryGeneralAccessProfileEditRoute;
});