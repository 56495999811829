define("account-talentrh/pods/components/dashboard-schedule-link/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardScheduleLinkComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class DashboardScheduleLinkComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "data", _descriptor5, this);
    }
    verifyData() {
      const {
        slideItem,
        slideItemContent
      } = this.args;
      if (slideItem && slideItemContent) {
        this.data = {
          // SlideItem
          color: slideItem.get('color'),
          refreshTimeInSeconds: slideItem.get('refreshTimeInSeconds'),
          title: slideItem.get('title'),
          // SlideItemContent
          url: slideItemContent.get('url')
        };
      }
    }
    durationSelect(event) {
      this.data.refreshTimeInSeconds = Number(event.target.value);
    }
    onCloseModal() {
      const {
        resetToSchedule,
        save
      } = this.args;
      this.data = {
        // slideItem
        color: 'blue',
        refreshTimeInSeconds: 5,
        title: 'Link',
        // slideItemContent
        url: ''
      };
      if (save) {
        this.router.transitionTo('registry.social.mural.gridstack');
      } else {
        resetToSchedule();
      }
    }
    *save() {
      const {
        save,
        add
      } = this.args;
      if (!this.data.url) {
        return this.swal.warning('É necessário informar um link');
      }
      if (this.data.url) {
        const turimCnpj = '02516479000149';
        const currentCompany = yield this.store.findRecord('company', this.session.user.company);
        const validateOnlyHttps = currentCompany.cnpj !== turimCnpj;
        let link = null;
        if (validateOnlyHttps) {
          link = this.data.url.match(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
        } else {
          link = this.data.url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
        }
        if (!link) {
          return this.swal.warning('Formato inválido, utilize o https:// antes do link. Exemplo: https://voors.com.br');
        }
      }
      if (save) {
        save(this.data);
      } else {
        add(this.data);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        // slideItem
        color: 'blue',
        refreshTimeInSeconds: 5,
        title: 'Link',
        // slideItemContent
        url: ''
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "verifyData", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "verifyData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "durationSelect", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "durationSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = DashboardScheduleLinkComponent;
});