define("account-talentrh/pods/registry/general/user/edit/change-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "37t2H3vR",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\"],[true,[30,[36,0],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Alterar senha\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Nova senha\"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"autocomplete\",\"off\"]],[[\"@value\",\"@type\"],[[32,0,[\"password\",\"new\"]],\"password\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Confirmar senha\"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"autocomplete\",\"off\"]],[[\"@value\",\"@type\"],[[32,0,[\"password\",\"confirmation\"]],\"password\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"A sua senha deve ter:\"],[13],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"\\n        \"],[10,\"ul\"],[12],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Pelo menos 8 caracteres;\"],[13],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Pelo menos 2 números;\"],[13],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Pelo menos 1 caractere especial.\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,1],[[32,0,[\"changePassword\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/edit/change-password/template.hbs"
    }
  });
  _exports.default = _default;
});