define("account-talentrh/pods/setup-wizard/steps/solicitation/rh-flow/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class SetupWizardStepsSolicitationRhFlowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "settings", [{
        label: 'Solicitação de benefícios',
        value: 'ignoreManagerApprovalToBenefits',
        utilize: 'useIgnoreManagerApprovalToBenefits'
      }, {
        label: 'Solicitação de banco de horas',
        value: 'ignoreManagerApprovalToCompensatoryTime',
        utilize: 'useIgnoreManagerApprovalToCompensatoryTime'
      }, {
        label: 'Solicitação de adiantamento de 13º',
        value: 'ignoreManagerApprovalToThirteen',
        utilize: 'useIgnoreManagerApprovalToThirteen'
      }, {
        label: 'Solicitação de alteração de dados cadastrais',
        value: 'ignoreManagerApprovalToRegistrationData',
        utilize: 'useIgnoreManagerApprovalToRegistrationData'
      }, {
        label: 'Solicitação de férias',
        value: 'ignoreManagerApprovalToVacation',
        utilize: 'useIgnoreManagerApprovalToVacation'
      }, {
        label: 'Solicitação de uniforme',
        value: 'ignoreManagerApprovalToUniform',
        utilize: 'useIgnoreManagerApprovalToUniform'
      }, {
        label: 'Solicitação de adiantamento salarial',
        value: 'ignoreManagerApprovalToAdvance',
        utilize: 'useIgnoreManagerApprovalToAdvance'
      }, {
        label: 'Justificativa de ponto',
        value: 'ignoreManagerApprovalToJustification',
        utilize: 'useIgnoreManagerApprovalToJustification'
      }, {
        label: 'Pedido de demissão',
        value: 'ignoreManagerApprovalToTermination',
        utilize: 'useIgnoreManagerApprovalToTermination'
      }, {
        label: 'Solicitação de outros itens',
        value: 'ignoreManagerApprovalToOthers',
        utilize: 'useIgnoreManagerApprovalToOthers'
      }]);
    }
  }
  _exports.default = SetupWizardStepsSolicitationRhFlowController;
});