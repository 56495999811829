define("account-talentrh/pods/setup-wizard/steps/groups/link/manage/edit/controller", ["exports", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SetupWizardStepsGroupsLinkManageEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model'), (_class = class SetupWizardStepsGroupsLinkManageEditController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "utils", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "userLink", _descriptor4, this);
    }
    *save() {
      try {
        let userLink = this.userLink;
        yield userLink.save();
        this.swal.success('Link salvo com sucesso');
        this.transitionToRoute('setup-wizard.steps.groups.link.manage');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *deleteImage() {
      try {
        const modelId = this.userLink.id;
        const currentImageUrl = this.userLink.imageUrl;
        let result = yield this.swal.confirm('Deseja remover a imagem deste link?');
        if (!result.value) {
          return;
        }
        let userLink = yield this.ajax.delete(`userLinks/${modelId}/deleteImage`, {
          host: _environment.default.apiUrl.social,
          data: {
            path: currentImageUrl
          }
        });
        this.store.pushPayload(userLink);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *uploadLinkImage(file) {
      try {
        let filename = file.blob.name;
        if (!file) {
          return;
        }
        const name = this.utils.generateS3Name(filename);
        const key = `user-link/${name}`;
        const ajax = this.ajax;
        const currentImageUrl = this.userLink.imageUrl;
        const modelId = this.userLink.id;
        this.swal.loading('Fazendo upload da imagem.');

        // Se a imagem já existe, remove antes.
        if (currentImageUrl) {
          yield ajax.delete(`userLinks/${modelId}/deleteImage`, {
            host: _environment.default.apiUrl.social,
            data: {
              path: currentImageUrl
            }
          });
        }
        let response = yield ajax.request('userLinks/putSignedLink', {
          host: _environment.default.apiUrl.social,
          data: {
            key
          }
        });
        yield file.uploadBinary(response.link, {
          method: 'PUT'
        });
        this.userLink.imageUrl = key;
        this.swal.close();
        this.userLink.save();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userLink", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteImage", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "deleteImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadLinkImage", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "uploadLinkImage"), _class.prototype)), _class));
  _exports.default = SetupWizardStepsGroupsLinkManageEditController;
});