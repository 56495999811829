define("account-talentrh/pods/manage-lunch-list/controller", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    confirmed: Ember.computed('model.{guest.length,employees.length}', function () {
      const confirmedEmployee = this.model.employees.filterBy('confirmed', true);
      return this.model.guest.length + confirmedEmployee.length;
    }),
    toggleStatus: (0, _emberConcurrency.task)(function* (record) {
      let confirmed = record.get('confirmed');
      let swalMsg = confirmed ? 'Deseja cancelar essa solicitação de almoço?' : 'Deseja confirmar essa solicitação de almoço?';
      try {
        let result = yield this.swal.confirm(swalMsg);
        if (!result.value) {
          return;
        }
        record.set('confirmed', !confirmed);
        yield record.save();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    removeSolicitation: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Deseja remover essa solicitação de almoço?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        this.get('model.guest').removeObject(record);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      toggleStatus(record) {
        this.toggleStatus.perform(record);
      },
      removeSolicitation(record) {
        this.removeSolicitation.perform(record);
      },
      print() {
        (0, _jquery.default)('#lunch-list').printThis({
          importCSS: true,
          loadCSS: '',
          importStyle: true
        });
      }
    }
  });
  _exports.default = _default;
});