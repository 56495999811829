define("account-talentrh/pods/super-user/post/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VxH+pFvf",
    "block": "{\"symbols\":[\"@postTemplate\"],\"statements\":[[10,\"li\"],[14,0,\"list-group-item px-3\"],[12],[2,\"\\n  \"],[10,\"small\"],[14,0,\"float-right\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,1],[[32,0,[\"postTitle\"]]],[[\"limit\"],[95]]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"super-user.post.show\",[32,1,[\"id\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"truncate-text\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/post/item/template.hbs"
    }
  });
  _exports.default = _default;
});