define("account-talentrh/pods/components/payment-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qKCgankI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card credit-card\"],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"update\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"credit-card-img\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-credit-card fa-5x\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"author-date\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"h4 bold post__author-name\"],[12],[2,\"\\n        Atualizar dados do cartão\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"post__date\"],[12],[2,\"\\n        Clique aqui para atualizar os dados relacionados ao cartão de crédito utilizado no pagamento da rede Voors.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/payment-card/template.hbs"
    }
  });
  _exports.default = _default;
});