define("account-talentrh/pods/super-user/companies/edit/plugins/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    companyConfig: Ember.computed.alias('model.companyConfig'),
    company: Ember.computed.alias('model.company'),
    // Multiline computed properties
    enablePlugin: Ember.computed.not('companyConfig.featureFlags.ENABLE'),
    pluginsOptions: Ember.computed('company.cnpj', function () {
      return Ember.A([{
        label: 'Alterar senha de usuários',
        field: 'changingUsersPassword',
        show: true
      }, {
        label: 'Anexo em postagens',
        field: 'taskAttachments',
        show: true
      }, {
        label: 'Assinatura de e-mail',
        field: 'emailSignature',
        show: true
      }, {
        label: 'Empréstimo consignado',
        field: 'payrollLoan',
        show: true
      }, {
        label: 'Publicar por filial',
        field: 'postPerBranch',
        show: true
      }, {
        label: 'Transferência e baixa de bens',
        field: 'goods',
        show: true
      }, {
        label: 'Usar metas exclusivas',
        field: 'useExclusiveGoals',
        show: true
      }, {
        label: 'Validação de recibos de pagamento',
        field: 'paymentValidation',
        show: true
      }, {
        label: 'Exibir somente o feed',
        field: 'showOnlyFeed',
        show: true
      }, {
        label: 'Minhas compras',
        field: 'useUserWallet',
        show: true
      }]);
    }),
    // Actions
    actions: {
      updatePlugin(param) {
        this.toggleProperty(`companyConfig.featureFlags.${param}`);
      },
      disableAllPlugin() {
        if (this.companyConfig.featureFlags.ENABLE) {
          Ember.setProperties(Ember.get(this, 'model.companyConfig.featureFlags'), {
            goods: false,
            taskAttachments: false
          });
        }
        this.toggleProperty('companyConfig.featureFlags.ENABLE');
      }
    }
  });
  _exports.default = _default;
});