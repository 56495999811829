define("account-talentrh/pods/registry/general/department/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralDepartmentCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('department');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.department').clearFilters();
    }
  }
  _exports.default = RegistryGeneralDepartmentCreateRoute;
});