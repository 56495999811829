define("account-talentrh/pods/lgpd/delete/user/create/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LgpdDeleteUserCreateController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.controller('lgpd.delete.user'), _dec7 = Ember.computed.alias('permission.user'), _dec8 = Ember.computed('model.userToDelete'), (_class = class LgpdDeleteUserCreateController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "lgpdDeleteUserSolicitationController", _descriptor6, this);
      _initializerDefineProperty(this, "user", _descriptor7, this);
    }
    get userFullName() {
      return `${this.model.userToDelete.get('firstName')} ${this.model.userToDelete.get('lastName')}`;
    }
    *confirmSolicitation() {
      let value = '';
      let dismiss = false;
      while (value != 'CONFIRMAR') {
        ({
          dismiss,
          value
        } = yield this.swal.fire({
          title: 'Atenção',
          input: 'text',
          text: `Deseja realmente excluir o usuário ${this.userFullName}?
                Ao confirmar a exclusão, será enviado um SMS com um código de verificação para
                o número existente no seu cadastro.
                Digite "CONFIRMAR" no campo abaixo para efetuar a operação.`,
          showCancelButton: true,
          confirmButtonText: 'Ok'
        }));
        if (dismiss) {
          return false;
        }
        if (value != 'CONFIRMAR') {
          yield this.swal.warning('É necessário digitar "CONFIRMAR" no campo para efetuar a operação.');
          return false;
        }
        return true;
      }
    }
    *cancelConfirmationCode() {
      const newModel = this.store.createRecord('lgpd-delete-user-solicitation', {
        userToDelete: this.model.userToDelete
      });
      yield this.model.destroy();
      this.model = newModel;
    }
    *saveDeleteSolicitation() {
      this.swal.loading('Enviando código de confirmação...');
      yield this.model.save();
      this.swal.close();
    }
    *confirmConfirmationCode() {
      let continueCodeValidation = true;
      while (continueCodeValidation) {
        const confirmationCode = yield this.swal.fire({
          title: 'Atenção',
          text: `Deseja realmente excluir o usuário ${this.userFullName}?
                 Ao confirmar a exclusão, serão removidos todos os registros deste usuário e não será
                 possível recuperá-los. A exclusão será feita dentro de 7 dias.
                 Digite o código enviado para seu telefone no campo abaixo para efetuar a operação.`,
          input: 'number',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.swal.isLoading()
        });
        if (confirmationCode.dismiss) {
          continueCodeValidation = false;
          yield this.cancelConfirmationCode.perform();
          return;
        }
        const responseVerifyCode = yield this.ajax.put('/lgpdDeleteUserSolicitationConfirmationCodes/verifyCode', {
          data: {
            lgpdDeleteUserSolicitationId: this.model.id,
            confirmationCode: confirmationCode.value
          }
        });
        if (responseVerifyCode.isValid) {
          continueCodeValidation = false;
          this.toast.success('Solicitação cadastrada com sucesso!');
          this.lgpdDeleteUserSolicitationController.clearFilters();
          this.router.transitionTo('lgpd.delete.user.show', this.model.id);
        } else {
          if (responseVerifyCode.errorType !== 'codeNotFound') {
            continueCodeValidation = false;
          }
          yield this.swal.warning(responseVerifyCode.message);
          this.model.rollbackAttributes();
        }
      }
    }
    *save() {
      if (!this.model.validations.isValid) {
        return this.swal.validation(this.model.validations);
      }
      if (!this.user.cellphone) {
        return this.swal.warning('Verifique seu cadastro, pois não foi identificado número para envio do código de confirmação');
      }
      try {
        const isConfirmed = yield this.confirmSolicitation.perform();
        if (!isConfirmed) {
          return;
        }
        yield this.saveDeleteSolicitation.perform();
        yield this.confirmConfirmationCode.perform();
      } catch (err) {
        console.log(err);
        Ember.debug(err.message);
        this.swal.catch(err);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lgpdDeleteUserSolicitationController", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "userFullName", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "userFullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmSolicitation", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "confirmSolicitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelConfirmationCode", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfirmationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveDeleteSolicitation", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveDeleteSolicitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmConfirmationCode", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "confirmConfirmationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = LgpdDeleteUserCreateController;
});