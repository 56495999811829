define("account-talentrh/pods/application/controller", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    isProductionBuild: Ember.computed(function () {
      return _environment.default.environment === 'production';
    }),
    isReady: Ember.computed.and('session.isAuthenticated', 'permission.isLoaded', 'session.user'),
    actions: {
      logout() {
        this.session.invalidate();
      }
    }
  });
  _exports.default = _default;
});