define("account-talentrh/pods/registry/general/user/edit/trainings/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RegistryGeneralUserEditTrainingsController = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.computed.alias('model.user'), _dec6 = Ember.computed.alias('model.company'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class RegistryGeneralUserEditTrainingsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "user", _descriptor5, this);
      _initializerDefineProperty(this, "company", _descriptor6, this);
      _initializerDefineProperty(this, "haveTedModule", _descriptor7, this);
      _initializerDefineProperty(this, "modalTrainings", _descriptor8, this);
      _initializerDefineProperty(this, "modalTitle", _descriptor9, this);
      _initializerDefineProperty(this, "openModal", _descriptor10, this);
    }
    availableModules() {
      if (this.permission.toAccessTED) {
        this.haveTedModule = true;
      }
    }
    *save() {
      let training = this.modalTrainings;
      try {
        if (training) {
          training.createdAt = new Date();
          if (!training.validations.isValid) {
            return this.swal.validation(training.validations);
          }
          if (training.new) {
            delete training.new;
            yield this.fetch.post('/training/createNewTraining', {
              data: {
                training: training,
                userId: this.user.id
              }
            }).then(async response => {
              if (response && response.training) {
                training = await this.store.findRecord('training', response.training.id);
                this.user.trainings.addObject(training);
                this.modalTrainings.new = true;
                this.toast.success('Treinamento cadastrado');
                this.openModal = false;
              } else {
                this.toast.warning('Não foi possivel criar treinamento');
                return;
              }
            });
          } else {
            delete training.new;
            let trainingSaved = yield training.save();
            this.user.trainings.addObject(trainingSaved);
            this.toast.success('Treinamento atualizado');
            this.modalTrainings.new = true;
            this.openModal = false;
          }
        }
      } catch (e) {
        this.swal.catch(e);
        this.toast.warning('Não foi possivel criar treinamento');
      }
    }
    *removeTraining(training) {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover treinamento?');
        if (!value) {
          return;
        }
        yield this.fetch.post('/training/deleteTraining', {
          data: {
            training: training.id,
            userId: this.user.id
          }
        }).then(response => {
          if (response && response.training) {
            this.user.trainings.removeObject(training);
          } else {
            this.toast.warning('Não foi possivel excluir treinamento');
            return;
          }
        });
        this.toast.success('Treinamento removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    closeModal() {
      if (!this.modalTrainings.new) {
        this.user.trainings.rollbackAttributes();
      }
    }
    openEditModal(training) {
      this.modalTrainings = training;
      this.modalTrainings.new = false;
      this.modalTitle = 'Editar treinamento';
      this.openModal = true;
    }
    openCreateModal() {
      this.modalTrainings = this.store.createRecord('training', {
        company: this.company
      });
      this.modalTrainings.new = true;
      this.modalTitle = 'Incluir treinamento';
      this.openModal = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "haveTedModule", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "modalTrainings", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTraining", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeTraining"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openEditModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateModal"), _class.prototype)), _class));
  _exports.default = RegistryGeneralUserEditTrainingsController;
});