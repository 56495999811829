define("account-talentrh/pods/components/plan-column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    actions: {
      selectPlan(plan) {
        let description = 'Plano ' + this.number + ' - ' + this.subtext + ' - ' + this.price;
        this.set('selected', {
          id: plan,
          price: this.price,
          number: this.number,
          subtext: this.subtext,
          description: description
        });
        if (this.page && this.page === 'login') {
          var stringSelectedPlan = JSON.stringify(this.selected);
          localStorage.setItem('nela:selectedPlan', stringSelectedPlan);
          this.routing.transitionTo('register.company');
        }
      }
    }
  });
  _exports.default = _default;
});