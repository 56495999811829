define("account-talentrh/pods/registry/social/import/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialImportsCreateRoute extends Ember.Route {
    model() {
      return {
        title: "",
        competence: "",
        files: []
      };
    }
    deactivate() {
      this.controllerFor('registry.social.import').clearFilters();
    }
  }
  _exports.default = RegistrySocialImportsCreateRoute;
});