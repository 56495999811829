define("account-talentrh/pods/registry/general/room/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralRoomShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.general.room').filters = {};
      this.controllerFor('registry.general.room').clearFilters();
    }
  }
  _exports.default = RegistryGeneralRoomShowRoute;
});