define("account-talentrh/pods/setup-wizard/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aThPMJSa",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[10,\"h1\"],[14,0,\"text-social text-center mb-5\"],[12],[2,\"Bem-vindo ao assistente de configuração Voors!\"],[13],[2,\"\\n\\n\"],[8,\"ui-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-5\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Vamos ajudá-lo nos primeiros passos para que possa configurar de forma personalizada a plataforma Voors e aproveitar ao máximo todas as suas funcionalidades.\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Clique em começar agora e em poucos minutos você estará com a plataforma pronta para utilização.\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h5\"],[12],[2,\"\\n          Caso precise interromper o cadastro, fique tranquilo, seu progresso será salvo de forma automática e você poderá retomar a qualquer momento, de onde parou.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[10,\"iframe\"],[14,0,\"w-100 h-vh-4\"],[14,\"src\",\"https://player.vimeo.com/video/757137460?h=852c591573\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center mt-5 mb-5\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-lg btn-social\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"start\"]]],null]],[14,4,\"button\"],[12],[2,\"Começar agora\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/welcome/template.hbs"
    }
  });
  _exports.default = _default;
});