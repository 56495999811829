define("account-talentrh/pods/components/dashboard-schedule/component", ["exports", "@glimmer/component", "account-talentrh/utils/dashboard-slide-item-utils"], function (_exports, _component, _dashboardSlideItemUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardScheduleComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('permission.companyConfig'), _dec3 = Ember.computed('companyConfig.{permitAd,permitAnniversary,permitAnnouncement,permitCompanyPhotos,permitCreateVacancyAnnouncement,permitEvent,permitExternalLink,permitMural,postAnniversaryCompany}'), _dec4 = Ember.computed('show'), _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class DashboardScheduleComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor2, this);
      _defineProperty(this, "types", _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES);
      _initializerDefineProperty(this, "show", _descriptor3, this);
    }
    get schedules() {
      return Ember.A([{
        title: 'Álbum de fotos',
        img: '/images/dashboard/vectors/álbum de fotos.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ALBUM,
        enabled: this.companyConfig.permitCompanyPhotos
      }, {
        title: 'Aniversariantes de empresa',
        img: '/images/dashboard/vectors/aniversariantes de empresa.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ADMISSION,
        enabled: this.companyConfig.permitAnniversary
      }, {
        title: 'Aniversariantes do dia',
        img: '/images/dashboard/vectors/Aniversariante do dia.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.BIRTHDAY,
        enabled: this.companyConfig.permitAnniversary
      }, {
        title: 'Ausências',
        img: '/images/dashboard/vectors/Ausencias.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ABSENCE,
        enabled: this.companyConfig.permitMural
      }, {
        title: 'Classificados',
        img: '/images/dashboard/vectors/classificados.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.AD,
        enabled: this.companyConfig.permitAd
      }, {
        title: 'Comunicados',
        img: '/images/dashboard/vectors/comunicados.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ANNOUNCEMENT,
        enabled: this.companyConfig.permitAnnouncement
      }, {
        title: 'Eventos',
        img: '/images/dashboard/vectors/Eventos.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.EVENT,
        enabled: this.companyConfig.permitEvent
      }, {
        title: 'Imagem',
        img: '/images/dashboard/vectors/imagens.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.IMAGE,
        enabled: true
      }, {
        title: 'Link',
        img: '/images/dashboard/vectors/link.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.LINK,
        enabled: this.companyConfig.permitExternalLink
      }, {
        title: 'Novos colaboradores',
        img: '/images/dashboard/vectors/Novos colaboradores.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.RECENT_USERS,
        enabled: true
      }, {
        title: 'Vagas',
        img: '/images/dashboard/vectors/vagas.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.VACANCY,
        enabled: this.companyConfig.permitCreateVacancyAnnouncement
      }, {
        title: 'Vídeo',
        img: '/images/dashboard/vectors/vídeos.svg',
        modal: _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.VIDEO,
        enabled: true
      }]);
    }
    get isShowSchedule() {
      return this.show === 'schedule';
    }
    showModal(modal) {
      this.show = modal;
    }
    add(type, data) {
      this.show = 'schedule';
      this.args.add(type, data);
    }
    resetToSchedule() {
      this.show = 'schedule';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "schedules", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "schedules"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isShowSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isShowSchedule"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "show", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'schedule';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetToSchedule", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "resetToSchedule"), _class.prototype)), _class));
  _exports.default = DashboardScheduleComponent;
});