define("account-talentrh/pods/super-user/companies/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SuperUserCompaniesShowRoute extends Ember.Route {
    model(_ref) {
      let {
        company_id
      } = _ref;
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', company_id),
        subscription: this.store.queryRecord('subscription', {
          company: company_id
        }),
        companyConfig: this.store.loadRecord('company-configuration', `companies/${company_id}/companyConfiguration`)
      });
    }
    deactivate() {
      this.controllerFor('super-user.companies').set('filters', {});
    }
  }
  _exports.default = SuperUserCompaniesShowRoute;
});