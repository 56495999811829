define("account-talentrh/models/sector", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Nome do setor deve ser preenchido'
    })
  });
  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company')
  });
  _exports.default = _default;
});