define("account-talentrh/pods/components/modal-indicator-user-details/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "chart-js"], function (_exports, _component, _emberConcurrencyDecorators, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ModalIndicatorUserDetailsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class ModalIndicatorUserDetailsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor3, this);
      _initializerDefineProperty(this, "details", _descriptor4, this);
      _initializerDefineProperty(this, "polarChart", _descriptor5, this);
    }
    *userDetailsTask() {
      try {
        this.isLoaded = false;

        // O user que vem para o modal é um objeto proxy, ember-data: por isso yield
        let user = yield this.args.user;
        let details = yield this.fetch.request('/indicators/indicatorUserDetails', {
          data: {
            userId: user.id
          }
        });
        this.details = details;
        this.isLoaded = true;
        this.buildChart();
      } catch (e) {
        Ember.debug(e);
      }
    }
    buildChart() {
      if (!this.details || !this.details.polarChartData) {
        return;
      }
      let chart = this.polarChart;
      let data = {
        labels: this.details.polarChartData.labels,
        datasets: [{
          data: this.details.polarChartData.interactions,
          backgroundColor: 'rgba(96, 144, 197, 0.6)'
        }]
      };
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('polarChart').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'polarArea',
          responsive: true,
          data: data,
          options: {
            responsive: true,
            legend: {
              display: false
            },
            animateRotate: true,
            animateScale: true,
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
              }
            }
          }
        });
      }
      this.polarChart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "details", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "polarChart", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "userDetailsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "userDetailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildChart", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "buildChart"), _class.prototype)), _class));
  _exports.default = ModalIndicatorUserDetailsComponent;
});