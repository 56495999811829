define("account-talentrh/pods/components/modal-indicator-filter/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    saveFilter: true,
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.indicatorToSetFilter) {
        let {
          endDate,
          startDate
        } = this.indicatorToSetFilter.filterData;
        if (endDate && startDate) {
          Ember.setProperties(this.indicatorToSetFilter.filterData, {
            endDate: (0, _moment.default)(endDate).toDate(),
            startDate: (0, _moment.default)(startDate).toDate()
          });
        }
        this.set('specificFilterTitle', this.generateSpecificFilterTitle());
        this.send('generateJobFunction');
      }
    },
    generateSpecificFilterTitle() {
      //Página clima organizacional
      if (this.indicatorToSetFilter.name === 'generalClimate') {
        return 'de Clima Predominante';
      } else if (this.indicatorToSetFilter.name === 'negativeFeelingsForEmployee') {
        return 'de Sentimentos Negativos por Colaborador';
      } else if (this.indicatorToSetFilter.name === 'amountOfFeelingsByType') {
        return 'de Sentimentos por Tipo';
      }
      //Página perfil da empresa
      else if (this.indicatorToSetFilter.name === 'salaryGeneralInfo') {
        return 'de Salários';
      } else if (this.indicatorToSetFilter.name === 'schoolingByType') {
        return 'de Escolaridade';
      } else if (this.indicatorToSetFilter.name === 'jobfunctionsByType') {
        return 'de Distribuição de Funções';
      } else if (this.indicatorToSetFilter.name === 'ageRange') {
        return 'de Faixa Etária';
      } else if (this.indicatorToSetFilter.name === 'companyYears') {
        return 'de Usuários por Tempo de Empresa';
      } else if (this.indicatorToSetFilter.name === 'absencesByType') {
        return 'de Ausências por Tipo';
      } else if (this.indicatorToSetFilter.name === 'usersByGender') {
        return 'de Usuários por Sexo';
      } else if (this.indicatorToSetFilter.name === 'hiresPerYear') {
        return 'de Contratação';
      } else if (this.indicatorToSetFilter.name === 'generalIndicators') {
        return 'de Indicadores Gerais';
      } else if (this.indicatorToSetFilter.name === 'disapprovalAndApproval') {
        return 'de Avaliações de Experiência';
      } else if (this.indicatorToSetFilter.name === 'functionEvaluationStatistics') {
        return 'de Avaliações de Função';
      } else if (this.indicatorToSetFilter.name === 'functionEvaluationChart') {
        return 'de Notas por Função';
      } else if (this.indicatorToSetFilter.name === 'totalOfEvaluationsByType') {
        return 'de Avaliações Criadas por Tipo';
      } else if (this.indicatorToSetFilter.name === 'evaluationsOpenByType') {
        return 'de Avaliações Abertas por Tipo';
      } else if (this.indicatorToSetFilter.name === 'evaluationsDelayedOrExpired') {
        return 'de Avaliações Dentro do prazo x Atrasadas x Expiradas';
      } else if (this.indicatorToSetFilter.name === 'evaluationsDelayedByType') {
        return 'de Avaliações Atrasadas por Tipo';
      } else if (this.indicatorToSetFilter.name === 'expiredOrCanceledByAppraiser') {
        return 'de Finalizadas e Canceladas por Avaliador Responsável';
      } else if (this.indicatorToSetFilter.name === 'employeeEvolutionByFunction') {
        return 'de Avaliação de Desempenho por Empregado';
      }
    },
    actions: {
      generateIndicators() {
        let indicatorToSetFilter = this.processesFilters(this.indicatorToSetFilter);
        let filterData = Ember.get(indicatorToSetFilter, 'filterData');
        if ((filterData.startDate || !indicatorToSetFilter.filters.startDate) && (filterData.endDate || !indicatorToSetFilter.filters.endDate)) {
          if (this.saveFilter && indicatorToSetFilter && indicatorToSetFilter.isGeneralFilters) {
            let storageFilters = {
              startDate: filterData.startDate,
              endDate: filterData.endDate
            };
            if (filterData.branch) {
              storageFilters.branch = filterData.branch.map(o => {
                return {
                  id: o.id,
                  name: o.name
                };
              });
            }
            if (filterData.department) {
              storageFilters.department = filterData.department.map(o => {
                return {
                  id: o.id,
                  name: o.name
                };
              });
            }
            if (filterData.branch) {
              storageFilters.branch = filterData.branch.map(o => {
                return {
                  id: o.id,
                  companyName: o.companyName
                };
              });
            }
            if (filterData.jobRole) {
              storageFilters.jobRole = filterData.jobRole.map(o => {
                return {
                  id: o.id,
                  title: o.title
                };
              });
            }
            if (filterData.jobFunction) {
              storageFilters.jobFunction = filterData.jobFunction.map(o => {
                return {
                  id: o.id,
                  title: o.title
                };
              });
            }
            localStorage.setItem('indicatorFilters', JSON.stringify(storageFilters));
          }
          Ember.set(this, 'show', false);
          this.loadIndicators(indicatorToSetFilter);
        } else {
          this.swal.warning('Esta tela de indicadores precisa de uma data início e fim');
        }
      },
      //Faço uma lista de JobRoles selecionados para listar os JobFunctions
      generateJobFunction() {
        let indicatorToSetFilter = this.processesFilters(this.indicatorToSetFilter);
        let filterData = Ember.get(indicatorToSetFilter, 'filterData');
        let listJobRoles = [];
        if (filterData.jobRole && filterData.jobRole.length > 0) {
          listJobRoles = filterData.jobRole.map(o => {
            return {
              id: o.id
            };
          });
        }
        this.set('listJobRoles', listJobRoles);
      },
      clearIndicatorFilters() {
        localStorage.removeItem('indicatorFilters');
        Ember.set(this.indicatorToSetFilter, "filterData", {});
      }
    },
    // Processa os dados selecionado no filtro e adiciona em indicatorToSetFilter no formato adequado
    processesFilters(indicatorToSetFilter) {
      if (indicatorToSetFilter.filterData.branch) {
        Ember.set(indicatorToSetFilter, 'filterData.branch', indicatorToSetFilter.filterData.branch);
      }
      if (indicatorToSetFilter.filterData.department) {
        Ember.set(indicatorToSetFilter, 'filterData.department', indicatorToSetFilter.filterData.department);
      }
      if (indicatorToSetFilter.filterData.jobRole) {
        Ember.set(indicatorToSetFilter, 'filterData.jobRole', indicatorToSetFilter.filterData.jobRole);
      }
      if (indicatorToSetFilter.filterData.jobFunction) {
        Ember.set(indicatorToSetFilter, 'filterData.jobFunction', indicatorToSetFilter.filterData.jobFunction);
      }
      return indicatorToSetFilter;
    }
  });
  _exports.default = _default;
});