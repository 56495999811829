define("account-talentrh/pods/payment-validation/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PaymentValidationCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('payment-validation');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = PaymentValidationCreateRoute;
});