define("account-talentrh/pods/super-user/lgpd/delete/user/show/projetos/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ruQrFfue",
    "block": "{\"symbols\":[],\"statements\":[[8,\"lgpd/delete/user/show/delete-counts\",[],[[\"@deleteCounts\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/lgpd/delete/user/show/projetos/template.hbs"
    }
  });
  _exports.default = _default;
});