define("account-talentrh/pods/registry/general/user/create/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel() {
      if (!this.permission.admin && !this.permission.toManageOnlyBranch && !this.permission.toRegistrationDataManagement) {
        this.swal.warning('Você não tem permissão para cadastrar usuários. Contate um administrador do sistema');
        this.transitionTo('registry.general.user.index');
      } else if (!this.admin && !this.permission.user.branches.length && this.permission.toManageOnlyBranch) {
        this.swal.warning('Você só tem permissão para gerenciar filiais, mas não pertence a nenhuma no momento. Contate um administrador do sistema');
        this.transitionTo('registry.general.user.index');
      }
    },
    model() {
      return this.modelTask.perform();
    },
    modelTask: (0, _emberConcurrency.task)(function* () {
      try {
        let company = yield this.store.findRecord('company', this.session.user.company);
        return this.store.createRecord('user', {
          company,
          active: true
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.user').clearFilters();
    }
  });
  _exports.default = _default;
});