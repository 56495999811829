define("account-talentrh/pods/registry/avd/goal/index/import/controller", ["exports", "ember-concurrency", "xlsx"], function (_exports, _emberConcurrency, _xlsx) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    goals: null,
    saveGoals: (0, _emberConcurrency.task)(function* () {
      try {
        yield Ember.RSVP.map(this.goals, goal => {
          if (!goal.hasError) {
            return goal.save().catch(() => {
              Ember.set(goal, 'hasError', true);
            });
          }
        });

        // Não fechar o modal caso tenha algum erro
        const hasGoalWithError = this.goals.filter(goal => goal.hasError);
        if (hasGoalWithError.length > 0) {
          Ember.set(this, 'goals', hasGoalWithError);
          this.toast.warning('Alguns registros não puderam ser importados');
          this.send('closeModal');
        } else {
          this.swal.close();
          this.toast.success('Todos os registros foram importados com sucesso');
          this.send('closeModal');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    importGoals: (0, _emberConcurrency.task)(function* (workbook) {
      try {
        this.swal.loading('importando...');
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = _xlsx.default.utils.sheet_to_json(sheet, {
          header: 1,
          blankrows: false
        });
        // Pula a primeira linha que é o cabeçario;
        const lines = json.splice(1);
        this.proccessXlsx(lines);
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    proccessXlsx(results) {
      try {
        let goals = results.map(line => {
          if (line.length <= 1) {
            return null;
          }
          const unitOptions = Ember.A([{
            label: 'numerica',
            value: 'numeric',
            fieldValueLabel: 'Quantidade'
          }, {
            label: 'porcentagem',
            value: 'percentage',
            fieldValueLabel: 'Percentual'
          }, {
            label: 'monetaria',
            value: 'monetary',
            fieldValueLabel: 'Valor'
          }, {
            label: 'horas',
            value: 'hours',
            fieldValueLabel: 'Horas'
          }]);
          const unitTypes = Ember.A([{
            label: 'maior que',
            value: 'greaterThan'
          }, {
            label: 'menor que',
            value: 'lessThan'
          }, {
            label: 'maior ou igual',
            value: 'greaterThanOrEqual'
          }, {
            label: 'menor ou igual',
            value: 'lessThanOrEqual'
          }]);
          let unit = null;
          if (line[2]) {
            line[2] = line[2] ? line[2].normalize('NFD').replace(/[\u0300-\u036f]/g, '') : null;
            unit = unitOptions.findBy('label', line[2].toLowerCase());
          }
          let unitType = null;
          if (line[3]) {
            unitType = unitTypes.findBy('label', line[3].toLowerCase());
          }
          let value = null;
          let valueArr = null;
          let pos = null;
          if (unit && unit.value && line[4]) {
            switch (unit.value) {
              case 'numeric':
                line[4] = line[4].replaceAll(',', '');
                line[4] = line[4].replaceAll('.', '');
                pos = line[4].length - 1;
                line[4] = line[4].substring(0, pos) + '.' + line[4].substring(pos);
                value = line[4];
                break;
              case 'percentage':
                value = line[4] / 100;
                break;
              case 'monetary':
                line[4] = line[4].replaceAll(',', '');
                line[4] = line[4].replaceAll('.', '');
                pos = line[4].length - 2;
                line[4] = line[4].substring(0, pos) + '.' + line[4].substring(pos);
                value = line[4];
                break;
              case 'hours':
                valueArr = line[4].split('h');
                if (valueArr.length > 1) {
                  value = parseInt(valueArr[0]) * 60;
                  if (valueArr[1].length) {
                    value = valueArr[1] ? value + parseInt(valueArr[1].replace('m', '')) : 0;
                  }
                } else if (valueArr.length === 1) {
                  value = valueArr[0] ? parseInt(valueArr[0].replace('m', '')) : 0;
                }
                break;
            }
          }
          let startAt = line[5] ? line[5] : null;
          let endAt = line[6] ? line[6] : null;
          return this.store.createRecord('goal', {
            hasError: !line[0] || !value || !unit || !unitType || !startAt || !endAt ? true : null,
            title: line[0] ? line[0] : null,
            description: line[1] ? line[1] : null,
            unit: unit && unit.value ? unit.value : null,
            unitType: unitType && unitType.value ? unitType.value : null,
            value: value ? value : null,
            startAt: startAt,
            endAt: endAt
          });
        });
        goals = goals.compact();
        Ember.set(this, 'goals', goals);
        this.saveGoals.perform();
      } catch (e) {
        this.swal.catch('Não foi possível importar planilha. Por favor verifique se o arquivo carregado está no padrão do arquivo modelo!');
        this.send('closeModal');
      }
    },
    actions: {
      closeModal() {
        this.transitionToRoute('registry.avd.goal.index');
      },
      loadXlsx(event) {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const data = new Uint8Array(reader.result);
            const workbook = _xlsx.default.read(data, {
              type: 'array',
              cellDates: true
            });
            this.importGoals.perform(workbook);
          } catch (e) {
            this.swal.catch('Não foi possível carregar o arquivo. Por favor verifique se o arquivo carregado está no padrão do arquivo modelo!!');
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  });
  _exports.default = _default;
});