define("account-talentrh/pods/configuration/nela/policy/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2f2kMHv7",
    "block": "{\"symbols\":[\"Page\",\"Card\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Políticas da Empresa\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"Breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,4,[\"Link\"]],[],[[\"@link\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Início\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,4,[\"Link\"]],[],[[\"@link\"],[\"registry.social\"]],[[\"default\"],[{\"statements\":[[2,\"Cadastros Voors\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,4,[\"Link\"]],[],[[\"@link\"],[\"configuration.nela.book\"]],[[\"default\"],[{\"statements\":[[2,\"Políticas da Empresa\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[4]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"configuration/nela/policy/form\",[],[[\"@policy\"],[[32,0,[\"model\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/nela/policy/new/template.hbs"
    }
  });
  _exports.default = _default;
});