define("account-talentrh/pods/registry/general/car/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralCarEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.car').filters = {};
      this.controllerFor('registry.general.car').clearFilters();
    }
  }
  _exports.default = RegistryGeneralCarEditRoute;
});