define("account-talentrh/pods/configuration/nela/reason/index/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    color: Ember.computed('reason.color', function () {
      let color = this.reason.color || '#FFF';
      return Ember.String.htmlSafe(`<span class="d-inline-block border border-dark rounded mr-2" style="height:20px;width:20px;background:${color}"/>`);
    }),
    removeReason: (0, _emberConcurrency.task)(function* (reason) {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover motivo de ausência?', {
          text: reason.title
        });
        if (!value) {
          return;
        }
        yield reason.destroyRecord();
        Ember.set(this, 'filter', {});
        this.toast.success('Motivo de ausência removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});