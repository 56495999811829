define("account-talentrh/models/institution", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A instituição deve ter um nome'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    address: (0, _model.attr)('string'),
    cnpj: (0, _model.attr)('string'),
    cpf: (0, _model.attr)('string'),
    complement: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    neighborhood: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    physicalPerson: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    zipcode: (0, _model.attr)('string'),
    // Associations
    city: (0, _model.belongsTo)('city'),
    organization: (0, _model.belongsTo)('organization')
  });
  _exports.default = _default;
});