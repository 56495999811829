define("account-talentrh/models/answer", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'É necessário informar uma descrição para a alternativa'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    color: (0, _model.attr)('string'),
    definition: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    ignoreToCalculate: (0, _model.attr)('boolean'),
    needJustification: (0, _model.attr)('boolean'),
    order: (0, _model.attr)('number'),
    percentage: (0, _model.attr)('string'),
    // Associations
    typeAnswer: (0, _model.belongsTo)('typeAnswer')
  });
  _exports.default = _default;
});