define("account-talentrh/models/user-link", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.belongsTo)('company'),
    name: (0, _model.attr)('string'),
    link: (0, _model.attr)('string'),
    imageUrl: (0, _model.attr)('string'),
    signedUrl: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user'),
    get userNames() {
      return this.users.mapBy('fullName').join(', ');
    }
  });
  _exports.default = _default;
});