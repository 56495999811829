define("account-talentrh/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('institution-base', 'institution-bases');
  inflector.irregular('course-base', 'course-bases');
  var _default = {};
  _exports.default = _default;
});