define("account-talentrh/pods/super-user/post/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    postTemplate: Ember.computed.alias('model'),
    removePostTemplate: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover publicação?');
        if (!value) {
          return;
        }
        yield this.postTemplate.destroyRecord();
        this.toast.success('Publicação removida');
        this.router.transitionTo('super-user.post.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});