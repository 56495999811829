define("account-talentrh/pods/errors/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BjTIRstt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-meh fa-3x text-primary\"],[12],[13],[13],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Você não tem autorização para ver esta página.\"],[13],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"Fale com um administrador do Voors para obter informações sobre as suas permissões de acesso.\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/errors/forbidden/template.hbs"
    }
  });
  _exports.default = _default;
});