define("account-talentrh/models/room", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Descrição deve ser preenchido'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    address: (0, _model.attr)('string'),
    allowAllUsers: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    capacity: (0, _model.attr)('number'),
    color: (0, _model.attr)('string'),
    complement: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    neighborhood: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    zipcode: (0, _model.attr)('string'),
    // Associations
    city: (0, _model.belongsTo)('city'),
    company: (0, _model.belongsTo)('company'),
    usersAllowed: (0, _model.hasMany)('user')
  });
  _exports.default = _default;
});