define("account-talentrh/pods/registry/general/institution/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Asrg8QCy",
    "block": "{\"symbols\":[\"@institution\"],\"statements\":[[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[1,[32,0,[\"identificationNumber\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/institution/item/template.hbs"
    }
  });
  _exports.default = _default;
});