define("account-talentrh/pods/registry/social/vacancy/show/job-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qHuaIQFm",
    "block": "{\"symbols\":[\"jobRole\",\"Card\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\",\"jobRoles\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-xl-4 col-md-6 mb-2\"],[12],[2,\"\\n        \"],[8,\"ui-card\",[[24,0,\"shadow-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,2,[\"Body\"]],[[24,0,\"d-flex align-items-center\"],[16,\"title\",[32,1,[\"title\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"flex-fill text-truncate ml-2\"],[12],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold mb-0\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"registry.general.job-role.show.index\",[32,1,[\"id\"]]]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-12 text-center font-italic\"],[12],[2,\"\\n        Nenhum cargo relacionado.\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/social/vacancy/show/job-roles/template.hbs"
    }
  });
  _exports.default = _default;
});