define("account-talentrh/pods/super-user/companies/show/plugins/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    company: Ember.computed.alias('model.company'),
    // Multiline computed properties
    pluginsOptions: Ember.computed('company.cnpj', function () {
      return Ember.A([{
        label: 'Alterar senha de usuários',
        field: 'changingUsersPassword',
        show: true
      }, {
        label: 'Anexo em postagens',
        field: 'taskAttachments',
        show: true
      }, {
        label: 'Assinatura de e-mail',
        field: 'emailSignature',
        show: true
      }, {
        label: 'Empréstimo consignado',
        field: 'payrollLoan',
        show: true
      }, {
        label: 'Publicar por filial',
        field: 'postPerBranch',
        show: true
      }, {
        label: 'Transferência e baixa de bens',
        field: 'goods',
        show: true
      }, {
        label: 'Usar metas exclusivas',
        field: 'useExclusiveGoals',
        show: true
      }, {
        label: 'Validação de recibos de pagamento',
        field: 'paymentValidation',
        show: true
      }, {
        label: 'Exibir somente o feed',
        field: 'showOnlyFeed',
        show: true
      }]);
    })
  });
  _exports.default = _default;
});