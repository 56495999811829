define("account-talentrh/pods/configuration/avd/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "alcD2gXt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"permission\",\"toAccessAVD\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-5\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[10,\"i\"],[14,0,\"fa fa-meh-o\"],[12],[13],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-center\"],[12],[2,\"Ops! talvez tenha ocorrido algum engano, pois\"],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-center\"],[12],[2,\"sua conta não tem as permissões necessárias para acessar este módulo.\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-center mt-3\"],[12],[2,\"Caso o módulo esteja disponível em sua empresa, será necessário que o administrador lhe conceda as\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-center\"],[12],[2,\" permissões necessárias para o gerênciamento destes cadastros.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/avd/template.hbs"
    }
  });
  _exports.default = _default;
});