define("account-talentrh/models/user-preference", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    account: (0, _model.attr)('raw'),
    projectManager: (0, _model.attr)('raw'),
    // Associations
    user: (0, _model.belongsTo)('user')
  });
  _exports.default = _default;
});