define("account-talentrh/pods/components/dashboard-action-timer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9J2wmTH7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"stop\"]]],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"will-destroy\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/dashboard-action-timer/template.hbs"
    }
  });
  _exports.default = _default;
});