define("account-talentrh/pods/payment-validation/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PaymentValidationIndexRoute extends Ember.Route {
    model() {
      return this.store.findAll('payment-validation');
    }
  }
  _exports.default = PaymentValidationIndexRoute;
});