define("account-talentrh/utils/validate-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateEmail;
  /**
   * Verifica se o emaio  é válido
   * Retirado de http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
   * Em: 10/05/2017
   */
  function validateEmail(email) {
    var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  }
});