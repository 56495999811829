define("account-talentrh/pods/registry/general/department/show/responsible-for-solicitation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralDepartmentShowResponsibleForSolicitationRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.general.department.show');
    }
  }
  _exports.default = RegistryGeneralDepartmentShowResponsibleForSolicitationRoute;
});