define("account-talentrh/models/city", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    state: (0, _model.belongsTo)('state'),
    ddd: (0, _model.attr)('string'),
    official: (0, _model.attr)('string'),
    zipcode: (0, _model.attr)('string'),
    ibge: (0, _model.attr)('string'),
    benefits: (0, _model.hasMany)('benefit', {
      inverse: 'cities'
    })
  });
  _exports.default = _default;
});