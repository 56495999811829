define("account-talentrh/pods/registry/general/access-profile/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    accessProfile: Ember.computed.alias('model'),
    removeAccessProfile: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do perfil de acesso?', {
          text: this.accessProfile.title
        });
        if (!value) {
          return;
        }
        let users = yield this.accessProfile.users;
        if (users.length) {
          return this.swal.warning('Há usuários vinculados a este perfil de acesso', {
            title: 'Não foi possível remover'
          });
        }
        yield this.accessProfile.destroyRecord();
        this.toast.success('Perfil de acesso removido');
        this.router.transitionTo('registry.general.access-profile');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});