define("account-talentrh/pods/super-user/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    links: Ember.A([{
      label: 'Empresas',
      route: 'super-user.companies.index'
    }, {
      label: 'Indicadores',
      route: 'super-user.indicators'
    }, {
      label: 'Publicações',
      route: 'super-user.post.index'
    }, {
      label: 'Assistente de Configurações',
      route: 'super-user.wizard-status.index'
    }, {
      label: 'Notificação de Versão',
      route: 'super-user.control-version.index'
    }, {
      label: 'Noticias do Habitus',
      route: 'super-user.article.index'
    }, {
      label: 'Solicitações de exclusão de usuários',
      route: 'super-user.lgpd.delete.user'
    }, {
      label: 'Solicitações de backup de empresa',
      route: 'super-user.lgpd.backup.company'
    }])
  });
  _exports.default = _default;
});