define("account-talentrh/pods/setup-wizard/steps/groups/group/manage/form/user-manager/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "account-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserManagerComponent = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('args.group'), _dec8 = Ember.computed('args.group.admins.@each', 'session.user.{admin,groupsAdmin}'), _dec9 = Ember._action, (_class = class UserManagerComponent extends _component.default {
    // Trackeds

    // Service

    // Computed

    get canManage() {
      let {
        id
      } = this.session.user || {};
      let {
        admin
      } = this.store.peekRecord('user', id) || {};
      let adminOfThisGroup = !!this.args.group.admins.findBy('id', String(id));
      return adminOfThisGroup || admin;
    }

    // Life Cycle
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "filters", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "session", _descriptor6, this);
      _initializerDefineProperty(this, "group", _descriptor7, this);
      this.filter = 'member';
      this.getFilters();
    }

    // Methods
    getFilters() {
      let filters = {};
      if (this.termFilter) {
        filters.term = this.termFilter;
      }
      if (this.filter === 'admin') {
        let userAdminIds = this.group.admins.mapBy('id');
        filters.id = userAdminIds.length ? userAdminIds : [null];
      } else if (this.filter === 'member') {
        let userIds = this.group.users.mapBy('id');
        filters.id = userIds.length ? userIds : [null];
      } else if (this.filter === 'notMember') {
        filters.exclude = this.group.users.mapBy('id');
      }
      this.filters = filters;
    }

    // Tasks
    *updateFilters() {
      try {
        yield (0, _emberConcurrency.timeout)(300);
        this.getFilters();
      } catch (e) {
        Ember.debug(e);
      }
    }
    *addAllUsers() {
      try {
        let {
          user,
          group
        } = this.args;
        let {
          value
        } = yield this.swal.confirm(`Deseja adicionar todos os usuários no grupo?`);
        if (!value) {
          return;
        }
        this.swal.loading('Estamos adicionando os usuários ao grupo.');
        yield this.ajax.put(`/userGroups/${group.id}/addUsers`, {
          host: _environment.default.apiUrl.social,
          data: {
            addAll: true
          }
        });
        yield group.reload();
        this.getFilters();
        this.toast.success(`Os usuários foram adicionados ao grupo.`);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *removeAllUsers() {
      try {
        let {
          user,
          group
        } = this.args;
        let {
          value
        } = yield this.swal.confirm(`Deseja remover todos os membros do grupo?`);
        if (!value) {
          return;
        }
        this.swal.loading('Estamos removendo os usuários do grupo.');
        yield this.ajax.put(`/userGroups/${group.id}/removeUsers`, {
          host: _environment.default.apiUrl.social,
          data: {
            removeAll: true
          }
        });
        yield group.reload();
        this.getFilters();
        this.toast.success(`Os usuários foram removidos do grupo.`);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }

    // Actions
    filterUsers() {
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "group", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canManage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "canManage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAllUsers", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "addAllUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAllUsers", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAllUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterUsers", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "filterUsers"), _class.prototype)), _class));
  _exports.default = UserManagerComponent;
});