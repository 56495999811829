define("account-talentrh/pods/super-user/companies/edit/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aJIOeL3c",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group ml-4 mt-4\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"moduleOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-checkbox\",[[24,0,\"d-block\"]],[[\"@checked\",\"@disabled\",\"@value\",\"@onChange\"],[[30,[36,0],[[32,0,[\"availableModules\"]],[32,1,[\"value\"]]],null],[30,[36,1],[[32,0,[\"subscription\",\"viasell\"]]],null],[32,1,[\"value\"]],[30,[36,2],[[32,0,[\"updateSelection\"]],[32,1,[\"value\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"includes\",\"not\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/companies/edit/index/template.hbs"
    }
  });
  _exports.default = _default;
});