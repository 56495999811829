define("account-talentrh/pods/settings/avd/ninebox-settings/quadrant-description-form/quadrant-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SettingsAvdNineboxSettingsQuadrantDescriptionFormQuadrantItemComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.alias('args.quadrant'), _dec6 = Ember.computed.alias('args.editingQuadrant'), _dec7 = Ember.computed('editingQuadrant.id', 'quadrant.id'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class SettingsAvdNineboxSettingsQuadrantDescriptionFormQuadrantItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "description", _descriptor3, this);
      _initializerDefineProperty(this, "color", _descriptor4, this);
      _initializerDefineProperty(this, "quadrant", _descriptor5, this);
      _initializerDefineProperty(this, "editingQuadrant", _descriptor6, this);
    }
    // Multi line computed
    get showContent() {
      if (!this.editingQuadrant) return false;
      return this.quadrant.id === this.editingQuadrant.id;
    }

    // Methods
    checkNameValue(name) {
      const isValid = this.checkValue(name);
      if (!isValid) {
        throw new Error("O nome do quadrante precisa ser informado.");
      }
    }
    checkDescriptionValue(description) {
      const isValid = this.checkValue(description);
      if (!isValid) {
        throw new Error("A descrição do quadrante precisa ser informado.");
      }
    }
    checkColorValue(color) {
      const isValid = this.checkValue(color);
      if (!isValid) {
        throw new Error("A cor do quadrante precisa ser informado.");
      }
    }
    checkValue(value) {
      return value && value.trim();
    }

    // Actions
    initValues() {
      this.name = this.quadrant.name;
      this.description = this.quadrant.description;
      this.color = this.quadrant.color;
    }
    alterShowContent() {
      if (this.args.onEditingQuadrantChange) {
        this.args.onEditingQuadrantChange(this.showContent ? null : this.quadrant);
      }
    }
    onNameChange(event) {
      try {
        const {
          value
        } = event.target;
        this.checkNameValue(value);
        this.quadrant.name = value;
      } catch (e) {
        this.name = this.quadrant.name;
        this.swal.error(e.message);
      }
    }
    onDescriptionChange(event) {
      try {
        const {
          value
        } = event.target;
        this.checkDescriptionValue(value);
        this.quadrant.description = value;
      } catch (e) {
        this.description = this.quadrant.description;
        this.swal.error(e.message);
      }
    }
    onColorChange(event) {
      try {
        const {
          value
        } = event.target;
        this.checkColorValue(value);
        this.quadrant.color = value;
      } catch (e) {
        this.color = this.quadrant.color;
        this.swal.error(e.message);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "quadrant", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editingQuadrant", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showContent", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initValues", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alterShowContent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "alterShowContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNameChange", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onNameChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDescriptionChange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDescriptionChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onColorChange", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onColorChange"), _class.prototype)), _class));
  _exports.default = SettingsAvdNineboxSettingsQuadrantDescriptionFormQuadrantItemComponent;
});