define("account-talentrh/pods/registry/general/user/show/index/emergency-contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DtO2B+k7",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2 mb-0\"],[12],[2,\"\\n    Contato de Emergência\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"list-group-item py-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4 text-truncate\"],[15,\"title\",[32,1,[\"emergencyContactName\"]]],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-user text-primary mr-1\"],[12],[13],[2,\"\\n            Nome:\\n          \"],[13],[2,\"\\n          \"],[1,[32,1,[\"emergencyContactName\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"col-md-4 text-truncate\"],[15,\"title\",[32,0,[\"emergencyPhone\"]]],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-phone text-primary mr-1\"],[12],[13],[2,\"\\n            Telefone:\\n          \"],[13],[2,\"\\n          \"],[1,[32,0,[\"emergencyPhone\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/index/emergency-contact/template.hbs"
    }
  });
  _exports.default = _default;
});