define("account-talentrh/pods/components/input-summernote/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      var element = '#' + this.elementId + '-summernote';
      $(element).summernote({
        dialogsInBody: true,
        height: 150,
        disableDragAndDrop: true,
        toolbar: [
        // [groupName, [list of button]]
        ['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', ['video', 'picture']]],
        lang: 'pt-BR',
        callbacks: {
          onChange: (contents /*, $editable*/) => {
            this.set('body', contents);
          }
        }
      });
      let input = this.element.querySelector('div.note-group-select-from-files');
      if (input) {
        input.remove();
      }
    }
  });
  _exports.default = _default;
});