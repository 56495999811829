define("account-talentrh/pods/registry/general/access-profile/form/schedules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3cy5J9U1",
    "block": "{\"symbols\":[\"day\",\"@accessProfile\"],\"statements\":[[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2,[\"admin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item bg-transparent border-bottom\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row mx-0 pt-2\"],[12],[2,\"\\n        Este perfil de acesso tem permissão para acessar o Voors a qualquer horário, portanto, o horário de acesso não pode ser alterado.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"companyConfig\",\"communicationApi\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"list-group-item border-bottom\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row mx-0 pt-2\"],[12],[2,\"\\n          \"],[8,\"ui-checkbox\",[[24,\"title\",\"Valida o horário de trabalho do colaborador e permite acesso somente neste horário\"]],[[\"@checked\"],[[30,[36,1],[[32,2],\"useUserHorary\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Validar horário de trabalho\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"weekdays\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"registry/general/access-profile/form/schedules/item\",[],[[\"@accessProfile\",\"@day\",\"@disabled\"],[[32,2],[32,1],[30,[36,0],[[32,2,[\"useUserHorary\"]],[32,0,[\"companyConfig\",\"communicationApi\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"get\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/access-profile/form/schedules/template.hbs"
    }
  });
  _exports.default = _default;
});