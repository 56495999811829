define("account-talentrh/pods/registry/general/access-profile/create/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hfxl7ZvY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"registry/general/access-profile/form/permissions\",[],[[\"@accessProfile\",\"@showManageLunchList\"],[[34,0],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/access-profile/create/index/template.hbs"
    }
  });
  _exports.default = _default;
});