define("account-talentrh/services/report", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    pdf() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const headers = this.session.authorizationBearer;
      const optionsDefault = {
        headers
      };
      options = Object.assign({}, options, optionsDefault);
      return fetch(_environment.default.apiUrl.conta + url, options).then(data => data.blob()).then(report => URL.createObjectURL(report));
    }
  });
  _exports.default = _default;
});