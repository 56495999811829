define("account-talentrh/pods/setup-wizard/step-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I815/Eyh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"wizard-step-control text-center mt-4\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-social btn-lg px-4 mx-2\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"prevStep\"]]],null]],[14,4,\"button\"],[12],[2,\"Anterior\"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-social btn-lg px-4 mx-2\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"nextStep\"]]],null]],[14,4,\"button\"],[12],[2,\"Próximo\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/step-control/template.hbs"
    }
  });
  _exports.default = _default;
});