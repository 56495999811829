define("account-talentrh/pods/super-user/companies/edit/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.not('args.companyConfig.featureFlags.ENABLE'), _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "enablePlugin", _descriptor6, this);
      _initializerDefineProperty(this, "sellTypeSelected", _descriptor7, this);
      _defineProperty(this, "sellTypes", Ember.A([{
        label: "Convencional",
        value: "convencional"
      }, {
        label: "Embarcada",
        value: "embarcada"
      }]));
    }
    get patternMask() {
      const {
        company
      } = this.args;
      return company.type === 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99';
    }
    *save() {
      try {
        const {
          company,
          companyConfig,
          subscription
        } = this.args;
        yield Ember.RSVP.all([company.save(), subscription.save(), this.ajax.put(`companyConfigurations/${companyConfig.id}/updateBySuperUser`, {
          data: {
            companyConfiguration: JSON.stringify(companyConfig)
          }
        })]);
        this.router.transitionTo('super-user.companies.show', company.id);
        this.toast.success('Empresa/Assinatura atualizada');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    setSellType() {
      let selected = this.sellTypes.find(sellType => {
        return this.args.subscription.sellType === sellType.value;
      });
      this.sellTypeSelected = selected;
    }
    sellTypeChanged(newSellType) {
      this.sellTypeSelected = newSellType;
      this.args.subscription.sellType = newSellType.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "enablePlugin", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sellTypeSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSellType", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setSellType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sellTypeChanged", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sellTypeChanged"), _class.prototype)), _class));
  _exports.default = FormComponent;
});