define("account-talentrh/pods/registry/general/job-function/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralJobFunctionShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.general.job-function').clearFilters();
    }
  }
  _exports.default = RegistryGeneralJobFunctionShowRoute;
});