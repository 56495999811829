define("account-talentrh/pods/registry/general/branch/show/skills/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralBranchShowSkillsRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.general.branch.show');
    }
  }
  _exports.default = RegistryGeneralBranchShowSkillsRoute;
});