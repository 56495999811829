define("account-talentrh/pods/setup-wizard/steps/company/benefits/manage/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsCompanyBenefitsManageEditRoute extends Ember.Route {
    deactivate() {
      return this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = SetupWizardStepsCompanyBenefitsManageEditRoute;
});