define("account-talentrh/pods/registry/avd/type-answer/show/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fG+SuMg4",
    "block": "{\"symbols\":[\"@alternative\"],\"statements\":[[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-6 d-flex align-items-center text-truncate\"],[15,\"title\",[32,1,[\"description\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"color\"]]],[2,\"\\n\\n    \"],[10,\"strong\"],[14,0,\"mr-1\"],[12],[2,\"Descrição:\"],[13],[2,\"\\n    \"],[1,[32,1,[\"description\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Percentual:\"],[13],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"alternative\",\"ignoreToCalculate\"]],\"Ignorar\",[32,0,[\"alternative\",\"percentage\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-sm btn-outline-primary\"]],[[\"@route\",\"@model\"],[\"registry.avd.type-answer.show.answer.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-edit\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-danger\"],[15,\"onclick\",[30,[36,2],[[35,1]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"removeAlternative\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/type-answer/show/item/template.hbs"
    }
  });
  _exports.default = _default;
});