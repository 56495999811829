define("account-talentrh/models/vacancy", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    })
  });
  var _default = _model.default.extend(Validations, {
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    registrationlink: (0, _model.attr)('string'),
    imageUrl: (0, _model.attr)('string'),
    applicationdeadline: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    onAllBranches: (0, _model.attr)('boolean'),
    noBranch: (0, _model.attr)('boolean'),
    company: (0, _model.belongsTo)('company'),
    postTemplate: (0, _model.belongsTo)('post-template'),
    jobRoles: (0, _model.hasMany)('job-role', {
      inverse: 'vacancies'
    }),
    jobFunctions: (0, _model.hasMany)('job-function', {
      inverse: 'vacancies'
    }),
    branches: (0, _model.hasMany)('branch', {
      inverse: 'vacancies'
    })
  });
  _exports.default = _default;
});