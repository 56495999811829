define("account-talentrh/pods/registry/avd/goal/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryAvdGoalShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.avd.goal').filters = {};
      this.controllerFor('registry.avd.goal').clearFilters();
    }
  }
  _exports.default = RegistryAvdGoalShowRoute;
});