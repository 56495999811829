define("account-talentrh/pods/registry/general/user/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IoI9oJJZ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"ui-card\",[[24,0,\"border-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"registry/general/user/show/index/general\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/contact\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/emergency-contact\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/interest\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/social-media\",[],[[\"@user\"],[[32,1,[\"user\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/experience\",[],[[\"@user\",\"@experiences\"],[[32,1,[\"user\"]],[32,1,[\"experiences\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/formation\",[],[[\"@user\",\"@formations\"],[[32,1,[\"user\"]],[32,1,[\"formations\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/professional-interest\",[],[[\"@user\",\"@professionalInterests\"],[[32,1,[\"user\"]],[32,1,[\"professiontalInterests\"]]]],null],[2,\"\\n  \"],[8,\"registry/general/user/show/index/book\",[],[[\"@user\",\"@userBooks\"],[[32,1,[\"user\"]],[32,1,[\"userBooks\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/index/template.hbs"
    }
  });
  _exports.default = _default;
});