define("account-talentrh/models/user-situation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SITUATION = {
    active: 'Ativo',
    inactive: 'Inativo',
    away: 'Afastado',
    vacation: 'Férias'
  };
  var _default = _model.default.extend({
    description: (0, _model.attr)('string'),
    situation: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    company: (0, _model.belongsTo)('company'),
    user: (0, _model.belongsTo)('user'),
    branchId: (0, _model.belongsTo)('branch'),
    cause: (0, _model.belongsTo)('cause'),
    situationFormated: Ember.computed('situation', function () {
      return SITUATION[this.situation];
    })
  });
  _exports.default = _default;
});