define("account-talentrh/pods/configuration/nela/reason/index/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CJ9Ca8ah",
    "block": "{\"symbols\":[\"@reason\"],\"statements\":[[10,\"td\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[32,0,[\"color\"]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary\"],[24,\"title\",\"Editar\"]],[[\"@route\",\"@model\"],[\"configuration.nela.reason.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"btn btn-danger\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"removeReason\"]],[32,1]],null]],[14,\"title\",\"Remover\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/nela/reason/index/item/template.hbs"
    }
  });
  _exports.default = _default;
});