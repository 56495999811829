define("account-talentrh/pods/subscription/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SubscriptionController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.alias('subscription.card'), _dec10 = Ember.computed.alias('model.company'), _dec11 = Ember.computed.alias('model.details.plan'), _dec12 = Ember.computed.alias('model.details.subscription'), _dec13 = Ember.computed.alias('model.details.transactions'), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class SubscriptionController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "initialDate", _descriptor6, this);
      _initializerDefineProperty(this, "endDate", _descriptor7, this);
      _initializerDefineProperty(this, "showModalPlan", _descriptor8, this);
      _defineProperty(this, "features", [{
        label: 'Feed de publicações'
      }, {
        label: 'Gestor de projetos'
      }, {
        label: 'Comunicados'
      }, {
        label: 'Chat'
      }, {
        label: 'Hard skills e Soft skills'
      }, {
        label: 'Gerenciador de pesquisas'
      }, {
        label: 'Mural de ausências'
      }, {
        label: 'Agenda colaborativa'
      }, {
        label: 'Notificações'
      }, {
        label: 'Portal do colaborador'
      }, {
        label: 'Grupos'
      }, {
        label: 'Sentimento do dia'
      }, {
        label: 'Enquetes'
      }]);
      _initializerDefineProperty(this, "card", _descriptor9, this);
      _initializerDefineProperty(this, "company", _descriptor10, this);
      _initializerDefineProperty(this, "plan", _descriptor11, this);
      _initializerDefineProperty(this, "subscription", _descriptor12, this);
      _initializerDefineProperty(this, "transactions", _descriptor13, this);
    }
    get availableUsers() {
      if (!this.plan.usersLimit || !this.subscription.userCount) {
        return 0;
      }
      const count = this.plan.usersLimit - this.subscription.userCount;
      return count > 0 ? count : 0;
    }
    get expirationDate() {
      if (!this.card) return "01/01";
      const day = this.card.expiration_date.substr(0, 2);
      const month = this.card.expiration_date.substr(2);
      return `${day}/${month}`;
    }
    get isCanceled() {
      return this.subscription.status === 'canceled';
    }
    *acceptTerms() {
      if (this.company.hasAcceptedUseTerms) {
        return;
      }
      try {
        const {
          value
        } = yield this.swal.confirm('Aceitar termos de uso?');
        if (!value) {
          return;
        }
        const user = this.store.peekRecord('user', this.session.user.id);
        this.company.hasAcceptedUseTerms = true;
        this.company.userAcceptedUseTerms = user;
        this.company.useTermsAcceptedAt = (0, _moment.default)().toDate();
        yield this.company.save();
        this.toast.success('Os termos de uso foram aceitos');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *cancelSubscription() {
      try {
        const password = 'CONFIRMAR';
        const result = yield this.swal.fire({
          title: 'Cancelar assinatura',
          html: `
          O cancelamento da assinatura é irreversível, sendo necessário criar uma nova assinatura. <br><br>
          <b>Volte a usar o Voors quando quiser.<br>
          Manteremos seus dados por até 3 meses, após esse prazo a base de dados será completamente eliminada.</b><br /><br>
          Digite "${password}" no campo abaixo para efetuar a operação.
        `,
          showCancelButton: true,
          input: 'text'
        });
        if (result.dismiss) {
          return;
        }
        if (result.value !== password) {
          return this.swal.warning(`É necessário digitar "${password}" no campo para efetuar a operação`);
        }
        yield this.fetch.request('subscriptions/cancelSubscription');
        this.send('reloadRoute');
        this.toast.success('Assinatura cancelada');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    getFilters() {
      let filters = {};
      let end = (0, _moment.default)(this.endDate).endOf('day');
      let start = (0, _moment.default)(this.initialDate).startOf('day');
      if (this.initialDate && this.endDate) {
        if (start && !end) {
          this.swal.warning('Data final deve ser informada.');
          return;
        }
        if (!start && end) {
          this.swal.warning('Data inicial deve ser informada.');
          return;
        }
        if (!(0, _moment.default)(end).isAfter(start)) {
          this.swal.warning('Data final deve ser posterior à data inicial');
          return;
        }
        filters.initialDate = start.toISOString();
        filters.endDate = end.toISOString();
      }
      this.filters = filters;
    }
    clearFilters() {
      this.filters = {};
      this.initialDate = null;
      this.endDate = null;
    }
    afterPlanChange() {
      this.send('reloadRoute');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showModalPlan", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "card", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "plan", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "subscription", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "transactions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "acceptTerms", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "acceptTerms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSubscription", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterPlanChange", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "afterPlanChange"), _class.prototype)), _class));
  _exports.default = SubscriptionController;
});