define("account-talentrh/pods/setup-wizard/steps/access-profile/manage/create/access-schedule/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsAccessProfileManageCreateAccessScheduleRoute extends Ember.Route {
    model() {
      return this.modelFor('setup-wizard.steps.access-profile.manage.create');
    }
  }
  _exports.default = SetupWizardStepsAccessProfileManageCreateAccessScheduleRoute;
});