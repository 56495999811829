define("account-talentrh/pods/registry/general/sector/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralSectorIndexRoute extends Ember.Route {
    // deactivate() {
    //   this.controllerFor('registry.general.sector').filters = {};
    //   this.controllerFor('registry.general.sector').clearFilters();
    // }
  }
  _exports.default = RegistryGeneralSectorIndexRoute;
});