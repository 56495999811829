define("account-talentrh/pods/super-user/post/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    deactivate() {
      this.controller.model.rollbackAttributes();
      Ember.set(this.controllerFor('super-user.post'), 'filters', {});
    }
  });
  _exports.default = _default;
});