define("account-talentrh/pods/components/settings/details/local-payroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bppTkJXg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Configuração do folha na sua empresa\"],[13],[2,\"\\n  \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[32,0,[\"payroll\",\"folhaLocalURL\"]]]],null],[2,\"\\n  \"],[10,\"small\"],[12],[2,\"Link para o IP de conexão do FOLHA offline (sem \\\"http:// e sem / no final\\\")\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Senha de acesso ao folha na sua empresa\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"position-relative\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[[32,0,[\"type\"]],[32,0,[\"payroll\",\"folhaLocalPassword\"]]]],null],[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-sm btn-light text-dark centralize-y position-absolute r-2\"],[15,\"onclick\",[30,[36,0],[[32,0],\"togglePassword\"],null]],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"fas \",[30,[36,1],[[32,0,[\"showPassword\"]],\"fa-eye-slash\",\"fa-eye\"],null]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"small\"],[12],[2,\"Esta senha deve ser configurada no servidor local da sua empresa pelo seu consultor.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-secondary\"],[15,\"onclick\",[30,[36,0],[[32,0],\"testLink\"],null]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-server\"],[12],[13],[2,\"\\n    Testar conexão\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,2],[[32,0,[\"save\"]]],null]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-check\"],[12],[13],[2,\"\\n    Salvar\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/details/local-payroll/template.hbs"
    }
  });
  _exports.default = _default;
});