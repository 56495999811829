define("account-talentrh/pods/settings/avd/ninebox-settings/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SettingsAvdNineboxSettingsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed.alias('model'), _dec7 = Ember.computed.filter('nineBox.lines.@each.hasDirtyAttributes', function () {
    return this.nineBox.lines.filterBy('hasDirtyAttributes', true);
  }), _dec8 = Ember.computed.filter('nineBox.columns.@each.hasDirtyAttributes', function () {
    return this.nineBox.columns.filterBy('hasDirtyAttributes', true);
  }), _dec9 = Ember.computed.filter('nineBox.quadrants.@each.hasDirtyAttributes', function () {
    return this.nineBox.quadrants.filterBy('hasDirtyAttributes', true);
  }), _dec10 = Ember.computed('tab'), _dec11 = Ember.computed('tab'), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class SettingsAvdNineboxSettingsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "tab", _descriptor5, this);
      _initializerDefineProperty(this, "nineBox", _descriptor6, this);
      _initializerDefineProperty(this, "alteredLines", _descriptor7, this);
      _initializerDefineProperty(this, "alteredColumns", _descriptor8, this);
      _initializerDefineProperty(this, "alteredQuadrants", _descriptor9, this);
    }
    // Multiline computed
    get quadrantScaleClass() {
      return this.tab === 'quadrantScale' ? 'text-primary tab-selected-nine-box-settings' : '';
    }
    get quadrantDescriptionClass() {
      return this.tab === 'quadrantDescription' ? 'text-primary tab-selected-nine-box-settings' : '';
    }
    // Methods

    validateNineBox() {
      this.validateLines();
      this.validateColumns();
      this.validateQuadrants();
    }
    validateLines() {
      this.validateLinesValues();
      this.validateLinesValuesBetweenLines();
      this.validateInitialValueGap();
      this.validateFinalValueGap();
    }
    validateLinesValues() {
      this.alteredLines.forEach(line => {
        if (line.initialValue >= line.finalValue) {
          throw new Error(`Valor inicial da linha ${line.title} precisa ser menor que valor final.`);
        }
      });
    }
    validateLinesValuesBetweenLines() {
      this.nineBox.lines.forEach((currentLine, index) => {
        if (index === this.nineBox.lines.length - 1) return;
        const nextLine = this.nineBox.lines.objectAt(index + 1);
        if (currentLine.finalValue >= nextLine.initialValue) {
          throw new Error(`Valor final da linha ${currentLine.title} é maior ou igual a valor inicial da linha ${nextLine.title}.`);
        }
      });
    }
    precisionRoundMod(number, precision) {
      const factor = Math.pow(10, precision);
      const n = precision < 0 ? number : 0.01 / factor + number;
      return Math.round(n * factor) / factor;
    }
    getGapValue(valueA, valueB) {
      const gapValue = valueA - valueB;
      return this.precisionRoundMod(gapValue, 4);
    }
    validateFinalValueGap() {
      this.nineBox.lines.forEach((currentLine, lineIndex) => {
        if (this.nineBox.lines.length - 1 === lineIndex) return;
        const {
          initialValue,
          title
        } = this.nineBox.lines.objectAt(lineIndex + 1);
        const gapValue = this.getGapValue(initialValue, currentLine.finalValue);
        if (gapValue >= 0.0002 || gapValue < 0.0001) {
          throw new Error(`Não pode existir intervalo de valor final da linha "${currentLine.title}" para o valor inicial da linha "${title}"`);
        }
      });
    }
    validateInitialValueGap() {
      this.nineBox.lines.forEach((currentLine, lineIndex) => {
        if (lineIndex === 0) return;
        const {
          finalValue,
          title
        } = this.nineBox.lines.objectAt(lineIndex - 1);
        const gapValue = this.getGapValue(currentLine.initialValue, finalValue);
        if (gapValue >= 0.0002 || gapValue < 0.0001) {
          throw new Error(`Não pode existir intervalo de valor inicial da linha "${currentLine.title}" para o valor final da linha "${title}"`);
        }
      });
    }
    validateColumns() {
      this.validateColumnsValues();
      this.validateColumnsValuesBetweenColumns();
      this.validateColumnInitialValueGap();
      this.validateColumnFinalValueGap();
    }
    validateColumnFinalValueGap() {
      this.nineBox.columns.forEach((currentColumn, columnIndex) => {
        if (this.nineBox.columns.length - 1 === columnIndex) return;
        const {
          initialValue,
          title
        } = this.nineBox.columns.objectAt(columnIndex + 1);
        const gapValue = this.getGapValue(initialValue, currentColumn.finalValue);
        if (gapValue >= 0.0002 || gapValue < 0.0001) {
          throw new Error(`Não pode existir intervalo de valor final da linha "${currentColumn.title}" para o valor inicial da linha "${title}"`);
        }
      });
    }
    validateColumnInitialValueGap() {
      this.nineBox.columns.forEach((currentColumn, columnIndex) => {
        if (columnIndex === 0) return;
        const {
          finalValue,
          title
        } = this.nineBox.columns.objectAt(columnIndex - 1);
        const gapValue = this.getGapValue(currentColumn.initialValue, finalValue);
        if (gapValue >= 0.0002 || gapValue < 0.0001) {
          throw new Error(`Não pode existir intervalo de valor inicial da linha "${currentColumn.title}" para o valor final da linha "${title}"`);
        }
      });
    }
    validateColumnsValues() {
      this.alteredColumns.forEach(column => {
        if (column.initialValue >= column.finalValue) {
          throw new Error(`Valor inicial da coluna ${column.title} precisa ser menor que valor final.`);
        }
      });
    }
    validateColumnsValuesBetweenColumns() {
      this.nineBox.columns.forEach((currentColumn, index) => {
        if (index === this.nineBox.columns.length - 1) return;
        const nextColumn = this.nineBox.columns.objectAt(index + 1);
        if (currentColumn.finalValue >= nextColumn.initialValue) {
          throw new Error(`Valor final da coluna ${currentColumn.title} é maior ou igual a valor inicial da coluna ${nextColumn.title}.`);
        }
      });
    }
    validateQuadrants() {
      this.alteredQuadrants.forEach(quadrant => {
        if (!quadrant.name || !quadrant.name.trim()) {
          throw new Error(`O nome do quadrante ${quadrant.title} é obrigatorio.`);
        }
        if (!quadrant.description || !quadrant.description.trim()) {
          throw new Error(`A descrição do quadrante ${quadrant.title} é obrigatoria.`);
        }
        if (!quadrant.color || !quadrant.color.trim()) {
          throw new Error(`A cor do quadrante ${quadrant.title} é obrigatoria.`);
        }
      });
    }

    //Actions
    closeModal() {
      this.router.transitionTo('settings.avd');
    }
    changeTab(tab) {
      this.tab = tab;
    }
    // Tasks
    *save() {
      try {
        this.validateNineBox();
        this.swal.loading('Salvando as configurações');
        yield Ember.RSVP.all([...this.nineBox.lines.filterBy('hasDirtyAttributes', true).map(line => line.save()), ...this.nineBox.columns.filterBy('hasDirtyAttributes', true).map(column => column.save()), ...this.nineBox.quadrants.filterBy('hasDirtyAttributes', true).map(quadrant => quadrant.save())]);
        yield this.nineBox.save();
        this.router.transitionTo('settings.avd');
        this.swal.close();
      } catch (e) {
        if (e.message) {
          this.swal.error(e.message);
        } else {
          this.swal.catch(e);
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'quadrantScale';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nineBox", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "alteredLines", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "alteredColumns", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "alteredQuadrants", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "quadrantScaleClass", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "quadrantScaleClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "quadrantDescriptionClass", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "quadrantDescriptionClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTab", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "changeTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = SettingsAvdNineboxSettingsController;
});