define("account-talentrh/pods/registry/general/sector/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralSectorCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('sector');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.sector').filters = {};
      this.controllerFor('registry.general.sector').clearFilters();
    }
  }
  _exports.default = RegistryGeneralSectorCreateRoute;
});