define("account-talentrh/models/company-album", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    coverPhoto: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    showOnDashboard: (0, _model.attr)('boolean'),
    updatedAt: (0, _model.attr)('date'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    photos: (0, _model.hasMany)('company-photo')
  });
  _exports.default = _default;
});