define("account-talentrh/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ebHjVqST",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"isReady\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"application/navbar\",[],[[],[]],null],[2,\"\\n  \"],[8,\"application/sidebar\",[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"ui-application\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/application/template.hbs"
    }
  });
  _exports.default = _default;
});