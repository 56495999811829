define("account-talentrh/pods/super-user/companies/show/plugins/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5aO1/od5",
    "block": "{\"symbols\":[\"plugin\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"pluginsOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-checkbox\",[[24,0,\"d-block\"]],[[\"@disabled\",\"@checked\"],[true,[30,[36,1],[[35,0,[\"companyConfig\",\"featureFlags\"]],[32,1,[\"field\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"get\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/companies/show/plugins/template.hbs"
    }
  });
  _exports.default = _default;
});