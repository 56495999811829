define("account-talentrh/pods/registry/general/institution/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model() {
      return this.ajax.request('accountConfigurations/findByCompany');
    }
  });
  _exports.default = _default;
});