define("account-talentrh/pods/components/indicator-loader/btn-filter/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let IndicatorLoaderBtnFilterComponent = (_dec = Ember.computed('args.indicator.filterData.jobFunction'), _dec2 = Ember.computed('args.indicator.filterData.branch'), _dec3 = Ember.computed('args.indicator.filterData.jobRole'), _dec4 = Ember.computed('args.indicator.filterData.department'), _dec5 = Ember.computed('args.indicator.filterData.{startDate,endDate}'), _dec6 = Ember.computed('args.indicator.filterData.jobFunction'), _dec7 = Ember.computed('args.indicator.filterData.branch'), _dec8 = Ember.computed('args.indicator.filterData.jobRole'), _dec9 = Ember.computed('args.indicator.filterData.department'), (_class = class IndicatorLoaderBtnFilterComponent extends _component.default {
    get jobFunctionTitle() {
      let indicator = this.args.indicator || [];
      return indicator.filterData.jobFunction.mapBy('title').join(', ');
    }
    get branchTitle() {
      let indicator = this.args.indicator || [];
      return indicator.filterData.branch.mapBy('name').join(', ');
    }
    get jobRoleTitle() {
      let indicator = this.args.indicator || [];
      return indicator.filterData.jobRole.mapBy('title').join(', ');
    }
    get departmentTitle() {
      let indicator = this.args.indicator || [];
      return indicator.filterData.department.mapBy('name').join(', ');
    }

    // Se o indicador usa o filtro e o mesmo tem dados, habilita.
    get dateEnabled() {
      let {
        indicator
      } = this.args;
      if (!indicator) {
        return false;
      }
      return indicator.filters && indicator.filters.startDate && indicator.filters.endDate && indicator.filterData.startDate && indicator.filterData.endDate;
    }
    get jobFunctionEnabled() {
      let {
        indicator
      } = this.args;
      if (!indicator) {
        return false;
      }
      return indicator.filters && indicator.filters.jobFunction && indicator.filterData.jobFunction;
    }
    get branchEnabled() {
      let {
        indicator
      } = this.args;
      if (!indicator) {
        return false;
      }
      return indicator.filters && indicator.filters.branch && indicator.filterData.branch;
    }
    get jobRoleEnabled() {
      let {
        indicator
      } = this.args;
      if (!indicator) {
        return false;
      }
      return indicator.filters && indicator.filters.jobRole && indicator.filterData.jobRole;
    }
    get departmentEnabled() {
      let {
        indicator
      } = this.args;
      if (!indicator) {
        return false;
      }
      return indicator.filters && indicator.filters.department && indicator.filterData.department;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "jobFunctionTitle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctionTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "branchTitle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "branchTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobRoleTitle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "jobRoleTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "departmentTitle", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "departmentTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateEnabled", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "dateEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobFunctionEnabled", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctionEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "branchEnabled", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "branchEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobRoleEnabled", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "jobRoleEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "departmentEnabled", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "departmentEnabled"), _class.prototype)), _class));
  _exports.default = IndicatorLoaderBtnFilterComponent;
});