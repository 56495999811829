define("account-talentrh/pods/registry/general/ia-repositories/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zc+5QE6C",
    "block": "{\"symbols\":[],\"statements\":[[8,\"registry/general/ia-repositories/form\",[],[[\"@openAiRepository\"],[[32,0,[\"model\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/ia-repositories/create/template.hbs"
    }
  });
  _exports.default = _default;
});