define("account-talentrh/models/post-template", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    body: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A publicação deve ter uma mensagem'
    }),
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'A publicação deve ter um título'
    })
  });
  const TYPE_OPTIONS = {
    announcement: 'Comunicado',
    message: 'Mensagem',
    news: 'Notícia',
    vacancy: 'Vaga'
  };
  var _default = _model.default.extend(Validations, {
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    link: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    posts: (0, _model.hasMany)('post', {
      inverse: 'postTemplate'
    }),
    typeLabel: Ember.computed('type', function () {
      return TYPE_OPTIONS[this.type];
    })
  });
  _exports.default = _default;
});