define("account-talentrh/pods/reports/team-management/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReportsTeamManagementEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();

      // isso é um workaround para forçar o reload do datatable da rota pai
      const parentController = this.controllerFor('reports.team-management');
      Ember.set(parentController, "filter", {
        limit: 10
      });
    }
  }
  _exports.default = ReportsTeamManagementEditRoute;
});