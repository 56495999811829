define("account-talentrh/pods/components/indicator-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Dt9v2b48",
    "block": "{\"symbols\":[\"indicator\",\"@generalFilters\"],\"statements\":[[8,\"indicator-loader/btn-filter\",[[16,\"onclick\",[30,[36,0],[[32,0],\"openModalFilter\",[32,2]],null]]],[[\"@indicator\"],[[32,2]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"indicatorsToLoad\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"indicator-loader/renderer\",[[16,0,[32,1,[\"classNames\"]]]],[[\"@indicator\",\"@openModalFilter\"],[[32,1],[30,[36,0],[[32,0],\"openModalFilter\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[8,\"modal-indicator-filter\",[],[[\"@loadIndicators\",\"@indicatorToSetFilter\",\"@show\"],[[30,[36,0],[[32,0],\"load\"],null],[32,0,[\"indicatorToSetFilter\"]],[32,0,[\"openIndicatorFilters\"]]]],null],[2,\"\\n\\n\"],[10,\"button\"],[14,0,\"btn btn-primary rounded-circle position-fixed r-4 b-4 z-index-1\"],[15,\"onclick\",[30,[36,0],[[32,0],\"openModalFilter\",[32,2]],null]],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-filter\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/indicator-loader/template.hbs"
    }
  });
  _exports.default = _default;
});