define("account-talentrh/pods/settings/emails/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      click(item) {
        document.getElementById(item).click();
      },
      afterUploading(response) {
        this.store.pushPayload(response);
      },
      removeImage(propertyName) {
        this.model.set(propertyName, null);
        this.model.save().then();
      }
    }
  });
  _exports.default = _default;
});