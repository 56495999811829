define("account-talentrh/helpers/nela-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function nelaEditor(params /*, hash*/) {
    return `<div class="ql-editor"> ${params[0]}</div>`;
  });
  _exports.default = _default;
});