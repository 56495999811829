define("account-talentrh/pods/registry/general/user/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'registry.general.user.show',
    model: Ember.computed.alias('user.id'),
    status: Ember.computed('user.{active,away}', function () {
      if (!this.user.active) {
        return 'Inativo';
      }
      if (this.user.away) {
        return 'Afastado';
      }
      return '';
    }),
    statusClass: Ember.computed('user.{active,away}', function () {
      if (!this.user.active) {
        return 'badge-danger';
      }
      if (this.user.away) {
        return 'border border-warning text-warning';
      }
      return '';
    })
  });
  _exports.default = _default;
});