define("account-talentrh/pods/registry/general/user/show/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    swal: Ember.inject.service(),
    afterModel(model) {
      return this.afterModelTask.perform(model);
    },
    deactivate() {
      this.controllerFor('registry.general.user').clearFilters();
    },
    afterModelTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;
      let {
        id
      } = _ref;
      return function* () {
        try {
          let {
            controllership
          } = yield _this.store.queryRecord('user-meta', {
            user: id
          });
          _this.controllerFor('registry.general.user.show').set('controllership', controllership);
        } catch (e) {
          _this.swal.catch(e);
        }
      }();
    }).drop()
  });
  _exports.default = _default;
});