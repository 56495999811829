define("account-talentrh/pods/subscription/plan/plans-slider/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PlansSliderComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PlansSliderComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "selectedPlan", _descriptor2, this);
      _initializerDefineProperty(this, "plans", _descriptor3, this);
      _initializerDefineProperty(this, "start", _descriptor4, this);
      _initializerDefineProperty(this, "range", _descriptor5, this);
    }
    getStart() {
      if (!this.args.plan || !this.plans) return 0;
      const index = this.plans.findIndex(plan => {
        return parseInt(plan.usersLimit) === parseInt(this.args.plan.usersLimit);
      });
      if (index >= 0) {
        this.start = index;
      } else {
        this.start = this.plans.length;
      }
    }

    // Actions
    onSliderUpdate(values) {
      if (!this.plans) return;
      let [indexPlan] = values;
      indexPlan = parseInt(indexPlan);
      this.selectedPlan = this.plans[indexPlan];
      if (this.args.onPlanUpdate) {
        this.args.onPlanUpdate(this.selectedPlan);
      }
    }
    onUpdateElement() {
      this.getStart();
    }
    *getPlans() {
      const response = yield this.ajax.request('plans/ajax', {
        data: {
          exclude: [1, 2, 3]
        }
      });
      const plans = response.plans;
      this.range = {
        min: [0],
        max: [plans ? plans.length - 1 : 0]
      };
      this.plans = plans;
      this.getStart();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedPlan", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "plans", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "start", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "range", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        min: [0],
        max: [0]
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getStart", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSliderUpdate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSliderUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateElement", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPlans", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPlans"), _class.prototype)), _class));
  var _default = PlansSliderComponent;
  _exports.default = _default;
});