define("account-talentrh/pods/registry/general/user/edit/form/component", ["exports", "ember-concurrency", "ember-file-upload/file", "moment", "account-talentrh/config/environment"], function (_exports, _emberConcurrency, _file, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    tagName: '',
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    fetch: Ember.inject.service(),
    // Default values
    avatarFile: null,
    openCropper: false,
    // Single line computed properties
    userMeta: Ember.computed.alias('loadUserMeta.lastSuccessful.value'),
    branches: Ember.computed.alias('user.branches'),
    allowEditRegistrationData: Ember.computed.alias('permission.accessProfile.allowEditRegistrationData'),
    communicationApi: Ember.computed.alias('permission.companyConfig.communicationApi'),
    oldBranches: Ember.A(),
    oldDepartment: null,
    // Multiline computed properties
    uploadButtonClass: Ember.computed('uploadAvatar.{isRunning,state}', function () {
      let btnClass = 'btn btn-sm btn-block cursor-pointer';
      if (this.uploadAvatar.isRunning) {
        return `${btnClass} btn-primary`;
      }
      return `${btnClass} btn-outline-primary`;
    }),
    disabledEditField: Ember.computed('allowEditRegistrationData', 'communicationApi', 'permission.admin', 'user.createdByNela', function () {
      let enabled;
      if (this.communicationApi) {
        this.user.createdByNela ? enabled = this.allowEditRegistrationData || this.permission.admin : enabled = false;
      } else {
        enabled = this.allowEditRegistrationData || this.permission.admin;
      }
      return !enabled;
    }),
    // Lifecycle hooks
    didInsertElement() {
      this._super(...arguments);
      if (this.user.birthDate) {
        Ember.set(this, 'birthday', (0, _moment.default)(this.user.birthDate).utc().format('DD/MM/YYYY'));
      }
      if (this.user.cnhExpirationDate) {
        Ember.set(this, 'cnhExpirationDate', (0, _moment.default)(this.user.cnhExpirationDate).utc().format('DD/MM/YYYY'));
      }
      Ember.set(this, 'oldBranches', this.branches.toArray());
      Ember.set(this, 'oldDepartment', this.user.department.get('id'));
    },
    loadUserMeta: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.store.queryRecord('user-meta', {
          user: this.user.id
        });
      } catch (e) {
        return yield this.store.createRecord('user-meta', {
          user: this.user.id
        });
      }
    }).on('didInsertElement').drop(),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let attrs = this.user.validations.attrs;
      if (!attrs.firstName.isValid || !attrs.lastName.isValid) {
        return this.swal.validation({
          messages: [attrs.firstName.messages, attrs.lastName.messages]
        });
      }
      if (!(0, _moment.default)(this.birthday, 'DD/MM/YYYY').isValid()) {
        return this.swal.warning('Informe uma data de nascimento válida');
      }
      if (this.cnhExpirationDate && !this.user.cnh) {
        return this.swal.warning('Informe a CNH');
      }
      if (this.user.cnh && !(0, _moment.default)(this.cnhExpirationDate, 'DD/MM/YYYY').isValid()) {
        return this.swal.warning('Informe uma data de expiração válida');
      }
      if (this.cnhExpirationDate) {
        Ember.set(this.user, 'cnhExpirationDate', (0, _moment.default)(this.cnhExpirationDate).format());
      }
      if (this.user.typeEvaluation == 3) {
        const team = this.user.team.get('content');
        if (!team) {
          return this.swal.warning('Equipe não relacionada ao usuário');
        }
      }
      const oldBranches = this.oldBranches;
      const newBranches = this.user.branches.toArray();

      // usuário não tinha filial e agora tem ou trocou uma filial por outra
      const branchAdded = !oldBranches.length && newBranches.length || oldBranches.length === 1 && newBranches.length === 1 && oldBranches[0] !== newBranches[0];

      // usuário tinha filial, uma ou mais removidas, deixando com uma ou nenhuma
      const branchRemoved = oldBranches.length && (!newBranches.length || oldBranches.length > newBranches.length);
      const oldDepartment = this.oldDepartment;
      const newDepartment = this.user.department.get('id');
      // usuário não tinha departamento e agora tem
      const departmentAdded = !oldDepartment && newDepartment;
      // usuário tinha departamento e foi removido ou trocado
      const departmentRemoved = oldDepartment && (!newDepartment || oldDepartment !== newDepartment);
      if (branchAdded || branchRemoved || departmentAdded || departmentRemoved) {
        // Verifico se tem liderados
        let containsLeds = yield this.fetch.request('users/findLeds', {
          data: {
            appraisedBy: this.user.id,
            sort: 'createdAt DESC',
            select: ['id']
          }
        }).then(o => o.toArray());
        if (containsLeds && containsLeds.length) {
          let msg = 'Para transferir esse usuário selecione um novo líder para assumir os liderados ou mantenha o líder';
          const {
            value
          } = yield this.swal.warning(msg, {
            cancelButtonText: 'Manter Líder',
            confirmButtonText: 'Escolher líder',
            reverseButtons: true,
            showCancelButton: true,
            title: 'Esse usuário é um líder!',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          if (value) {
            let usersDepartment = [];
            if (this.user.department.get('id')) {
              // recupero os usuários do mesmo departamento
              usersDepartment = yield this.store.query('user', {
                department: [oldDepartment],
                id: {
                  '!': containsLeds.map(user => user.id)
                },
                active: 1,
                limit: 6
              }).then(o => o.toArray());
              usersDepartment = usersDepartment.filter(item => item.id !== this.user.id);
            }
            // abre o modal com os usuários do departamento
            Ember.set(this, 'modalChooseLeader', true);
            Ember.set(this, 'usersDepartment', usersDepartment);
            return;
          }
        }
      }
      if (this.user.hasNoGoal) {
        yield this.ajax.request(`/users/${this.user.id}/removeGoalsForUser`);
      }
      this.send('saveUser');
    }).drop(),
    selectLeader: (0, _emberConcurrency.task)(function* (user) {
      try {
        let {
          value
        } = yield this.swal.confirm(`Atribuir Líder`, {
          text: `Deseja atribuir ${user.firstName} como líder aos usuários?`
        });
        if (!value) {
          return;
        }
        let users = yield this.store.query('user', {
          appraisedBy: this.user.id
        }).then(o => o.toArray());
        users = users.map(item => {
          return {
            id: item.id
          };
        });
        yield this.fetch.post('/users/updateAppraisedBy', {
          data: {
            records: users,
            appraisedBy: user.id
          }
        });
        this.send('saveUser');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    uploadAvatar: (0, _emberConcurrency.task)(function* () {
      const url = `${_environment.default.apiUrl.conta}/users/uploadAvatar/${this.user.id}`;
      this.swal.loading('Atualizando foto');
      try {
        let file = _file.default.fromBlob(this.avatarFile);
        let response = yield file.upload(url, {
          method: 'POST',
          fileKey: 'avatar',
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        });
        this.store.pushPayload(response.body);
        this.user.reload();
        this.toast.success('Foto atualizada');
        Ember.setProperties(this, {
          openCropper: false,
          avatarFile: null
        });
      } catch (e) {
        this.swal.error(e.body.firstObject.innerHTML);
      }
    }).drop(),
    actions: {
      cropAvatar(file) {
        if (!['jpg', 'jpeg', 'png'].includes(file.extension.toLowerCase())) {
          file.queue.remove(file);
          return this.swal.warning('O formato do arquivo deve ser .jpg, .jpeg ou .png');
        }
        Ember.setProperties(this, {
          openCropper: true,
          avatarFile: file.blob
        });
      },
      setCnhExpirationDate() {
        Ember.set(this.user, 'cnhExpirationDate', this.cnhExpirationDate);
      },
      saveUser() {
        let messageWarningCellPhoneTelephoneAndZipcode;
        messageWarningCellPhoneTelephoneAndZipcode = this.user.zipcode && this.user.zipcode.length < 8 ? 'CEP inválido.<br />' : '';
        messageWarningCellPhoneTelephoneAndZipcode += this.user.cellphone && this.user.cellphone.length < 11 ? 'Celular inválido.<br />' : '';
        messageWarningCellPhoneTelephoneAndZipcode += this.user.professionalCellphone && this.user.professionalCellphone.length < 11 ? 'Celular corporativo inválido.<br />' : '';
        messageWarningCellPhoneTelephoneAndZipcode += this.user.emergencyContactPhone && this.user.emergencyContactPhone.length < 11 ? 'Telefone inválido.<br />' : '';
        messageWarningCellPhoneTelephoneAndZipcode += this.user.professionalPhone && this.user.professionalPhone.length < 10 ? 'Telefone corporativo inválido.<br />' : '';
        if (messageWarningCellPhoneTelephoneAndZipcode) {
          this.swal.warning('', {
            html: messageWarningCellPhoneTelephoneAndZipcode,
            confirmButtonText: 'OK',
            reverseButtons: true
          });
          return;
        }
        const currentTimeZone = -(new Date().getTimezoneOffset() / 60);
        try {
          Ember.setProperties(this.user, {
            birthDate: (0, _moment.default)(this.birthday, 'DD/MM/YYYY').utcOffset(currentTimeZone, true).toDate(),
            cnhExpirationDate: this.cnhExpirationDate ? (0, _moment.default)(this.cnhExpirationDate, 'DD/MM/YYYY').utcOffset(currentTimeZone, true).toDate() : null,
            customFields: this.userMeta
          });
          Ember.RSVP.all([this.user.save(), this.userMeta.save()]).then(() => {
            this.toast.success('Perfil atualizado');
            this.router.transitionTo('registry.general.user.show', this.user.id);
          }).catch(e => {
            this.swal.catch(e);
          });
        } catch (e) {
          this.swal.catch(e);
        }
      }
    }
  });
  _exports.default = _default;
});