define("account-talentrh/models/lgpd-backup-company-solicitation", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    backupContasModule: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['backupContasModule', 'backupSocialModule', 'backupAgendaModule', 'backupPesquisasModule', 'backupGestorProjetosModule', 'backupGestaoDesempenhoModule', 'backupEducacaoCorporativaModule'],
      validate(value, options, model) {
        const {
          backupContasModule,
          backupSocialModule,
          backupAgendaModule,
          backupPesquisasModule,
          backupGestorProjetosModule,
          backupGestaoDesempenhoModule,
          backupEducacaoCorporativaModule
        } = model;
        if (backupContasModule || backupSocialModule || backupAgendaModule || backupPesquisasModule || backupGestorProjetosModule || backupGestaoDesempenhoModule || backupEducacaoCorporativaModule) {
          return true;
        }
        return 'É necessário informar pelo menos um modulo para backup';
      }
    })
  });
  let LgpdBackupCompanySolicitationModel = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.belongsTo)('user'), _dec13 = (0, _model.belongsTo)('user'), _dec14 = (0, _model.belongsTo)('company'), _dec15 = Ember.computed('status'), _dec16 = Ember.computed('status'), _dec17 = Ember.computed('status'), _dec18 = Ember.computed('status'), _dec19 = Ember.computed('status'), (_class = class LgpdBackupCompanySolicitationModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "createdAt", _descriptor, this);
      _initializerDefineProperty(this, "status", _descriptor2, this);
      _initializerDefineProperty(this, "url", _descriptor3, this);
      _initializerDefineProperty(this, "path", _descriptor4, this);
      _initializerDefineProperty(this, "backupContasModule", _descriptor5, this);
      _initializerDefineProperty(this, "backupSocialModule", _descriptor6, this);
      _initializerDefineProperty(this, "backupAgendaModule", _descriptor7, this);
      _initializerDefineProperty(this, "backupPesquisasModule", _descriptor8, this);
      _initializerDefineProperty(this, "backupGestorProjetosModule", _descriptor9, this);
      _initializerDefineProperty(this, "backupGestaoDesempenhoModule", _descriptor10, this);
      _initializerDefineProperty(this, "backupEducacaoCorporativaModule", _descriptor11, this);
      _initializerDefineProperty(this, "createdBy", _descriptor12, this);
      _initializerDefineProperty(this, "lastDownloadBy", _descriptor13, this);
      _initializerDefineProperty(this, "company", _descriptor14, this);
    }
    get statusDescription() {
      switch (this.status) {
        case 'pending':
          return 'Em andamento';
        case 'available':
          return 'Disponível para download';
        case 'finished':
          return 'Concluída';
        case 'canceled':
          return 'Cancelada';
        default:
          return 'Pendente';
      }
    }
    get isCanceled() {
      return this.status === 'canceled';
    }
    get isPending() {
      return this.status === 'pending';
    }
    get isAvailable() {
      return this.status === 'available';
    }
    get isFinished() {
      return this.status === 'finished';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "path", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "backupContasModule", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "backupSocialModule", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backupAgendaModule", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "backupPesquisasModule", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "backupGestorProjetosModule", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "backupGestaoDesempenhoModule", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "backupEducacaoCorporativaModule", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "lastDownloadBy", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statusDescription", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "statusDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCanceled", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isCanceled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isPending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAvailable", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "isAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFinished", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "isFinished"), _class.prototype)), _class));
  _exports.default = LgpdBackupCompanySolicitationModel;
});