define("account-talentrh/pods/super-user/indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AxJGEtkJ",
    "block": "{\"symbols\":[\"Page\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Início\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"super-user.index\"]],[[\"default\"],[{\"statements\":[[2,\"Super Usuário\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"Link\"]],[],[[\"@link\"],[\"super-user.indicators\"]],[[\"default\"],[{\"statements\":[[2,\"Indicadores\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Indicadores do Super Usuário\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"indicator-loader\",[],[[\"@generalFilters\",\"@indicatorDefinitions\"],[[34,0],[34,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"generalFilters\",\"indicatorDefinitions\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/indicators/template.hbs"
    }
  });
  _exports.default = _default;
});