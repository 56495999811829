define("account-talentrh/pods/setup-wizard/steps/registry/department/manage/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "phDO9qwd",
    "block": "{\"symbols\":[\"@department\",\"@remove\"],\"statements\":[[6,[37,3],[[32,0,[\"isEdit\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"setup-wizard/steps/registry/department/manage/form\",[],[[\"@department\",\"@save\",\"@cancel\"],[[32,1],[30,[36,1],[[32,0,[\"save\"]],[32,1]],null],[30,[36,1],[[32,0,[\"cancel\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"branch\",\"name\"]],\"Não informado\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[14,0,\"text-right text-nowrap\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"employees\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"cannotEdit\"]]],null]],[14,\"title\",\"Há colaboradores relacionados a este departamento.\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil-alt\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,1],[[30,[36,0],[[32,0,[\"isEdit\"]]],null],true],null]],[14,\"title\",\"Editar\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil-alt\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-danger py-0\"],[15,\"onclick\",[30,[36,1],[[32,2],[32,1]],null]],[14,\"title\",\"Apagar\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/department/manage/item/template.hbs"
    }
  });
  _exports.default = _default;
});