define("account-talentrh/pods/registry/general/user/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    controllershipAccess: Ember.computed.and('permission.toAccessGoods', 'controllership'),
    showSettingsChecked: Ember.computed.or('user.away', 'controllershipAccess'),
    user: Ember.computed.alias('model'),
    userCPF: Ember.computed('user.cpf', function () {
      return this.user.cpf ? this.user.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : 'Não informado.';
    }),
    userGender: Ember.computed('user.gender', function () {
      if (this.user.gender === 'F') {
        return 'Feminino';
      }
      if (this.user.gender === 'M') {
        return 'Masculino';
      }
      if (this.user.gender === 'U') {
        return 'Não informado';
      }
      return '';
    }),
    // Tasks
    approveUser: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar aprovação do usuário', {
          text: 'O usuário será notificado no email cadastrado'
        });
        if (!value) {
          return;
        }
        yield this.ajax.post(`users/${this.user.id}/approve`);
        Ember.set(this, 'user.active', true);
        this.toast.success('Usuário aprovado');
      } catch (e) {
        Ember.debug(e);
        this.swal.catch(e);
      }
    }).drop(),
    declineUser: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Recusar ativação do usuário?', {
          text: 'Se desejar, informe uma mensagem para informar o usuário que não foi aprovado para utilizar o Voors',
          input: 'text',
          inputPlaceholder: 'Ex: Favor, passar no RH'
        });
        if (value === undefined) {
          return;
        }
        yield this.ajax.post(`users/${this.user.id}/reject`, {
          data: {
            reason: value
          }
        });
        Ember.set(this, 'user.active', false);
        this.toast.success('Usuário rejeitado');
      } catch (e) {
        Ember.debug(e);
        this.swal.catch(e);
      }
    }),
    removeAccessProfile: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do perfil de acesso?', {
          text: this.accessProfile.title
        });
        if (!value) {
          return;
        }
        yield this.accessProfile.destroyRecord();
        this.toast.success('Perfil de acesso removido');
        this.router.transitionTo('registry.general.access-profile');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    toggleInactive: (0, _emberConcurrency.task)(function* () {
      try {
        //Verifica se o usuário é o único administrador da company
        const isOnlyAdmin = yield this.fetch.request('/users/onlyAdmin/' + this.user.id).then(result => {
          return result.isOnlyAdmin ? true : false;
        });
        if (isOnlyAdmin) {
          return this.swal.warning('Esse usuário é o único administrador do sistema. Não é possível inativá-lo.');
        }
        const {
          value
        } = yield this.swal.warning('', {
          html: `${this.user.fullName} <br /> <p>Se confirmado, o usuário será removido de todos os vínculos a ele associados<p/>`,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          reverseButtons: true,
          showCancelButton: true,
          title: `Deseja inativar o usuário?`
        });
        if (!value) {
          return;
        }

        // Verifico se tem liderados
        let containsLeds = yield this.fetch.request('users/findLeds', {
          data: {
            appraisedBy: this.user.id,
            sort: 'createdAt DESC',
            select: ['id']
          }
        }).then(o => o.toArray());
        if (containsLeds && containsLeds.length) {
          const {
            value
          } = yield this.swal.warning('Para inativar esse usuário selecione um novo líder para assumir os liderados', {
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Escolher líder',
            reverseButtons: true,
            showCancelButton: true,
            title: 'Esse usuário é um líder!'
          });
          if (!value) {
            return;
          }
          let usersDepartment = [];
          if (this.user.department.get('id')) {
            usersDepartment = yield this.store.query('user', {
              department: [this.user.department.get('id')],
              id: {
                '!': containsLeds.map(user => user.id)
              },
              active: 1,
              limit: 6
            }).then(o => o.toArray());
            usersDepartment = usersDepartment.filter(item => item.id !== this.user.id);
          }
          Ember.set(this, 'modalChooseLeader', true);
          Ember.set(this, 'usersDepartment', usersDepartment);
        } else {
          yield this._afterInactiveUser.perform(this.user);
        }
      } catch (e) {
        this.user.rollbackAttributes();
        this.swal.catch(e);
      }
    }).drop(),
    toggleActivity: (0, _emberConcurrency.task)(function* () {
      try {
        this.user.active = 1;
        yield this._afterActiveUser.perform(this.user);
        this.user.save();
        this.toast.success(`${this.user.fullName} ativado`);
      } catch (e) {
        this.user.rollbackAttributes();
        this.swal.catch(e);
      }
    }).drop(),
    selectLeader: (0, _emberConcurrency.task)(function* (user) {
      try {
        let {
          value
        } = yield this.swal.confirm(`Atribuir Líder`, {
          text: `Deseja atribuir ${user.firstName} como líder aos usuários?`
        });
        if (!value) {
          return;
        }
        let users = yield this.store.query('user', {
          appraisedBy: this.user.id
        }).then(o => o.toArray());
        users = users.map(item => {
          return {
            id: item.id
          };
        });
        yield this.fetch.post('/users/updateAppraisedBy', {
          data: {
            records: users,
            appraisedBy: user.id
          }
        });
        this.toast.success('Líder selecionado com sucesso!');
        Ember.set(this, 'modalChooseLeader', false);
        Ember.set(this, 'modalChangeLeader', false);
        Ember.set(this, 'usersDepartment', null);
        Ember.set(this, 'leaderSelected', null);
        this.user.active = 0;
        this.user.status = 'inactive';
        this.user.save();
        yield this._afterInactiveUser.perform(this.user);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    _afterActiveUser: (0, _emberConcurrency.task)(function* (user) {
      try {
        const userSituation = this.store.createRecord('userSituation', {
          situation: 'active',
          user,
          company: user.company,
          date: new Date()
        });
        yield userSituation.save();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    _afterInactiveUser: (0, _emberConcurrency.task)(function* (user) {
      try {
        user.active = 0;
        user.status = 'inactive';
        this.ajax.delete(`cars/${user.id}/deleteWhenInactivateUser`);
        this.ajax.delete(`userResponsibleForSolicitation/${user.id}`);
        this.ajax.delete(`userProjectAdmin/${user.id}`);
        this.ajax.delete(`userGroup/${user.id}`);
        this.ajax.delete(`adminGroup/${user.id}`);
        this.ajax.delete(`users/${user.id}/removeInsights`);
        this.ajax.post(`users/${user.id}/terminateUserAppointments`);
        const userSituation = this.store.createRecord('userSituation', {
          situation: 'inactive',
          user,
          company: user.company,
          date: new Date()
        });
        yield userSituation.save();
        yield user.save();
        this.toast.success(`${user.fullName} inativado`);
      } catch (e) {
        this.user.rollbackAttributes();
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});