define("account-talentrh/pods/registry/avd/evaluation/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex'],
    statusEvaluation: Ember.computed('evaluation.{isConcluded,canceled}', function () {
      if (Ember.get(this, 'evaluation.canceled')) {
        return {
          style: 'badge-danger',
          text: 'Cancelada'
        };
      }
      if (Ember.get(this, 'evaluation.isConcluded')) {
        return {
          style: 'badge-success',
          text: 'Concluída'
        };
      }
      return {
        style: 'badge-warning text-white',
        text: 'Pendente'
      };
    })
  });
  _exports.default = _default;
});