define("account-talentrh/pods/registry/general/institution/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.institution.validations.isValid) {
        return this.swal.validation(this.institution.validations);
      }
      let message = this.institution.isNew ? 'Instituição cadastrada' : 'Instituição atualizada';
      try {
        let institution = yield this.institution.save();
        this.toast.success(message);
        this.router.transitionTo('registry.general.institution.show', institution.id);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      toggleIdentification() {
        this.toggleProperty('institution.physicalPerson');
        Ember.setProperties(this.institution, {
          cpf: null,
          cnpj: null
        });
      }
    }
  });
  _exports.default = _default;
});