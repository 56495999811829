define("account-talentrh/pods/configuration/avd/question/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+iE/oTh/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"configuration/avd/question/form\",[],[[\"@question\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/configuration/avd/question/edit/template.hbs"
    }
  });
  _exports.default = _default;
});