define("account-talentrh/pods/super-user/indicators/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Multiline computed properties
    indicatorDefinitions: Ember.A([{
      name: 'usersActiveByCompany',
      componentName: 'super-user/indicators/users-active-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filterData: {}
    }, {
      name: 'postsByCompany',
      componentName: 'super-user/indicators/posts-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'accessByCompany',
      componentName: 'super-user/indicators/access-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'messagesByCompany',
      componentName: 'super-user/indicators/messages-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'trainingsByCompany',
      componentName: 'super-user/indicators/trainings-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'tasksByCompany',
      componentName: 'super-user/indicators/tasks-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'appointmentsByCompany',
      componentName: 'super-user/indicators/appointments-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'searchsByCompany',
      componentName: 'super-user/indicators/searchs-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }, {
      name: 'avdsByCompany',
      componentName: 'super-user/indicators/avds-by-company',
      classNames: 'indicator-viewer mb-3 col-md-4',
      filters: {
        startDate: true,
        endDate: true
      },
      filterData: {}
    }]),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.generalFilters = {
        isGeneralFilters: true,
        filters: {
          startDate: true,
          endDate: true
        },
        filterData: {}
      };
    }
  });
  _exports.default = _default;
});