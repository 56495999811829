define("account-talentrh/pods/registry/general/user/edit/avd/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    user: Ember.computed.alias('model'),
    newPerformanceModule: Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'),
    typeOptions: Ember.A([{
      label: '90',
      value: 1
    }, {
      label: '180',
      value: 2
    }, {
      label: '360',
      value: 3
    }]),
    typeSelected: Ember.computed('user.{typeEvaluation,excludedFromAutoPerformanceParticipation}', 'typeOptions', 'newPerformanceModule', {
      get() {
        if (this.user.excludedFromAutoPerformanceParticipation && this.newPerformanceModule) {
          return null;
        }
        return this.typeOptions.find(option => {
          return option.value === this.user.typeEvaluation;
        });
      },
      set(key, selected) {
        Ember.set(this, 'user.typeEvaluation', selected.value);
        return selected;
      }
    }),
    removeGoals: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.user.hasNoGoal) {
          let {
            value
          } = yield this.swal.confirm('Todas as metas do usuário serão removidas, deseja continuar?');
          if (!value) {
            return;
          }
        }
        Ember.set(this, 'user.hasNoGoal', !this.user.hasNoGoal);
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    actions: {
      setPropsAsNull() {
        Ember.set(this, 'user.excludedFromAutoPerformanceParticipation', !this.user.excludedFromAutoPerformanceParticipation);
        Ember.set(this, 'user.team', null);
      }
    }
  });
  _exports.default = _default;
});