define("account-talentrh/pods/super-user/companies/edit/plugins/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nqcQ8fKs",
    "block": "{\"symbols\":[\"plugin\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n  \"],[8,\"ui-checkbox\",[],[[\"@checked\",\"@onChange\"],[[32,0,[\"companyConfig\",\"featureFlags\",\"ENABLE\"]],[30,[36,2],[[32,0],\"disableAllPlugin\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Habilitar\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"pluginsOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-checkbox\",[[24,0,\"d-block\"]],[[\"@disabled\",\"@checked\",\"@onChange\"],[[32,0,[\"enablePlugin\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"companyConfig\",\"featureFlags\"]],[32,1,[\"field\"]]],null],[32,0,[\"companyConfig\",\"featureFlags\",\"ENABLE\"]]],null],[30,[36,2],[[32,0],\"updatePlugin\",[32,1,[\"field\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"and\",\"action\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/companies/edit/plugins/template.hbs"
    }
  });
  _exports.default = _default;
});