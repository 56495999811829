define("account-talentrh/pods/configuration/nela/post-tag/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* () {
      const message = this.postTag.isNew ? 'Etiqueta cadastrada' : 'Etiqueta atualizada';
      try {
        if (!this.postTag.validations.isValid) {
          return this.swal.validation(this.postTag.validations);
        }
        yield this.postTag.save();
        this.toast.success(message);
        this.router.transitionTo('configuration.nela.post-tag.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});