define("account-talentrh/models/birthday-message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.belongsTo)('company'),
    message: (0, _model.attr)('string'),
    pathImage: (0, _model.attr)('string'),
    signedUrl: (0, _model.attr)('string')
  });
  _exports.default = _default;
});