define("account-talentrh/pods/super-user/wizard-status/show/groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wRb7Bg8x",
    "block": "{\"symbols\":[\"setting\"],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"settings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n        \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,1,[\"hasRegistry\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"hasRegistry\"]],[32,1,[\"count\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n          \"],[10,\"p\"],[14,0,\"ml-4\"],[12],[1,[32,1,[\"count\"]]],[2,\" Cadastros\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/show/groups/template.hbs"
    }
  });
  _exports.default = _default;
});