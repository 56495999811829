define("account-talentrh/pods/components/dashboard-schedule-absence/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardScheduleAbsenceComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class DashboardScheduleAbsenceComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "data", _descriptor3, this);
    }
    verifyData() {
      const {
        slideItem,
        slideItemContent
      } = this.args;
      if (slideItem && slideItemContent) {
        this.data = {
          // SlideItem
          title: slideItem.get('title'),
          color: slideItem.get('color'),
          refreshTimeInSeconds: slideItem.get('refreshTimeInSeconds'),
          // SlideItemContent
          branches: slideItemContent.get('branches') || [],
          hasExpireDate: slideItemContent.get('hasExpireDate'),
          expireDateStart: slideItemContent.get('expireDateStart'),
          expireDateEnd: slideItemContent.get('expireDateEnd')
        };
      }
    }
    durationSelect(event) {
      this.data.refreshTimeInSeconds = Number(event.target.value);
    }
    onCloseModal() {
      const {
        resetToSchedule,
        save
      } = this.args;
      this.data = {
        // SlideItem
        title: 'Ausências',
        color: 'blue',
        refreshTimeInSeconds: 5,
        // SlideItemContent
        branches: [],
        hasExpireDate: false,
        expireDateStart: null,
        expireDateEnd: null
      };
      if (save) {
        this.router.transitionTo('registry.social.mural.gridstack');
      } else {
        resetToSchedule();
      }
    }
    onChangeEndDate(_ref) {
      let {
        date
      } = _ref;
      const startDate = this.data.expireDateStart ? (0, _moment.default)(this.data.expireDateStart).startOf('day') : null;
      const endDate = (0, _moment.default)(date).endOf('day');
      if (!startDate) {
        return this.swal.warning('A data de início não foi informada');
      }
      if (startDate) {
        if (endDate.isBefore(startDate)) {
          return this.swal.warning('A data de término não pode ser anterior a data de início');
        }
      }
      Ember.set(this.data, 'expireDateEnd', date);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        // SlideItem
        title: 'Ausências',
        color: 'blue',
        refreshTimeInSeconds: 5,
        // SlideItemContent
        branches: [],
        hasExpireDate: false,
        expireDateStart: null,
        expireDateEnd: null
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "verifyData", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "verifyData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "durationSelect", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "durationSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeEndDate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeEndDate"), _class.prototype)), _class));
  _exports.default = DashboardScheduleAbsenceComponent;
});