define("account-talentrh/pods/registry/general/branch/index/print/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('registry.general.branch.index');
      }
    }
  });
  _exports.default = _default;
});