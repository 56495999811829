define("account-talentrh/models/questions-group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    description: (0, _model.attr)('string'),
    questionsDetails: (0, _model.hasMany)('groupQuestionDetail'),
    // Retorna a soma do total de todas as perguntas usando o Math.round para aredondar o valor
    percentageTotal: Ember.computed('questionsDetails.@each.percentage', function () {
      let total = 0,
        percentage;
      this.questionsDetails.forEach(questionsDetail => {
        percentage = questionsDetail.get('percentage');
        total += percentage ? parseFloat(percentage) : 0;
      });
      return Math.round(total);
    })
  });
  _exports.default = _default;
});