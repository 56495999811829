define("account-talentrh/pods/components/card-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dlf5gGhB",
    "block": "{\"symbols\":[\"Card\",\"&attrs\",\"@icon\",\"@route\",\"&default\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"pt-3 pb-5 border-primary text-right\"]],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"avatar-7 position-absolute t-4 mt-1 border border-3 border-white bg-primary\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"position-absolute text-white font-weight-bold centralize h4\"],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"mt-1 mb-0 ml-3 pl-5 text-truncate text-right d-block font-weight-bold stretched-link\"]],[[\"@route\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,5,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"display-2\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"position-absolute centralize opacity-1 fa \",[32,3],\" text-primary\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/card-menu/template.hbs"
    }
  });
  _exports.default = _default;
});