define("account-talentrh/pods/registry/general/job-function/show/trainings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WGtRjSax",
    "block": "{\"symbols\":[\"training\"],\"statements\":[[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"jobFunction\",\"trainings\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item py-3\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"font-weight-bold mb-0\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-truncate\"],[15,\"title\",[30,[36,1],[[30,[36,0],[[32,1,[\"description\",\"length\"]],40],null],[32,1,[\"description\"]],\"\"],null]],[12],[2,\"\\n        \"],[1,[32,1,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"list-group-item text-center font-italic\"],[12],[2,\"\\n      Nenhum treinamento relacionado\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"gt\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/job-function/show/trainings/template.hbs"
    }
  });
  _exports.default = _default;
});