define("account-talentrh/pods/reports/team-management/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    branchs: Ember.A(),
    departments: Ember.A(),
    users: Ember.A(),
    leaders: Ember.A(),
    jobFunctions: Ember.A(),
    jobRoles: Ember.A(),
    accessProfiles: Ember.A(),
    showLoadMore: true,
    // Single line computed properties
    disableChangeAll: Ember.computed.not('appraisedBy'),
    user: Ember.computed.alias('permission.user'),
    disableIfLeaderIsFiltered: Ember.computed('leaders.length', function () {
      return this.leaders?.length;
    }),
    disableIfUserIsFiltered: Ember.computed('users.length', function () {
      return this.users?.length;
    }),
    exportFields: Ember.A([{
      field: 'userFullName',
      fieldName: 'Colaborador'
    }, {
      field: 'cpf',
      fieldName: 'CPF'
    }, {
      field: 'email',
      fieldName: 'E-mail'
    }, {
      field: 'branch',
      fieldName: 'Filial'
    }, {
      field: 'department',
      fieldName: 'Departamento'
    }, {
      field: 'jobRole',
      fieldName: 'Cargo'
    }, {
      field: 'jobFunction',
      fieldName: 'Função'
    }, {
      field: 'appraiserFullName',
      fieldName: 'Líder'
    }, {
      field: 'accessProfileTitle',
      fieldName: 'Perfil de acesso'
    }]),
    // Custom methods
    clearFilters: function () {
      Ember.set(this, 'filter', {});
      Ember.set(this, 'showList', false);
      Ember.set(this, 'notLeader', false);
      Ember.set(this, 'users', []);
      Ember.set(this, 'leaders', []);
      Ember.set(this, 'jobFunctions', []);
      Ember.set(this, 'jobRoles', []);
      Ember.set(this, 'departments', []);
      Ember.set(this, 'branchs', []);
      Ember.set(this, 'accessProfiles', []);
    },
    // Tasks
    saveItem: (0, _emberConcurrency.task)(function* (user) {
      try {
        if (!user) {
          return;
        }
        const records = [{
          id: user.id
        }];
        let appraisedBy = null;
        if (user.appraisedBy) {
          appraisedBy = user.appraisedBy.id;
        }
        yield this.fetch.post('/users/updateAppraisedBy', {
          data: {
            records,
            appraisedBy
          }
        });
        yield this.toast.success('Salvo com sucesso');
        Ember.set(this, 'showSave', false);
        Ember.set(this, 'appraisedBy', null);
        Ember.set(this, 'disableEdit', false);
        Ember.set(this, 'hideComboEdit', false);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.records || !this.records.length || !this.appraisedBy) {
          return;
        }
        const records = this.records.map(user => {
          return {
            id: user.id
          };
        });
        const containsUser = this.records.find(user => {
          return user.id === this.appraisedBy.id;
        });
        if (containsUser) {
          return this.swal.warning('Não é possível incluir o próprio usuário como seu Líder imediato');
        }
        yield this.fetch.post('/users/updateAppraisedBy', {
          data: {
            records,
            appraisedBy: this.appraisedBy.id
          }
        });
        this.toast.success('Salvo com sucesso');
        Ember.set(this, 'showSave', false);
        Ember.set(this, 'appraisedBy', null);
        Ember.set(this, 'disableEdit', false);
        Ember.set(this, 'hideComboEdit', false);
        Ember.set(this, 'showLoadMore', true);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    changeAll: (0, _emberConcurrency.task)(function* (records) {
      if (!records || !records.length) {
        return;
      }
      const users = records.filter(user => {
        return user.id === this.appraisedBy.id;
      });
      if (users && users.length) {
        const text = 'O líder selecionado está presente na lista de usuários e não é possível incluí-lo como ' + 'seu Líder imediato. O mesmo não será alterado, deseja continuar? ';
        const {
          value
        } = yield this.swal.confirm(`Usuário não pode ser seu próprio Líder`, {
          text
        });
        if (!value) {
          return;
        }
        const list = records.filter(item => {
          return !users.some(user => user.id === item.id);
        });
        if (!list.length) {
          return this.swal.warning('Nenhum usuário disponível para alteração em massa');
        }
        records = list;
      }
      const {
        value
      } = yield this.swal.confirm('Todos os usuário exibidos na tabela terão o líder alterado, deseja continuar?');
      if (!value) {
        return;
      }
      records.forEach(item => {
        item.appraisedBy = this.appraisedBy.id;
      });
      Ember.set(this, 'showSave', true);
      Ember.set(this, 'records', records);
      Ember.set(this, 'disableEdit', true);
      Ember.set(this, 'showLoadMore', false);
      Ember.set(this, 'hideComboEdit', true);
      yield this.save.perform();
      yield this.send('showReport');
    }),
    getUsersNoLimit: (0, _emberConcurrency.task)(function* () {
      try {
        let users = this.users.mapBy('id');
        let departments = this.departments.mapBy('id');
        let branchs = this.branchs.mapBy('id');
        let jobFunctions = this.jobFunctions.mapBy('id');
        let jobRoles = this.jobRoles.mapBy('id');
        let leaders = this.leaders.mapBy('id');
        let accessProfiles = this.accessProfiles.mapBy('id');
        const filters = {
          users,
          departments,
          branchs,
          jobFunctions,
          jobRoles,
          leaders,
          accessProfiles,
          getUsersNoLimit: true
        };
        if (this.notLeader) {
          filters.notLeader = this.notLeader;
        }
        const usersNoLimit = yield this.fetch.request('/users/teamManagement', {
          data: filters
        });
        Ember.set(this, 'usersNoLimit', usersNoLimit);
      } catch (error) {
        console.log(error);
      }
    }).drop(),
    // Actions
    actions: {
      async showReport() {
        let users = this.users.mapBy('id');
        let departments = this.departments.mapBy('id');
        let branchs = this.branchs.mapBy('id');
        let jobFunctions = this.jobFunctions.mapBy('id');
        let jobRoles = this.jobRoles.mapBy('id');
        let leaders = this.leaders.mapBy('id');
        let accessProfiles = this.accessProfiles.mapBy('id');
        const filter = {
          users,
          departments,
          branchs,
          jobFunctions,
          jobRoles,
          leaders,
          accessProfiles
        };
        if (this.notLeader) {
          filter.notLeader = this.notLeader;
        }
        Ember.set(this, 'filter', filter);
        Ember.set(this, 'showSave', false);
        Ember.set(this, 'showList', true);
        Ember.set(this, 'disableEdit', false);
        await this.getUsersNoLimit.perform();
      },
      cancel() {
        Ember.set(this, 'showSave', false);
        Ember.set(this, 'appraisedBy', null);
        Ember.set(this, 'disableEdit', false);
        Ember.set(this, 'showLoadMore', true);
        Ember.set(this, 'hideComboEdit', false);
        this.send('showReport');
      },
      async onClickEdit() {
        Ember.set(this, 'showSave', false);
        Ember.set(this, 'appraisedBy', null);
        Ember.set(this, 'disableEdit', true);
      },
      async onCancelEdit() {
        this.send('showReport');
        Ember.set(this, 'disableEdit', false);
      },
      onChangeCheckbox() {
        Ember.set(this, 'notLeader', !this.notLeader);
        Ember.set(this, 'leaders', []);
      },
      changeBranch() {
        Ember.set(this, 'branches', this.user.branches.mapBy('id'));
      }
    }
  });
  _exports.default = _default;
});