define("account-talentrh/pods/super-user/companies/edit/plugins/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('super-user.companies.edit');
    },
    deactivate() {
      this.controllerFor('super-user.companies').set('filters', {});
    }
  });
  _exports.default = _default;
});