define("account-talentrh/pods/configuration/avd/question/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    deleteQuestion: (0, _emberConcurrency.task)(function* (question) {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover pergunta?');
        if (!value) {
          return;
        }
        yield question.destroyRecord();
        Ember.set(this, 'filter', {});
        this.toast.success('Pergunta removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});