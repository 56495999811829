define("account-talentrh/pods/setup-wizard/steps/groups/link/manage/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsGroupsLinkManageNewRoute extends Ember.Route {
    model() {
      return this.store.createRecord('user-link');
    }
  }
  _exports.default = SetupWizardStepsGroupsLinkManageNewRoute;
});