define("account-talentrh/pods/application/sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SidebarComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('permission.toCreateBenefits'), _dec8 = Ember.computed.alias('permission.toCreateCompanyProgram'), _dec9 = Ember.computed.alias('permission.toCreateCompanyPolicy'), _dec10 = Ember.computed.alias('permission.admin'), _dec11 = Ember.computed.alias('permission.toAccessTED'), _dec12 = Ember.computed.alias('permission.toAccessAVD'), _dec13 = Ember.computed.or('permission.admin', 'permission.accessProfile.allowViewVersionNotificationHistory'), _dec14 = Ember.computed.or('manageSocialRecords', 'manageGeneralRecords', 'manageConfigurations'), _dec15 = Ember.computed.and('permission.allowCreateAndManageHabitusIA', 'permission.aiAssistant'), _dec16 = Ember.computed.or('permission.admin', 'permission.accessProfile.allowRegistrationDataManagement', 'canManageHabitusIA'), _dec17 = Ember.computed.or('permission.accessProfile.admin', 'permission.accessProfile.allowRegistrationDataManagement'), _dec18 = Ember.computed.and('permission.accessProfile.allowManageVacancies', 'permission.companyConfig.permitCreateVacancyAnnouncement'), _dec19 = Ember.computed.or('permission.accessProfile.allowConsultEngagement', 'admin'), _dec20 = Ember.computed.or('permission.admin', 'permission.accessProfile.allowCreateCompanyPolicy', 'permission.accessProfile.allowCreateCompanyProgram', 'permission.accessProfile.allowCreateBenefits', 'allowVacancy', 'permission.accessProfile.allowImportFiles'), _dec21 = Ember.computed('admin'), _dec22 = Ember.computed('permission.accessProfile.{allowCreateGoals,generalManager}', 'permission.{admin,toAccessAVD}'), _dec23 = Ember.computed('permission.accessProfile.generalManager', 'permission.{admin,toAccessAVD}'), _dec24 = Ember._action, _dec25 = Ember.computed.filter('moduleOptions', function (item) {
    let environment = Ember.getOwner(this).factoryFor('config:environment').class;
    let companyConfig = this.permission.companyConfig;
    if (!environment) {
      return true;
    }
    return item.modulePrefix !== environment.modulePrefix && (!item.validation || companyConfig && companyConfig.get(item.validation));
  }), (_class = class SidebarComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "wizard", _descriptor5, this);
      _initializerDefineProperty(this, "deleteCompanySolicitation", _descriptor6, this);
      _initializerDefineProperty(this, "toCreateBenefits", _descriptor7, this);
      _initializerDefineProperty(this, "toCreateCompanyProgram", _descriptor8, this);
      _initializerDefineProperty(this, "toCreateCompanyPolicy", _descriptor9, this);
      _initializerDefineProperty(this, "admin", _descriptor10, this);
      _initializerDefineProperty(this, "accessEducation", _descriptor11, this);
      _initializerDefineProperty(this, "accessAVD", _descriptor12, this);
      _initializerDefineProperty(this, "showVersionHistory", _descriptor13, this);
      _initializerDefineProperty(this, "manageRecords", _descriptor14, this);
      _initializerDefineProperty(this, "canManageHabitusIA", _descriptor15, this);
      _initializerDefineProperty(this, "manageGeneralRecords", _descriptor16, this);
      _initializerDefineProperty(this, "allowRegistrationDataManagement", _descriptor17, this);
      _initializerDefineProperty(this, "allowVacancy", _descriptor18, this);
      _initializerDefineProperty(this, "allowConsultEngagement", _descriptor19, this);
      _initializerDefineProperty(this, "manageSocialRecords", _descriptor20, this);
      _defineProperty(this, "moduleOptions", Ember.A([{
        title: 'Agenda',
        type: 'agenda',
        modulePrefix: 'agenda'
      }, {
        title: 'Gestão de Desempenho',
        type: 'avd',
        modulePrefix: 'avaliacao-talentrh'
      }, {
        title: 'Educação',
        type: 'ted',
        modulePrefix: 'ted-ui',
        validation: 'hasTEDModule'
      }, {
        title: 'Painel do Administrador',
        type: 'conta',
        modulePrefix: 'account-talentrh'
      }, {
        title: 'Pesquisas',
        type: 'pesquisa',
        modulePrefix: 'pesquisa-talentrh'
      }, {
        title: 'Projetos',
        type: 'projetos',
        modulePrefix: 'gestor-projetos'
      }, {
        title: 'Social',
        type: 'social',
        modulePrefix: 'portal-talentrh'
      }]));
      _initializerDefineProperty(this, "modules", _descriptor21, this);
    }
    get urlReports() {
      return this.admin ? 'reports.access-log' : 'reports.team-management';
    }
    get manageConfigurations() {
      return this.permission.toAccessAVD && (this.permission.accessProfile.generalManager || this.permission.accessProfile.allowCreateGoals) || this.permission.admin;
    }
    get show() {
      return this.permission.toAccessAVD && this.permission.accessProfile.generalManager || this.permission.admin;
    }
    logout() {
      this.session.invalidate().then();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteCompanySolicitation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toCreateBenefits", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "toCreateCompanyProgram", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "toCreateCompanyPolicy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "accessEducation", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "accessAVD", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showVersionHistory", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "manageRecords", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "canManageHabitusIA", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "manageGeneralRecords", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "allowRegistrationDataManagement", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "allowVacancy", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "allowConsultEngagement", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "manageSocialRecords", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "urlReports", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "urlReports"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "manageConfigurations", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "manageConfigurations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "show", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "modules", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SidebarComponent;
});