define("account-talentrh/pods/setup-wizard/steps/feed/events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "87NsdEwQ",
    "block": "{\"symbols\":[\"Card\",\"@model\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n        \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-8 py-4\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"Habilitar a publicação de eventos?\"],[13],[2,\"\\n\\n        \"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,2,[\"permitEvent\"]],[30,[36,1],[[30,[36,0],[[32,2,[\"permitEvent\"]]],null]],null]]],null],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Fortaleça a cultura organizacional e mantenha os colaboradores por \\n          dentro de datas importantes: os eventos ficam em destaque na home do Voors, \\n          garantindo a facilidade de acesso aos detalhes dos eventos e a confirmação de participação.\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-3\"],[14,\"src\",\"https://player.vimeo.com/video/757137645?h=3fc020b706\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n          No Perfil de Acesso você poderá gerenciar quais usuários estão habilitados \\n          para realizar publicações de eventos no feed.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"setup-wizard/step-control\",[],[[\"@saveConfigOnChange\"],[true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/feed/events/template.hbs"
    }
  });
  _exports.default = _default;
});