define("account-talentrh/pods/setup-wizard/steps/feed/news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Qo7VoGO6",
    "block": "{\"symbols\":[\"Card\",\"@model\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n        \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-8 py-4\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"Habilitar a publicação de notícias?\"],[13],[2,\"\\n\\n        \"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,2,[\"permitNews\"]],[30,[36,1],[[30,[36,0],[[32,2,[\"permitNews\"]]],null]],null]]],null],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Transforme o Voors em uma fonte de informações e notícias importantes. \\n          Habilitando este recurso os colaboradores poderão compartilhar notícias no \\n          feed indicando sua fonte de referência.\\n        \"],[13],[2,\"\\n\\n\"],[2,\"\\n        \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n          No perfil de acesso, você poderá gerenciar quais usuários \\n          podem estar habilitados a realizar publicações de notícias no feed.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"setup-wizard/step-control\",[],[[\"@saveConfigOnChange\"],[true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/feed/news/template.hbs"
    }
  });
  _exports.default = _default;
});