define("account-talentrh/models/type-answer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    // Associations
    answers: (0, _model.hasMany)('answer')
  });
  _exports.default = _default;
});