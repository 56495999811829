define("account-talentrh/pods/super-user/indicators/appointments-by-company/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didUpdate() {
      this._super(...arguments);
      let indicator = this.indicator;
      if (indicator && indicator.data) {
        this.initChart(indicator);
      }
    },
    initChart(indicator) {
      let {
        chartData
      } = indicator.data;
      let haveData = {
        labels: chartData.labels,
        datasets: [{
          data: chartData.data,
          backgroundColor: ['#5389c5', '#35b386', '#465773', '#e86a69', '#2081ce', '#7d5ac2', '#d2c93f', '#6475FD', '#F08A47', '#C5A8A4']
        }]
      };
      let noData = {
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#eee']
        }]
      };
      let data = chartData.labels.length ? haveData : noData;
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart-appointments-by-company').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'doughnut',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'right'
            },
            legendCallback: function (chart) {
              // Cria uma lengenda customizada usando html
              let html = [];
              chart.data.labels.forEach((obj, index) => {
                let color = chart.data.datasets[0].backgroundColor[index];
                let labelName = chart.data.labels[index];
                let legendHtml = `
                <li class="py-1 text-truncate" title="${labelName}">
                  <span class="pr-1 mr-2 rounded" style="background: ${color}"></span> ${labelName}
                </li>
              `;
                html.push(legendHtml);
              });
              return html.join('');
            }
          }
        });
      }
      document.getElementById('chart-appointments-by-company-legend').innerHTML = chart.generateLegend();
      this.set('chart', chart);
    }
  });
  _exports.default = _default;
});