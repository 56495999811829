define("account-talentrh/pods/registry/general/access-profile/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'registry.general.access-profile.show',
    model: Ember.computed.alias('accessProfile.id')
  });
  _exports.default = _default;
});