define("account-talentrh/pods/registry/general/job-role/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7A0aoN/9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"registry/general/job-role/form\",[],[[\"@jobRole\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/job-role/create/template.hbs"
    }
  });
  _exports.default = _default;
});