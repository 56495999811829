define("account-talentrh/services/pesquisa", ["exports", "account-talentrh/services/ajax", "account-talentrh/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    host: _environment.default.apiUrl.pesquisa
  });
  _exports.default = _default;
});