define("account-talentrh/pods/registry/general/job-role/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    jobRole: Ember.computed.alias('model'),
    accessAVD: Ember.computed.alias('permission.toAccessAVD'),
    removeJobRole: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do cargo?', {
          text: this.jobRole.title
        });
        if (!value) {
          return;
        }
        let users = yield this.jobRole.users;
        if (users.length) {
          return this.swal.warning('Há usuários vinculados à este cargo', {
            title: 'Não foi possível remover.'
          });
        }
        yield this.ajax.delete(`/jobRoles/${this.jobRole.id}`).then(() => {
          this.jobRole.jobFunctions.map(jobFunction => {
            jobFunction.jobRole = null;
            jobFunction.save();
          });
          this.jobRole.deleteRecord();
          this.toast.success('Cargo removido');
          this.router.transitionTo('registry.general.job-role.index');
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});