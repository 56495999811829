define("account-talentrh/pods/registry/general/user/show/index/general/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    permission: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    userMeta: Ember.computed.alias('loadUserMeta.lastSuccessful.value'),
    userAddress: Ember.computed('user.{address,complement,number}', function () {
      let address = '';
      if (this.user.address) {
        address += this.user.address;
      }
      if (this.user.number) {
        address += ` ${this.user.number}`;
      }
      if (this.user.complement) {
        address += ` - ${this.user.complement}`;
      }
      return address;
    }),
    userZipcode: Ember.computed('user.zipcode', function () {
      if (!this.user.zipcode) {
        return '';
      }
      return this.user.zipcode.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    }),
    loadUserMeta: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.store.queryRecord('user-meta', {
          user: this.user.id
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).on('didReceiveAttrs').drop()
  });
  _exports.default = _default;
});