define("account-talentrh/pods/registry/general/access-profile/form/permissions/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PermissionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('permission.companyConfig'), _dec5 = Ember.computed.alias('permission.toAccessAgenda'), _dec6 = Ember.computed.alias('permission.admin'), _dec7 = Ember.computed('options', 'args.accessProfile'), _dec8 = Ember.computed('accessAgenda', 'args.accessProfile.{allowAccessSchedule,allowAccessSearches,allowSendMessageIndividualChat}', 'companyConfig.featureFlags.{ENABLE,postPerBranch}', 'companyConfig.{allowImportFiles,allowIndicatorCompanyProfile,allowIndicatorEngagementAllUsers,allowIndicatorOrganizationalClimateAllUsers,allowIndicatorPerformanceAllUsers,communicationApi,manageLunchList,permitAd,permitAnnouncement,permitBenefit,permitChart,permitChat,permitCompanyPhotos,permitCompanyPrograms,permitComplaint,permitCreateVacancyAnnouncement,permitDecisionMaking,permitEvent,permitExternalLink,permitGroups,permitImports,permitMeetingMinute,permitMessageRh,permitNews,permitPolicy,permitPostMessage,permitReceipt,permitSurvey,permitTimeCard,permitUserFeeling,subscriptionManagement,usesCompensatoryTime}', 'permission.{toAccessAVD,toAccessAgenda,toAccessPesquisa,toAccessProjects,toAccessTED}'), (_class = class PermissionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor4, this);
      _initializerDefineProperty(this, "accessAgenda", _descriptor5, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor6, this);
    }
    get sections() {
      return this.options.map(option => {
        option.permissions = option.permissions.filterBy('show', true);
        return option;
      });
    }
    get options() {
      const {
        accessProfile
      } = this.args;
      return Ember.A([{
        title: 'Publicações',
        permissions: [{
          label: 'Publicar Post',
          description: 'Permite publicar posts',
          granted: 'postMessage',
          show: this.companyConfig.permitPostMessage
        }, {
          label: 'Publicar comunicados',
          description: 'Permite publicar comunicados',
          granted: 'postAnnouncement',
          show: this.companyConfig.permitAnnouncement
        }, {
          label: 'Publicar eventos',
          description: 'Permite publicar eventos',
          granted: 'postEvent',
          show: this.companyConfig.permitEvent
        }, {
          label: 'Publicar notícias',
          description: 'Permite publicar notícias',
          granted: 'postNews',
          show: this.companyConfig.permitNews
        }, {
          label: 'Publicar classificados',
          description: 'Permite publicar anúncios nos classificados',
          granted: 'postAd',
          show: this.companyConfig.permitAd
        }, {
          label: 'Publicar enquetes',
          description: 'Permite publicar enquetes',
          granted: 'postQuiz',
          show: this.companyConfig.permitSurvey
        }, {
          label: 'Comentar publicações',
          description: 'Permite comentar publicações',
          granted: 'permitComment',
          show: true
        }, {
          label: 'Curtir publicações e comentários',
          description: 'Permite curtir publicações e comentários',
          granted: 'permitLike',
          show: true
        }, {
          label: 'Fixar publicações',
          description: 'Permite fixar publicações no feed',
          granted: 'allowPinPost',
          show: true
        }, {
          label: 'Gerenciar publicações reportadas',
          description: 'Permite gerenciar publicações reportadas.',
          granted: 'allowManageReportedPost',
          show: this.companyConfig.permitComplaint
        }, {
          label: 'Publicar apenas em grupos',
          description: 'Permite fazer as publicações que estão liberadas no perfil somente nos grupos dos quais o usuário é membro.',
          granted: 'publishOnlyInGroup',
          show: true
        }, {
          label: 'Publicar por filial',
          description: 'Habilita publicações por filiais',
          granted: 'allowPublishPerBranch',
          show: this.companyConfig.featureFlags.ENABLE && this.companyConfig.featureFlags.postPerBranch
        }, {
          label: 'Compartilhar publicações',
          description: 'Permite compartilhar as publicações que estão liberadas a esse perfil',
          granted: 'allowSharePosts',
          show: true
        }]
      }, {
        title: 'Recursos',
        permissions: [{
          label: 'Criar grupos',
          description: 'Permite criar grupos',
          granted: 'createGroup',
          show: this.companyConfig.permitGroups
        }, {
          label: 'Publicar álbum de fotos',
          description: 'Permite publicar álbum de fotos',
          granted: 'publishAlbum',
          show: this.companyConfig.permitCompanyPhotos
        }, {
          label: 'Alterar foto de perfil',
          description: 'Permite a alteração de foto de perfil.',
          granted: 'changeAvatar',
          show: true
        }, {
          label: 'Criar ausências',
          description: 'Permite criar ausências.',
          granted: 'allowCreateAbsenceForYourself',
          show: true
        }, {
          label: 'Criar ausências para outros colaboradores',
          description: 'Permite criar ausências para outros colaboradores',
          granted: 'permitUserMessageCreate',
          show: true
        }, {
          label: 'Chat Individual',
          description: 'Permite o envio e recebimento de mensagens pelo chat individual.',
          granted: 'allowSendMessageIndividualChat',
          show: this.companyConfig.permitChat
        }, {
          label: 'Chat Grupos',
          description: 'Permite o envio e recebimento de mensagens pelo chat em grupo.',
          granted: 'allowSendMessageGroupChat',
          show: this.companyConfig.permitChat
        }, {
          label: 'Criar listas de transmissão',
          description: 'Permite a criação de lista de transmissão para envio de mensagens.',
          granted: 'allowCreateTransmissionList',
          show: this.companyConfig.permitChat && accessProfile.allowSendMessageIndividualChat
        }, {
          label: 'Criar Links',
          description: 'Permite a criação de links externos.',
          granted: 'allowCreateExternalLink',
          show: this.companyConfig.permitExternalLink
        }, {
          label: 'Criar atas de reunião',
          description: 'Permite a criação de atas de reunião.',
          granted: 'allowCreateMeetingMinute',
          show: this.companyConfig.permitMeetingMinute
        }, {
          label: 'Criar tomada de decisão',
          description: 'Permite a criação de tomadas de decisão.',
          granted: 'allowCreateDecisionMaking',
          show: this.companyConfig.permitDecisionMaking
        }, {
          label: 'Alterar dados cadastrais',
          description: 'Permite a edição de dados pessoais e endereço.',
          granted: 'allowEditRegistrationData',
          show: true
        }, {
          label: 'Gerenciar pedidos de almoço',
          description: 'Permite gerenciar pedidos de almoço',
          granted: 'manageLunchList',
          show: this.companyConfig.manageLunchList
        }, {
          label: 'Solicitar feedback',
          description: 'Permite solicitar feedback',
          granted: 'requestFeedback',
          show: true
        }]
      }, {
        title: 'Lideranças',
        permissions: [{
          label: 'Consulta dados históricos',
          description: 'Permite consultar os dados históricos de seus liderados.',
          granted: 'allowHistoricalDataMyTeam',
          show: this.companyConfig.communicationApi
        }, {
          label: 'Consulta holerites',
          description: 'Permite consultar os holerites de seus liderados.',
          granted: 'allowReceiptMyTeam',
          show: this.companyConfig.permitReceipt && (this.companyConfig.communicationApi || this.companyConfig.permitImports)
        }, {
          label: 'Consulta cartão ponto',
          description: 'Permite consultar os cartões ponto de seus liderados.',
          granted: 'allowTimeCardMyTeam',
          show: this.companyConfig.permitTimeCard && (this.companyConfig.communicationApi || this.companyConfig.permitImports)
        }, {
          label: 'Consulta sentimento do dia',
          description: 'Permite consultar o histórico de sentimento do dia de seus liderados.',
          granted: 'allowFeelingsTodayMyTeam',
          show: this.companyConfig.permitUserFeeling
        }, {
          label: 'Consulta banco de horas',
          description: 'Permite consultar bancos de horas de seus liderados.',
          granted: 'allowCompensatoryTimeMyTeam',
          show: this.companyConfig.usesCompensatoryTime && (this.companyConfig.communicationApi || this.companyConfig.permitImports)
        }, {
          label: 'Consulta compromissos',
          description: 'Permite consultar os compromissos de seus liderados.',
          granted: 'allowAppointmentMyTeam',
          show: this.accessAgenda
        }, {
          label: 'Aprovar solicitações',
          description: 'Permite aprovar/reprovar solicitações de seus liderados, antes de passar ao Gestão de Pessoas.',
          granted: 'manager',
          show: this.companyConfig.permitMessageRh
        }, {
          label: 'Consulta engajamento',
          description: 'Será possível visualizar os indicadores de engajamento dos liderados.',
          granted: 'allowIndicatorEngagementMyTeam',
          show: true
        }, {
          label: 'Consulta clima organizacional',
          description: 'Será possível visualizar os indicadores de clima organizacional dos liderados.',
          granted: 'allowIndicatorOrganizationalClimateMyTeam',
          show: true
        }, {
          label: 'Consulta desempenho',
          description: 'Será possível visualizar os indicadores de desempenho dos liderados.',
          granted: 'allowIndicatorPerformanceMyTeam',
          show: this.permission.toAccessAVD
        }, {
          label: 'Consulta perfil do time',
          description: 'Permite consultar os indicadores de perfil do time.',
          granted: 'allowIndicatorTeamProfile',
          show: true
        }, {
          label: 'Consulta time',
          description: 'Permite consultar as informações dos liderados no menu meu time.',
          granted: 'allowConsultMyTeam',
          show: true
        }, {
          label: 'Consulta salário',
          description: 'Permite consultar salário no menu membros do time',
          granted: 'allowConsultSalaryMyTeam',
          show: true
        }]
      }, {
        title: 'Gestão de pessoas',
        permissions: [{
          label: 'Consulta dados históricos',
          description: ' Permite consultar os dados históricos de todos os colaboradores.',
          granted: 'allowHistoricalDataManagement',
          show: this.companyConfig.communicationApi
        }, {
          label: 'Consulta holerites',
          description: 'Permite consultar os holerites de todos os colaboradores.',
          granted: 'allowReceiptManagement',
          show: this.companyConfig.permitReceipt && (this.companyConfig.communicationApi || this.companyConfig.permitImports)
        }, {
          label: 'Consulta cartão ponto',
          description: ' Permite consultar os cartões ponto de todos os colaboradores.',
          granted: 'allowTimeCardManagement',
          show: this.companyConfig.permitTimeCard && (this.companyConfig.communicationApi || this.companyConfig.permitImports)
        }, {
          label: 'Consulta sentimento do dia',
          description: 'Permite consultar o histórico de sentimento do dia de todos os colaboradores.',
          granted: 'allowFeelingsTodayManagement',
          show: this.companyConfig.permitUserFeeling
        }, {
          label: 'Consulta banco de horas',
          description: 'Permite consultar os bancos de horas de todos os colaboradores.',
          granted: 'allowCompensatoryTimeManagement',
          show: this.companyConfig.usesCompensatoryTime && this.companyConfig.communicationApi
        }, {
          label: 'Consulta histórico de mensagens',
          description: 'Permite consultar o histórico de mensagens de todos os usuários e todos os grupos no chat, até mesmo dos grupos que o usuário não faz parte.',
          granted: 'allowChatHistoryManagement',
          show: true
        }, {
          label: 'Gerenciar solicitações',
          description: 'Permite aprovar/reprovar as solicitações dos colaboradores',
          granted: 'profileRh',
          show: this.companyConfig.permitMessageRh
        }, {
          label: 'Gerenciar dados cadastrais',
          description: 'Permite gerenciar o cadastro dos colaboradores.',
          granted: 'allowRegistrationDataManagement',
          show: true
        }, {
          label: 'Gerenciar benefícios',
          description: 'Permite realizar o cadastro de benefícios da empresa.',
          granted: 'allowCreateBenefits',
          show: this.companyConfig.permitBenefit
        }, {
          label: 'Gerenciar políticas da empresa',
          description: 'Permite realizar o cadastro de políticas da empresa.',
          granted: 'allowCreateCompanyPolicy',
          show: this.companyConfig.permitPolicy
        }, {
          label: 'Gerenciar programas da empresa',
          description: 'Permite realizar o cadastro de programas da empresa.',
          granted: 'allowCreateCompanyProgram',
          show: this.companyConfig.permitCompanyPrograms
        }, {
          label: 'Gerenciar educação corporativa',
          description: 'Permite acessar e gerenciar todas as funcionalidades no módulo Educação Corporativa.',
          granted: 'allowManageCorporativeEdu',
          show: this.permission.toAccessTED
        }, {
          label: 'Gerenciar apenas filial',
          description: 'Permite acessar e gerenciar gestão de pessoas somente da filial em que faz parte.',
          granted: 'manageOnlyBranch',
          show: true
        }, {
          label: 'Notificação de versão',
          description: 'Permite receber e visualizar histórico de notificação de versão.',
          granted: 'allowViewVersionNotificationHistory',
          show: true
        }, {
          label: 'Gerenciar Vagas',
          description: 'Permite cadastrar as vagas a serem exibidas no Social.',
          granted: 'allowManageVacancies',
          show: this.companyConfig.permitCreateVacancyAnnouncement
        }, {
          label: 'Consulta engajamento',
          description: 'Permite consultar os indicadores de engajamento de todos os colaboradores.',
          granted: 'allowIndicatorEngagementAllUsers',
          show: true
        }, {
          label: 'Consulta clima organizacional',
          description: 'Permite consultar os indicadores de clima organizacional  de todos os colaboradores.',
          granted: 'allowIndicatorOrganizationalClimateAllUsers',
          show: true
        }, {
          label: 'Consulta desempenho',
          description: 'Permite consultar os indicadores de desempenho de todos os colaboradores.',
          granted: 'allowIndicatorPerformanceAllUsers',
          show: this.permission.toAccessAVD
        }, {
          label: 'Consulta perfil da empresa',
          description: 'Permite consultar os indicadores de perfil da empresa.',
          granted: 'allowIndicatorCompanyProfile',
          show: true
        }, {
          label: 'Importar arquivos',
          description: 'Permite realizar a importação de recibos de pagamento, cartões ponto e comprovantes de rendimentos, para exibição nas rotinas do Voors.',
          granted: 'allowImportFiles',
          show: this.companyConfig.permitImports
        }, {
          label: 'Gerenciar dashboard',
          description: 'Permite acessar os dashboard do gestão de pessoas',
          granted: 'allowDashboardHumanResources',
          show: true
        }]
      }, {
        title: 'Acessos',
        permissions: [{
          label: 'Gerenciar Gestão de Desempenho',
          description: 'Permite acessar e gerenciar todas as funcionalidades no módulo Gestão de Desempenho',
          granted: 'generalManager',
          show: this.permission.toAccessAVD
        }, {
          label: 'Acessar módulo Agenda',
          description: 'Permite acessar o módulo Agenda',
          granted: 'allowAccessSchedule',
          show: this.permission.toAccessAgenda
        }, {
          label: 'Acessar módulo Gestor de Projetos',
          description: 'Permite acessar o módulo Gestor de Projetos',
          granted: 'allowCreateProjects',
          show: this.permission.toAccessProjects
        }, {
          label: 'Acessar módulo Pesquisas',
          description: 'Permite acessar o modulo pesquisa para responder questionários',
          granted: 'allowAccessSearches',
          show: this.permission.toAccessPesquisa
        }, {
          label: 'Consultar engajamento',
          description: 'Permite consultar os indicadores de engajamento da empresa',
          granted: 'allowConsultEngagement',
          show: true
        }, {
          label: 'Criar metas',
          description: 'Permite criar metas',
          granted: 'allowCreateGoals',
          show: this.permission.toAccessAVD
        }, {
          label: 'Gerenciar metas',
          description: 'Permite atualizar o preogresso de metas',
          granted: 'allowManageGoals',
          show: this.permission.toAccessAVD
        }, {
          label: 'Gerenciar assistentes IA',
          description: 'Permite criar e gerenciar os assistentes IA',
          granted: 'allowCreateAndManageHabitusIA',
          show: this.permission.aiAssistant
        }, {
          label: 'Visualizar assistentes IA',
          description: 'Permite visualizar os assistentes IA',
          granted: 'allowShowHabitusIA',
          show: this.permission.aiAssistant
        }]
      }, {
        title: 'Pesquisa',
        permissions: [{
          label: 'Gerenciar módulo Pesquisas',
          description: 'Permite gerenciar todos os questionários do módulo Pesquisas',
          granted: 'allowManageSearches',
          show: this.permission.toAccessPesquisa && accessProfile.allowAccessSearches
        }, {
          label: 'Criar questionários',
          description: 'Permite criar e gerenciar questionários criados no módulo Pesquisas',
          granted: 'allowCreateQuestionnaires',
          show: this.permission.toAccessPesquisa && accessProfile.allowAccessSearches
        }]
      }, {
        title: 'Agenda',
        permissions: [{
          label: 'Criar compromisso público',
          description: 'Permite criar compromisso público',
          granted: 'allowCreatePublicAppointment',
          show: this.permission.toAccessAgenda && accessProfile.allowAccessSchedule
        }, {
          label: 'Reservar veículos',
          description: ' Permite fazer reservas de veículos no módulo Agenda.',
          granted: 'allowReserveVehicles',
          show: this.permission.toAccessAgenda && accessProfile.allowAccessSchedule
        }, {
          label: 'Reservar sala de reuniões',
          description: 'Permite fazer reservas de salas no módulo Agenda.',
          granted: 'allowReserveMeetingRoom',
          show: this.permission.toAccessAgenda && accessProfile.allowAccessSchedule
        }, {
          label: 'Consulta compromissos',
          description: ' Permite consultar os compromissos de todos os colaboradores.',
          granted: 'allowAppointmentManagement',
          show: this.permission.toAccessAgenda && accessProfile.allowAccessSchedule
        }, {
          label: 'Gerenciar relatórios Agenda',
          description: 'Permite acessar e gerenciar os relatórios do módulo Agenda',
          granted: 'allowScheduleReportManagement',
          show: this.permission.toAccessAgenda && accessProfile.allowAccessSchedule
        }]
      }]);
    }
    *save(permission) {
      const {
        accessProfile
      } = this.args;
      try {
        if (permission) {
          accessProfile[permission] = !accessProfile[permission];
          if (permission === 'generalManager') {
            if (!accessProfile.generalManager) {
              accessProfile.allowCreateGoals = false;
              accessProfile.allowManageGoals = false;
            } else {
              accessProfile.allowCreateGoals = true;
              accessProfile.allowManageGoals = true;
            }
          }
          if (permission === 'allowAccessSchedule' && !accessProfile.allowAccessSchedule) {
            const password = 'CONFIRMAR';
            const result = yield this.swal.fire({
              title: 'Remover acesso ao módulo Agenda',
              html: `
            Ao CONFIRMAR a operação as permissões da sessão agenda irão ser inativadas pois é necessário acesso ao módulo. <br>
              Digite "${password}" no campo abaixo para efetuar a operação.
            `,
              showCancelButton: true,
              input: 'text'
            });
            if (result.dismiss) {
              accessProfile.allowAccessSchedule = true;
              return;
            }
            if (result.value !== password) {
              accessProfile.allowAccessSchedule = true;
              return this.swal.warning(`É necessário digitar "${password}" no campo para efetuar a operação`);
            }
            accessProfile.allowAppointmentManagement = false;
            accessProfile.allowReserveMeetingRoom = false;
            accessProfile.allowReserveVehicles = false;
            accessProfile.allowCreatePublicAppointment = false;
          } else if (permission === 'allowAccessSearches' && !accessProfile.allowAccessSearches) {
            const password = 'CONFIRMAR';
            const result = yield this.swal.fire({
              title: 'Remover acesso ao módulo Pesquisas',
              html: `
            Ao CONFIRMAR a operação, as permissões da sessão para o módulo de pesquisa serão desativadas, pois é necessário acesso ao módulo. <br>
              Digite "${password}" no campo abaixo para efetuar a operação.
            `,
              showCancelButton: true,
              input: 'text'
            });
            if (result.dismiss) {
              accessProfile.allowAccessSearches = true;
              return;
            }
            if (result.value !== password) {
              accessProfile.allowAccessSearches = true;
              return this.swal.warning(`É necessário digitar "${password}" no campo para efetuar a operação`);
            }
            accessProfile.allowManageSearches = false;
            accessProfile.allowCreateQuestionnaires = false;
          } else if (permission === 'allowPublishPerBranch' && accessProfile.publishOnlyInGroup) {
            const password = 'CONFIRMAR';
            const result = yield this.swal.fire({
              title: 'Remover permissão de publicar apenas em grupo',
              html: `
            Ao CONFIRMAR a operação, a permissão "Publicar apenas em grupo" vai ser inativada. <br>
              Digite "${password}" no campo abaixo para efetuar a operação.
            `,
              showCancelButton: true,
              input: 'text'
            });
            if (result.dismiss) {
              accessProfile.allowPublishPerBranch = false;
              return;
            }
            if (result.value !== password) {
              accessProfile.allowPublishPerBranch = false;
              return this.swal.warning(`É necessário digitar "${password}" no campo para efetuar a operação`);
            }
            accessProfile.publishOnlyInGroup = false;
          } else if (permission === 'publishOnlyInGroup' && accessProfile.allowPublishPerBranch) {
            const password = 'CONFIRMAR';
            const result = yield this.swal.fire({
              title: 'Remover permissão de publicar por filiais',
              html: `
            Ao CONFIRMAR a operação, a permissão "Publicar por filiais" vai ser inativada <br>
              Digite "${password}" no campo abaixo para efetuar a operação.
            `,
              showCancelButton: true,
              input: 'text'
            });
            if (result.dismiss) {
              accessProfile.publishOnlyInGroup = false;
              return;
            }
            if (result.value !== password) {
              accessProfile.publishOnlyInGroup = false;
              return this.swal.warning(`É necessário digitar "${password}" no campo para efetuar a operação`);
            }
            accessProfile.allowPublishPerBranch = false;
          }
        }
      } catch (e) {
        this.toast.warning('Não foi possivel salvar as alterações');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accessAgenda", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sections", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sections"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "options", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = PermissionsComponent;
});