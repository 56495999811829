define("account-talentrh/pods/components/lunch-solicitation/modal-guest/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    didInsertElement() {
      this.onOpenModal();
      this.onCloseModal();
    },
    actions: {
      save(model) {
        let current = new Date();
        model.set('day', current.getDate());
        model.set('month', current.getMonth() + 1);
        model.set('year', current.getFullYear());
        model.save().then(created => {
          this.swal.success('Registro salvo com sucesso');
          this.send('closeModal');
          this.guest.pushObject(created);
        }).catch(e => this.swal.catch(e));
      },
      closeModal() {
        (0, _jquery.default)('#modal-guest').modal('toggle');
      }
    },
    createRecord: function () {
      var modelToEdit = this.modelToEdit;
      if (modelToEdit) {
        return;
      }
      if (!this.model || !this.get('model.isNew')) {
        var model = this.store.createRecord('guest-lunch-solicitation');
        this.set('model', model);
      }
    },
    onEdit: Ember.observer('modelToEdit', function () {
      var modelToEdit = this.modelToEdit;
      if (modelToEdit) {
        (0, _jquery.default)('#modal-guest').modal('toggle');
        this.set('model', modelToEdit);
      }
    }),
    onOpenModal() {
      (0, _jquery.default)('#modal-guest').on('show.bs.modal', () => {
        this.createRecord();
      });
    },
    onCloseModal: function () {
      (0, _jquery.default)('#modal-guest').on('hidden.bs.modal', () => {
        let model = this.model;
        if (model) {
          model.rollbackAttributes();
          this.set('modelToEdit', null);
          this.set('model', null);
        }
      });
    }
  });
  _exports.default = _default;
});