define("account-talentrh/pods/super-user/article/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('args', 'classOptions', {
    get() {
      const {
        article
      } = this.args;
      if (!article) {
        return '';
      }
      return this.classOptions.find(option => {
        return option.value === article.class;
      });
    },
    set(key, selected) {
      const {
        article
      } = this.args;
      if (selected && selected.value) {
        article.class = selected.value;
      }
      return selected;
    }
  }), (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _defineProperty(this, "classOptions", [{
        label: 'Líderanças',
        value: 'leadershipNews'
      }, {
        label: 'Gestão de Empresas',
        value: 'companyManagementNews'
      }, {
        label: 'Processos',
        value: 'processesNews'
      }, {
        label: 'Recursos Humanos',
        value: 'humanResourcesNews'
      }, {
        label: 'Tecnologia',
        value: 'technologyNews'
      }, {
        label: 'Marketing',
        value: 'marketingNews'
      }, {
        label: 'Comunicação',
        value: 'communicationNews'
      }, {
        label: 'Economia',
        value: 'economyNews'
      }, {
        label: 'Saúde',
        value: 'healthNews'
      }]);
      _initializerDefineProperty(this, "classSelected", _descriptor4, this);
    }
    *save() {
      const {
        article
      } = this.args;
      try {
        if (article.validations.isInvalid) {
          return this.swal.validation(article.validations);
        }
        const {
          id
        } = yield article.save();
        this.router.transitionTo('super-user.article.show', id);
        this.toast.success(`${article.isNew ? 'Notícia cadastrada!' : 'Notícia atualizada!'}`);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "classSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = FormComponent;
});