define("account-talentrh/pods/registry/general/institution/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    deactivate() {
      this.controllerFor('registry.general.institution').set('filters', {});
    }
  });
  _exports.default = _default;
});