define("account-talentrh/models/employee-evaluation-team", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    employeeEvaluation: (0, _model.belongsTo)('employee-evaluation'),
    appraisedUser: (0, _model.belongsTo)('user'),
    appraiserUser: (0, _model.belongsTo)('user'),
    appraiserAnswer: (0, _model.attr)('raw'),
    appraiserTotal: (0, _model.attr)('number'),
    appraiserConcluded: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    limitDate: Ember.computed('createdAt', function () {
      var endDate = moment(`${this.createdAt}`).endOf('month');
      return endDate;
    })
  });
  _exports.default = _default;
});