define("account-talentrh/pods/setup-wizard/steps/access-profile/manage/edit/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b3T2u0lU",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"registry/general/access-profile/form/permissions\",[],[[\"@accessProfile\",\"@showManageLunchList\"],[[32,1],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/access-profile/manage/edit/index/template.hbs"
    }
  });
  _exports.default = _default;
});