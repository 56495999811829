define("account-talentrh/pods/super-user/wizard-status/show/access-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2ZcngGE7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n  \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,0,[\"hasAccessProfile\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Perfil de Acesso\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n      \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,0,[\"hasAccessProfile\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cadastro de Perfil\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"hasAccessProfile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-md-4 d-flex\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"mt-1\"],[12],[1,[32,0,[\"wsCountRegistries\",\"accessProfileCount\"]]],[2,\" Cadastros\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"ml-4 mt-1\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"wsCountRegistries\",\"accessProfileCount\"]],3],null],\"(Perfis padrões)\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lte\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/show/access-profile/template.hbs"
    }
  });
  _exports.default = _default;
});