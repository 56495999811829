define("account-talentrh/pods/registry/general/access-profile/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralAccessProfileCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('access-profile');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('registry.general.access-profile').clearFilters();
    }
  }
  _exports.default = RegistryGeneralAccessProfileCreateRoute;
});