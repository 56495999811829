define("account-talentrh/pods/setup-wizard/steps/registry/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IEU3K3p6",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"wizard-step-control text-center mt-4\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-social btn-lg px-4 mx-2\"],[15,\"onclick\",[30,[36,2],[[32,0,[\"prevStep\"]]],null]],[14,4,\"button\"],[12],[2,\"Anterior\"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-social btn-lg px-4 mx-2\"],[15,\"onclick\",[30,[36,3],[[32,0,[\"nextStep\"]]],null]],[14,4,\"button\"],[12],[2,\"Próximo\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"fn\",\"perform\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/user/template.hbs"
    }
  });
  _exports.default = _default;
});