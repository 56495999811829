define("account-talentrh/pods/components/dashboard-gridstack/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "account-talentrh/utils/dashboard-slide-item-utils"], function (_exports, _component, _emberConcurrencyDecorators, _dashboardSlideItemUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardGridstackComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class DashboardGridstackComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "selectedGridItem", _descriptor3, this);
      _initializerDefineProperty(this, "isShowingScheduleSelection", _descriptor4, this);
      _defineProperty(this, "types", _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES);
    }
    showScheduleSelection(gridItem) {
      this.selectedGridItem = gridItem;
      this.isShowingScheduleSelection = !this.isShowingScheduleSelection;
    }
    onChange(event, params) {
      this.args.onRealocate(params[0], this.args.slideIndex);
    }
    didInsert() {
      const {
        slideIndex
      } = this.args;
      let div = document.querySelector(`.dashboard[data-slide="${slideIndex}"]`);
      if (!div) {
        div = document.querySelector(`.dashboard-template-body[data-slide="${slideIndex}"]`);
      }
      div.addEventListener('click', e => {
        if (!e.target) return;
        const {
          classList
        } = e.target;

        // por mais que a propriedade seja o data-gs-id ele pega o
        // index por conta de quando é uma criação ele não ter id
        if (classList.contains('grid-stack-item-content')) {
          const itemIndex = e.target.parentElement.getAttribute('data-gs-id');
          if (this.args.onSelect) {
            this.args.onSelect(Number(itemIndex), slideIndex);
          }
        } else if (classList.contains('schedule-image-wrapper')) {
          const itemIndex = e.target.parentElement.parentElement.getAttribute('data-gs-id');
          if (this.args.onSelect) {
            this.args.onSelect(Number(itemIndex), slideIndex);
          }
        }
      });
    }
    *add(type, data) {
      if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ALBUM) {
        this.selectedGridItem.title = data.title || 'Álbum de Fotos';
        this.selectedGridItem.dashboardSlideItemAlbum = yield this.store.createRecord('dashboard-slide-item-album', {
          companyAlbum: yield this.store.peekRecord('company-album', data.companyAlbum.id),
          dashboardSlideItem: this.selectedGridItem,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ADMISSION) {
        this.selectedGridItem.title = data.title || 'Aniversariantes de empresa';
        this.selectedGridItem.dashboardSlideItemAdmission = yield this.store.createRecord('dashboard-slide-item-admission', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.BIRTHDAY) {
        this.selectedGridItem.title = data.title || 'Aniversariantes';
        this.selectedGridItem.dashboardSlideItemBirthday = yield this.store.createRecord('dashboard-slide-item-birthday', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ABSENCE) {
        this.selectedGridItem.title = data.title || 'Ausências';
        this.selectedGridItem.dashboardSlideItemAbsence = yield this.store.createRecord('dashboard-slide-item-absence', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.AD) {
        this.selectedGridItem.title = data.title || 'Classificados';
        this.selectedGridItem.dashboardSlideItemAds = yield this.store.createRecord('dashboard-slide-item-ad', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.ANNOUNCEMENT) {
        this.selectedGridItem.title = data.title || 'Comunicados';
        this.selectedGridItem.dashboardSlideItemAnnouncement = yield this.store.createRecord('dashboard-slide-item-announcement', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.EVENT) {
        this.selectedGridItem.title = data.title || 'Eventos';
        this.selectedGridItem.dashboardSlideItemEvent = yield this.store.createRecord('dashboard-slide-item-event', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.IMAGE) {
        this.selectedGridItem.title = data.title || 'Imagem';
        this.selectedGridItem.dashboardSlideItemImage = yield this.store.createRecord('dashboard-slide-item-image', {
          url: data.url,
          signUrl: data.signUrl,
          path: data.path,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.VIDEO) {
        this.selectedGridItem.title = data.title || 'Vídeo';
        this.selectedGridItem.dashboardSlideItemVideo = yield this.store.createRecord('dashboard-slide-item-video', {
          url: data.url,
          signUrl: data.signUrl,
          path: data.path,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.LINK) {
        this.selectedGridItem.title = data.title || 'Link';
        this.selectedGridItem.dashboardSlideItemLink = yield this.store.createRecord('dashboard-slide-item-link', {
          url: data.url,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.RECENT_USERS) {
        this.selectedGridItem.title = data.title || 'Novos colaboradores';
        this.selectedGridItem.dashboardSlideItemRecentUser = yield this.store.createRecord('dashboard-slide-item-recent-user', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      } else if (type === _dashboardSlideItemUtils.DASHBOARD_SLIDE_ITEM_TYPES.VACANCY) {
        this.selectedGridItem.title = data.title || 'Vagas';
        this.selectedGridItem.dashboardSlideItemVacancy = yield this.store.createRecord('dashboard-slide-item-vacancy', {
          branches: data.branches ? data.branches : null,
          hasExpireDate: data.hasExpireDate,
          expireDateStart: data.expireDateStart,
          expireDateEnd: data.expireDateEnd,
          dashboardSlideItem: this.selectedGridItem
        });
      }
      this.selectedGridItem.type = type;
      this.selectedGridItem.color = data.color;
      this.selectedGridItem.refreshTimeInSeconds = data.refreshTimeInSeconds;
      this.showScheduleSelection();
    }
    *onDelete(index, slideIndex) {
      const {
        onDelete
      } = this.args;
      try {
        const {
          value
        } = yield this.swal.confirm('Isso irá remover permanentemente a programação, deseja continuar?');
        if (!value) {
          return;
        }
        onDelete(index, slideIndex);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedGridItem", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isShowingScheduleSelection", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showScheduleSelection", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showScheduleSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype)), _class));
  _exports.default = DashboardGridstackComponent;
});