define("account-talentrh/pods/configuration/nela/book/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.book.title) {
        return this.swal.warning('É necessário informar um título');
      }
      let message = this.book.isNew ? 'Livro cadastrado' : 'Livro atualizado';
      try {
        yield this.book.save();
        this.toast.success(message);
        this.router.transitionTo('configuration.nela.book');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});