define("account-talentrh/pods/registry/general/job-function/form/professional-progressions/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfessionalProgressionsComponent = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.inject.service(), _dec6 = Ember.computed.alias('args.jobFunction'), _dec7 = Ember.computed.alias('args.jobFunction.professionalProgressions'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ProfessionalProgressionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "toast", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "session", _descriptor5, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor6, this);
      _initializerDefineProperty(this, "professionalProgressions", _descriptor7, this);
      _initializerDefineProperty(this, "modalTitle", _descriptor8, this);
      _initializerDefineProperty(this, "openModal", _descriptor9, this);
      _initializerDefineProperty(this, "newProfessionalProgression", _descriptor10, this);
      _initializerDefineProperty(this, "nextLevels", _descriptor11, this);
    }
    *validateIfProgressionAlreadyOnSector() {
      try {
        const progressionsOnSector = yield this.store.query('professional-progression', {
          jobFunction: this.jobFunction.get('id'),
          sector: this.newProfessionalProgression.sector.get('id'),
          board: this.newProfessionalProgression.board.get('id')
        });
        if (progressionsOnSector.length > 0) {
          this.swal.error('Está função já foi cadastrada para este setor.');
          return false;
        }
        return true;
      } catch (e) {
        this.swal.error(e);
      }
    }
    *validateLevel() {
      const sectorId = (yield this.newProfessionalProgression.sector).id;
      const boardId = (yield this.newProfessionalProgression.board).id;
      const query = {
        sectorId,
        boardId
      };
      const progressionsOnSameBoardAndSector = yield this.store.query('professional-progression', query);
      const progressionWithNextLevel = progressionsOnSameBoardAndSector.find(progression => {
        let existsInNextLevel = false;
        progression.jobFunctionsForNextLevel.forEach(nextLevel => {
          if (nextLevel.id === this.jobFunction.get('id')) {
            existsInNextLevel = true;
          }
        });
        return existsInNextLevel;
      });
      if (progressionWithNextLevel && progressionWithNextLevel.level >= this.newProfessionalProgression.level) {
        return false;
      }
      return true;
    }
    *save() {
      try {
        if (!this.newProfessionalProgression.validations.isValid) {
          return this.swal.validation(this.newProfessionalProgression.validations);
        }
        if (this.newProfessionalProgression.isNew && !(yield this.validateIfProgressionAlreadyOnSector.perform())) {
          return;
        }
        const valid = yield this.validateLevel.perform();
        if (!valid) {
          return this.swal.warning(`Já existe uma função de nível ${this.newProfessionalProgression.level} neste progressão profissional.`);
        }
        this.newProfessionalProgression.jobFunctionsForNextLevel = this.nextLevels;
        yield this.newProfessionalProgression.save();
        if (this.newProfessionalProgression.isNew) {
          this.toast.success('Progressão profissional cadastrada');
        } else {
          this.toast.success('Progressão profissional editada');
        }
        this.openModal = false;
      } catch (e) {
        this.swal.error(e);
      }
    }
    *remove(professionalProgression) {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover progressão profissional?');
        if (!value) {
          return;
        }
        professionalProgression.deleteRecord();
        professionalProgression.save();
        this.professionalProgressions.removeObject(professionalProgression);
        this.toast.success('Progressão profissional removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    closeModal() {
      this.newProfessionalProgression.rollbackAttributes();
    }
    async openEditModal(professionalProgression) {
      this.newProfessionalProgression = professionalProgression;
      this.nextLevels = await professionalProgression.jobFunctionsForNextLevel;
      this.newProfessionalProgression.new = false;
      this.newProfessionalProgression.jobFunction = this.jobFunction;
      this.newProfessionalProgression.company = this.session.company;
      this.modalTitle = 'Editar Progressão Profissional';
      this.openModal = true;
    }
    openCreateModal() {
      this.newProfessionalProgression = this.store.createRecord('professionalProgression', {
        level: 1,
        jobFunction: this.jobFunction
      });
      this.newProfessionalProgression.new = true;
      this.modalTitle = 'Incluir Progressão Profissional';
      this.openModal = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "professionalProgressions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newProfessionalProgression", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "nextLevels", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validateIfProgressionAlreadyOnSector", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "validateIfProgressionAlreadyOnSector"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateLevel", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "validateLevel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openEditModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateModal", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateModal"), _class.prototype)), _class));
  _exports.default = ProfessionalProgressionsComponent;
});