define("account-talentrh/models/car", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    model: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha o modelo'
    }),
    brand: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha a marca'
    }),
    plate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha a placa'
    }),
    responsibles: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Preencha o(s) responsável(eis)'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    brand: (0, _model.attr)('string'),
    buyDate: (0, _model.attr)('timestamp'),
    color: (0, _model.attr)('string'),
    km: (0, _model.attr)('number'),
    manufacturingYear: (0, _model.attr)('string'),
    model: (0, _model.attr)('string'),
    modelYear: (0, _model.attr)('string'),
    needsManagerApproval: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    plate: (0, _model.attr)('string'),
    renavam: (0, _model.attr)('string'),
    sellDate: (0, _model.attr)('timestamp'),
    showOnCalendar: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    // Relationships
    branch: (0, _model.belongsTo)('branch'),
    company: (0, _model.belongsTo)('company'),
    department: (0, _model.belongsTo)('department'),
    responsibles: (0, _model.hasMany)('user')
  });
  _exports.default = _default;
});