define("account-talentrh/pods/forgot-password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ForgotPasswordRoute extends Ember.Route {
    model() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let trasition = arguments.length > 1 ? arguments[1] : undefined;
      return trasition.to.queryParams.from;
    }
  }
  _exports.default = ForgotPasswordRoute;
});