define("account-talentrh/pods/setup-wizard/steps/registry/user/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DGSlPcDS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n  \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"col-md-8 py-4 position-relative\"],[12],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n    O Cadastro de Usuários funciona para adicionar os colaboradores que terão \\n    acesso ao Voors. Você poderá incluir ao Cadastro de Usuário o Perfil de Acesso que \\n    determinará como cada colaborador irá interagir com a plataforma. Os colaboradores \\n    cadastrados receberão um e-mail contendo um link para completar seu cadastro no Voors.\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-4\"],[14,\"src\",\"https://player.vimeo.com/video/757138173?h=b51efc2d3b\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n    Além do cadastro manual é possível realizar a importação de dados em massa a partir de um modelo.\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"wizard-step-control text-center mt-4\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social\"]],[[\"@route\"],[\"setup-wizard.steps.registry.user.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cadastrar usuário\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/user/index/template.hbs"
    }
  });
  _exports.default = _default;
});