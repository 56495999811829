define("account-talentrh/pods/components/image-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "y+CKKPJg",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"input\"],[16,\"onchange\",[30,[36,0],[[32,0,[\"fileDidChange\"]]],null]],[17,1],[24,4,\"file\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/image-uploader/template.hbs"
    }
  });
  _exports.default = _default;
});