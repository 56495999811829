define("account-talentrh/pods/setup-wizard/steps/company/benefits/manage/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qHfLSeQH",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"configuration/nela/benefit/form\",[],[[\"@benefit\",\"@useInWizard\"],[[32,1],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/company/benefits/manage/new/template.hbs"
    }
  });
  _exports.default = _default;
});