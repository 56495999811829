define("account-talentrh/pods/super-user/article/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SuperUserArticleEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('super-user.article').clearFilters();
    }
  }
  _exports.default = SuperUserArticleEditRoute;
});