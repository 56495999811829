define("account-talentrh/pods/registry/general/user/show/history/job-role/item/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ItemComponent = (_dec = Ember.computed('args'), (_class = class ItemComponent extends _component.default {
    get dateFormatted() {
      const {
        history
      } = this.args;
      return _moment.default.utc(history.date, 'MM-DD-YYYY').format('DD/MM/YYYY');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "dateFormatted", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dateFormatted"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});