define("account-talentrh/pods/registry/social/vacancy/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed.alias('model'), _dec9 = Ember.computed.alias('permission.admin'), _dec10 = Ember.computed.alias('permission.accessProfile'), _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "utils", _descriptor6, this);
      _initializerDefineProperty(this, "permission", _descriptor7, this);
      _initializerDefineProperty(this, "vacancy", _descriptor8, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor9, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor10, this);
      _initializerDefineProperty(this, "losers", _descriptor11, this);
      _initializerDefineProperty(this, "showFormTopic", _descriptor12, this);
      _initializerDefineProperty(this, "topic", _descriptor13, this);
      _initializerDefineProperty(this, "permitSelectAllBranches", _descriptor14, this);
      _initializerDefineProperty(this, "description", _descriptor15, this);
      _initializerDefineProperty(this, "advancedEditorVideos", _descriptor16, this);
    }
    validSelectAllBranches() {
      if (this.isAdmin) {
        this.permitSelectAllBranches = true;
      } else if (!this.accessProfile.manageOnlyBranch) {
        this.permitSelectAllBranches = true;
      }
    }
    clearInputSelect() {
      let {
        vacancy
      } = this.args;
      if (vacancy.jobRoles.length) {
        this.args.vacancy.jobRoles = [];
      }
      if (vacancy.branches.length) {
        this.args.vacancy.branches = [];
      }
    }
    *save() {
      try {
        let {
          vacancy
        } = this.args;
        let isNew = vacancy.isNew;
        if (!vacancy.validations.isValid) {
          return this.swal.validation(vacancy.validations);
        }
        if (!vacancy.applicationdeadline) {
          return this.swal.warning('Informe o prazo para inscrição');
        }
        if (vacancy.registrationlink) {
          const links = vacancy.registrationlink.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
          if (!links) {
            return this.swal.warning('Formato inválido, utilize o http:// antes do link. Exemplo: http://Voors.com.br');
          }
        }
        let applicationdeadline = (0, _moment.default)(vacancy.applicationdeadline).format('DD/MM/YYYY');
        let bodyLink = '<p><b>Link para inscrição: </b><a href="' + vacancy.registrationlink + '" target="_blank">' + vacancy.registrationlink + '</a></p>';
        let body = '<p><b>Prazo para inscrição: </b>' + applicationdeadline + '</p>';
        if (vacancy.registrationlink) {
          body = body + bodyLink;
        }
        if (vacancy.description) {
          body = vacancy.description + '<br/>' + body;
        }
        if (isNew) {
          let postTemplate = this.store.createRecord('post-template', {
            type: 'vacancy',
            typeLabel: 'Vaga',
            body: body,
            link: vacancy.registrationlink,
            title: 'Vaga ' + vacancy.title,
            applicationdeadline: applicationdeadline
          });
          yield postTemplate.save();
          vacancy.postTemplate = postTemplate;
        } else {
          let postTemplate = yield this.store.findRecord('post-template', Number(vacancy.postTemplate.get('id')));
          postTemplate.type = 'vacancy';
          postTemplate.typeLabel = 'Vaga';
          postTemplate.body = body;
          postTemplate.link = vacancy.registrationlink;
          postTemplate.title = 'Vaga ' + vacancy.title;
          postTemplate.applicationdeadline = applicationdeadline;
          yield postTemplate.save();
          vacancy.postTemplate = postTemplate;
        }
        if (!vacancy.onAllBranches && !vacancy.branches.length) {
          vacancy.noBranch = true;
        } else {
          vacancy.noBranch = false;
        }
        yield vacancy.save();
        this.toast.success('Registro salvo!');
        this.router.transitionTo('registry.social.vacancy.show', vacancy.id);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *changeJobRole() {
      const {
        vacancy
      } = this.args;
      if (vacancy.jobRoles && vacancy.jobRoles.length > 0) {
        vacancy.description = '<p>' + (vacancy.jobRoles.firstObject.description ? vacancy.jobRoles.firstObject.description : '') + '</p>';
        if (vacancy.jobRoles.length > 1) {
          let {
            value
          } = yield this.swal.confirm('Ao selecionar outro cargo, a descrição da vaga será alterada.');
          vacancy.jobRoles.forEach((jobRole, index) => {
            if (!value) {
              if (index > 0) {
                vacancy.jobRoles.removeObject(jobRole);
              }
            } else {
              if (index !== vacancy.jobRoles.length) {
                vacancy.jobRoles.removeObject(jobRole);
              }
            }
          });
          vacancy.description = vacancy.jobRoles.firstObject.description;
        }
      } else {
        vacancy.description = '';
      }
    }
    *changejobFunction() {
      const {
        vacancy
      } = this.args;
      if (vacancy.jobFunctions && vacancy.jobFunctions.length > 0) {
        vacancy.description = '<p>' + (vacancy.jobFunctions.firstObject.description ? vacancy.jobFunctions.firstObject.description : '') + '</p>';
        if (vacancy.jobFunctions.length > 1) {
          let {
            value
          } = yield this.swal.confirm('Ao selecionar outra função, a descrição da vaga será alterada.');
          vacancy.jobFunctions.forEach((jobFunction, index) => {
            if (!value) {
              if (index > 0) {
                vacancy.jobFunctions.removeObject(jobFunction);
              }
            } else {
              if (index !== vacancy.jobFunctions.length) {
                vacancy.jobFunctions.removeObject(jobFunction);
              }
            }
          });
          vacancy.description = vacancy.jobFunctions.firstObject.description;
        }
      } else {
        vacancy.description = '';
      }
    }
    onSelectAllBranches() {
      let {
        vacancy
      } = this.args;
      vacancy.onAllBranches = !vacancy.onAllBranches;
      if (vacancy.onAllBranches) {
        vacancy.branches = [];
      }
    }
    afterUploading(response) {
      this.store.pushPayload(response);
    }
    click(item) {
      document.getElementById(item).click();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "vacancy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "losers", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showFormTopic", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "topic", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "permitSelectAllBranches", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "advancedEditorVideos", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validSelectAllBranches", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "validSelectAllBranches"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInputSelect", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "clearInputSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeJobRole", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeJobRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changejobFunction", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "changejobFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAllBranches", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAllBranches"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterUploading", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = FormComponent;
});