define("account-talentrh/pods/super-user/wizard-status/show/chat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X8DBqeY5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group mt-4 ml-4\"],[12],[2,\"\\n  \"],[8,\"ui-checkbox\",[[24,0,\"d-block mb-4\"]],[[\"@disabled\",\"@checked\"],[true,[32,0,[\"hasChat\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Chat\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/wizard-status/show/chat/template.hbs"
    }
  });
  _exports.default = _default;
});