define("account-talentrh/utils/dashboard-slide-item-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DASHBOARD_SLIDE_ITEM_TYPES = void 0;
  const DASHBOARD_SLIDE_ITEM_TYPES = {
    BIRTHDAY: 'birthday',
    ALBUM: 'album',
    ADMISSION: 'admission',
    ABSENCE: 'absence',
    AD: 'ad',
    ANNOUNCEMENT: 'announcement',
    EVENT: 'event',
    VACANCY: 'vacancy',
    RECENT_USERS: 'recent_users',
    IMAGE: 'image',
    VIDEO: 'video',
    LINK: 'link'
  };
  _exports.DASHBOARD_SLIDE_ITEM_TYPES = DASHBOARD_SLIDE_ITEM_TYPES;
});