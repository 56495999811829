define("account-talentrh/pods/registry/general/job-role/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralJobRoleShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.general.job-role').clearFilters();
    }
  }
  _exports.default = RegistryGeneralJobRoleShowRoute;
});