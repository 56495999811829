define("account-talentrh/pods/registry/avd/type-answer/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    descriptionMessageIsNull: Ember.computed.equal('typeAnswer.description', undefined),
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.typeAnswer.name) {
        return this.swal.warning('É necessário informar um título');
      }
      let message = this.typeAnswer.isNew ? 'Tipo de resposta cadastrado' : 'Tipo de resposta atualizado';
      try {
        let {
          id
        } = yield this.typeAnswer.save();
        this.toast.success(message);
        this.router.transitionTo('registry.avd.type-answer.show', id);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});