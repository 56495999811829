define("account-talentrh/pods/reports/team-management/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AyEVEChl",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"userFullName\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"userFullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"email\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"email\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"branch\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"branch\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"department\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"department\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"jobRole\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"jobRole\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"jobFunction\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"jobFunction\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"appraiserFullName\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"appraiserFullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"accessProfileTitle\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"accessProfileTitle\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-right text-nowrap max-w-100\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-sm btn-outline-primary\"],[24,\"title\",\"Editar\"]],[[\"@route\",\"@model\"],[\"reports.team-management.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/reports/team-management/item/template.hbs"
    }
  });
  _exports.default = _default;
});