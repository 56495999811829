define("account-talentrh/pods/settings/avd/ninebox-settings/quadrant-scale-form/line/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SettingsAvdNineboxSettingsQuadrantScaleFormLineComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed.alias('args.lineIndex'), _dec5 = Ember.computed('args.lines.@each{initialValue,finalValue}'), _dec6 = Ember.computed('args.line.{initialValue,finalValue}'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class SettingsAvdNineboxSettingsQuadrantScaleFormLineComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "initialValue", _descriptor2, this);
      _initializerDefineProperty(this, "finalValue", _descriptor3, this);
      _initializerDefineProperty(this, "lineIndex", _descriptor4, this);
    }
    // Multiline computed
    get lines() {
      return this.args.lines;
    }
    get line() {
      return this.args.line;
    }

    // Methods
    validateInitialValue(initialValue) {
      if (this.line.finalValue <= initialValue) {
        throw new Error('Valor inicial tem que ser menor que valor final.');
      }
      if (initialValue > 1) {
        throw new Error('Valor inicial não pode ser maior que 100%');
      }
    }
    validateFinalValue(finalValue) {
      if (this.line.initialValue >= finalValue) {
        throw new Error('Valor final tem que ser maior que valor inicial.');
      }
      if (finalValue > 10) {
        throw new Error('Valor final não pode ser maior que 1000%');
      }
    }
    validateNextLineValue(finalValue) {
      if (this.lines.length - 1 === this.lineIndex) return;
      const nextLine = this.lines.objectAt(this.lineIndex + 1);
      if (finalValue >= nextLine.initialValue) {
        throw new Error(`Valor final da linha "${this.line.title}" é maior ou igual a valor inicial da linha "${nextLine.title}".`);
      }
    }
    validatePreviousLineValue(initialValue) {
      if (this.lineIndex === 0) return;
      const previousLine = this.lines.objectAt(this.lineIndex - 1);
      if (previousLine.finalValue >= initialValue) {
        throw new Error(`Valor final da linha "${previousLine.title}" é maior ou igual a valor inicial da linha "${this.line.title}".`);
      }
    }
    precisionRoundMod(number, precision) {
      const factor = Math.pow(10, precision);
      const n = precision < 0 ? number : 0.01 / factor + number;
      return Math.round(n * factor) / factor;
    }
    getGapValue(valueA, valueB) {
      const gapValue = valueA - valueB;
      return this.precisionRoundMod(gapValue, 4);
    }
    validateFinalValueGap(finalValue) {
      const {
        initialValue,
        title
      } = this.lines.objectAt(this.lineIndex + 1);
      const gapValue = this.getGapValue(initialValue, finalValue);
      if (gapValue >= 0.0002 || gapValue < 0.0001) {
        throw new Error(`Não pode existir intervalo de valor final da linha "${this.line.title}" para o valor inicial da linha "${title}"`);
      }
    }
    validateInitialValueGap(initialValue) {
      if (this.lineIndex === 0) return;
      const {
        finalValue,
        title
      } = this.lines.objectAt(this.lineIndex - 1);
      const gapValue = this.getGapValue(initialValue, finalValue);
      if (gapValue >= 0.0002 || gapValue < 0.0001) {
        throw new Error(`Não pode existir intervalo de valor inicial da linha "${this.line.title}" para o valor final da linha "${title}"`);
      }
    }
    changeNineBoxIsValid(isValid) {
      if (this.args.onNineBoxIsValidChange) {
        this.args.onNineBoxIsValidChange(isValid);
      }
    }
    // Actions
    initValues() {
      this.initialValue = this.line.initialValue;
      this.finalValue = this.line.finalValue;
    }
    initialValueChange(initialValue) {
      try {
        this.line.initialValue = this.initialValue;
        this.validateInitialValue(initialValue);
        this.validatePreviousLineValue(initialValue);
        this.validateInitialValueGap(initialValue);
      } catch (e) {
        this.swal.error(e.message);
      }
    }
    finalValueChange(finalValue) {
      try {
        this.line.finalValue = this.finalValue;
        this.validateFinalValue(finalValue);
        this.validateNextLineValue(finalValue);
        this.validateFinalValueGap(finalValue);
      } catch (e) {
        this.swal.error(e.message);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initialValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "finalValue", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lineIndex", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lines", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "lines"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "line", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "line"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initValues", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "initValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initialValueChange", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initialValueChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finalValueChange", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "finalValueChange"), _class.prototype)), _class));
  _exports.default = SettingsAvdNineboxSettingsQuadrantScaleFormLineComponent;
});