define("account-talentrh/pods/registry/social/mural/gridstack/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialMuralGridstackEditRoute extends Ember.Route {
    model(params) {
      const {
        slideItemId
      } = params;
      return this.store.findRecord('dashboard-slide-item', slideItemId);
    }
    afterModel(model, transition) {
      const {
        type
      } = transition.to.queryParams;
      const models = {
        birthday: 'dashboardSlideItemBirthday',
        album: 'dashboardSlideItemAlbum',
        admission: 'dashboardSlideItemAdmission',
        absence: 'dashboardSlideItemAbsence',
        ad: 'dashboardSlideItemAds',
        announcement: 'dashboardSlideItemAnnouncement',
        event: 'dashboardSlideItemEvent',
        vacancy: 'dashboardSlideItemVacancy',
        recent_users: 'dashboardSlideItemRecentUser',
        image: 'dashboardSlideItemImage',
        video: 'dashboardSlideItemVideo',
        link: 'dashboardSlideItemLink'
      };
      this.controllerFor('registry.social.mural.gridstack.edit').set('slideItemContent', model.get(models[type]));
    }
  }
  _exports.default = RegistrySocialMuralGridstackEditRoute;
});