define("account-talentrh/pods/super-user/wizard-status/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SuperUserWizardStatusShowController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('model.wizardStatus'), (_class = class SuperUserWizardStatusShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "wizardStatus", _descriptor2, this);
    }
    get steps() {
      return [{
        title: 'Feed',
        route: 'index'
      }, {
        title: 'Colaboração',
        route: 'collaboration'
      }, {
        title: 'Chat',
        route: 'chat'
      }, {
        title: 'Perfil de Acesso',
        route: 'access-profile'
      }, {
        title: 'Cadastros',
        route: 'registrations'
      }, {
        title: 'Solicitações',
        route: 'requests'
      }, {
        title: 'Empresa',
        route: 'company'
      }, {
        title: 'Grupos',
        route: 'groups'
      }];
    }
    get status() {
      if (this.wizardStatus.status === 'done') {
        return 'Empresa não utilizou o wizard para cadastro';
      }
      if (this.wizardStatus.status === 'pending') {
        return 'Pendente';
      }
      return 'Andamento';
    }
    checkStep() {
      if (this.wizardStatus.done) {
        this.router.transitionTo('super-user.wizard-status.show.index');
      } else {
        let currentStep = this.steps[this.wizardStatus.step];
        this.router.transitionTo(`super-user.wizard-status.show.${currentStep.route}`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wizardStatus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SuperUserWizardStatusShowController;
});