define("account-talentrh/components/slick-slider", ["exports", "ember-cli-slick/components/slick-slider"], function (_exports, _slickSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _slickSlider.default;
    }
  });
});