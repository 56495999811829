define("account-talentrh/pods/settings/avd/evaluation-skills-questions/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SettingsAvdEvaluationSkillsQuestionsController = (_dec = Ember.computed.alias('model'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('alternatives.length'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class SettingsAvdEvaluationSkillsQuestionsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "questionnaire", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "alternatives", _descriptor5, this);
    }
    get canAddAlternative() {
      return this.alternatives.length < 5;
    }
    getAlternatives() {
      return this.alternatives.map(alternative => {
        return {
          ...alternative,
          percentage: alternative.percentage * 100
        };
      });
    }
    *save() {
      try {
        if (this.alternatives.length <= 1) {
          return this.swal.warning("É preciso ter pelo menos duas alternativas");
        }
        const alternativesWithoutDescriptionOrPercentage = this.alternatives.filter(alternative => !alternative.description || alternative.percentage === null || alternative.percentage === undefined);
        if (alternativesWithoutDescriptionOrPercentage.length) {
          return this.swal.warning("As alternativas precisam ter o título e a porcetagem");
        }
        this.questionnaire.pages[0].questions[0].alternatives = this.getAlternatives();
        yield this.ajax.put(`questionnaires/${this.questionnaire.id}`, {
          data: {
            questionnaire: this.questionnaire
          }
        });
        this.router.transitionTo('settings.avd');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onValueChange(index, value) {
      const alternativeSize = this.questionnaire.pages[0].questions[0].alternatives.length - 1;
      if (alternativeSize < index && value > 100) {
        return this.swal.warning('A porcetagem não pode ser superior a 100%');
      }
      const alternative = this.questionnaire.pages[0].questions[0].alternatives[index];
      Ember.set(alternative, 'percentage', value);
    }
    closeModal() {
      this.router.transitionTo('settings.avd');
    }
    deleteAlternative(index) {
      const alternatives = [...this.alternatives];
      alternatives.splice(index, 1);
      this.alternatives = Ember.A(alternatives);
    }
    addAlternative() {
      const lastAlternative = this.alternatives[this.alternatives.length - 1];
      this.alternatives.pushObject({
        description: "",
        definition: "",
        id: lastAlternative.id + 1,
        ignoreToCalculate: false,
        needJustification: false,
        percentage: 0.00,
        last: false
      });
      console.log(this.alternatives);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "questionnaire", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "alternatives", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canAddAlternative", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canAddAlternative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onValueChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onValueChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteAlternative", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAlternative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAlternative", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addAlternative"), _class.prototype)), _class));
  _exports.default = SettingsAvdEvaluationSkillsQuestionsController;
});