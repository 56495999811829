define("account-talentrh/pods/super-user/companies/show/controller", ["exports", "ember-concurrency-decorators", "vanilla-masker"], function (_exports, _emberConcurrencyDecorators, _vanillaMasker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SuperUserCompaniesShowController = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember.inject.service(), _dec6 = Ember.computed.alias('model.company'), _dec7 = Ember.computed.alias('model.companyConfig'), _dec8 = Ember.computed.alias('model.subscription'), _dec9 = Ember.computed('model.company.cnpj'), (_class = class SuperUserCompaniesShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "company", _descriptor6, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor7, this);
      _initializerDefineProperty(this, "subscription", _descriptor8, this);
    }
    get companyCnpj() {
      if (!this.company.cnpj) {
        return '';
      }
      let pattern = this.company.type === 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99';
      return _vanillaMasker.default.toPattern(this.company.cnpj, pattern);
    }
    get companyPhone() {
      if (!this.company.phone) {
        return '';
      }
      return _vanillaMasker.default.toPattern(this.company.phone, '(99) 99999-9999');
    }
    get completeAddress() {
      let address = '';
      if (this.company.address) {
        address += this.company.address;
      }
      if (this.company.number) {
        address += ` ${this.company.number}`;
      }
      if (this.company.complement) {
        address += ` - ${this.company.complement}`;
      }
      if (this.company.neighborhood) {
        address += `, ${this.company.neighborhood}`;
      }
      return address;
    }
    *toggleActivity() {
      this.toggleProperty('subscription.active');
      this.toggleProperty('company.active');
      if (!this.subscription.active) {
        this.company.active = false;
        this.companyConfig.featureFlags = {};
        this.subscription.status = 'blocked';
        this.subscription.viasellCustomModules = [];
        this.subscription.viasellCustomUsersLimit = 0;
      }
      let message = this.subscription.active ? 'Empresa ativada' : 'Empresa inativada';
      try {
        yield Ember.RSVP.all([this.ajax.put(`companyConfigurations/${this.companyConfig.id}/updateBySuperUser`, {
          data: {
            companyConfiguration: JSON.stringify(this.companyConfig)
          }
        }), this.subscription.save()]);
        this.toast.success(message);
      } catch (e) {
        this.subscription.rollbackAttributes();
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "subscription", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "companyCnpj", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "companyCnpj"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleActivity", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "toggleActivity"), _class.prototype)), _class));
  _exports.default = SuperUserCompaniesShowController;
});