define("account-talentrh/pods/setup-wizard/steps/solicitation/manager-flow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HoMe79pZ",
    "block": "{\"symbols\":[\"Card\",\"setting\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n        \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-8 py-4\"],[12],[2,\"\\n        \"],[10,\"h5\"],[14,0,\"font-weight-bold text-social\"],[12],[2,\"Defina qual  solicitação o gestor poderá cadastrar para os seus liderados. Esta solicitação passará por aprovação do setor de RH. Por padrão, todas as solicitações estão liberadas para uso do gestor. Caso não queira utilizar, desmarque a opção “Utilizar”.\"],[13],[2,\"\\n        \"],[10,\"ul\"],[14,0,\"list-group list-group-flush list-unstyled mt-4\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"settingsManager\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"settings/details/manage-requests/item\",[],[[\"@settingManager\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"setup-wizard/step-control\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/solicitation/manager-flow/template.hbs"
    }
  });
  _exports.default = _default;
});