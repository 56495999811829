define("account-talentrh/pods/super-user/post/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iJVdTYwJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"super-user/post/form\",[],[[\"@postTemplate\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/post/create/template.hbs"
    }
  });
  _exports.default = _default;
});