define("account-talentrh/pods/setup-wizard/steps/groups/link/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eUdIgyXS",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h4\"],[14,0,\"text-center text-social mb-4\"],[12],[2,\"Habilitar a criação e exibição de links externos?\"],[13],[2,\"\\n\\n\"],[8,\"setup-wizard/radio-buttons\",[],[[\"@selected\",\"@onChange\"],[[32,1,[\"permitExternalLink\"]],[30,[36,1],[[30,[36,0],[[32,1,[\"permitExternalLink\"]]],null]],null]]],null],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n  Disponibilize, através do menu Links Externos, atalhos para sites importantes \\n  e para sistemas ou recursos corporativos externos. A exibição dos links externos \\n  proporciona agilidade na busca pela informação.\\n\"],[13],[2,\"\\n\\n\"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-4\"],[14,\"src\",\"https://player.vimeo.com/video/756948395?h=000e60e8ad\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  A visualização dos Links Externos pode ser personalizada por usuários ou grupos de usuários.\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"permitExternalLink\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-social mt-3\"]],[[\"@route\"],[\"setup-wizard.steps.groups.link.manage\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cadastrar Link Externo\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/groups/link/index/template.hbs"
    }
  });
  _exports.default = _default;
});