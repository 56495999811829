define("account-talentrh/pods/registry/avd/rating-notes/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oNyqU0MD",
    "block": "{\"symbols\":[\"Card\",\"item\",\"@rating\",\"&attrs\",\"@setTitleManual\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm my-3\"],[17,4]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[\"fas \",[32,3,[\"icon\"]],\" fa-2x text-warning text-center\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row mt-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"title\"],[12],[2,\"Título\"],[13],[2,\"\\n        \"],[8,\"input\",[[24,1,\"title\"],[24,0,\"form-control\"],[24,\"autocomplete\",\"off\"],[16,\"disabled\",[32,0,[\"disableInputTitle\"]]]],[[\"@placeholder\",\"@value\"],[\"Informe o título\",[32,3,[\"title\"]]]],null],[2,\"\\n\"],[6,[37,2],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"small\"],[12],[2,\"Exemplos: Não atende, atende parcial, atende, atende com excelência.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"percentage\"],[12],[2,\"Porcentagem\"],[13],[2,\"\\n        \"],[8,\"input\",[[24,1,\"percentage\"],[24,0,\"form-control\"],[16,\"disabled\",[32,0,[\"disableInputPercent\"]]]],[[\"@placeholder\",\"@value\",\"@type\",\"@min\",\"@max\"],[\"Informe a porcentagem\",[32,3,[\"value\"]],\"number\",\"0\",\"100\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/avd/rating-notes/item/template.hbs"
    }
  });
  _exports.default = _default;
});