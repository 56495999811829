define("account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ChpjuTRK",
    "block": "{\"symbols\":[\"@jobRole\",\"@save\",\"&attrs\",\"@cancel\"],\"statements\":[[11,\"tr\"],[17,3],[4,[38,1],[[30,[36,0],[[32,0,[\"saveOnEnter\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"removeSaveOnEnter\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fas fa-exclamation-circle text-danger mr-1\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"placeholder\",\"Nome do cargo*\"],[24,\"title\",\"Ex: Gerente administrativo\"]],[[\"@value\"],[[32,1,[\"title\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-right text-nowrap\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,0],[[32,2],[32,1]],null]],[14,\"title\",\"Salvar\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-file-check\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-danger py-0\"],[15,\"onclick\",[30,[36,0],[[32,4],[32,1]],null]],[14,\"title\",\"Cancelar\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-times\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"will-destroy\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/form/template.hbs"
    }
  });
  _exports.default = _default;
});