define("account-talentrh/pods/settings/avd/ninebox-settings/quadrant-description-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Rr4KRwnp",
    "block": "{\"symbols\":[\"quadrant\"],\"statements\":[[10,\"p\"],[14,0,\"text-primary mb-0\"],[12],[2,\"Informe nomes, descrição e cores para cada quadrante da nine box.\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"text-primary mb-0\"],[12],[2,\"Só é possível editar um quadrante por vez.\"],[13],[2,\"\\n\"],[10,\"h4\"],[12],[10,\"strong\"],[14,0,\"text-primary d-block my-3\"],[12],[2,\"Quadrantes\"],[13],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"quadrants\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"settings/avd/ninebox-settings/quadrant-description-form/quadrant-item\",[],[[\"@quadrant\",\"@editingQuadrant\",\"@onEditingQuadrantChange\"],[[32,1],[32,0,[\"editingQuadrant\"]],[32,0,[\"onEditingQuadrantChange\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/settings/avd/ninebox-settings/quadrant-description-form/template.hbs"
    }
  });
  _exports.default = _default;
});