define("account-talentrh/pods/super-user/companies/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        company: this.store.findRecord('company', params.company_id),
        subscription: this.store.queryRecord('subscription', {
          company: params.company_id
        }),
        companyConfig: this.store.loadRecord('company-configuration', `companies/${params.company_id}/companyConfiguration`)
      });
    },
    deactivate() {
      this.controllerFor('super-user.companies').set('filters', {});
    }
  });
  _exports.default = _default;
});