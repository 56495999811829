define("account-talentrh/pods/registry/general/user/show/index/book/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FF6DHkl2",
    "block": "{\"symbols\":[\"userBook\",\"Card\",\"@userBooks\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2 mb-0\"],[12],[2,\"\\n    Livros\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"list-group-item py-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"ui-card\",[[24,5,\"margin-bottom: 0.5rem;\"],[24,0,\"shadow-sm col-5 mx-auto\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,2,[\"Body\"]],[[24,0,\"pb-0 text-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,1,[\"book\",\"title\"]],[32,1,[\"otherTitle\"]]],null]],[2,\"\\n              \"],[13],[2,\"\\n\\n              \"],[10,\"h6\"],[14,0,\"font-italic text-muted\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,1,[\"book\",\"author\"]],\"Não informado\"],null]],[2,\"\\n              \"],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/index/book/template.hbs"
    }
  });
  _exports.default = _default;
});