define("account-talentrh/pods/registry/general/user/show/index/emergency-contact/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    emergencyPhone: Ember.computed('user.emergencyContactPhone', function () {
      if (!this.user.emergencyContactPhone) {
        return '';
      }
      return this.user.emergencyContactPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    })
  });
  _exports.default = _default;
});