define("account-talentrh/pods/registry/general/user/show/avd/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    model() {
      return this.modelFor('registry.general.user.show');
    },
    afterModel(model, transition) {
      if (!this.permission.toAccessAVD) {
        transition.abort();
        this.swal.warning('Entre em contato com seu administrador do sistema para mais informações.', {
          title: 'Você não tem acesso ao módulo Gestão de Desempenho'
        });
        this.transitionTo('registry.general.user.show', model.id);
      }
      return;
    }
  });
  _exports.default = _default;
});