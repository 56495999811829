define("account-talentrh/pods/super-user/companies/show/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let SuperUserCompaniesShowIndexController = (_dec = Ember.computed('model.subscription.{viasellCustomModules,plan.availableModules,viasell}'), (_class = class SuperUserCompaniesShowIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "moduleOptions", Ember.A([{
        label: 'Agenda',
        value: 'AGENDA'
      }, {
        label: 'Gestão de Desempenho',
        value: 'AVD'
      }, {
        label: 'Educação Corporativa',
        value: 'TED'
      }, {
        label: 'Pesquisa',
        value: 'PESQUISA'
      }, {
        label: 'Projetos',
        value: 'PROJECT-MANAGER'
      }, {
        label: 'Social',
        value: 'NELA'
      }, {
        label: 'Treinamento',
        value: 'TRAINING'
      }]));
    }
    get availableModules() {
      if (!this.model.subscription || !this.model.subscription.plan) {
        return [];
      }
      if (this.model.subscription.viasell) {
        return this.model.subscription.viasellCustomModules;
      }
      return this.model.subscription.plan.get('availableModules');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "availableModules", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "availableModules"), _class.prototype)), _class));
  _exports.default = SuperUserCompaniesShowIndexController;
});