define("account-talentrh/pods/registry/general/branch/index/print/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    report: Ember.inject.service(),
    // Lifecycle hooks
    model() {
      return this.report.pdf('/reports/branchList');
    }
  });
  _exports.default = _default;
});