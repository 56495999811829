define("account-talentrh/pods/errors/not-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oq7/pm+Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"section-body contain-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[10,\"span\"],[14,0,\"text-xxxl\"],[12],[10,\"i\"],[14,0,\"fa fa-meh-o fa-5x text-blue\"],[12],[13],[13],[13],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"Opa! Você não tem acesso a esse módulo.\"],[13],[2,\"\\n        \"],[10,\"h3\"],[12],[2,\"Entre em contato com a Viasoft para maiores detalhes.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n        \"],[10,\"a\"],[15,6,[30,[36,0],[\"social\"],null]],[14,0,\"btn btn-primary\"],[12],[2,\"Voltar\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"app-url\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/errors/not-access/template.hbs"
    }
  });
  _exports.default = _default;
});