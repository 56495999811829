define("account-talentrh/pods/super-user/wizard-status/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'super-user.wizard-status.show',
    model: Ember.computed.alias('wizardStatus.company.id'),
    // Multiline computed properties
    status: Ember.computed('wizardStatus.status', function () {
      if (this.wizardStatus.status === 'done') {
        return 'Finalizado';
      }
      if (this.wizardStatus.status === 'pending') {
        return 'Pendente';
      }
      return 'Andamento';
    }),
    statusClass: Ember.computed('wizardStatus.status', function () {
      if (this.wizardStatus.status === 'done') {
        return 'badge-success';
      }
      if (this.wizardStatus.status === 'pending') {
        return 'badge-danger';
      }
      return 'badge-warning text-white';
    })
  });
  _exports.default = _default;
});