define("account-talentrh/models/branch", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Razão social não informada'
    }),
    cnpj: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'CNPJ não informado'
    }),
    groupAdmins: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['onlyAdminSendMessages'],
      validate(value, options, model) {
        const {
          onlyAdminSendMessages
        } = model;
        if (value.length > 0 || !onlyAdminSendMessages) {
          return true;
        }
        return 'É necessário informar pelo menos um administrador';
      }
    })
  });
  let BranchModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec15 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec16 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec17 = (0, _model.belongsTo)('city'), _dec18 = (0, _model.belongsTo)('branch-group'), _dec19 = (0, _model.hasMany)('benefit', {
    inverse: 'branches'
  }), _dec20 = (0, _model.hasMany)('company-program', {
    inverse: 'branches'
  }), _dec21 = (0, _model.hasMany)('department', {
    inverse: 'branch'
  }), _dec22 = (0, _model.hasMany)('policy', {
    inverse: 'branches'
  }), _dec23 = (0, _model.hasMany)('skill', {
    inverse: 'branches'
  }), _dec24 = (0, _model.hasMany)('goal', {
    inverse: 'branches'
  }), _dec25 = (0, _model.hasMany)('user', {
    inverse: 'branches'
  }), _dec26 = (0, _model.hasMany)('vacancy', {
    inverse: 'branches'
  }), _dec27 = (0, _model.hasMany)('user', {
    inverse: 'BranchGroupAdmin'
  }), _dec28 = (0, _model.hasMany)('open-ai-repository', {
    inverse: 'branches'
  }), _dec29 = Ember.computed.or('standardName', 'tradingName', 'name'), (_class = class BranchModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "address", _descriptor, this);
      _initializerDefineProperty(this, "cei", _descriptor2, this);
      _initializerDefineProperty(this, "cnpj", _descriptor3, this);
      _initializerDefineProperty(this, "complement", _descriptor4, this);
      _initializerDefineProperty(this, "name", _descriptor5, this);
      _initializerDefineProperty(this, "neighborhood", _descriptor6, this);
      _initializerDefineProperty(this, "number", _descriptor7, this);
      _initializerDefineProperty(this, "phone", _descriptor8, this);
      _initializerDefineProperty(this, "phoneDDD", _descriptor9, this);
      _initializerDefineProperty(this, "tradingName", _descriptor10, this);
      _initializerDefineProperty(this, "standardName", _descriptor11, this);
      _initializerDefineProperty(this, "zipcode", _descriptor12, this);
      _initializerDefineProperty(this, "externalLine", _descriptor13, this);
      _initializerDefineProperty(this, "isChatGroup", _descriptor14, this);
      _initializerDefineProperty(this, "confirmMessageBeforeSend", _descriptor15, this);
      _initializerDefineProperty(this, "onlyAdminSendMessages", _descriptor16, this);
      _initializerDefineProperty(this, "city", _descriptor17, this);
      _initializerDefineProperty(this, "group", _descriptor18, this);
      _initializerDefineProperty(this, "benefits", _descriptor19, this);
      _initializerDefineProperty(this, "companyprograms", _descriptor20, this);
      _initializerDefineProperty(this, "departments", _descriptor21, this);
      _initializerDefineProperty(this, "policies", _descriptor22, this);
      _initializerDefineProperty(this, "skills", _descriptor23, this);
      _initializerDefineProperty(this, "goals", _descriptor24, this);
      _initializerDefineProperty(this, "users", _descriptor25, this);
      _initializerDefineProperty(this, "vacancies", _descriptor26, this);
      _initializerDefineProperty(this, "groupAdmins", _descriptor27, this);
      _initializerDefineProperty(this, "openAiRepositories", _descriptor28, this);
      _initializerDefineProperty(this, "companyName", _descriptor29, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cei", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cnpj", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "complement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "neighborhood", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "number", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phoneDDD", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "tradingName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "standardName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "zipcode", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "externalLine", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isChatGroup", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "confirmMessageBeforeSend", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "onlyAdminSendMessages", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "group", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "benefits", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "companyprograms", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "departments", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "policies", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "goals", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "vacancies", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "groupAdmins", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "openAiRepositories", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BranchModel;
});