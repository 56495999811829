define("account-talentrh/pods/registry/social/mural/gridstack/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistrySocialMuralGridstackRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controller.isShowingTemplateSelection = false;
      this.controller.isShowingTimeSelection = false;
      this.controller.activeIndex = 0;
      this.controllerFor('registry.social.mural.index').clearFilters();
    }
  }
  _exports.default = RegistrySocialMuralGridstackRoute;
});