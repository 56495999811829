define("account-talentrh/pods/registry/general/access-profile/form/schedules/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    toast: Ember.inject.service(),
    focus: (0, _emberConcurrency.task)(function* (state) {
      yield (0, _emberConcurrency.timeout)(150);
      Ember.set(this, 'showCopy', state);
    }).restartable(),
    actions: {
      setSchedule() {
        let openTime = Ember.get(this.accessProfile, this.day.openTime);
        let closeTime = Ember.get(this.accessProfile, this.day.closeTime);
        Ember.setProperties(this.accessProfile, {
          mondayCloseTime: closeTime,
          mondayOpenTime: openTime,
          tuesdayCloseTime: closeTime,
          tuesdayOpenTime: openTime,
          wednesdayCloseTime: closeTime,
          wednesdayOpenTime: openTime,
          thursdayCloseTime: closeTime,
          thursdayOpenTime: openTime,
          fridayCloseTime: closeTime,
          fridayOpenTime: openTime,
          saturdayCloseTime: closeTime,
          saturdayOpenTime: openTime,
          sundayCloseTime: closeTime,
          sundayOpenTime: openTime
        });
      }
    }
  });
  _exports.default = _default;
});