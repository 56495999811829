define("account-talentrh/pods/setup-wizard/steps/access-profile/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AjHAsHcS",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"bg-transparent\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"py-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 py-4 border-right\"],[12],[2,\"\\n        \"],[8,\"setup-wizard/substep-list\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-8 py-4\"],[12],[2,\"\\n        \"],[10,\"h5\"],[14,0,\"mb-4\"],[12],[2,\"\\n          Os Perfis de Acesso são um conjunto de permissões atribuídas a cada \\n          usuário ou grupo de usuários para acesso e utilização das diversas \\n          funcionalidades do Voors. No Perfil de Acesso, além das permissões de \\n          publicações e recursos, é possível definir os horários de acesso. \\n          Além dos perfis de acesso que já existem na plataforma, \\n          também é possível criar novos.\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"iframe\"],[14,0,\"w-100 h-vh-2 mb-3\"],[14,\"src\",\"https://player.vimeo.com/video/757137888?h=985c5ec9f5\"],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n          Crie quantos perfis de acesso forem necessários e personalize de acordo \\n          com as necesidades do seu time.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"setup-wizard/step-control\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/steps/access-profile/info/template.hbs"
    }
  });
  _exports.default = _default;
});