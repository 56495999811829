define("account-talentrh/pods/super-user/companies/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return {
        company: this.store.createRecord('company'),
        user: this.store.createRecord('user'),
        organization: this.store.createRecord('organization')
      };
    },
    deactivate() {
      this.controllerFor('super-user.companies').set('filters', {});
    }
  });
  _exports.default = _default;
});