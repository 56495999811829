define("account-talentrh/models/department", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O departamento deve ter um nome'
    }),
    groupAdmins: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['onlyAdminSendMessages'],
      validate(value, options, model) {
        const {
          onlyAdminSendMessages
        } = model;
        if (value.length > 0 || !onlyAdminSendMessages) {
          return true;
        }
        return 'É necessário informar pelo menos um administrador';
      }
    })
  });
  let DepartmentModel = (_dec = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec6 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec7 = (0, _model.belongsTo)('branch', {
    inverse: 'departments'
  }), _dec8 = (0, _model.belongsTo)('company'), _dec9 = (0, _model.hasMany)('user'), _dec10 = (0, _model.hasMany)('user', {
    inverse: 'departmentsResponsibleForSolicitation'
  }), _dec11 = (0, _model.hasMany)('training'), _dec12 = (0, _model.hasMany)('skill', {
    inverse: 'departments'
  }), _dec13 = (0, _model.hasMany)('goal', {
    inverse: 'departments'
  }), _dec14 = (0, _model.hasMany)('user', {
    inverse: 'DepartmentGroupAdmin'
  }), _dec15 = (0, _model.hasMany)('open-ai-repository', {
    inverse: 'departments'
  }), (_class = class DepartmentModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "active", _descriptor, this);
      _initializerDefineProperty(this, "description", _descriptor2, this);
      _initializerDefineProperty(this, "name", _descriptor3, this);
      _initializerDefineProperty(this, "isChatGroup", _descriptor4, this);
      _initializerDefineProperty(this, "confirmMessageBeforeSend", _descriptor5, this);
      _initializerDefineProperty(this, "onlyAdminSendMessages", _descriptor6, this);
      _initializerDefineProperty(this, "branch", _descriptor7, this);
      _initializerDefineProperty(this, "company", _descriptor8, this);
      _initializerDefineProperty(this, "employees", _descriptor9, this);
      _initializerDefineProperty(this, "usersResponsibleForSolicitation", _descriptor10, this);
      _initializerDefineProperty(this, "trainings", _descriptor11, this);
      _initializerDefineProperty(this, "skills", _descriptor12, this);
      _initializerDefineProperty(this, "goals", _descriptor13, this);
      _initializerDefineProperty(this, "groupAdmins", _descriptor14, this);
      _initializerDefineProperty(this, "openAiRepositories", _descriptor15, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isChatGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmMessageBeforeSend", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onlyAdminSendMessages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "branch", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "employees", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "usersResponsibleForSolicitation", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "trainings", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "goals", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "groupAdmins", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "openAiRepositories", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DepartmentModel;
});