define("account-talentrh/pods/subscription/plan/form-plan/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d1Bu3fvy",
    "block": "{\"symbols\":[\"@plan\",\"@updateSlider\",\"@updateSubscription\"],\"statements\":[[10,\"div\"],[14,0,\"text-center w-100\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Monte seu plano\"],[13],[2,\"\\n  \"],[8,\"subscription/plan/plans-slider\",[],[[\"@plan\",\"@onPlanUpdate\",\"@update\"],[[32,1],[32,0,[\"onPlanUpdate\"]],[32,2]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isNotInUserCount\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"text-danger mt-3\"],[12],[2,\"Quantidade de usuários ativos é maior que o limite de usuários do plano selecionado!\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"button\"],[14,0,\"btn btn-primary mt-2\"],[15,\"onclick\",[30,[36,1],[[32,3],[32,0,[\"selectedPlan\"]]],null]],[15,\"disabled\",[30,[36,2],[[32,0,[\"isContractedPlan\"]],[32,0,[\"isNotInUserCount\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"isContractedPlan\"]],\"Plano atual\",\"Contratar\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex flex-column justify-content-between align-items-center mt-2\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,0,\"btn btn-primary\"],[14,6,\"https://nela.com.br/contato\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"Para mais de 100 usuários clique aqui\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"or\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/subscription/plan/form-plan/template.hbs"
    }
  });
  _exports.default = _default;
});