define("account-talentrh/pods/registry/general/user/show/index/professional-interest/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TSBldRS8",
    "block": "{\"symbols\":[\"interest\",\"@professionalInterests\"],\"statements\":[[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-primary font-weight-bold border-bottom pb-2 mb-0\"],[12],[2,\"\\n    Interesses profissionais\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"list-group-item py-4\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"list-group-item border my-1\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fal fa-user-tie fa-lg text-primary\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"border-left pl-2 ml-3\"],[12],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"mb-1\"],[12],[2,\"\\n                \"],[10,\"strong\"],[12],[2,\"Área:\"],[13],[2,\" \"],[1,[32,1,[\"area\"]]],[2,\"\\n              \"],[13],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"mb-1\"],[12],[2,\"\\n                \"],[10,\"strong\"],[12],[2,\"Descrição:\"],[13],[2,\" \"],[1,[30,[36,0],[[32,1,[\"description\"]],\"Não informado\"],null]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/index/professional-interest/template.hbs"
    }
  });
  _exports.default = _default;
});