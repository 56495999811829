define("account-talentrh/pods/super-user/companies/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Default values
    filters: null,
    // Multiline computed properties
    statusOptions: Ember.A([{
      label: 'Ativo',
      value: 1
    }, {
      label: 'Inativo',
      value: 0
    }]),
    sellTypes: Ember.A([{
      label: "Convencional",
      value: "convencional"
    }, {
      label: "Embarcada",
      value: "embarcada"
    }, {
      label: "Saas",
      value: "saas"
    }]),
    // Tasks
    filterName: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    // Custom methods
    getFilters() {
      let filters = {};
      if (this.nameFilter) {
        filters.name = this.nameFilter;
      }
      if (this.statusFilter) {
        filters.active = this.statusFilter.value;
      }
      if (this.sellTypeFilter) {
        filters.sellType = this.sellTypeFilter.value;
      }
      Ember.set(this, 'filters', filters);
    },
    actions: {
      filterStatus(selected) {
        Ember.set(this, 'statusFilter', selected);
        this.getFilters();
      },
      filterSellType(selected) {
        Ember.set(this, 'sellTypeFilter', selected);
        this.getFilters();
      },
      clearFilters() {
        Ember.setProperties(this, {
          filters: {},
          statusFilter: null,
          nameFilter: null,
          sellTypeFilter: null
        });
      }
    }
  });
  _exports.default = _default;
});