define("account-talentrh/models/training-group", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O tipo de treinamento deve ter uma descrição'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    description: (0, _model.attr)('string'),
    // Associations
    organization: (0, _model.belongsTo)('organization')
  });
  _exports.default = _default;
});