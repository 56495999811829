define("account-talentrh/models/employee-evaluation", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    appraisedAnswer: (0, _model.attr)('raw'),
    appraisedConcluded: (0, _model.attr)('boolean'),
    appraisedTotal: (0, _model.attr)('number'),
    appraiserAnswer: (0, _model.attr)('raw'),
    appraiserConcluded: (0, _model.attr)('boolean'),
    appraiserTotal: (0, _model.attr)('number'),
    approved: (0, _model.attr)('boolean'),
    canceled: (0, _model.attr)('boolean'),
    cancellationReason: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    dismiss: (0, _model.attr)('boolean'),
    dismissalCompany: (0, _model.attr)('boolean'),
    dismissalEmployee: (0, _model.attr)('boolean'),
    dueDate: (0, _model.attr)('date'),
    expirationDate: (0, _model.attr)('date'),
    finalFeedback: (0, _model.attr)('string'),
    finalTotal: (0, _model.attr)('number'),
    improvePoints: (0, _model.attr)('string'),
    keepPoints: (0, _model.attr)('string'),
    periodExpired: (0, _model.attr)('number'),
    reopened: (0, _model.attr)('boolean'),
    suggestion: (0, _model.attr)('string'),
    teamConcluded: (0, _model.attr)('boolean'),
    type: (0, _model.attr)('number'),
    originType: (0, _model.attr)('number'),
    consensusAnswer: (0, _model.attr)('raw'),
    consensusConcluded: (0, _model.attr)('boolean'),
    consensusTotal: (0, _model.attr)('number'),
    concluded: (0, _model.attr)('boolean'),
    skill: (0, _model.attr)('boolean'),
    // Associations
    appraisedUser: (0, _model.belongsTo)('user'),
    appraiserUser: (0, _model.belongsTo)('user'),
    questionnaire: (0, _model.belongsTo)('questionnaire'),
    evaluationTeams: (0, _model.hasMany)('employeeEvaluationTeam'),
    // Computed Properties
    appraisersConcluded: Ember.computed.mapBy('evaluationTeams', 'appraiserConcluded'),
    scores: Ember.computed.mapBy('evaluationTeams', 'appraiserTotal'),
    sumOfScores: Ember.computed.sum('scores'),
    avarageFinal: Ember.computed('averageTeam', 'appraiserTotal', 'appraisedTotal', function () {
      return (this.averageTeam + this.appraiserTotal + this.appraisedTotal) / 3;
    }),
    averageTeam: Ember.computed('sumOfScores', 'scores.length', function () {
      return this.sumOfScores / this.get('scores.length');
    }),
    concludedTeam: Ember.computed('appraisersConcluded', function () {
      var concluded = true;
      for (var i = 0; i < this.appraisersConcluded.length; i++) {
        if (!this.appraisersConcluded[i]) {
          concluded = false;
        }
      }
      return concluded;
    }),
    isConcluded: Ember.computed('concluded', function () {
      return this.concluded;
    }),
    limitDate: Ember.computed('createdAt', function () {
      var endDate = (0, _moment.default)(`${this.createdAt}`).endOf('month');
      return endDate;
    }),
    isAvailable: Ember.computed('canceled', 'periodExpired', function () {
      return !this.canceled && this.periodExpired !== 1;
    }),
    typeDescription: Ember.computed('type', 'originType', function () {
      let types = {
        1: 'Primeira Avaliação',
        2: 'Avaliação de Efetivação',
        3: 'Avaliação de Função',
        4: 'Avaliação de Skills'
      };
      if (this.type === 4) {
        return `${types[this.originType]} - Skills`;
      }
      return types[this.type];
    })
  });
  _exports.default = _default;
});