define("account-talentrh/pods/super-user/lgpd/backup/company/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L1vqwhHW",
    "block": "{\"symbols\":[\"@solicitation\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-fill\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[2,\"Solicitação de backup\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"company\",\"companyName\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"status\"]]],null,[[\"default\"],[{\"statements\":[[2,\"   \"],[10,\"div\"],[14,0,\"float-right\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"mb-0 w-100 text-right\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[15,0,[31,[\"badge \",[32,0,[\"solicitationStatusClass\"]]]]],[12],[2,\"\\n        \"],[1,[32,1,[\"statusDescription\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/super-user/lgpd/backup/company/item/template.hbs"
    }
  });
  _exports.default = _default;
});