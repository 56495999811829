define("account-talentrh/pods/components/dashboard/birthday/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardBirthComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed.alias('args.dashboardSlideItemBirthday'), _dec7 = Ember.computed.alias('args.dashboardSlideItem'), _dec8 = Ember.computed('dashboardSlideItem.{width,height}'), _dec9 = Ember.computed('dashboardSlideItem.{height,width}'), _dec10 = Ember.computed('rows'), _dec11 = (0, _emberConcurrencyDecorators.dropTask)(), _dec12 = Ember._action, (_class = class DashboardBirthComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", 'div');
      _defineProperty(this, "classNames", ['h-100']);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "birthdays", _descriptor4, this);
      _initializerDefineProperty(this, "maxHeight", _descriptor5, this);
      _initializerDefineProperty(this, "dashboardSlideItemBirthday", _descriptor6, this);
      _initializerDefineProperty(this, "dashboardSlideItem", _descriptor7, this);
    }
    get rows() {
      if (this.dashboardSlideItem.width === 4 && this.dashboardSlideItem.height === 2) return 3;
      if (this.dashboardSlideItem.width >= 8 && this.dashboardSlideItem.height === 2) return 3;
      if (!this.dashboardSlideItem.width || this.dashboardSlideItem.width <= 4) return 1;
      return 1;
    }
    get slidesPerRow() {
      if (!this.dashboardSlideItem.width || this.dashboardSlideItem.width <= 4) {
        return 1;
      }
      if (this.dashboardSlideItem.width === 12 && this.dashboardSlideItem.height === 2) return 4;
      return Math.floor(this.dashboardSlideItem.width / 2) - 1;
    }
    get sliderClass() {
      return this.rows > 1 ? 'multiline' : '';
    }
    *refreshDashboard() {
      yield this.loadBirthday.perform();
    }
    *loadBirthday() {
      try {
        const showBirthdayMonth = this.permission.companyConfig.showBirthdayMonth;
        const branches = this.dashboardSlideItemBirthday.get('branches');
        const branchesIds = branches.mapBy('id');
        const {
          users
        } = yield this.ajax.request('/widgets/birthday', {
          data: {
            branch: branchesIds || [],
            variant: showBirthdayMonth ? 'month' : 'today'
          },
          host: _environment.default.apiUrl.social
        });
        this.birthdays = users;
      } catch (error) {
        console.log(error);
        this.swal.catch(error);
      }
    }
    checkHeight(e) {
      this.maxHeight = e.offsetHeight;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "birthdays", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "maxHeight", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemBirthday", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItem", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rows", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "rows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slidesPerRow", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "slidesPerRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sliderClass", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "sliderClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshDashboard", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "refreshDashboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadBirthday", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadBirthday"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHeight", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "checkHeight"), _class.prototype)), _class));
  _exports.default = DashboardBirthComponent;
});