define("account-talentrh/pods/application/navbar/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NavbarComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('permission.companyConfig.subscription.sellType'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class NavbarComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "swal", _descriptor5, this);
      _initializerDefineProperty(this, "userCookie", _descriptor6, this);
      _initializerDefineProperty(this, "canReturnToAccount", _descriptor7, this);
      _initializerDefineProperty(this, "user", _descriptor8, this);
      if (this.session.data.keybkp) {
        this.canReturnToAccount = true;
      }
      this.user = this.store.peekRecord('user', this.session.user.id);
    }
    get supportLink() {
      if (this.permission.companyConfig.subscription.sellType === 'convencional') {
        return "https://viasoft.movidesk.com/";
      } else if (this.permission.companyConfig.subscription.sellType === 'saas' || this.permission.companyConfig.subscription.sellType === 'embarcada') {
        return "https://atendimento.viasoft.com.br/form/3664/";
      }
      return "https://viasoft.movidesk.com/";
    }
    *superUserLogin() {
      try {
        this.session.set('data.keybkp', this.session.data.authenticated);
        yield this.session.authenticate('authenticator:super-user', {
          identification: this.userIdentifier
        });
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *superUserReturn() {
      try {
        let {
          userAccountToken,
          user,
          company
        } = this.session.data.keybkp;
        yield this.session.authenticate('authenticator:jwt', {
          token: userAccountToken,
          user: user.id,
          company: company.id
        });
        this.session.set('data.keybkp', undefined);
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    async logout() {
      await this.userCookie.deleteUserDbToken();
      this.session.invalidate();
    }
    requestPersonalData() {
      Ember.set(this, 'openPersonalDataModal', !this.openPersonalDataModal);
    }
    *getPersonalDataReport() {
      let swalLoading = null;
      try {
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        let url = `${_environment.default.apiUrl.social}/receipts/personalData`;
        swalLoading = this.swal.loading('Gerando o pdf...');
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        const report = yield response.blob();
        const objectURL = URL.createObjectURL(report);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = 'NELA-Dados-Pessoais-LGPD.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        swalLoading.close();
      } catch (e) {
        if (swalLoading) swalLoading.close();
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userCookie", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "canReturnToAccount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "supportLink", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "supportLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "superUserLogin", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "superUserLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "superUserReturn", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "superUserReturn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPersonalData", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "requestPersonalData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPersonalDataReport", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getPersonalDataReport"), _class.prototype)), _class));
  _exports.default = NavbarComponent;
});