define("account-talentrh/pods/super-user/companies/create/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    sellTypes: Ember.A([{
      label: "Convencional",
      value: "convencional"
    }, {
      label: "Embarcada",
      value: "embarcada"
    }]),
    selectedSellType: null,
    // Single line computed properties
    company: Ember.computed.alias('model.company'),
    organization: Ember.computed.alias('model.organization'),
    user: Ember.computed.alias('model.user'),
    patternMask: Ember.computed('company.type', function () {
      return this.company.type === 'cpf' ? '999.999.999-99' : '99.999.999/9999-99';
    }),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'organization.name', this.company.name);
      if (!this.company.validations.isValid) {
        return this.swal.validation(this.company.validations);
      }
      if (!this.company.sellType) {
        return this.swal.warning('É necessário informar o tipo de venda');
      }
      if (!this.user.firstName) {
        return this.swal.warning('É necessário informar um nome');
      }
      if (!this.user.lastName) {
        return this.swal.warning('É necessário informar um sobrenome');
      }
      if (!this.user.cpf) {
        return this.swal.warning('É necessário informar um CPF');
      }
      if (!this.user.email) {
        return this.swal.warning('É necessário informar um email');
      }
      Ember.setProperties(this.user, {
        admin: true
      });
      this.swal.loading('Cadastrando empresa');
      try {
        yield this.ajax.post('register/company-nocart', {
          contentType: 'application/json; charset=utf-8',
          data: {
            company: this.company.toJSON(),
            user: this.user.toJSON(),
            organization: this.organization.toJSON()
          }
        });
        this.toast.success('Empresa cadastrada');
        this.transitionToRoute('super-user.companies.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      sellTypeChanged(newSellType) {
        Ember.set(this, 'company.sellType', newSellType.value);
        Ember.set(this, 'selectedSellType', newSellType);
      }
    }
  });
  _exports.default = _default;
});