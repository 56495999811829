define("account-talentrh/helpers/app-url", ["exports", "account-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function appUrl(params /*, hash*/) {
    return _environment.default.appUrl[params[0]] + (params[1] || '');
  });
  _exports.default = _default;
});