define("account-talentrh/pods/registry/avd/skill/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember.computed('args.skill.type', 'typeOptions', {
    get() {
      const {
        skill
      } = this.args;
      return this.typeOptions.find(option => {
        return option.value === skill.type;
      });
    },
    set(key, selected) {
      const {
        skill
      } = this.args;
      if (selected && selected.value) {
        skill.type = selected.value;
      } else {
        skill.type = null;
      }
      return selected;
    }
  }), (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _defineProperty(this, "typeOptions", Ember.A([{
        label: 'Soft',
        value: 'soft'
      }, {
        label: 'Hard',
        value: 'hard'
      }]));
      _initializerDefineProperty(this, "typeSelected", _descriptor4, this);
    }
    clearInputSelect() {
      let {
        skill
      } = this.args;
      if (skill.branches.length) {
        this.args.skill.branches = [];
      }
      if (skill.departments.length) {
        this.args.skill.departments = [];
      }
      if (skill.jobRoles.length) {
        this.args.skill.jobRoles = [];
      }
      if (skill.jobFunctions.length) {
        this.args.skill.jobFunctions = [];
      }
    }
    *save() {
      let {
        skill
      } = this.args;
      let message = skill.isNew ? 'Skill cadastrada' : 'Skill atualizada';
      try {
        if (!skill.validations.isValid) {
          return this.swal.validation(skill.validations);
        }
        yield skill.save();
        this.toast.success(message);
        this.router.transitionTo('registry.avd.skill.show', skill.id);
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearInputSelect", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearInputSelect"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "typeSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = FormComponent;
});