define("account-talentrh/pods/registry/general/access-profile/show/users/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    accessProfile: Ember.computed.alias('model')
  });
  _exports.default = _default;
});