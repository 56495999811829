define("account-talentrh/utils/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    getInvalidInputs: function (object, inputKeys) {
      var invalidInputs = '';
      var that = this;
      inputKeys.forEach(function (input) {
        var value = object.get(input.key);
        if (!value || input.isEmail && !that.isValidEmail(value) || input.isCpf && !that.isValidCpf(value) || input.isZipCode && !that.isValidZipCode(value) || input.isRelationship && !that.isValidRelationship(value)) {
          if (invalidInputs) {
            invalidInputs += ', ';
          }
          invalidInputs += input.name;
        }
      });
      return invalidInputs;
    },
    isValidEmail: function (email) {
      let filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return filter.test(email);
    },
    isValidCpf: function (cpf) {
      var filter = /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/;
      return filter.test(cpf);
    },
    isValidRelationship: function (relationship) {
      return relationship.get('content') ? true : false;
    }
  };
  _exports.default = _default;
});