define("account-talentrh/pods/components/settings/details/manage-requests/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('permission.companyConfig'), _dec5 = Ember.computed.alias('companyConfig.userSolicitationConfigs'), _dec6 = Ember.computed.alias('companyConfig.managerSolicitationConfigs'), (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor4, this);
      _initializerDefineProperty(this, "userSolicitationConfigs", _descriptor5, this);
      _initializerDefineProperty(this, "managerSolicitationConfigs", _descriptor6, this);
    }
    get optionSelected() {
      let {
        setting
      } = this.args;
      let option = 'both';
      if (Ember.get(this, `userSolicitationConfigs.${setting.value}`)) {
        option = 'rh';
      }
      return option;
    }
    set optionSelected(option) {
      let {
        setting
      } = this.args;
      if (option === 'both') {
        Ember.set(this, `userSolicitationConfigs.${setting.value}`, false);
      } else {
        Ember.set(this, `userSolicitationConfigs.${setting.value}`, true);
      }
    }

    // Tasks
    *save(subject) {
      let {
        setting,
        settingManager
      } = this.args;
      if (!this.managerSolicitationConfigs) {
        Ember.set(this, 'managerSolicitationConfigs', {});
      }
      if (!this.userSolicitationConfigs) {
        Ember.set(this, 'userSolicitationConfigs', {});
      }

      // Habilita/Desabilita a solicitação pelo portal do gestor.
      if (Ember.isEqual(subject, 'useSolicitationManager')) {
        Ember.set(this, `managerSolicitationConfigs.${settingManager.utilize}`, !Ember.get(this, `managerSolicitationConfigs.${settingManager.utilize}`));
      }

      // Habilita/Desabilita a solicitação pelo portal do colaborador e volta a propriedade da solicitção ao padrão;
      if (Ember.isEqual(subject, 'useSolicitationEmployee')) {
        Ember.set(this, `userSolicitationConfigs.${setting.utilize}`, !Ember.get(this, `userSolicitationConfigs.${setting.utilize}`));
        Ember.set(this, `userSolicitationConfigs.${setting.value}`, false);
      }
      try {
        yield this.companyConfig.save();
        this.toast.success('Configurações atualizadas');
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userSolicitationConfigs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "managerSolicitationConfigs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});