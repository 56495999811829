define("account-talentrh/pods/registry/general/user/show/index/social-media/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    iconClass: 'fab fa-lg border-right pr-2 mr-1',
    isVisible: Ember.computed('socialMedias.@each.url', 'user.id', function () {
      return Boolean(this.socialMedias.find(s => s.url));
    }),
    socialMedias: Ember.computed('user.id', function () {
      return Ember.A([{
        label: 'Facebook',
        url: this.user.facebookUrl,
        icon: Ember.String.htmlSafe(`<i class="fa-facebook-square ${this.iconClass}" style="color:#2F5B9D"/>`)
      }, {
        label: 'Google+',
        url: this.user.googleplusUrl,
        icon: Ember.String.htmlSafe(`<i class="fa-google-plus-g ${this.iconClass}" style="color:#DD4B39"/>`)
      }, {
        label: 'Instagram',
        url: this.user.instagramUrl,
        icon: Ember.String.htmlSafe(`<i class="fa-instagram ${this.iconClass}" style="color:#E1306C"/>`)
      }, {
        label: 'Linkedin',
        url: this.user.linkedinUrl,
        icon: Ember.String.htmlSafe(`<i class="fa-linkedin ${this.iconClass}" style="color:#0E76A8"/>`)
      }, {
        label: 'Skype',
        url: this.user.skype,
        icon: Ember.String.htmlSafe(`<i class="fa-skype ${this.iconClass}" style="color:#00AFF0"/>`)
      }, {
        label: 'Twitter',
        url: this.user.twitterUrl,
        icon: Ember.String.htmlSafe(`<i class="fa-twitter ${this.iconClass}" style="color:#38A1F3"/>`)
      }]);
    })
  });
  _exports.default = _default;
});