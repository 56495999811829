define("account-talentrh/pods/registry/general/user/edit/change-recover-password-info/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralUserEditChangeRecoverPasswordInfoRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = RegistryGeneralUserEditChangeRecoverPasswordInfoRoute;
});