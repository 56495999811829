define("account-talentrh/pods/setup-wizard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pNljTgHe",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"],[24,0,\"setup-wizard wizard-modal\"],[24,\"tabindex\",\"null\"]],[[\"@show\",\"@size\"],[true,\"xl\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"modal-title\"],[12],[2,\"Assistente de configuração\"],[13],[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-link btn-lg text-social py-0\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"continueAfter\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n      Continuar Depois \"],[10,\"i\"],[14,0,\"fas fa-angle-right\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"pt-3 pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/setup-wizard/template.hbs"
    }
  });
  _exports.default = _default;
});