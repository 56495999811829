define("account-talentrh/pods/registry/general/professional-progression-board/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralProfessionalProgressionBoardDetailsRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('registry.general.professional-progression-board').filters = {};
      this.controllerFor('registry.general.professional-progression-board').clearFilters();
    }
  }
  _exports.default = RegistryGeneralProfessionalProgressionBoardDetailsRoute;
});