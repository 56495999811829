define("account-talentrh/pods/setup-wizard/steps/groups/group/manage/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('group.isNew'), _dec7 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isUpdatingImage", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
    }
    // Multiline computed properties
    get isNew() {
      let {
        group
      } = this.args;
      if (!group) {
        return;
      }
      return group.isNew;
    }
    *save() {
      let {
        group
      } = this.args;
      if (!group.validations.isValid) {
        return this.swal.validation(group.validations);
      }
      try {
        const isNew = this.isNew;
        this.swal.loading('Salvando grupo', {
          allowOutsideClick: false
        });
        yield group.save();
        if (isNew) {
          this.swal.success('Agora você pode adicionar uma imagem para o grupo');
          this.router.transitionTo('setup-wizard.steps.groups.group.manage.edit', group.id);
        } else {
          this.toast.success('Alterações salvas');
          this.router.transitionTo('setup-wizard.steps.groups.group.manage');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *changeGroupImage() {
      let {
        group
      } = this.args;
      const groupId = group.id;
      let element = document.getElementById('group-image-file-field');
      let file = element.files[0];
      if (!file) {
        return false;
      }
      let form = new FormData();
      form.append('attachment', file);
      this.swal.loading('A imagem está sendo atualizada');
      this.isUpdatingImage = true;
      try {
        let response = yield this.ajax.request(`usergroups/${groupId}/image`, {
          host: _environment.default.apiUrl.social,
          contentType: false,
          processData: false,
          type: 'POST',
          data: form
        });
        this.toast.success('Imagem atualizada');
        this.isUpdatingImage = false;
        group.imageUrl = response.userGroup.imageUrl;
      } catch (e) {
        this.swal.catch(e);
      }
    }
    click(item) {
      document.getElementById(item).click();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isUpdatingImage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeGroupImage", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeGroupImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = FormComponent;
});