define("account-talentrh/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberScreen.default.extend({
    isMobile: (0, _emberScreen.breakpoint)('(max-width: 479px)'),
    isDesktop: (0, _emberScreen.breakpoint)('(min-width: 480px)')
  });
  _exports.default = _default;
});