define("account-talentrh/pods/super-user/control-version/show/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SuperUserControlVersionShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('model'), (_class = class SuperUserControlVersionShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "ajax", _descriptor5, this);
      _initializerDefineProperty(this, "version", _descriptor6, this);
    }
    get summernoteDescription() {
      return Ember.String.htmlSafe(this.version.description);
    }
    get createdAt() {
      return 'Data de Criação: ' + (0, _moment.default)(this.version.createdAt, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }
    get viewedAt() {
      return 'Data da visualização da notificação: ' + (0, _moment.default)(this.version.viewedAt, 'DD/MM/YYYY').format('DD/MM/YYYY HH:mm');
    }
    get publishedAt() {
      return 'Data de envio da notificação: ' + (0, _moment.default)(this.version.publishedAt, 'DD/MM/YYYY').format('DD/MM/YYYY HH:mm');
    }
    *removeVersion() {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da versão?', {
          text: this.version.number
        });
        if (!value) {
          return;
        }
        if (this.version.notificationSent) {
          return this.swal.warning('Notificação já foi enviada para esta versão', {
            title: 'Não foi possível remover.'
          });
        }
        yield this.version.destroyRecord();
        this.toast.success('Versão removida');
        this.router.transitionTo('super-user.control-version.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *sendNotification() {
      try {
        const msg = 'Deseja enviar a notificação da versão?';
        let {
          value
        } = yield this.swal.confirm(msg, {
          input: 'text',
          html: 'Serão notificados os usuários Administradores e Gestão de Pessoas com permissão.' + '</br></br>' + ' Para realizar o envio da notificação digite ENVIAR no campo abaixo.',
          confirmButtonText: 'Enviar'
        });
        if (value === undefined) {
          return;
        }
        if (!value || value.trim().toLowerCase() !== 'enviar') {
          return this.swal.warning('É necessário digitar ENVIAR para enviar a notificação.');
        }
        if (this.version.notificationSent) {
          return this.swal.warning('Notificação já foi enviada para esta versão', {
            title: 'Não foi possível enviar.'
          });
        }
        const response = yield this.ajax.post(`versions/${this.version.id}/sendNotification`);
        this.store.pushPayload(response);
        this.toast.success('Notificação enviada com sucesso');
        this.version.notificationSent = true;
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "removeVersion", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeVersion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendNotification", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendNotification"), _class.prototype)), _class));
  _exports.default = SuperUserControlVersionShowController;
});