define("account-talentrh/pods/registry/general/user/show/history/job-role/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MGo8KG6U",
    "block": "{\"symbols\":[\"@history\"],\"statements\":[[10,\"div\"],[14,0,\"row border-bottom mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-2 mb-2\"],[12],[2,\"\\n    \"],[1,[32,0,[\"dateFormatted\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-10 mb-2\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"jobRole\",\"id\"]],\" - \",[32,1,[\"jobRole\",\"title\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/registry/general/user/show/history/job-role/item/template.hbs"
    }
  });
  _exports.default = _default;
});