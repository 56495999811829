define("account-talentrh/models/import-annual-incoming-file", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    path: (0, _model.attr)('string'),
    filename: (0, _model.attr)('string'),
    import: (0, _model.belongsTo)('import-annual-incoming'),
    user: (0, _model.belongsTo)('user')
  });
  _exports.default = _default;
});