define("account-talentrh/pods/setup-wizard/steps/registry/job-role/manage/import/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupWizardStepsRegistryJobRoleManageImportRoute extends Ember.Route {
    deactivate() {
      this.controller.records = [];
    }
  }
  _exports.default = SetupWizardStepsRegistryJobRoleManageImportRoute;
});