define("account-talentrh/pods/lgpd/backup/company/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LgpdBackupCompanyController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('permission.companyConfig'), _dec12 = Ember.computed.alias('companyConfiguration.hasAVDModule'), _dec13 = Ember.computed.alias('companyConfiguration.hasAgendaModule'), _dec14 = Ember.computed.alias('companyConfiguration.hasPesquisaModule'), _dec15 = Ember.computed.alias('companyConfiguration.hasProjectsModule'), _dec16 = Ember.computed.alias('companyConfiguration.hasTEDModule'), _dec17 = Ember.computed.alias('companyConfiguration.hasSocialModule'), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class LgpdBackupCompanyController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "statusFilter", _descriptor3, this);
      _initializerDefineProperty(this, "startDateFilter", _descriptor4, this);
      _initializerDefineProperty(this, "endDateFilter", _descriptor5, this);
      _initializerDefineProperty(this, "companyFilter", _descriptor6, this);
      _initializerDefineProperty(this, "selectedModule", _descriptor7, this);
      _initializerDefineProperty(this, "openModalFilter", _descriptor8, this);
      _initializerDefineProperty(this, "filters", _descriptor9, this);
      _initializerDefineProperty(this, "modules", _descriptor10, this);
      _initializerDefineProperty(this, "companyConfiguration", _descriptor11, this);
      _initializerDefineProperty(this, "hasGestaoDesempenhoModule", _descriptor12, this);
      _initializerDefineProperty(this, "hasAgendaModule", _descriptor13, this);
      _initializerDefineProperty(this, "hasPesquisaModule", _descriptor14, this);
      _initializerDefineProperty(this, "hasGestorProjetosModule", _descriptor15, this);
      _initializerDefineProperty(this, "hasEducacaoCorporativaModule", _descriptor16, this);
      _initializerDefineProperty(this, "hasSocialModule", _descriptor17, this);
    }
    filter() {
      const filters = {};
      if (this.startDateFilter && this.endDateFilter && this.startDateFilter > this.endDateFilter) {
        return this.swal.warning('Data inicial não pode ser maior que a data final');
      }
      if (this.startDateFilter) {
        filters.startDate = (0, _moment.default)(this.startDateFilter).format('YYYY-MM-DD 00:00:00');
      }
      if (this.endDateFilter) {
        filters.endDate = (0, _moment.default)(this.endDateFilter).format('YYYY-MM-DD 23:59:59');
      }
      if (this.companyFilter) {
        filters.company = this.companyFilter.id;
      }
      if (this.statusFilter !== 'all') {
        filters.status = this.statusFilter;
      }
      if (this.selectedModule) {
        filters.module = this.selectedModule.value;
      }
      this.filters = filters;
    }
    selectModule(selectedModule) {
      this.selectedModule = selectedModule;
    }
    clearFilters() {
      this.startDateFilter = null;
      this.endDateFilter = null;
      this.statusFilter = 'all';
      this.selectedModule = null;
      this.filter();
    }
    setModulesFilterOptions() {
      this.modules.clear();
      if (this.hasAgendaModule) {
        this.modules.addObject({
          label: 'Agenda',
          value: 'backupAgendaModule'
        });
      }
      if (this.hasEducacaoCorporativaModule) {
        this.modules.addObject({
          label: 'Educação Corporativa',
          value: 'backupEducacaoCorporativaModule'
        });
      }
      if (this.hasGestaoDesempenhoModule) {
        this.modules.addObject({
          label: 'Gestão de Desempenho',
          value: 'backupGestaoDesempenhoModule'
        });
      }
      if (this.hasGestorProjetosModule) {
        this.modules.addObject({
          label: 'Gestor de Projetos',
          value: 'backupGestorProjetosModule'
        });
      }
      if (this.hasPesquisaModule) {
        this.modules.addObject({
          label: 'Pesquisas',
          value: 'backupPesquisasModule'
        });
      }
      if (this.hasSocialModule) {
        this.modules.addObject({
          label: 'Social',
          value: 'backupSocialModule'
        });
      }
      this.modules.addObject({
        label: 'Painel do Administrador',
        value: 'backupContasModule'
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "statusFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "startDateFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "endDateFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedModule", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openModalFilter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "modules", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "companyConfiguration", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasGestaoDesempenhoModule", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasAgendaModule", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasPesquisaModule", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "hasGestorProjetosModule", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasEducacaoCorporativaModule", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasSocialModule", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filter", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "filter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectModule", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "selectModule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setModulesFilterOptions", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "setModulesFilterOptions"), _class.prototype)), _class));
  _exports.default = LgpdBackupCompanyController;
});