define("account-talentrh/pods/reports/team-management/item-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lw2tdjmO",
    "block": "{\"symbols\":[\"option\",\"@user\",\"@save\",\"@cancel\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[8,\"nela-select\",[[24,0,\"input-size\"]],[[\"@selected\",\"@modelName\",\"@endpoint\",\"@placeholder\",\"@dropdownClass\",\"@renderInPlace\",\"@allowClear\"],[[32,2,[\"appraisedBy\"]],\"user\",\"/users/ajax\",[32,2,[\"appraiserFullName\"]],\"in-modal-dropdown\",false,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"fullName\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"text-right text-nowrap\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,0],[[32,3],[32,2]],null]],[14,\"title\",\"Salvar\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-file-check\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-link btn-sm text-social py-0\"],[15,\"onclick\",[30,[36,0],[[32,4],[32,2]],null]],[14,\"title\",\"Cancelar\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-times\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/reports/team-management/item-edit/template.hbs"
    }
  });
  _exports.default = _default;
});