define("account-talentrh/models/questionnaire", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    description: (0, _model.attr)('string'),
    questionnaireQuestionGroup: (0, _model.hasMany)('questionnaireQuestionGroup'),
    pages: (0, _model.attr)('raw', {
      defaultValue: () => [{
        porcentage: null,
        description: null,
        questions: [{
          title: null,
          order: 0,
          type: null,
          porcentage: null,
          alternatives: [{
            description: null,
            percent: null,
            order: 0
          }]
        }]
      }]
    }),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    // Retorna a soma do total de todas as perguntas usando o Math.round para aredondar o valor
    percentageTotal: Ember.computed('pages.@each.percentage', function () {
      let total = 0,
        percentage;
      this.pages.forEach(obj => {
        percentage = obj.percentage;
        total += percentage ? parseFloat(percentage) : 0;
      });
      return Math.round(total);
    })
  });
  _exports.default = _default;
});