define("account-talentrh/pods/reports/team-management/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    deactivate() {
      this.controller.clearFilters();
    },
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      if (!this.permission.accessProfile.get('admin') && !this.permission.accessProfile.get('allowRegistrationDataManagement')) {
        this.swal.warning('Você não tem permissão para acessar esta rota');
        this.router.transitionTo('home');
      }
    }
  });
  _exports.default = _default;
});