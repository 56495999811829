define("account-talentrh/pods/registry/general/user/show/trainings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RegistryGeneralUserShowTrainingsRoute extends Ember.Route {
    model() {
      return this.modelFor('registry.general.user.show');
    }
  }
  _exports.default = RegistryGeneralUserShowTrainingsRoute;
});