define("account-talentrh/pods/super-user/lgpd/delete/user/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    // HTML Attributes
    tagName: 'li',
    classNames: ['list-group-item'],
    // Default values
    route: 'super-user.lgpd.delete.user.show',
    // Single line computed properties
    model: Ember.computed.alias('solicitation.id'),
    solicitationStatusClass: Ember.computed('solicitation.status', function () {
      const status = this.solicitation.status;
      switch (status) {
        case 'pending':
          return 'badge-warning text-white';
        case 'finished':
          return 'badge-success';
        case 'canceled':
          return 'badge-danger';
        default:
          return 'badge-warning';
      }
    })
  });
  _exports.default = _default;
});