define("account-talentrh/pods/registry/general/branch/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    // HTML Attributes
    tagName: 'li',
    classNames: ['list-group-item'],
    // Default values
    route: 'registry.general.branch.show',
    // Single line computed properties
    model: Ember.computed.alias('branch.id')
  });
  _exports.default = _default;
});